import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Translator, store } from "../../tools/Tools";
import { fetchCustomer, patchCustomerExtraInfos, fetchCustomerEvents } from "../../tools/Api_Customers";
import RichText from "../../components/RichText";
import Card from "../../components/Card";
import moment from "moment";

class CustomerExtra extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.generalInfos.id,
      extraInfos: "",
      edit: false,
    };
  }

  componentDidMount() {
    this.fetchInformations();
  }

  fetchInformations() {
    fetchCustomer(this.state.id).then((cust) => {
      this.setState({ extraInfos: cust.data[0].extraInfos, edit: false });
    });
  }
  patchCustomerExtraInfos() {
    const payload = { id: this.state.id, extraInfos: this.state.extraInfos };
    patchCustomerExtraInfos(payload).then(() => {
      this.fetchInformations();
    });
  }

  onChange(content) {
    this.setState({ extraInfos: content });
  }
  render() {
    moment.locale(store().getLanguage());
    const renderEditButton = () => {
      return (
        <button
          className={`btn`}
          onClick={(e) => {
            e.preventDefault();
            if (this.state.edit) {
              this.patchCustomerExtraInfos();
            } else {
              this.setState((state) => {
                return { edit: !state.edit };
              });
            }
          }}
        >
          {this.state.edit ? <i className="fas far fa-save"> </i> : <i className="fas fa-edit"> </i>}
        </button>
      );
    };
    const renderHtml = (html) => {
      return { __html: html };
    };

    return (
      <Card title={this.props.generalInfos.name} tools={renderEditButton()}>
        {this.state.edit ? (
          <RichText
            title={Translator("extra informations")}
            defaultValue={this.state.extraInfos}
            developped
            onChange={this.onChange.bind(this)}
          ></RichText>
        ) : (
          <div dangerouslySetInnerHTML={renderHtml(this.state.extraInfos)}></div>
        )}
      </Card>
    );

    /* return <RichText title={Translator("extra informations")} defaultValue={this.state.extraInfos}
         developped></RichText> */
  }
}

export default withRouter(CustomerExtra);
