import React, { useEffect, useState } from "react";
import { Translator } from "../../tools/Tools";

const MachineTypeCreationForm = (props) => {
    const [listName, setListName] = useState("")
    const [listDescription, setListDescription] = useState("")
    const [newMachineTypePayload, setNewMachineTypePayload] = useState({ name: null, description: "" })

    useEffect(() => {
        if (props.editingMode && props.selectedMachineType && JSON.stringify(props.selectedMachineType) !== JSON.stringify(newMachineTypePayload) && newMachineTypePayload.name === null) {
            console.log("use effect fot props payload")
            setNewMachineTypePayload(props.selectedMachineType)
            setListName(props.selectedMachineType.name)
            setListDescription(props.selectedMachineType.description)
        }
    })

    const resetForm = () => {
        props.cancelMachineTypeCallback();
        setListName("")
        setListDescription("")
        setNewMachineTypePayload({ name: null, description: "" })
        console.log(listName, listDescription)
    }

    const renderMachineTypeModalFooterButtons = () => {
        return (
            <form className="form-inline col-sm-12">
                <div className="input-group mb-2">
                    <button
                        className="btn btn-info btn mr-2 "
                        onClick={(e) => {
                            e.preventDefault();
                            //create payload to send
                            let machineTypeToSendPayload = newMachineTypePayload
                            props.submitCallback(machineTypeToSendPayload, props.editingMode).then(success => {
                                if (success) {
                                    resetForm();
                                }
                            });
                        }}
                    >
                        {Translator("enregistrer")}
                    </button>
                    <button
                        className="btn btn-info btn "
                        data-dismiss="modal"
                        onClick={(e) => {
                            e.preventDefault();
                            resetForm();
                        }}
                    >
                        {Translator("annuler")}
                    </button>
                </div>
            </form>
        );
    }
    return (
        <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title" id="staticBackdropLabel">
                    {Translator("new machine type ")}
                </h5>
                <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={(e) => {
                        e.preventDefault();
                        resetForm();
                    }}
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <div className={props.creatingMode ? "info-box slideInDown animated" : "info-box"}>
                    <span className="info-box-icon bg-warning">
                        <span className="fa-stack">
                            <i className="fa-solid fa-gears"></i>
                        </span>
                    </span>
                    <div className="table-responsive">
                        <div className="info-box-content">
                            <div className="row my-1">
                                <div className="col-6">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={Translator("title")}
                                        onChange={(e) => {
                                            setListName(e.target.value)
                                            e.target.value.trim().length !== 0 ? (setNewMachineTypePayload({ ...newMachineTypePayload, name: e.target.value })) : (setNewMachineTypePayload({ ...newMachineTypePayload, name: null }));
                                        }}
                                        value={listName}
                                    ></input>
                                </div>
                            </div>
                            <div className="row my-1">
                                <div className="col-11">
                                    <textarea
                                        type="text-area"
                                        className="form-control"
                                        rows="6"
                                        placeholder={Translator("description")}
                                        onChange={(e) => {
                                            setListDescription(e.target.value)
                                            setNewMachineTypePayload({ ...newMachineTypePayload, description: e.target.value })
                                        }}
                                        value={listDescription}
                                    ></textarea>
                                </div>
                            </div>
                        </div>
                    </div >
                    <div className="ribbon-wrapper ribbon-xl">
                        <div className="ribbon bg-success">{Translator("new machine type")}</div>
                    </div>
                </div >
            </div>

            <div className="modal-footer">{renderMachineTypeModalFooterButtons()}</div>
        </div>

    );
};

export default MachineTypeCreationForm;
