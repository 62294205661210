import React, { Component } from "react";
import moment from "moment";
import $ from "jquery";
import { parseTime } from "../tools/Tools";

class TicketButton extends Component {
  constructor(props) {
    super(props);
    this.isDisplayed = true;
    this.state = {
      counter: this.props.ticket.workTime,
    };
    //console.log(this.props.ticket.workTime);
    setInterval(() => {
      if (this.props.ticket.running && this.isDisplayed) {
        this.setState({ counter: this.state.counter + 1 / 60 });
      }
    }, 1000);
  }
  buttonClick() {
    this.props.clickFunction(this.props.ticket);
    //this.setState({ timer: !this.state.timer });
  }
  componentWillUnmount() {
    this.isDisplayed = false;
  }

  render() {
    //if (this.props.ticket.running) console.log("tb: " + this.state.counter);
    const momentString = parseTime(this.state.counter);
    $(function () {
      $(".btn").tooltip({
        html: true,
        placement: "bottom",
      });
    });
    const internalStatuses = ["low_priority", "priority", "asap"];

    const translatePriority = (priority) => {
      switch (priority) {
        case "yellow":
        case "low_priority":
          return "warning";
        case "priority":
        case "orange":
          return "orange";
        case "asap":
        case "red":
          return "danger";
      }
    };
    return (
      <button
        type="button"
        className={`btn btn-block bg-gradient-` + translatePriority(this.props.ticket.priority) + ` m-1`}
        onClick={this.buttonClick.bind(this)}
        rel="tooltip"
        data-html="true"
        data-placement="bottom"
        title={this.props.ticket.customer + "<br>" + this.props.ticket.description}
        style={{
          width: this.props.ticket.running ? "14rem" : "10rem",
          backgroundColor: this.props.ticket.priority,
          border: internalStatuses.includes(this.props.ticket.priority) ? "solid" : "none",
          borderColor: "cyan",
        }}
      >
        <div className="row align-items-center text-dark">
          {this.props.ticket.running ? (
            <span className="col-2 fa-stack " style={{ verticalAlign: "top" }}>
              <i className="fas fa-pause fa-stack-1x"></i>
              <i className="fas fa-circle-notch fa-spin fa-stack-2x"></i>
            </span>
          ) : (
            <i className="col-2 fas fa-play"></i>
          )}
          <div className={this.props.ticket.running ? "col-4" : "col-7"}>
            <small className="row justify-content-center my-n1">{this.props.ticket.number}</small>
            <small className="row my-n1 text-truncate">{this.props.ticket.customer}</small>
          </div>
          {this.props.ticket.running ? <div className="col-3">{momentString}</div> : null}{" "}
          {this.props.ticket.assignee ? (
            <img
              src={`/assets/dist/img/` + this.props.ticket.assignee + `160x160.jpg`}
              className={`img-circle ` + ((this.props.ticket.running ? `ml-4` : `ml-2`) + ` mr-n1`)}
              alt="User"
              style={{ width: "2rem", border: "solid", borderColor: "#444444" }}
            />
          ) : (
            <i className="far fa-hand-paper fa-2x ml-1"></i>
          )}{" "}
        </div>
      </button>
    );
  }
}

export default TicketButton;
