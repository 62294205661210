import React, { useState } from 'react';

import CreatableSelect from 'react-select/creatable';
import { Translator } from '../tools/Tools';
import { v4 as uuidv4 } from 'uuid';


const MultiCreateSelect = (props) => {
    const [inputValue, setInputValue] = useState('')
    const [value, setValue] = useState([])

    const components = {
        DropdownIndicator: null,
    };

    const createOption = (label) => ({
        label,
        value: uuidv4(),
    });

    const handleChange = (value, actionMeta) => {
        props.exposeValues(value);
        setValue(value)
    }
    const handleInputChange = (inputValue) => {
        setInputValue(inputValue)
    }

    const handleKeyDown = (event) => {
        if (!inputValue) return;
        switch (event.key) {
            case 'Enter':
            case 'Tab':
                setInputValue('')
                if (props.values.find(val => val.label === inputValue)) {
                    console.error(inputValue + " already exist")
                }
                else {
                    props.exposeValues([...props.values, createOption(inputValue)]);
                    setValue([...props.values, createOption(inputValue)])
                    console.group('Value Added');
                    console.log(inputValue);
                    console.groupEnd();
                }
                event.preventDefault();
        }
    };


    return (
        <CreatableSelect
            id="toFocusComponent"
            components={components}
            inputValue={inputValue}
            isClearable
            isMulti
            menuIsOpen={false}
            onChange={handleChange}
            onInputChange={handleInputChange}
            onKeyDown={handleKeyDown}
            placeholder={Translator("type in possible entries, confirm each by pressing enter or tab")}
            value={props.values}
        />
    )
}

export default MultiCreateSelect