import React, { useEffect, useState } from "react";
import { filterOptions, store, Translator } from "../../tools/Tools";
import Select from "react-select";
import Creatable from "react-select/creatable";
import RichText from "../../components/RichText";
import Card from "../../components/Card";
import BSTable from "../../components/BSTable";
import { set } from "date-fns";

const ChecklistModelCreationForm = (props) => {
    const [newModelPayload, setNewModelPayload] = useState({ Modules: [], ChecklistModelModules: [] })
    const [selectedChecklistType, setSelectedChecklistType] = useState(null)
    const [selectedModules, setSelectedModules] = useState([])
    const [selectedModule, setSelectedModule] = useState(null)
    const [triggerRenderValue, setTriggerRenderValue] = useState(true)
    const [sortingChanged, setSortingChanged] = useState(false)
    const [showDebugPayload, setShowDebugPayload] = useState(false)

    useEffect(() => {
        if (props.payload && JSON.stringify(props.payload) !== JSON.stringify(newModelPayload) && !$('#modal-model').hasClass('show')) {
            console.log("use effect for props payload")
            console.log(props.payload)
            setNewModelPayload({
                ...props.payload, Modules: props.payload.Modules.sort((a, b) => {
                    if (a.sortingOrder < b.sortingOrder) {
                        return -1
                    }
                    if (a.sortingOrder > b.sortingOrder) {
                        return 1
                    }
                    return 0
                })
            })
            const type = props.checklistTypes.find(type => type.id === props.payload.ChecklistTypeId) || { id: "", name: "" }
            setSelectedChecklistType({ value: type.id, label: type.name })
            setSelectedModules(props.payload.Modules.map(module => { return { value: module.id, label: module.name } }))
        }
    })

    const renderModelModalFooterButtons = () => {

        return (
            <form className="form-inline col-sm-12">
                <div className="input-group mb-2">
                    <button
                        className="btn btn-info btn mr-2 "
                        onClick={(e) => {
                            e.preventDefault();
                            let toSendPayload = {}
                            toSendPayload.id = newModelPayload.id
                            toSendPayload.name = newModelPayload.name
                            toSendPayload.serial = newModelPayload.serial
                            toSendPayload.ChecklistTypeId = newModelPayload.ChecklistTypeId
                            toSendPayload.ChecklistModelModules = newModelPayload.Modules.map(module => { return { id: module.id, sortingOrder: module.sortingOrder } })
                            console.log(JSON.stringify(toSendPayload))
                            props.submitCallback(toSendPayload, props.editingMode).then(success => {
                                console.log("success !!", success)
                                if (success) {
                                    resetForm();
                                }
                            });

                        }}
                        disabled={!newModelPayload.name || newModelPayload.Modules.length === 0 || !selectedChecklistType}
                    >
                        {props.creatingMode ? Translator("save") : Translator("update")}
                    </button>
                    <button
                        className="btn btn-info btn "
                        data-dismiss="modal"
                        onClick={(e) => {
                            e.preventDefault();
                            resetForm()
                        }}
                    >
                        {Translator("annuler")}
                    </button>
                </div>
            </form >
        );

    }

    const resetForm = () => {
        console.log("resetting form")
        setNewModelPayload({ Modules: [] })
        setSelectedModule(null)
        setSelectedChecklistType(null)
        setSelectedModules([])
        props.cancelModelCreation();
    }
    return (
        <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title" id="staticBackdropLabel">
                    {Translator("new model ")}
                </h5>
                <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={(e) => {
                        e.preventDefault();
                        resetForm();
                    }}
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <div className={props.creatingMode ? "info-box slideInDown animated" : "info-box"}>
                    <span className="info-box-icon bg-info">
                        <span className="fa-stack">
                            <i className="fa-solid fa-rectangle-list"></i>
                        </span>
                    </span>
                    <div className="table-responsive">
                        <div className="info-box-content">
                            <div className="row my-1">
                                <div className="col-6">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={Translator("title")}
                                        onChange={(e) => {
                                            const tmpNewModelPayload = { ...newModelPayload, name: e.target.value.trim().length !== 0 ? e.target.value : null }
                                            setNewModelPayload(tmpNewModelPayload)
                                        }}
                                        value={newModelPayload.name || ""}
                                    ></input>
                                </div>
                            </div>
                            <div className="row my-1">
                                <div className="col-6">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={Translator("serial")}
                                        onChange={(e) => {
                                            const tmpNewModelPayload = { ...newModelPayload, serial: e.target.value.trim().length !== 0 ? e.target.value : null }
                                            setNewModelPayload(tmpNewModelPayload)
                                        }}
                                        value={newModelPayload.serial || ""}
                                    ></input>
                                </div>
                            </div>
                            <hr></hr>
                            <div className="row">
                                <Select
                                    className="col-6"
                                    isClearable
                                    noOptionsMessage={() => {
                                        return <label>{Translator("none")}</label>;
                                    }}
                                    placeholder={Translator("checklist type")}
                                    onChange={(selectedOption) => {
                                        if (selectedOption) {
                                            let tmpNewModelPayload = newModelPayload
                                            tmpNewModelPayload.ChecklistTypeId = selectedOption.value
                                            tmpNewModelPayload.checklistType = selectedOption
                                            setNewModelPayload(tmpNewModelPayload)
                                            setSelectedChecklistType(selectedOption)
                                        } else {
                                            let tmpNewModelPayload = newModelPayload
                                            tmpNewModelPayload.ChecklistTypeId = null
                                            tmpNewModelPayload.checklistType = {}
                                            setNewModelPayload(tmpNewModelPayload)
                                            setSelectedChecklistType(selectedOption)
                                        }
                                    }}
                                    options={props.checklistTypes.map(type => { return { value: type.id, label: type.name } })}
                                    value={selectedChecklistType}
                                    menuPortalTarget={document.body}
                                    isSearchable
                                    filterOption={filterOptions}
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                    isDisabled={selectedModules.length > 0}
                                />
                                <i className="fa-solid fa-list pt-2"></i>
                            </div>
                            <hr></hr>
                            <div className="row">
                                <table className="module-table col">
                                    <thead>
                                        <tr>
                                            <th className="module-table  pl-2" colSpan={3}>{Translator("module")}<i className="fa-solid fa-list-ol"></i></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {newModelPayload.Modules.map(module => {
                                            {
                                                //console.log(module)
                                                //On last iteration, set selected value to null to trigger render (because somehow the render does not trigger by itself in this case)
                                                if (newModelPayload.Modules.indexOf(module) + 1 === newModelPayload.Modules.length && selectedModule !== null) {
                                                    // setSelectedModule(null)
                                                }
                                                return (<tr className="module-table" key={module.id}>
                                                    <td className="module-table pl-2" style={{ width: '50%' }}>
                                                        {(props.modules.find(mod => mod.id === module.id) || { name: "not found" }).name}
                                                    </td>
                                                    <td>
                                                        <div className="input-group px-5">
                                                            <div className="input-group-prepend">
                                                                <button className="input-group-text" onClick={() => {
                                                                    let tmpNewModelPayload = newModelPayload
                                                                    tmpNewModelPayload.Modules.find(el => el.id === module.id).sortingOrder = tmpNewModelPayload.Modules.find(el => el.id === module.id).sortingOrder - 10
                                                                    const beforeSort = JSON.stringify(tmpNewModelPayload.Modules)
                                                                    const toSort = JSON.parse(beforeSort)
                                                                    toSort.sort((moA, moB) => moA.sortingOrder > moB.sortingOrder)
                                                                    const afterSort = JSON.stringify(toSort)
                                                                    if (beforeSort !== afterSort) setSortingChanged(true)

                                                                    setNewModelPayload(tmpNewModelPayload)
                                                                    setTriggerRenderValue(!triggerRenderValue)
                                                                }}>-</button>
                                                            </div>
                                                            <input id="order" type="text" className="form-control" style={{ textAlign: "center" }} value={newModelPayload.Modules.find(el => el.id === module.id).sortingOrder} onChange={(e) => {
                                                                const parsed = parseInt(e.target.value)
                                                                if (!isNaN(parsed)) {
                                                                    let tmpNewModelPayload = newModelPayload
                                                                    tmpNewModelPayload.Modules.find(el => el.id === module.id).sortingOrder = parsed
                                                                    const beforeSort = JSON.stringify(tmpNewModelPayload.Modules)
                                                                    const toSort = JSON.parse(beforeSort)
                                                                    toSort.sort((moA, moB) => moA.sortingOrder > moB.sortingOrder)
                                                                    const afterSort = JSON.stringify(toSort)
                                                                    if (beforeSort !== afterSort) setSortingChanged(true)

                                                                    setNewModelPayload(tmpNewModelPayload)
                                                                    setTriggerRenderValue(!triggerRenderValue)

                                                                }
                                                            }}></input>
                                                            <div className="input-group-append">
                                                                <button className="input-group-text" onClick={() => {
                                                                    let tmpNewModelPayload = newModelPayload
                                                                    tmpNewModelPayload.Modules.find(el => el.id === module.id).sortingOrder = tmpNewModelPayload.Modules.find(el => el.id === module.id).sortingOrder + 10
                                                                    const beforeSort = JSON.stringify(tmpNewModelPayload.Modules)
                                                                    const toSort = JSON.parse(beforeSort)
                                                                    toSort.sort((moA, moB) => moA.sortingOrder > moB.sortingOrder)
                                                                    const afterSort = JSON.stringify(toSort)
                                                                    if (beforeSort !== afterSort) setSortingChanged(true)

                                                                    setNewModelPayload(tmpNewModelPayload)
                                                                    setTriggerRenderValue(!triggerRenderValue)

                                                                }}>+</button>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td><button className="btn btn-default px-5" onClick={() => {
                                                        let tmpNewModelPayload = newModelPayload
                                                        tmpNewModelPayload.Modules = tmpNewModelPayload.Modules.filter(mod => mod.id !== module.id)
                                                        const newModuleList = selectedModules.filter(mod => mod.value !== module.id)
                                                        setNewModelPayload(tmpNewModelPayload)
                                                        setSelectedModules(newModuleList)
                                                    }} ><i className="fa-solid fa-trash-can"></i></button></td></tr>)
                                            }
                                        })}
                                        <tr><td colSpan={selectedModules.length === 0 ? 3 : 1}><Select
                                            isClearable
                                            noOptionsMessage={() => {
                                                return <label>{Translator("none")}</label>;
                                            }}
                                            placeholder={Translator("add new module...")}
                                            onChange={(selectedOption) => {
                                                if (selectedOption) {
                                                    console.log(selectedOption)
                                                    let tmpNewModelPayload = newModelPayload
                                                    let tmpSelectedOption = { ...selectedOption, sortingOrder: props.modules.find(el => el.id === selectedOption.value).sortingOrder }
                                                    tmpNewModelPayload.Modules = [
                                                        ...tmpNewModelPayload.Modules, { id: tmpSelectedOption.value, name: tmpSelectedOption.label, sortingOrder: tmpSelectedOption.sortingOrder }
                                                    ]
                                                    const beforeSort = JSON.stringify(tmpNewModelPayload.Modules)
                                                    const toSort = JSON.parse(beforeSort)
                                                    toSort.sort((moA, moB) => moA.sortingOrder > moB.sortingOrder)
                                                    const afterSort = JSON.stringify(toSort)
                                                    if (beforeSort !== afterSort) setSortingChanged(true)

                                                    setNewModelPayload(tmpNewModelPayload)
                                                    setSelectedModules([...selectedModules, tmpSelectedOption])
                                                    setSelectedModule(null)
                                                }
                                                //setTriggerRender(!triggerRender)
                                            }}
                                            options={props.modules.filter(mod => {
                                                if (!selectedChecklistType) return false
                                                return mod.ChecklistTypes.find(ty => ty.id === selectedChecklistType.value)
                                            }).map(type => {
                                                return { value: type.id, label: type.name }
                                            }).filter(module => !selectedModules.find(stateModule => stateModule.value === module.value))}
                                            value={selectedModule}
                                            menuPortalTarget={document.body}
                                            isSearchable
                                            filterOption={filterOptions}
                                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                        /></td>
                                            {selectedModules.length === 0 ? null : <td><button className="btn btn-default"
                                                onClick={e => {
                                                    let tmpNewModelPayload = newModelPayload
                                                    tmpNewModelPayload.Modules.sort((moA, moB) => moA.sortingOrder > moB.sortingOrder)
                                                    setNewModelPayload(tmpNewModelPayload)
                                                    setSortingChanged(false)
                                                    setTriggerRenderValue(!triggerRenderValue)
                                                }}
                                                disabled={!sortingChanged}>{Translator("sort on sorting number")}</button>
                                            </td>}</tr>
                                    </tbody>
                                </table>
                            </div>
                            <hr></hr>
                            <button className="btn bg-gradient-secondary" onClick={e => {
                                setShowDebugPayload(!showDebugPayload)
                            }}>Debug</button>
                            {showDebugPayload ? <div><h2>debug payload preview</h2>
                                {JSON.stringify(newModelPayload)}</div> : null}
                        </div>
                    </div >
                    <div className="ribbon-wrapper ribbon-xl">
                        <div className="ribbon bg-success">{Translator("new model")}</div>
                    </div>
                </div >
            </div >
            <div className="modal-footer">{renderModelModalFooterButtons()}</div>
        </div >
    );
};

export default ChecklistModelCreationForm;
