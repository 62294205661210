import toastr from "toastr";
import { store, Translator } from "./Tools";

export const fetchParameters = () => {
  return fetch("/api/getParameters/", {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then((rawResponse) => {
    if (rawResponse.ok) {
      return rawResponse.json();
    } else {
      toastr.options = {
        progressBar: true,
        timeOut: "5000",
      };
      console.log(rawResponse);
      rawResponse.json().then((body) => {
        console.log(body);
        toastr.error(body);
      });
    }
  });
};
export const patchParameter = (param) => {
  return fetch("/api/patchParameter/", {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(param),
  }).then((rawResponse) => {
    if (rawResponse.ok) {
      return rawResponse.json();
    } else {
      toastr.options = {
        progressBar: true,
        timeOut: "5000",
      };
      console.log(rawResponse);
      rawResponse.json().then((body) => {
        toastr.error(body);
      });
    }
  });
};

export const fetchContacts = async (customerId) => {
  const response = await fetch("/api/getCustomerContacts/" + customerId);
  if (response.ok) {
    return response.json().then((result) => {
      //console.log(result);
      let toReturn = {
        columns: [],
        data: [],
      };
      if (result.length > 0) {
        const columns = Object.keys(result[0]).map((key) => {
          return { dataField: key };
        });
        toReturn = {
          columns: columns,
          data: result,
        };
      }
      return toReturn;
    });
  } else {
    toastr.options = {
      progressBar: true,
    };
    toastr.error(response.statusText);
  }
};
export const searchContact = async (name) => {
  const response = await fetch("/api/searchContact/" + name);
  if (response.ok) {
    console.log(response);
    return response.json().then((result) => {
      console.log(result);
      let toReturn = {
        columns: [],
        data: [],
      };
      if (result.length > 0) {
        const columns = Object.keys(result[0]).map((key) => {
          return { dataField: key };
        });
        toReturn = {
          columns: columns,
          data: result,
        };
      }
      return toReturn;
    });
  } else {
    toastr.options = {
      progressBar: true,
    };
    toastr.error(response.statusText);
  }
};
export const fetchConveyors = () => {
  return fetch("/api/getConveyors").then((response) => {
    if (response.ok) {
      return response.json().then((result) => result);
    } else {
      toastr.options = {
        progressBar: true,
        timeOut: "5000",
      };
      console.log(response);
      response.json().then((body) => {
        console.log(body);
        toastr.error(body.errors[0].value + " " + body.errors[0].validatorKey, response.statusText);
      });
    }
  });
};
export const fetchCustomerConveyors = (customerId) => {
  return fetch("/api/getCustomerConveyors/" + customerId).then((response) => {
    if (response.ok) {
      return response.json().then((result) => result);
    } else {
      toastr.options = {
        progressBar: true,
        timeOut: "5000",
      };
      console.log(response);
      response.json().then((body) => {
        console.log(body);
        toastr.error(body.errors[0].value + " " + body.errors[0].validatorKey, response.statusText);
      });
    }
  });
};

export const postSite = (customerId, site) => {
  return fetch("/api/customer/" + customerId + "/newSite/", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(site),
  }).then((rawResponse) => {
    if (rawResponse.ok) {
      return rawResponse.json();
    } else {
      toastr.options = {
        progressBar: true,
        timeOut: "5000",
      };
      console.log(rawResponse);
      rawResponse.json().then((body) => {
        console.log(body);
        toastr.error(body.errors[0].value + " " + body.errors[0].validatorKey, rawResponse.statusText);
      });
    }
  });
};
export const postConveyor = (siteId, conveyor) => {
  return fetch("/api/site/" + siteId + "/newConveyor/", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(conveyor),
  }).then((rawResponse) => {
    if (rawResponse.ok) {
      return rawResponse.json();
    } else {
      toastr.options = {
        progressBar: true,
        timeOut: "5000",
      };
      console.log(rawResponse);
      rawResponse.json().then((body) => {
        console.log(body);
        toastr.error(body.errors[0].value + " " + body.errors[0].validatorKey, rawResponse.statusText);
      });
    }
  });
};
export const postSpecifications = (conveyorId, specification) => {
  return fetch("/api/conveyor/" + conveyorId + "/setSpecifications/", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(specification),
  }).then((rawResponse) => {
    if (rawResponse.ok) {
      return rawResponse.json();
    } else {
      toastr.options = {
        progressBar: true,
        timeOut: "5000",
      };
      console.log(rawResponse);
      rawResponse.json().then((body) => {
        console.log(body);
        toastr.error(body.errors[0].value + " " + body.errors[0].validatorKey, rawResponse.statusText);
      });
    }
  });
};
export const deleteConveyor = (conveyorId) => {
  return fetch("/api/deleteConveyor/" + conveyorId, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.ok) {
      response.json().then((result) => result);
    } else {
      toastr.options = {
        progressBar: true,
        timeOut: "5000",
      };
      console.log(response);

      switch (response.satus) {
        case 400:
          response.json().then((body) => {
            console.log(body);
            toastr.error(body.errors[0].value + " " + body.errors[0].validatorKey, response.statusText);
          });
          break;
        default:
          toastr.error(response.status, response.statusText);
      }
    }
  });
};
export const deleteCustomers = (customerIds) => {
  return fetch("/api/deleteCustomer/" + customerIds, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then((rawResponse) => {
    if (rawResponse.ok) {
      return;
    } else {
      toastr.options = {
        progressBar: true,
        timeOut: "5000",
      };
      console.log(rawResponse);
      rawResponse.json().then((body) => {
        console.log(body);
        toastr.error(body.errors[0].value + " " + body.errors[0].validatorKey, rawResponse.statusText);
      });
    }
  });
};
export const fetchCustomers = () => {
  return fetch("/api/getCustomers").then((response) => {
    if (response.ok) {
      return response.json().then((result) => {
        if (result.length > 0) {
          const columns = Object.keys(result[0]).map((key) => {
            return { dataField: key };
          });
          const toReturn = {
            columns: columns,
            data: result,
          };
          return toReturn;
        }
      });
    } else {
      toastr.options = {
        progressBar: true,
        timeOut: "5000",
      };
      console.log(response);
      response.json().then((body) => {
        console.log(body);
        try {
          toastr.error(body.errors[0].value + " " + body.errors[0].validatorKey, response.statusText);
        } catch (error) { }
        try {
          toastr.error(body.original.message, response.statusText);
        } catch (error) { }
      });
      const toReturn = {
        columns: [{ dataField: "none" }],
        data: [],
      };
      return toReturn;
    }
  });
};
export const fetchSuppliers = () => {
  return fetch("/api/getSuppliers").then((response) => {
    if (response.ok) {
      console.log(response);
      return response.json().then((result) => {
        console.log(result);
        if (result.length > 0) {
          const columns = Object.keys(result[0]).map((key) => {
            return { dataField: key };
          });
          const toReturn = {
            columns: columns,
            data: result,
          };
          return toReturn;
        } else {
          return {
            columns: [],
            data: [],
          };
        }
      });
    } else {
      toastr.options = {
        progressBar: true,
        timeOut: "5000",
      };
      console.log(response);
      response.json().then((body) => {
        console.log(body);
        try {
          toastr.error(body.errors[0].value + " " + body.errors[0].validatorKey, response.statusText);
        } catch (error) { }
        try {
          toastr.error(body.original.message, response.statusText);
        } catch (error) { }
      });
      const toReturn = {
        columns: [{ dataField: "none" }],
        data: [],
      };
      return toReturn;
    }
  });
};
export const fetchCustomer = (CustomerId) => {
  return fetch("/api/getCustomerFullInfo/" + CustomerId).then((response) => {
    console.log(response.url);
    if (response.ok) {
      return response.json().then((result) => {
        if (result.length > 0) {
          const columns = Object.keys(result[0]).map((key) => {
            return { dataField: key };
          });
          const toReturn = {
            columns: columns,
            data: result,
          };
          return toReturn;
        }
      });
    } else {
      toastr.options = {
        progressBar: true,
        timeOut: "5000",
      };
      console.log(response);
      response.json().then((body) => {
        console.log(body);
        toastr.error(body.errors[0].value + " " + body.errors[0].validatorKey, response.statusText);
      });
    }
  });
};

export const patchCustomerExtraInfos = (Customer) => {
  return fetch("/api/patchCustomerExtraInfos/", {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(Customer),
  }).then((rawResponse) => {
    if (rawResponse.ok) {
      return rawResponse.json();
    } else {
      toastr.options = {
        progressBar: true,
        timeOut: "5000",
      };
      console.log(rawResponse);
      rawResponse.json().then((body) => {
        console.log(body);
        toastr.error(body.errors[0].value + " " + body.errors[0].validatorKey, rawResponse.statusText);
      });
    }
  });
};

export const patchCustomerConnectivityInfos = (infos) => {
  return fetch("/api/patchCustomerRemoteConnectivity/" + infos.id, {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(infos.infos),
  }).then((rawResponse) => {
    console.log(rawResponse.url);
    if (rawResponse.ok) {
      return rawResponse.json();
    } else {
      toastr.options = {
        progressBar: true,
        timeOut: "5000",
      };
      console.log(rawResponse);
      rawResponse.json().then((body) => {
        console.log(body);
        toastr.error(body.errors[0].value + " " + body.errors[0].validatorKey, rawResponse.statusText);
      });
    }
  });
};

export const deleteCustomerConnectivityInfos = (infos) => {
  return fetch("/api/deleteCustomerRemoteConnectivity/" + infos.id, {
    method: "delete",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(infos.infos),
  }).then((rawResponse) => {
    console.log(rawResponse.url);

    if (rawResponse.ok) {
      return rawResponse.json();
    } else {
      toastr.options = {
        progressBar: true,
        timeOut: "5000",
      };
      console.log(rawResponse);
      rawResponse.json().then((body) => {
        console.log(body);
        toastr.error(body.errors[0].value + " " + body.errors[0].validatorKey, rawResponse.statusText);
      });
    }
  });
};

export const fetchUsers = () => {
  return fetch("/api/getUsers").then((response) => {
    if (response.ok) {
      return response.json().then((result) => {
        if (result.length > 0) {
          const columns = Object.keys(result[0]).map((key) => {
            return { dataField: key };
          });
          const toReturn = {
            columns: columns,
            data: result,
          };
          return toReturn;
        }
      });
    } else {
      toastr.options = {
        progressBar: true,
        timeOut: "5000",
      };
      console.log(response);
      response.json().then((body) => {
        console.log(body);
        toastr.error(body.errors[0].value + " " + body.errors[0].validatorKey, response.statusText);
      });
    }
  });
};

export const patchUser = (user) => {
  return fetch("/api/patchUser/" + user.id, {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(user),
  }).then((rawResponse) => {
    if (rawResponse.ok) {
      return rawResponse.json();
    } else {
      toastr.options = {
        progressBar: true,
        timeOut: "5000",
      };
      console.log(rawResponse);
      rawResponse.json().then((body) => {
        console.log(body);
        toastr.error(body.errors[0].value + " " + body.errors[0].validatorKey, rawResponse.statusText);
      });
    }
  });
};

export const postNewCustomer = (newCustomer) => {
  console.log(newCustomer);
  return fetch("/api/newCustomer", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(newCustomer),
  }).then((rawResponse) => {
    if (rawResponse.ok) {
      return rawResponse.json();
    } else {
      toastr.options = {
        progressBar: true,
        timeOut: "5000",
      };
      console.log(rawResponse);
      rawResponse.json().then((body) => {
        console.log(body);
        toastr.error(body.errors[0].value + " " + body.errors[0].validatorKey, rawResponse.statusText);
      });
    }
  });
};
export const patchCustomer = (patchedCustomer) => {
  return fetch("/api/patchCustomer/", {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(patchedCustomer),
  }).then((rawResponse) => {
    console.log(rawResponse);
    if (rawResponse.ok) {
      return rawResponse;
    } else {
      toastr.options = {
        progressBar: true,
        timeOut: "5000",
      };
      console.log(rawResponse);
      rawResponse.json().then((body) => {
        console.log(body);
        toastr.error(body.errors[0].value + " " + body.errors[0].validatorKey, rawResponse.statusText);
      });
    }
  });
};
export const addRelatedCustomers = (mainCustomer, relatedCustomers) => {
  return fetch("/api/addRelatedCustomer/" + mainCustomer, {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(relatedCustomers),
  }).then((rawResponse) => {
    console.log(rawResponse);
    if (rawResponse.ok) {
      return rawResponse;
    } else {
      toastr.options = {
        progressBar: true,
        timeOut: "5000",
      };
      console.log(rawResponse);
      rawResponse.json().then((body) => {
        console.log(body);
        toastr.error(body.errors[0].value + " " + body.errors[0].validatorKey, rawResponse.statusText);
      });
    }
  });
};

export const removeRelatedCustomers = (mainCustomer, relatedCustomers) => {
  return fetch("/api/removeRelatedCustomer/" + mainCustomer, {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(relatedCustomers),
  }).then((rawResponse) => {
    console.log(rawResponse);
    if (rawResponse.ok) {
      return rawResponse;
    } else {
      toastr.options = {
        progressBar: true,
        timeOut: "5000",
      };
      console.log(rawResponse);
      rawResponse.json().then((body) => {
        console.log(body);
        toastr.error(body.errors[0].value + " " + body.errors[0].validatorKey, rawResponse.statusText);
      });
    }
  });
};

export const patchSite = (patchedSite) => {
  return fetch("/api/patchSite/" + patchedSite.id, {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(patchedSite),
  }).then((rawResponse) => {
    console.log(rawResponse);
    if (rawResponse.ok) {
      return rawResponse;
    } else {
      toastr.options = {
        progressBar: true,
        timeOut: "5000",
      };
      rawResponse.json().then(
        (body) => {
          console.log(body);
          toastr.error(body.errors[0].value + " " + body.errors[0].validatorKey, rawResponse.statusText);
        },
        (reject) => {
          toastr.error(rawResponse.status, rawResponse.statusText);
        }
      );
    }
  });
};
export const postNewContact = (customerId, newContact) => {
  return fetch("/api/newCustomerContact/" + customerId, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(newContact),
  }).then((rawResponse) => {
    if (rawResponse.ok) {
      return rawResponse.json();
    } else {
      toastr.options = {
        progressBar: true,
        timeOut: "5000",
      };
      toastr.error(rawResponse.statusText);
    }
  });
};

export const fetchLogbooks = async (customerId) => {
  const response = await fetch("/api/getCustomerLogbooks/" + customerId);
  if (response.ok) {
    return response.json().then((result) => {
      let toReturn = {
        columns: [],
        data: [],
      };
      if (result.length > 0) {
        const columns = Object.keys(result[0]).map((key) => {
          return { dataField: key };
        });
        toReturn = {
          columns: columns,
          data: result,
        };
      }
      return toReturn;
    });
  } else {
    toastr.options = {
      progressBar: true,
    };
    toastr.error(response.statusText);
  }
};
export const postNewLogbook = (customerId, newLogbook) => {
  return fetch("/api/newCustomerLogbook/" + customerId, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(newLogbook),
  }).then((rawResponse) => {
    console.log(rawResponse);
    if (rawResponse.ok) {
      return rawResponse.json();
    } else {
      toastr.options = {
        progressBar: true,
        timeOut: "5000",
      };
      toastr.error(rawResponse.statusText);
    }
  });
};

export const postLogin = (credentials) => {
  return fetch("/api/login/", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(credentials),
  }).then((rawResponse) => {
    //console.log(rawResponse);
    if (rawResponse.ok) {
      return rawResponse;
    } else {
      toastr.options = {
        progressBar: true,
        timeOut: "5000",
      };
      toastr.error(rawResponse.statusText);
      return rawResponse;
    }
  });
};
export const postEvent = (event, noWE) => {
  return fetch("/api/newEvent/" + noWE, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(event),
  }).then((rawResponse) => {
    if (rawResponse.ok) {
      toastr.options = {
        positionClass: "toast-top-full-width",
        showDuration: "300",
        hideDuration: "1000",
        timeOut: "5000",
      };
      return rawResponse.json().then((result) => {
        toastr.success(Translator("event successfully saved"));
        return result;
      });
    } else {
      toastr.options = {
        progressBar: true,
        timeOut: "5000",
      };
      rawResponse.text().then((txt) => {
        toastr.error(txt, rawResponse.statusText);
      });
      return rawResponse;
    }
  });
};

export const editUserEvent = (userEvent) => {
  return fetch("/api/editUserEvent/" + userEvent.userEventId, {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(userEvent),
  }).then((rawResponse) => {
    if (rawResponse.ok) {
      return rawResponse;
    } else {
      toastr.options = {
        progressBar: true,
        timeOut: "5000",
      };
      toastr.error(rawResponse.statusText);
      return rawResponse;
    }
  });
};

export const acceptEvent = (id) => {
  return fetch("/api/acceptRequestedEvent/" + id, {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then((rawResponse) => {
    if (rawResponse.ok) {
      return rawResponse;
    } else {
      toastr.options = {
        progressBar: true,
        timeOut: "5000",
      };
      toastr.error(rawResponse.statusText);
      return rawResponse;
    }
  });
};

export const editEvent = (event) => {
  return fetch("/api/editEvent/" + event.eventId, {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(event),
  }).then((rawResponse) => {
    if (rawResponse.ok) {
      return rawResponse;
    } else {
      toastr.options = {
        progressBar: true,
        timeOut: "5000",
      };
      toastr.error(rawResponse.statusText);
      return rawResponse;
    }
  });
};

export const lockEvents = (from, to, lock) => {
  return fetch("/api/lockEvents/" + from + "/" + to + "/" + lock, {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then((rawResponse) => {
    if (rawResponse.ok) {
      return rawResponse;
    } else {
      toastr.options = {
        progressBar: true,
        timeOut: "5000",
      };
      toastr.error(rawResponse.statusText);
      return rawResponse;
    }
  });
};

export const deleteUserEvent = (userEventId) => {
  console.log("deleting user event " + userEventId);
  return fetch("/api/deleteUserEvent/" + userEventId, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then((rawResponse) => {
    if (rawResponse.ok) {
      return rawResponse;
    } else {
      toastr.options = {
        progressBar: true,
        timeOut: "5000",
      };
      toastr.error(rawResponse.statusText);
      return rawResponse;
    }
  });
};

export const deleteEvent = (eventId) => {
  console.log("deleting user event " + eventId);
  return fetch("/api/deleteEvent/" + eventId, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      UserId: store().getUser().userId,
    },
  }).then((rawResponse) => {
    if (rawResponse.ok) {
      return rawResponse;
    } else {
      toastr.options = {
        progressBar: true,
        timeOut: "5000",
      };
      toastr.error(rawResponse.statusText);
      return rawResponse;
    }
  });
};
export const deleteContact = (contactIds) => {
  return fetch("/api/deleteCustomerContact/" + contactIds, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then((rawResponse) => {
    if (rawResponse.ok) {
      return rawResponse;
    } else {
      toastr.options = {
        progressBar: true,
        timeOut: "5000",
      };
      toastr.error(rawResponse.statusText);
      return rawResponse;
    }
  });
};
export const fetchTodos = () => {
  return fetch("/api/getAllTodos/").then((response) => {
    if (response.ok) {
      return response.json().then((result) => {
        return result;
      });
    } else {
      toastr.options = {
        progressBar: true,
        timeOut: "5000",
      };
      response.json().then((body) => {
        console.log(body);
        toastr.error(body.status + " " + body.statusText);
      });
    }
  });
};
export const fetchCustomerTodo = (customerId) => {
  return fetch("/api/getCustomerTodos/" + customerId).then((response) => {
    if (response.ok) {
      return response.json().then((result) => {
        return result;
      });
    } else {
      toastr.options = {
        progressBar: true,
        timeOut: "5000",
      };
      response.json().then((body) => {
        console.log(body);
        toastr.error(body.status + " " + body.statusText);
      });
    }
  });
};
export const fetchUserTodo = (userId) => {
  return fetch("/api/getUserTodos/" + userId).then((response) => {
    if (response.ok) {
      return response.json().then((result) => {
        return result;
      });
    } else {
      toastr.options = {
        progressBar: true,
        timeOut: "5000",
      };
      response.json().then((body) => {
        console.log(body);
        toastr.error(body.status + " " + body.statusText);
      });
    }
  });
};

export const postCustomerTodo = (todo) => {
  const customerId = todo.CustomerId ? todo.CustomerId : todo.Customer;
  return fetch("/api/newCustomerTodo/" + customerId, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(todo),
  }).then((rawResponse) => {
    if (rawResponse.ok) {
      return rawResponse;
    } else {
      toastr.options = {
        progressBar: true,
        timeOut: "5000",
      };
      toastr.error(rawResponse.statusText);
      return rawResponse;
    }
  });
};
export const patchTodo = (todo) => {
  console.log("patchingTodo");
  return fetch("/api/editTodo/" + todo.id, {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(todo),
  }).then((rawResponse) => {
    if (rawResponse.ok) {
      return rawResponse;
    } else {
      toastr.options = {
        progressBar: true,
        timeOut: "5000",
      };
      toastr.error(rawResponse.statusText);
      return rawResponse;
    }
  });
};

export const fetchEvents = (from, to, users) => {
  let userResult = "";
  users.forEach((user) => {
    userResult = userResult === "" ? user : userResult + "," + user;
  });
  return fetch("/api/getEvents/" + from + "/" + to + "/" + userResult).then((response) => {
    //console.log(response)
    if (response.ok) {
      toastr.options = {
        positionClass: "toast-top-full-width",
        showDuration: "300",
        hideDuration: "1000",
        timeOut: "2000",
      };
      return response.json().then((result) => {
        toastr.success(Translator("event loaded"));
        return result;
      });
    } else {
      toastr.options = {
        progressBar: true,
        timeOut: "5000",
      };
      response.json().then((body) => {
        console.log(body);
        toastr.error(body.status + " " + body.statusText);
      });
    }
  });
};

export const fetchUserEvents = (user) => {
  return fetch("/api/getAllEventsForUser/" + user).then((response) => {
    //console.log(response)
    if (response.ok) {
      return response.json().then((result) => {
        return result;
      });
    } else {
      toastr.options = {
        progressBar: true,
        timeOut: "5000",
      };
      response.json().then((body) => {
        console.log(body);
        toastr.error(body.status + " " + body.statusText);
      });
    }
  });
};
export const fetchUserEventsWithRequest = (user) => {
  return fetch("/api/getAllEventsWithRequestForUser/" + user).then((response) => {
    //console.log(response)
    if (response.ok) {
      return response.json().then((result) => {
        return result;
      });
    } else {
      toastr.options = {
        progressBar: true,
        timeOut: "5000",
      };
      response.json().then((body) => {
        console.log(body);
        toastr.error(body.status + " " + body.statusText);
      });
    }
  });
};

export const fetchCustomerEvents = (customer) => {
  return fetch("/api/getAllEventsForCustomer/" + customer).then((response) => {
    //console.log(response)
    if (response.ok) {
      return response.json().then((result) => {
        return result;
      });
    } else {
      toastr.options = {
        progressBar: true,
        timeOut: "5000",
      };
      response.json().then((body) => {
        console.log(body);
        toastr.error(body.status + " " + body.statusText);
      });
    }
  });
};

export const fetchPendingUserEvents = (user) => {
  return fetch("/api/getPendingUserEvents/" + user).then((response) => {
    //console.log(response)
    if (response.ok) {
      return response.json().then((result) => {
        return result;
      });
    } else {
      toastr.options = {
        progressBar: true,
        timeOut: "5000",
      };
      response.json().then((body) => {
        console.log(body);
        toastr.error(body.status + " " + body.statusText);
      });
    }
  });
};
export const getProjectList = () => {
  return fetch("/api/projects/get").then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      toastr.options = {
        progressBar: true,
        timeOut: "5000",
      };
      response.json().then((body) => {
        console.log(body);
        toastr.error(body);
      });
    }
  });
};

export const patchProjectList = (list) => {
  return fetch("/api/projects/list/patch", {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(list),
  }).then((rawResponse) => {
    if (rawResponse.ok) {
      return rawResponse.json();
    } else {
      toastr.options = {
        progressBar: true,
        timeOut: "5000",
      };
      console.log(rawResponse);
      rawResponse.json().then((body) => {
        console.log(body);
        toastr.error(body);
      });
    }
  });
};
export const postProject = (project) => {
  return fetch("/api/projects/post", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(project),
  }).then((rawResponse) => {
    if (rawResponse.ok) {
      return rawResponse.json().then((result) => result);
    } else {
      toastr.options = {
        progressBar: true,
        timeOut: "5000",
      };
      console.log(rawResponse);
      rawResponse.json().then((body) => {
        console.log(body);
        toastr.error(body.errors[0].value + " " + body.errors[0].validatorKey, rawResponse.statusText);
      });
    }
  });
};

export const patchProject = (project) => {
  return fetch("/api/projects/patch", {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(project),
  }).then((rawResponse) => {
    if (rawResponse.ok) {
      return rawResponse.json().then((result) => result);
    } else {
      toastr.options = {
        progressBar: true,
        timeOut: "5000",
      };
      console.log(rawResponse);
      rawResponse.json().then((body) => {
        console.log(body);
        toastr.error(body.errors[0].value + " " + body.errors[0].validatorKey, rawResponse.statusText);
      });
    }
  });
};
export const fetchProjects = () => {
  return fetch("/api/projects/getAll").then((response) => {
    if (response.ok) {
      return response.json().then((result) => result);
    } else {
      toastr.options = {
        progressBar: true,
        timeOut: "5000",
      };
      console.log(response);
      response.json().then((body) => {
        console.log(body);
        toastr.error(body.errors[0].value + " " + body.errors[0].validatorKey, response.statusText);
      });
    }
  });
};

export const fetchPendingProjects = () => {
  return fetch("/api/projects/getAllPending").then((response) => {
    if (response.ok) {
      return response.json().then((result) => result);
    } else {
      toastr.options = {
        progressBar: true,
        timeOut: "5000",
      };
      console.log(response);
      toastr.error("Error " + response.status + ", " + response.statusText);
      return [];
    }
  });
};

export const fetchProject = (projectId) => {
  return fetch("/api/projects/get/" + projectId).then((response) => {
    if (response.ok) {
      return response.json().then((result) => result);
    } else {
      toastr.options = {
        progressBar: true,
        timeOut: "5000",
      };
      console.log(response);
      response.json().then((body) => {
        console.log(body);
        toastr.error(body.errors[0].value + " " + body.errors[0].validatorKey, response.statusText);
      });
    }
  });
};
export const deleteProject = (projectId) => {
  return fetch("/api/projects/delete/" + projectId, { method: "DELETE" }).then((response) => {
    if (response.ok) {
      return response;
    } else {
      toastr.options = {
        progressBar: true,
        timeOut: "5000",
      };
      console.log(response);
      response.json().then((body) => {
        console.log(body);
        toastr.error(body.errors[0].value + " " + body.errors[0].validatorKey, response.statusText);
      });
    }
  });
};
export const fetchOpenTickets = (status) => {
  return fetch("/api/tickets/getOpen/" + status, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then((rawResponse) => {
    if (rawResponse.ok) {
      return rawResponse.json();
    } else {
      toastr.options = {
        progressBar: true,
        timeOut: "5000",
      };
      console.log(rawResponse);
      toastr.error(rawResponse.statusText);
    }
  });
};
export const fetchOpenInternalTickets = (status) => {
  return fetch("/api/tickets/internal/getOpen/" + status, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then((rawResponse) => {
    if (rawResponse.ok) {
      return rawResponse.json();
    } else {
      toastr.options = {
        progressBar: true,
        timeOut: "5000",
      };
      toastr.error(rawResponse.statusText);
    }
  });
};
export const fetchOpenCustomerTickets = (status) => {
  return fetch("/api/tickets/customer/getOpen/" + status, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then((rawResponse) => {
    if (rawResponse.ok) {
      return rawResponse.json();
    } else {
      toastr.options = {
        progressBar: true,
        timeOut: "5000",
      };
      toastr.error(rawResponse.statusText);
    }
  });
};
export const fetchTicket = (number) => {
  return fetch("/api/tickets/get/" + number, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then((rawResponse) => {
    if (rawResponse.ok) {
      return rawResponse.json();
    } else {
      toastr.options = {
        progressBar: true,
        timeOut: "5000",
      };
      console.log(rawResponse);
      toastr.error(rawResponse.statusText);
    }
  });
};
export const fetchClosedTickets = (year) => {
  return fetch("/api/tickets/getClosed/" + year, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then((rawResponse) => {
    if (rawResponse.ok) {
      return rawResponse.json();
    } else {
      toastr.options = {
        progressBar: true,
        timeOut: "5000",
      };
      toastr.error(rawResponse.statusText);
    }
  });
};
export const fetchClosedInternalTickets = (year) => {
  return fetch("/api/tickets/internal/getClosed/" + year, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then((rawResponse) => {
    if (rawResponse.ok) {
      return rawResponse.json();
    } else {
      toastr.options = {
        progressBar: true,
        timeOut: "5000",
      };
      toastr.error(rawResponse.statusText);
    }
  });
};
export const fetchOldestTicketYear = () => {
  return fetch("/api/tickets/getOldestYear/", {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then((rawResponse) => {
    if (rawResponse.ok) {
      return rawResponse.json();
    } else {
      toastr.options = {
        progressBar: true,
        timeOut: "5000",
      };
      toastr.error(rawResponse.statusText);
    }
  });
};
export const fetchOldestInternalTicketYear = () => {
  return fetch("/api/tickets/internal/getOldestYear/", {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then((rawResponse) => {
    if (rawResponse.ok) {
      return rawResponse.json();
    } else {
      toastr.options = {
        progressBar: true,
        timeOut: "5000",
      };
      toastr.error(rawResponse.statusText);
    }
  });
};
export const postTicket = (ticket) => {
  return fetch("/api/tickets/new/", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(ticket),
  }).then((rawResponse) => {
    if (rawResponse.ok) {
      return rawResponse.json();
    } else {
      toastr.options = {
        progressBar: true,
        timeOut: "5000",
      };
      console.log(rawResponse);
      toastr.error(rawResponse.statusText);
    }
  });
};
export const handleTicket = (ticketId, userId) => {
  return fetch("/api/tickets/" + ticketId + "/handle", {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ user: userId }),
  }).then((rawResponse) => {
    if (rawResponse.ok) {
      return rawResponse.json();
    } else {
      toastr.options = {
        progressBar: true,
        timeOut: "5000",
      };
      toastr.error(rawResponse.statusText);
    }
  });
};
export const changeTicketStatus = (ticketId, status) => {
  return fetch("/api/tickets/" + ticketId + "/status", {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ status: status }),
  }).then((rawResponse) => {
    if (rawResponse.ok) {
      return rawResponse.json();
    } else {
      toastr.options = {
        progressBar: true,
        timeOut: "5000",
      };
      toastr.error(rawResponse.statusText);
    }
  });
};
export const releaseTicket = (ticketId) => {
  return fetch("/api/tickets/" + ticketId + "/release", {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then((rawResponse) => {
    if (rawResponse.ok) {
      return rawResponse.json();
    } else {
      toastr.options = {
        progressBar: true,
        timeOut: "5000",
      };
      toastr.error(rawResponse.statusText);
    }
  });
};
export const pauseTicket = (ticketId, userId) => {
  return fetch("/api/tickets/stop/" + ticketId, {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ user: userId }),
  }).then((rawResponse) => {
    if (rawResponse.ok) {
      return rawResponse.json();
    } else {
      toastr.options = {
        progressBar: true,
        timeOut: "5000",
      };
      toastr.error(rawResponse.statusText);
    }
  });
};
export const closeTicket = (ticketId, userId) => {
  return fetch("/api/tickets/close/" + ticketId, {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ user: userId }),
  }).then((rawResponse) => {
    if (rawResponse.ok) {
      return rawResponse.json();
    } else {
      toastr.options = {
        progressBar: true,
        timeOut: "5000",
      };
      toastr.error(rawResponse.statusText);
    }
  });
};
export const editTicketDescription = (ticketId, description) => {
  return fetch("/api/tickets/" + ticketId + "/editDescription", {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ description: description }),
  }).then((rawResponse) => {
    if (rawResponse.ok) {
      return rawResponse.json();
    } else {
      toastr.options = {
        progressBar: true,
        timeOut: "5000",
      };
      toastr.error(rawResponse.statusText);
    }
  });
};
export const editTicketContact = (ticketId, contact) => {
  return fetch("/api/tickets/" + ticketId + "/editContact", {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ contact: contact }),
  }).then((rawResponse) => {
    if (rawResponse.ok) {
      return rawResponse.json();
    } else {
      toastr.options = {
        progressBar: true,
        timeOut: "5000",
      };
      toastr.error(rawResponse.statusText);
    }
  });
};
export const editTicketPeriod = (ticketPeriodId, description) => {
  return fetch("/api/ticketPeriod/" + ticketPeriodId, {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ description: description }),
  }).then((rawResponse) => {
    if (rawResponse.ok) {
      return rawResponse.json();
    } else {
      toastr.options = {
        progressBar: true,
        timeOut: "5000",
      };
      toastr.error(rawResponse.statusText);
    }
  });
};
export const deleteTicket = (ticketId) => {
  return fetch("/api/tickets/delete/" + ticketId, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then((rawResponse) => {
    if (rawResponse.ok) {
      return rawResponse.json();
    } else {
      toastr.options = {
        progressBar: true,
        timeOut: "5000",
      };
      toastr.error(rawResponse.statusText);
    }
  });
};

export const linkTicketToInvoice = (ticket, invoice) => {
  return fetch("/api/tickets/linkInvoice/" + ticket.id, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ invoiceNumber: invoice, price: ticket.price }),
  }).then((rawResponse) => {
    if (rawResponse.ok) {
      return rawResponse.json();
    } else {
      toastr.options = {
        progressBar: true,
        timeOut: "5000",
      };
      toastr.error(rawResponse.statusText);
    }
  });
};
export const fetchInvoices = (year) => {
  return fetch("/api/invoice/getAll/" + year, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then((rawResponse) => {
    if (rawResponse.ok) {
      return rawResponse.json();
    } else {
      toastr.options = {
        progressBar: true,
        timeOut: "5000",
      };
      console.log(rawResponse);
      toastr.error(rawResponse.statusText);
    }
  });
};

export const fetchCustomerUnpaidInvoices = (customerId) => {
  return fetch("/api/invoice/getUnpaidForCustomer/" + customerId, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then((rawResponse) => {
    if (rawResponse.ok) {
      return rawResponse.json();
    } else {
      toastr.options = {
        progressBar: true,
        timeOut: "5000",
      };
      console.log(rawResponse);
      toastr.error(rawResponse.statusText);
    }
  });
};
