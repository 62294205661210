import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import {
  store,
  Translator,
  MomentDateString,
  MomentDateTimeString,
  parseForTable,
  computeWorkTime,
  NotifyReloadTicket,
  ShouldReloadTicket,
  shouldAlwaysReloadTicket,
} from "../../tools/Tools";
import Tickets from "./Tickets";

class TicketPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      monitoringMode: false,
    };
    shouldAlwaysReloadTicket(true);
  }

  alwaysReloadTicket() {
    /*if (ShouldReloadTicket("changeShouldAlwaysReloadTicket")) {
      console.log("changing monitoring mode to true");
      //shouldAlwaysReloadTicket(true);
      this.setState({ monitoringMode: true });
    } else {
      //shouldAlwaysReloadTicket(false);
      console.log("changing monitoring mode to false");
      this.setState({ monitoringMode: false });
    }*/
    this.setState({ monitoringMode: !this.state.monitoringMode });
  }
  render() {
    console.log(this.state.monitoringMode);
    return (
      <div>
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0 text-dark">
                  <i className="fa-solid fa-ticket"></i>&nbsp;
                  {Translator("Tickets")}
                </h1>
                <div className="form-group">
                  <div
                    className="custom-control custom-switch custom-switch-off-secondary custom-switch-on-primary"
                    onClick={this.alwaysReloadTicket.bind(this)}
                  >
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="customSwitch3"
                      value={this.state.monitoringMode}
                      checked={this.state.monitoringMode}
                      onChange={(e) => { }}
                    ></input>
                    <label className="custom-control-label" htmlFor="customSwitch3" onClick={(e) => e.preventDefault()}>
                      {" "}
                      {Translator("monitoring mode")}
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to={{ pathname: "../", state: this.props.history.state }}>{Translator("Home")}</Link>
                  </li>
                  <li className="breadcrumb-item active">{Translator("Tickets")}</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="container-fluid">
            <Tickets monitoringMode={this.state.monitoringMode}></Tickets>
          </div>
        </section>
      </div>
    );
  }
}
export default withRouter(TicketPage);
