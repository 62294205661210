import React, { Component } from "react";
import GeneralInfos from "../../components/GeneralInfos";
import BSTable from "../../components/BSTable";
import isLoading from "../../components/Loading";
import { fetchContacts, postNewContact, deleteContact } from "../../tools/Api_Customers";
import { Translator, parseForTable, store } from "../../tools/Tools";
import { v4 as uuidv4 } from 'uuid';
import { withRouter } from "react-router-dom";

class SupplierData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [],
      data: [],
    };
  }
  handleCreateButton(e) {
    e.preventDefault();
    this.setState((state) => {
      return {
        edit: true,
        data: [
          ...state.data,
          {
            id: uuidv4(),
            name: "Nouveau Contact",
            position: "",
            phone1: "",
            phone2: "",
            mail: "",
            note: "",
          },
        ],
      };
    });
  }
  componentDidMount() {
    this.setState({ isFetching: true });
    fetchContacts(this.props.generalInfos.id).then((contacts) => {
      this.setState(() => {
        return { ...parseForTable(contacts), isFetching: false };
      });
    });
    //this.setState( parseForTable(fakeFetched))
  }

  postCallback(newContacts) {
    this.setState({ isFetching: true });
    Promise.all(
      newContacts.map((newContact) => {
        //console.log(newContact);
        postNewContact(this.props.generalInfos.id, newContact);
      })
    ).then(() => this.setState({ isFetching: false }));
  }
  deleteContact(ids) {
    this.setState({ isFetching: true });
    deleteContact(ids).then((result) => {
      this.reload();
    });
  }
  render() {
    //console.log(this.state)
    return (
      <div>
        <div className="row">
          <div className="col-md-3">
            <GeneralInfos generalInfos={this.props.generalInfos} infoType="customer" />
          </div>
          <div className="col-md-9">
            <BSTable
              icon="user-"
              columns={this.state.columns}
              data={this.state.data}
              title={Translator("contacts")}
              cud={
                this.state.showLinkedContacts
                  ? { c: false, u: false, d: false }
                  : { c: this.handleCreateButton, u: true, d: true }
              }
              theme="info"
              postCallback={this.postCallback.bind(this)}
              deleteCallback={this.deleteContact.bind(this)}
            />
          </div>
        </div>
        {isLoading(this.state.isFetching)}
      </div>
    );
  }
}
export default withRouter(SupplierData);
