import moment from "moment";
import "moment/locale/fr";
import React from "react";
import toastr from "toastr";
import { fetchUserEvents, fetchParameters } from "./Api_Customers";

let translation_table1 = [
  {
    key: "name",
    fr: "Nom",
    nl: "Naam",
  },
  {
    key: "address",
    fr: "Addresse",
    nl: "Adres",
  },
  {
    key: "postal",
    fr: "Code postal",
    nl: "Postcode",
  },
  {
    key: "country",
    fr: "Pays",
    nl: "Land",
  },
  {
    key: "Language",
    fr: "Langue",
    nl: "Taal",
  },
  {
    key: "phone",
    fr: "Téléphone",
    nl: "Telefoon",
  },
  {
    key: "type",
    fr: "Type",
    nl: "Type",
  },
  {
    key: "contract",
    fr: "Contrat",
    nl: "Contract",
  },
  {
    key: "customer",
    fr: "Client",
    nl: "Klant",
  },
  {
    key: "customers",
    fr: "Clients",
    nl: "Klanten",
  },
  {
    key: "add",
    fr: "Ajouter",
    nl: "Toevoegen",
  },
  {
    key: "Belgique",
    fr: "Belgique",
    nl: "België",
  },
  {
    key: "France",
    fr: "France",
    nl: "Frankrijk",
  },
  {
    key: "Pays-Bas",
    fr: "Pays-Bas",
    nl: "Nederland",
  },
  {
    key: "Suisse",
    fr: "Suisse",
    nl: "Zwitserland",
  },
  {
    key: "Luxembourg",
    fr: "Luxembourg",
    nl: "Luxemburg",
  },
  {
    key: "Monaco",
    fr: "Monaco",
    nl: "Monaco",
  },
  {
    key: "none",
    fr: "Aucun",
    nl: "Geen",
  },
  {
    key: "activeClient",
    fr: "Est un client EB",
    nl: "Is een EB Klant",
  },
  {
    key: true,
    fr: "Oui",
    nl: "Ja",
  },
  {
    key: false,
    fr: "Non",
    nl: "Nee",
  },
  {
    key: "Pressing",
    fr: "Pressing",
    nl: "Droogkuis",
  },
  {
    key: "Hopital",
    fr: "Hôpital",
    nl: "Ziekenhuis",
  },
  {
    key: "Blanchisserie",
    fr: "Blanchisserie",
    nl: "Wasserij",
  },
  {
    key: "Hotel",
    fr: "Hôtel",
    nl: "Hotel",
  },
  {
    key: "Industrie",
    fr: "Industrie",
    nl: "Industrie",
  },
  {
    key: "Omnium",
    fr: "Omnium",
    nl: "Omnium",
  },
  {
    key: "Basic2",
    fr: "Basic2",
    nl: "Basic2",
  },
  {
    key: "Warranty",
    fr: "Garantie",
    nl: "Garantie",
  },
  {
    key: "Conveyor",
    fr: "Convoyeur",
    nl: "Baan",
  },
  {
    key: "Basic-1",
    fr: "Basic1",
    nl: "Basic1",
  },
  {
    key: "position",
    fr: "Position",
    nl: "Functie",
  },
  {
    key: "phone1",
    fr: "Téléphone 1",
    nl: "Telefoon 1",
  },
  {
    key: "phone2",
    fr: "Téléphone 2",
    nl: "Telefoon 2",
  },
  {
    key: "mail",
    fr: "Mail",
    nl: "Mail",
  },
  {
    key: "note",
    fr: "Note",
    nl: "Notities",
  },
  {
    key: "1er Chef d'Equipe",
    fr: "1er Chef d'Equipe",
    nl: "1ste ploegbaas",
  },
  {
    key: "2ème Chef d'Equipe",
    fr: "2ème Chef d'Equipe",
    nl: "2de ploegbaas",
  },
  {
    key: "Achats - logistique Huderf",
    fr: "Achats - logistique Huderf",
    nl: "Aankopen - Logistiek Huderf",
  },
  {
    key: "Achats Bio-médical",
    fr: "Achats Bio-médical",
    nl: "Bio medische aankopen",
  },
  {
    key: "Achats et assurances",
    fr: "Achats et assurances",
    nl: "Aankopen en verzekeringen",
  },
  {
    key: "Achats technique Chu St Pierre",
    fr: "Achats technique Chu St Pierre",
    nl: "Technische aankopen Chu St Pierre",
  },
  {
    key: "Acheteur",
    fr: "Acheteur",
    nl: "Aankoper",
  },
  {
    key: "Acheteur DAV",
    fr: "Acheteur DAV",
    nl: "Aankoper KUA",
  },
  {
    key: "Acheteur fournitures générales",
    fr: "Acheteur fournitures générales",
    nl: "Aankoper algemeen materiaal",
  },
  {
    key: "Acheteur médicale",
    fr: "Acheteur médicale",
    nl: "Medisch aankoper",
  },
  {
    key: "Acheteuse",
    fr: "Acheteuse",
    nl: "Aankoopster",
  },
  {
    key: "Adjoint au directeur des infrastructures",
    fr: "Adjoint au directeur des infrastructures",
    nl: "Assistent van de directeur infrastructuur",
  },
  {
    key: "Adjoint de la gouvernante",
    fr: "Adjoint de la gouvernante",
    nl: "Assistent van de gouvernante",
  },
  {
    key: "Adjoint direction technique et infrastructures",
    fr: "Adjoint direction technique et infrastructures",
    nl: "Assistent technisch en infrastructuurbeheer",
  },
  {
    key: "Adjoint responsable fonction linge CHU - Chargé de mission EHL",
    fr: "Adjoint responsable fonction linge CHU - Chargé de mission EHL",
    nl: "Plaatsvervangend verantwoordelijke voor de linnenfunctie CHU - EHL beleidsmedewerker",
  },
  {
    key: "Adjointe gouvernante",
    fr: "Adjointe gouvernante",
    nl: "Assistent van de gouvernante",
  },
  {
    key: "Administrateur réseau ISOSL",
    fr: "Administrateur réseau ISOSL",
    nl: "Netwerkadministrator ISOSL",
  },
  {
    key: "Administrateur réseau - informaticien",
    fr: "Administrateur réseau - informaticien",
    nl: "Netwekradministrator - informaticus",
  },
  {
    key: "Administration",
    fr: "Administration",
    nl: "Administratie",
  },
  {
    key: "Administration housekeeping",
    fr: "Administration housekeeping",
    nl: "Administratie houskeeping",
  },
  {
    key: "Administration (Depairon)",
    fr: "Administration (Depairon)",
    nl: "Administratie (Depairon)",
  },
  {
    key: "Antenne blanchisserie Rangueil",
    fr: "Antenne blanchisserie Rangueil",
    nl: "Antenne wasserij Rangueil",
  },
  {
    key: "Architect de l'hopital",
    fr: "Architect de l'hopital",
    nl: "Architect van het ziekenhuis",
  },
  {
    key: "Architecte, responsable entre autres des contrats de maintenance",
    fr: "Architecte, responsable entre autres des contrats de maintenance",
    nl: "Architect, verantwoordelijke contracten",
  },
  {
    key: "AS Elis",
    fr: "AS Elis",
    nl: "AS Elis",
  },
  {
    key: "Assistant administratif - entretien",
    fr: "Assistant administratif - entretien",
    nl: "Administratief assistent - onderhoud",
  },
  {
    key: "Assistant de Karamoko",
    fr: "Assistant de Karamoko",
    nl: "Assistent van Karamoko",
  },
  {
    key: "Assistant exécutif housekeeper",
    fr: "Assistant exécutif housekeeper",
    nl: "Uitvoerende assistent houskeeper",
  },
  {
    key: "Assistant lingerie",
    fr: "Assistant lingerie",
    nl: "Assistent linnendienst",
  },
  {
    key: "Assistant responsable technique",
    fr: "Assistant responsable technique",
    nl: "Assistent technisch verantwoordelijke",
  },
  {
    key: "Assistant Steven",
    fr: "Assistant Steven",
    nl: "Assistent Steven",
  },
  {
    key: "Assistant informatique",
    fr: "Assistant informatique",
    nl: "Assistent informatica",
  },
  {
    key: "Assistante administrative technique",
    fr: "Assistante administrative technique",
    nl: "Assistente technische administratie",
  },
  {
    key: "Assistante commerciale",
    fr: "Assistante commerciale",
    nl: "Commercieel assistente",
  },
  {
    key: "Assistante de Béatrice Granjou",
    fr: "Assistante de Béatrice Granjou",
    nl: "Assistente van Béatrice Granjou",
  },
  {
    key: "Assistante de direction",
    fr: "Assistante de direction",
    nl: "Directieassistente",
  },
  {
    key: "Assistante de la gouvernante générale",
    fr: "Assistante de la gouvernante générale",
    nl: "Assistente algemene gouvernant",
  },
  {
    key: "Assistante gouvernante générale - Responsable lingerie",
    fr: "Assistante gouvernante générale - Responsable lingerie",
    nl: "Assistente algemene gouvernant - Verantwoordelijke linnendienst",
  },
  {
    key: "Assistante gouvernante générale",
    fr: "Assistante gouvernante générale",
    nl: "Assistente algemene gouvernant",
  },
  {
    key: "Assistante responsable de site",
    fr: "Assistante responsable de site",
    nl: "Assistente site verantwoordelijke",
  },
  {
    key: "Assistante responsable des achats - Responsable hygiène et qualité",
    fr: "Assistante responsable des achats - Responsable hygiène et qualité",
    nl: "Assistente verantwoordelijke aankopen - Verantwoordelijke hygiëne en qualiteit",
  },
  {
    key: "Assistante responsable logistique",
    fr: "Assistante responsable logistique",
    nl: "Assistente verantwoordelijke logistiek",
  },
  {
    key: "Assistante service achats",
    fr: "Assistante service achats",
    nl: "Assistente verantwoordelijke aankopen",
  },
  {
    key: "Attachée de direction",
    fr: "Attachée de direction",
    nl: "Directieassistent",
  },
  {
    key: "Blanchisserie",
    fr: "Blanchisserie",
    nl: "Wasserij",
  },
  {
    key: "Blanchisserie Blésoise",
    fr: "Blanchisserie Blésoise",
    nl: "Wasserij Blésoise",
  },
  {
    key: "Blanchisserie Lavotel",
    fr: "Blanchisserie Lavotel",
    nl: "Wasserij Lavotel",
  },
  {
    key: "Brigadière",
    fr: "Brigadière",
    nl: "Brigadier",
  },
  {
    key: "Buanderie",
    fr: "Buanderie",
    nl: "Wasserij",
  },
  {
    key: "CBC Petit",
    fr: "CBC Petit",
    nl: "CBC Petit",
  },
  {
    key: "Chargé clientèle Claude Bernard et Robert Schuman",
    fr: "Chargé clientèle Claude Bernard et Robert Schuman",
    nl: "Cliëntbeheerder Claude Bernard en Robert Schuman",
  },
  {
    key: "Chargé clientèle Claude Bernard",
    fr: "Chargé clientèle Claude Bernard",
    nl: "Cliëntbeheerder Claude Bernard",
  },
  {
    key: "Chargé clientèle Robert Schuman",
    fr: "Chargé clientèle Robert Schuman",
    nl: "Cliëntbeheerder Robert Schuman",
  },
  {
    key: "Chargée d'affaires grands comptes",
    fr: "Chargée d'affaires grands comptes",
    nl: "Key accountmanager",
  },
  {
    key: "Chargée d'affaires grands comptes - chef de projet DAV",
    fr: "Chargée d'affaires grands comptes - chef de projet DAV",
    nl: "Key accountmanager - Projectleider KUA",
  },
  {
    key: "Chef de projets automatisation, RFID et DAV - Direction des projets industriels",
    fr: "Chef de projets automatisation, RFID et DAV - Direction des projets industriels",
    nl: "Projectmanager automatisering, RFID en KUA - Directoraat industriële projecten",
  },
  {
    key: "Chef du département de l'administration des vêtements professionnels et de l'alimentation",
    fr: "Chef du département de l'administration des vêtements professionnels et de l'alimentation",
    nl: "Hoofd van de afdeling bedrijfskleding en voedseladministratie",
  },
  {
    key: "Chef équipiers Anett Novotel Paries 15eme",
    fr: "Chef équipiers Anett Novotel Paries 15eme",
    nl: "Bemanningsleider Anett Novotel Paries 15eme",
  },
  {
    key: "Co-gérant",
    fr: "Co-gérant",
    nl: "Co-beheerder",
  },
  {
    key: "Commercial - Responsable comptes",
    fr: "Commercial - Responsable comptes",
    nl: "Verkoper - Verantwoordelijke accounts",
  },
  {
    key: "Commercial Kannegieser",
    fr: "Commercial Kannegieser",
    nl: "Verkoper Kannegieser",
  },
  {
    key: "Commercial pour Lutetia",
    fr: "Commercial pour Lutetia",
    nl: "Verkoper voor Lutetia",
  },
  {
    key: "Commercial",
    fr: "Commercial",
    nl: "Verkoper",
  },
  {
    key: "Comptabilité fournisseur",
    fr: "Comptabilité fournisseur",
    nl: "Boekhouding leveranciers",
  },
  {
    key: "Comptable",
    fr: "Comptable",
    nl: "Boekhouder",
  },
  {
    key: "Connexion maintenance",
    fr: "Connexion maintenance",
    nl: "Verbinding onderhoud",
  },
  {
    key: "Conseiller en prévention",
    fr: "Conseiller en prévention",
    nl: "Preventieadviseur",
  },
  {
    key: "Conseiller en vente et services",
    fr: "Conseiller en vente et services",
    nl: "Adviseur in verkoop en service",
  },
  {
    key: "Conseilleur Rosewood",
    fr: "Conseilleur Rosewood",
    nl: "Adviseur Rosewood",
  },
  {
    key: "Construction",
    fr: "Construction",
    nl: "Bouw",
  },
  {
    key: "Construction et conseiller en prévention",
    fr: "Construction et conseiller en prévention",
    nl: "Bouw en preventieadviseur",
  },
  {
    key: "Contremaître de maintenance",
    fr: "Contremaître de maintenance",
    nl: "Voorman onderhoud",
  },
  {
    key: "Contre-maître",
    fr: "Contre-maître",
    nl: "Voorman",
  },
  {
    key: "Contrôle des factures",
    fr: "Contrôle des factures",
    nl: "Nazicht facturen",
  },
  {
    key: "Coordinateur de Pullman",
    fr: "Coordinateur de Pullman",
    nl: "Coördinator Pullman",
  },
  {
    key: "Coordinateur déménagement DAV",
    fr: "Coordinateur déménagement DAV",
    nl: "Coördinator verhuis KUA",
  },
  {
    key: "Coordinateur facilitair",
    fr: "Coordinateur facilitair",
    nl: "Coördinator van de facilitatie",
  },
  {
    key: "Coordinateur fonction linge - lingère",
    fr: "Coordinateur fonction linge - lingère",
    nl: "Coördinator linnendienst - Linnenvrouw",
  },
  {
    key: "Coordinateur housekeeping",
    fr: "Coordinateur housekeeping",
    nl: "Coördinator housekeeping",
  },
  {
    key: "Coordinateur ingénierie - Technique",
    fr: "Coordinateur ingénierie - Technique",
    nl: "Coördinator ingenieur - Technisch",
  },
  {
    key: "Coordinateur opérationnel usine du linge",
    fr: "Coordinateur opérationnel usine du linge",
    nl: "Operationeel coördinator wasserij",
  },
  {
    key: "Coordinateur technique",
    fr: "Coordinateur technique",
    nl: "Technisch coördinator",
  },
  {
    key: "Coordinateur",
    fr: "Coordinateur",
    nl: "Coördinator",
  },
  {
    key: "Coordinatrice lingerie",
    fr: "Coordinatrice lingerie",
    nl: "Coördinatrice linnendienst",
  },
  {
    key: "Coordinatrice Projet DAV",
    fr: "Coordinatrice Projet DAV",
    nl: "Coördinatrice project KUA",
  },
  {
    key: "Correspondant ETN",
    fr: "Correspondant ETN",
    nl: "Correspondent ETN",
  },
  {
    key: "DAV support (Cleanlease)",
    fr: "DAV support (Cleanlease)",
    nl: "KUA support (Cleanlease)",
  },
  {
    key: "Dect de la permanence",
    fr: "Dect de la permanence",
    nl: "Dect van de permanentie",
  },
  {
    key: "Département des équipements",
    fr: "Département des équipements",
    nl: "Afdeling uitrusting",
  },
  {
    key: "Département des équipements, secrétariat, lingère",
    fr: "Département des équipements, secrétariat, lingère",
    nl: "Afdeling uitrusting, secretariaat, linnendienst",
  },
  {
    key: "Département logistique - Service économat",
    fr: "Département logistique - Service économat",
    nl: "Afdeling logistiek - Dienst économat",
  },
  {
    key: "Développeur - Sous-traitant informatique socièté BCM",
    fr: "Développeur - Sous-traitant informatique socièté BCM",
    nl: "Ontwikkelaar - Onderaannemer informatica bedrijf BCM",
  },
  {
    key: "DIL - Adjoint infrastructures",
    fr: "DIL - Adjoint infrastructures",
    nl: "DIL - Infrastructuurassistent",
  },
  {
    key: "Directeur adjoint",
    fr: "Directeur adjoint",
    nl: "Assistent directeur",
  },
  {
    key: "Directeur administratif et financier",
    fr: "Directeur administratif et financier",
    nl: "Administratief en financieel directeur",
  },
  {
    key: "Directeur CHC-Clinique de l'Espérance",
    fr: "Directeur CHC-Clinique de l'Espérance",
    nl: "Directeur CHC-ziekenhuis Espérance",
  },
  {
    key: "Directeur de centre Elis Grenoble",
    fr: "Directeur de centre Elis Grenoble",
    nl: "Centrumdirecteur Elis Grenoble",
  },
  {
    key: "Directeur de l'établissement",
    fr: "Directeur de l'établissement",
    nl: "Directeur van de vestiging",
  },
  {
    key: "Directeur de site Kalhyge - Site de Brie Comte Robert",
    fr: "Directeur de site Kalhyge - Site de Brie Comte Robert",
    nl: "Directeur site Kalhyge - Site Brie comte Robert",
  },
  {
    key: "Directeur d'unité RLD",
    fr: "Directeur d'unité RLD",
    nl: "Directeur eenheid RLD",
  },
  {
    key: "Directeur Elis Liège et Anderlecht",
    fr: "Directeur Elis Liège et Anderlecht",
    nl: "Directeur Elis Luik en Anderlecht",
  },
  {
    key: "Directeur financier Belux",
    fr: "Directeur financier Belux",
    nl: "Financieel directeur Belux",
  },
  {
    key: "Directeur financier",
    fr: "Directeur financier",
    nl: "Financieel directeur",
  },
  {
    key: "Directeur général",
    fr: "Directeur général",
    nl: "Algemeen directeur",
  },
  {
    key: "Directeur général One Monte-Carlo",
    fr: "Directeur général One Monte-Carlo",
    nl: "Algemeen directeur One Monte-Carlo",
  },
  {
    key: "Directeur général RZ Tienen",
    fr: "Directeur général RZ Tienen",
    nl: "Algemeen directeur RZ Tienen",
  },
  {
    key: "Directeur logistique et technique",
    fr: "Directeur logistique et technique",
    nl: "Logistiek en technisch directeur",
  },
  {
    key: "Directeur operationel",
    fr: "Directeur operationel",
    nl: "Operationeel directeur",
  },
  {
    key: "Directeur Pôle Textile (Anett)",
    fr: "Directeur Pôle Textile (Anett)",
    nl: "Directeur Pôle Textile (Anett)",
  },
  {
    key: "Directeur technique",
    fr: "Directeur technique",
    nl: "Technisch directeur",
  },
  {
    key: "Directeur Unité Mureaux",
    fr: "Directeur Unité Mureaux",
    nl: "Directeur eenheid Mureaux",
  },
  {
    key: "Directeur ZNA Hoge Beuken",
    fr: "Directeur ZNA Hoge Beuken",
    nl: "Directeur ZNA Hoge Beuken",
  },
  {
    key: "Directeur",
    fr: "Directeur",
    nl: "Directeur",
  },
  {
    key: "Directeur operationel",
    fr: "Directeur operationel",
    nl: "Operationeel directeur",
  },
  {
    key: "Direction",
    fr: "Direction",
    nl: "Directie",
  },
  {
    key: "Direction achats SBM - Finances",
    fr: "Direction achats SBM - Finances",
    nl: "Aankoopdirectie SBM - Financieën",
  },
  {
    key: "Direction du système d'Information Groupe Hospitalier Pellegrin",
    fr: "Direction du système d'Information Groupe Hospitalier Pellegrin",
    nl: "Directie informatiesysteembeheer Groupe Hospitalier Pellegrin",
  },
  {
    key: "Direction du système d'information",
    fr: "Direction du système d'information",
    nl: "Directie informatiesysteembeheer",
  },
  {
    key: "Direction juriste",
    fr: "Direction juriste",
    nl: "Directie juriste",
  },
  {
    key: "Economat",
    fr: "Economat",
    nl: "Economat",
  },
  {
    key: "Economat IMC Tournai",
    fr: "Economat IMC Tournai",
    nl: "Economat IMC Tournai",
  },
  {
    key: "Electricien de garde",
    fr: "Electricien de garde",
    nl: "Elektrieker van wacht",
  },
  {
    key: "Elis",
    fr: "Elis",
    nl: "Elis",
  },
  {
    key: "Entrepreneur sous-traitant pour les travaux",
    fr: "Entrepreneur sous-traitant pour les travaux",
    nl: "Onderaannemer voor de werken",
  },
  {
    key: "Entretien",
    fr: "Entretien",
    nl: "Onderhoud",
  },
  {
    key: "Facturation",
    fr: "Facturation",
    nl: "Facturatie",
  },
  {
    key: "Fournisseur linge",
    fr: "Fournisseur linge",
    nl: "Leverancier linnen",
  },
  {
    key: "Garde",
    fr: "Garde",
    nl: "Bewaking",
  },
  {
    key: "Gère le projet au niveau technique et architectural",
    fr: "Gère le projet au niveau technique et architectural",
    nl: "Beheerd het project op technisch en architectueel niveau",
  },
  {
    key: "Gestion des matériaux",
    fr: "Gestion des matériaux",
    nl: "Beheer van materiaal",
  },
  {
    key: "Gestion des soins infirmiers",
    fr: "Gestion des soins infirmiers",
    nl: "Verpleegkundig beheer",
  },
  {
    key: "Gestionnaire de comptes - Commercial",
    fr: "Gestionnaire de comptes - Commercial",
    nl: "Accountmanager - Verkoper",
  },
  {
    key: "Gestionnaire de comptes - service commercial",
    fr: "Gestionnaire de comptes - service commercial",
    nl: "Accountmanager - Verkoper",
  },
  {
    key: "Gestionnaire de comptes Elis",
    fr: "Gestionnaire de comptes Elis",
    nl: "Accountmanager Elis",
  },
  {
    key: "Gestionnaire de risques - cadre de santé",
    fr: "Gestionnaire de risques - cadre de santé",
    nl: "Risk Manager - Health Executive",
  },
  {
    key: "Gestionnaire des contrats de maintenance",
    fr: "Gestionnaire des contrats de maintenance",
    nl: "Beheerder onderhoudcontracten",
  },
  {
    key: "Gestionnaire du DAV",
    fr: "Gestionnaire du DAV",
    nl: "Beheerder KUA",
  },
  {
    key: "Gestionnaire du magasin, linger",
    fr: "Gestionnaire du magasin, linger",
    nl: "Beheerder magazijn, linnenman",
  },
  {
    key: "Gestionnaire technique zone Agora Pèri",
    fr: "Gestionnaire technique zone Agora Pèri",
    nl: "Technisch beheerder zone Agora Pèri",
  },
  {
    key: "Gestionnaire technique",
    fr: "Gestionnaire technique",
    nl: "Technisch beheerder",
  },
  {
    key: "Gouvernante Générale / Executive Housekeeper",
    fr: "Gouvernante Générale / Executive Housekeeper",
    nl: "Algemeen gouvernant / Leidinggevende housekeeper",
  },
  {
    key: "Gouvernante générale",
    fr: "Gouvernante générale",
    nl: "Algemeen gouvernant",
  },
  {
    key: "Gouvernante générale - executive housekeeper",
    fr: "Gouvernante générale - executive housekeeper",
    nl: "Algemeen gouvernant / Leidinggevende housekeeper",
  },
  {
    key: "Gouvernante",
    fr: "Gouvernante",
    nl: "Gouvernant",
  },
  {
    key: "Helpdesk informatique",
    fr: "Helpdesk informatique",
    nl: "Helpdesk informatica",
  },
  {
    key: "Helpdesk regie",
    fr: "Helpdesk regie",
    nl: "Helpdesk regie",
  },
  {
    key: "Housekeeping",
    fr: "Housekeeping",
    nl: "Housekeeping",
  },
  {
    key: "Housekeeping (lingère)",
    fr: "Housekeeping (lingère)",
    nl: "Houskeeping (linnenvrouw)",
  },
  {
    key: "Informaticien",
    fr: "Informaticien",
    nl: "Informaticus",
  },
  {
    key: "Informaticien - Administrateur bases de données",
    fr: "Informaticien - Administrateur bases de données",
    nl: "Informaticus - Administrator database",
  },
  {
    key: "Informaticien - Administrateur réseau",
    fr: "Informaticien - Administrateur réseau",
    nl: "Informaticus - Netwerkadministrator",
  },
  {
    key: "Informaticien - Employé du service desk ZNA",
    fr: "Informaticien - Employé du service desk ZNA",
    nl: "Informaticus - Personeelslid service desk ZNA",
  },
  {
    key: "Informaticien - Gestion domaine Iris Sud",
    fr: "Informaticien - Gestion domaine Iris Sud",
    nl: "Informaticus - Beheerder van het domein Iris Sud",
  },
  {
    key: "Informaticien - gestionnaire de système",
    fr: "Informaticien - gestionnaire de système",
    nl: "Informaticus - Systeembeheerder",
  },
  {
    key: "Informaticien - Remote Access",
    fr: "Informaticien - Remote Access",
    nl: "Informaticus - Toegang op afstand",
  },
  {
    key: "Informaticien - réseau",
    fr: "Informaticien - réseau",
    nl: "Informaticus - Netwerk",
  },
  {
    key: "Informaticien - Responsable de domaine systèmes de gestion technique",
    fr: "Informaticien - Responsable de domaine systèmes de gestion technique",
    nl: "Informaticus - Verantwoordelijke domein van technische beheerssystemen",
  },
  {
    key: "Informaticien - Responsable de projet réseau",
    fr: "Informaticien - Responsable de projet réseau",
    nl: "Informaticus - Verantwoordelijke netwerkprojecten",
  },
  {
    key: "Informaticien - Responsable des applications ICT supportant les applications",
    fr: "Informaticien - Responsable des applications ICT supportant les applications",
    nl: "Informaticus - Verantwoordelijke ICT applicaties",
  },
  {
    key: "Informaticien - Responsable du service applications",
    fr: "Informaticien - Responsable du service applications",
    nl: "Informaticus - Verantwoordelijke dienst applicaties",
  },
  {
    key: "Informaticien - Responsable infrastructure",
    fr: "Informaticien - Responsable infrastructure",
    nl: "Informaticus - Verantwoordelijke infrastructuur",
  },
  {
    key: "Informaticien - responsable pour B2K",
    fr: "Informaticien - responsable pour B2K",
    nl: "Informaticus - Verantwoordelijke B2K",
  },
  {
    key: "Informaticien - Responsable projets informatique",
    fr: "Informaticien - Responsable projets informatique",
    nl: "Informaticus - Verantwoordelijke informatische projecten",
  },
  {
    key: "Informaticien - Responsable réseau",
    fr: "Informaticien - Responsable réseau",
    nl: "Informaticus - Verantwoordelijke netwerk",
  },
  {
    key: "Informaticien - Responsable système et réseaux",
    fr: "Informaticien - Responsable système et réseaux",
    nl: "Informaticus - Verantwoordelijke systeem en netwerk",
  },
  {
    key: "Informaticien - système et réseau",
    fr: "Informaticien - système et réseau",
    nl: "Informaticus - systeem en netwerk",
  },
  {
    key: "Informaticien - technicien",
    fr: "Informaticien - technicien",
    nl: "Informaticus - technieker",
  },
  {
    key: "Informaticien administration du système",
    fr: "Informaticien administration du système",
    nl: "Informaticus administratie van het systeem",
  },
  {
    key: "Informaticien applicatif",
    fr: "Informaticien applicatif",
    nl: "Informaticus applicaties",
  },
  {
    key: "Informaticien bureaux à distance domaine",
    fr: "Informaticien bureaux à distance domaine",
    nl: "Informaticus remote desktop domein",
  },
  {
    key: "Informaticien DAV",
    fr: "Informaticien DAV",
    nl: "Informaticus KUA",
  },
  {
    key: "Informaticien infrastructure",
    fr: "Informaticien infrastructure",
    nl: "Informaticus infrastructuur",
  },
  {
    key: "Informaticien",
    fr: "Informaticien",
    nl: "Informaticus",
  },
  {
    key: "Ingénieur",
    fr: "Ingénieur",
    nl: "Ingenieur",
  },
  {
    key: "Ingénieur - Responsable de projet",
    fr: "Ingénieur - Responsable de projet",
    nl: "Ingenieur - Projectverantwoordelijke",
  },
  {
    key: "Ingénieur - Responsable technique",
    fr: "Ingénieur - Responsable technique",
    nl: "Ingenieur - Technisch verantwoordelijke",
  },
  {
    key: "Ingénieur architecte",
    fr: "Ingénieur architecte",
    nl: "Ingenieur architect",
  },
  {
    key: "Ingénieur Blanchisserie Inter Hospitalière 47",
    fr: "Ingénieur Blanchisserie Inter Hospitalière 47",
    nl: "Ingenieur wasserij Inter Hospitalière 47",
  },
  {
    key: "Ingénieur blanchisserie",
    fr: "Ingénieur blanchisserie",
    nl: "Ingenieur wasserij",
  },
  {
    key: "Ingénieur de projet",
    fr: "Ingénieur de projet",
    nl: "Projectingenieur",
  },
  {
    key: "Ingénieur département infrastructures",
    fr: "Ingénieur département infrastructures",
    nl: "Ingenieur departement infrastructuur",
  },
  {
    key: "Ingenieur en chef",
    fr: "Ingenieur en chef",
    nl: "Ingenieur verantwoordelijke",
  },
  {
    key: "Ingénieur fonction linge",
    fr: "Ingénieur fonction linge",
    nl: "Ingenieur linnenfunctie",
  },
  {
    key: "Ingénieur logistique",
    fr: "Ingénieur logistique",
    nl: "Logistiek ingenieur",
  },
  {
    key: "Ingénieur principal - Responsable technique BIH du Val d'Oise",
    fr: "Ingénieur principal - Responsable technique BIH du Val d'Oise",
    nl: "Hoofdingenieur - Technisch verantwoordelijke BIH du Val d'Oise",
  },
  {
    key: "Ingénieur principal - direction technique",
    fr: "Ingénieur principal - direction technique",
    nl: "Hoofdingenieur - Technische directie",
  },
  {
    key: "Ingenieur projets - technicien",
    fr: "Ingenieur projets - technicien",
    nl: "Projectingenieur - Technieker",
  },
  {
    key: "Interlocuteur officiel pour la gestion de l'installation - Anett",
    fr: "Interlocuteur officiel pour la gestion de l'installation - Anett",
    nl: "Officieel contact voor facility management - Anett",
  },
  {
    key: "Juge de l'administration alimentaire",
    fr: "Juge de l'administration alimentaire",
    nl: "Voedseladministratie rechter",
  },
  {
    key: "Kalhyge",
    fr: "Kalhyge",
    nl: "Kalhyge",
  },
  {
    key: "Linger",
    fr: "Linger",
    nl: "Linnenman",
  },
  {
    key: "Linger - Lingère",
    fr: "Linger - Lingère",
    nl: "Linnenman - Linnenvrouw",
  },
  {
    key: "Linger sur place (Servitex)",
    fr: "Linger sur place (Servitex)",
    nl: "Linnenman ter plaatse (Servitex)",
  },
  {
    key: "Lingère",
    fr: "Lingère",
    nl: "Linnenvrouw",
  },
  {
    key: "Lingère (dect)",
    fr: "Lingère (dect)",
    nl: "Linnenvrouw (dect)",
  },
  {
    key: "Lingère à Montreuil",
    fr: "Lingère à Montreuil",
    nl: "Linnenvrouw bij Montreuil",
  },
  {
    key: "Lingère Cleanlease",
    fr: "Lingère Cleanlease",
    nl: "Linnenvrouw Cleanlease",
  },
  {
    key: "Lingère et linger Cleanlease All-in",
    fr: "Lingère et linger Cleanlease All-in",
    nl: "Linnenvrouw en linnenman Cleanlease All-in",
  },
  {
    key: "Logiciel pour la blanchisserie et le nettoyage à sec, envoie des commandes à la machine Metalprogetti",
    fr: "Logiciel pour la blanchisserie et le nettoyage à sec, envoie des commandes à la machine Metalprogetti",
    nl: "Software voor wasserij en stomerij, stuurt commando's naar de machine Metalprogetti",
  },
  {
    key: "Logistique",
    fr: "Logistique",
    nl: "Logistiek",
  },
  {
    key: "Logistique - achateuse",
    fr: "Logistique - achateuse",
    nl: "Logistiek - Aankoopster",
  },
  {
    key: "Logistique et travaux Huderf",
    fr: "Logistique et travaux Huderf",
    nl: "Logistiek en werken Huderf",
  },
  {
    key: "Logistique patient - Responsable de service",
    fr: "Logistique patient - Responsable de service",
    nl: "Logistiek patiënten - Verantwoordelijke van de dienst",
  },
  {
    key: "Magasin central",
    fr: "Magasin central",
    nl: "Centaal magazijn",
  },
  {
    key: "Même fonction que Jean-Pierre Ovart",
    fr: "Même fonction que Jean-Pierre Ovart",
    nl: "Zelfde functie dan Jean-Pierre Ovart",
  },
  {
    key: "MRG",
    fr: "MRG",
    nl: "MRG",
  },
  {
    key: "Opérateur système",
    fr: "Opérateur système",
    nl: "Systeemoperator",
  },
  {
    key: "Personne de contact projet DAV - informaticien",
    fr: "Personne de contact projet DAV - informaticien",
    nl: "Contactpersoon KUA project - informaticus",
  },
  {
    key: "PLC DAV QOP",
    fr: "PLC DAV QOP",
    nl: "PLC KUA QOP",
  },
  {
    key: "Pôle Marchés Publics - Investissements",
    fr: "Pôle Marchés Publics - Investissements",
    nl: "Pôle Marchés Publics - Investeringen",
  },
  {
    key: "Pour l'envoi des factures",
    fr: "Pour l'envoi des factures",
    nl: "Voor het versturen van facturen",
  },
  {
    key: "Pour questions de facturation",
    fr: "Pour questions de facturation",
    nl: "Voor vragen over facturatie",
  },
  {
    key: "Prévention",
    fr: "Prévention",
    nl: "Preventie",
  },
  {
    key: "Protection antivirus",
    fr: "Protection antivirus",
    nl: "Antivirus beveiliging",
  },
  {
    key: "Remplaçant de Mme Lamberts",
    fr: "Remplaçant de Mme Lamberts",
    nl: "Vervanger Mme Lamberts",
  },
  {
    key: "Remplace Catherine Lopez lors de ses absences",
    fr: "Remplace Catherine Lopez lors de ses absences",
    nl: "Vervangt Catherine Lopez indien afwezig",
  },
  {
    key: "Remplace Steven Van Eester quand il n'est pas la",
    fr: "Remplace Steven Van Eester quand il n'est pas la",
    nl: "Vervangt Steven Van Eester indien afwezig",
  },
  {
    key: "Rénovation de l'électricité et des murs des locaux",
    fr: "Rénovation de l'électricité et des murs des locaux",
    nl: "Renovatie van de elektriciteit en muren van de lokalen",
  },
  {
    key: "Resources humaines",
    fr: "Resources humaines",
    nl: "Personeelsdienst",
  },
  {
    key: "Responsable",
    fr: "Responsable",
    nl: "Verantwoordelijke",
  },
  {
    key: "Responsable achats",
    fr: "Responsable achats",
    nl: "Aankoopverantwoordelijke",
  },
  {
    key: "Responsable achats et logistique",
    fr: "Responsable achats et logistique",
    nl: "Logistiek en aankoopverantwoordelijke",
  },
  {
    key: "Responsable adjoint du nettoyage",
    fr: "Responsable adjoint du nettoyage",
    nl: "Assistent verantwoordelijke schoonmaak",
  },
  {
    key: "Responsable adjoint maintenance",
    fr: "Responsable adjoint maintenance",
    nl: "Assistent verantwoordelijke onderhoud",
  },
  {
    key: "Responsable antenne informatique et téléphonie",
    fr: "Responsable antenne informatique et téléphonie",
    nl: "Verantwoordelijke antenne informatica en telefonie",
  },
  {
    key: "Responsable approvisionnement et qualité - Blanchisserie du CHU de Toulouse",
    fr: "Responsable approvisionnement et qualité - Blanchisserie du CHU de Toulouse",
    nl: "Verantwoordelijke levering en kwaliteit - Wasserij CHU de Toulouse",
  },
  {
    key: "Responsable blanchisserie - Responsable Logistique",
    fr: "Responsable blanchisserie - Responsable Logistique",
    nl: "Verantwoordelijke wasserij - Logistiek verantwoordelijke",
  },
  {
    key: "Responsable blanchisserie CPAS",
    fr: "Responsable blanchisserie CPAS",
    nl: "Verantwoordelijke wasserij CPAS",
  },
  {
    key: "Responsable blanchisserie",
    fr: "Responsable blanchisserie",
    nl: "Verantwoordelijke wasserij",
  },
  {
    key: "Responsable brigadiers",
    fr: "Responsable brigadiers",
    nl: "Verantwoordelijke brigadiers",
  },
  {
    key: "Responsable chantier",
    fr: "Responsable chantier",
    nl: "Werfverantwoordelijke",
  },
  {
    key: "Responsable clientèle",
    fr: "Responsable clientèle",
    nl: "Verantwoordelijke klanten",
  },
  {
    key: "Responsable commercial",
    fr: "Responsable commercial",
    nl: "Verkoopsverantwoordelijke",
  },
  {
    key: "Responsable commercial healthcare",
    fr: "Responsable commercial healthcare",
    nl: "Verkoopsverantwoordelijke healtcare",
  },
  {
    key: "Responsable DAV",
    fr: "Responsable DAV",
    nl: "Verantwoordelijke KUA",
  },
  {
    key: "Responsable DAV - Coordinateur housekeeping",
    fr: "Responsable DAV - Coordinateur housekeeping",
    nl: "Verantwoordelijke KUA - Coödinator houskeeping",
  },
  {
    key: "Responsable DAV - Responsable de l'équipe locale",
    fr: "Responsable DAV - Responsable de l'équipe locale",
    nl: "Verantwoordelijke KUA - Verantwoordelijke lokaal team",
  },
  {
    key: "Responsable DAV (Kalhyge)",
    fr: "Responsable DAV (Kalhyge)",
    nl: "Verantwoordelijke KUA (Kalhyge)",
  },
  {
    key: "Responsable DAV Madeleine Bres",
    fr: "Responsable DAV Madeleine Bres",
    nl: "Verantwoordelijke KUA Madeleine Bres",
  },
  {
    key: "Responsable DAV Initial",
    fr: "Responsable DAV Initial",
    nl: "Verantwoordelijke KUA Initial",
  },
  {
    key: "Responsable de centre du Luxembourg",
    fr: "Responsable de centre du Luxembourg",
    nl: "Verantwoordelijke centre du Luxembourg",
  },
  {
    key: "Responsable de Centre Elis Liège",
    fr: "Responsable de Centre Elis Liège",
    nl: "Verantwoordelijke centre Elis Liège",
  },
  {
    key: "Responsable de chantier - technicien",
    fr: "Responsable de chantier - technicien",
    nl: "Werfverantwoordelijke - Technieker",
  },
  {
    key: "Responsable de chantiers techniques",
    fr: "Responsable de chantiers techniques",
    nl: "Werfverantwoordelijke technische werven",
  },
  {
    key: "Responsable de la logistique et de la blanchisserie interne",
    fr: "Responsable de la logistique et de la blanchisserie interne",
    nl: "Logistiek verantwoordelijke en verantwoordelijke wasserij intern",
  },
  {
    key: "Responsable de l'équipe locale - Responsable DAV - Housekeeping",
    fr: "Responsable de l'équipe locale - Responsable DAV - Housekeeping",
    nl: "Verantwoordelijke lokaal team - Verantwoordelijke KUA - Housekeeping",
  },
  {
    key: "Responsable de l'équipe locale housekeeping",
    fr: "Responsable de l'équipe locale housekeeping",
    nl: "Verantwoordelijke lokaal team houskeeping",
  },
  {
    key: "Responsable de maintenance Elis santé Rousset",
    fr: "Responsable de maintenance Elis santé Rousset",
    nl: "Verantwoordelijke onderhoud Elis santé Rousset",
  },
  {
    key: "Responsable de projet automatisation et DAV - ELIS Services",
    fr: "Responsable de projet automatisation et DAV - ELIS Services",
    nl: "Projectverantwoordelijke automatisatie en KUA - Elis diensten",
  },
  {
    key: "Responsable de projets informatique",
    fr: "Responsable de projets informatique",
    nl: "Projectverantwoordelijke informatica",
  },
  {
    key: "Responsable de secteur Cofely",
    fr: "Responsable de secteur Cofely",
    nl: "Sectorverantwoordelijke Cofely",
  },
  {
    key: "Responsable de secteurs - Logistique patients",
    fr: "Responsable de secteurs - Logistique patients",
    nl: "Sectorverantwoordelijke - Logistiek patiënten",
  },
  {
    key: "Responsable de service",
    fr: "Responsable de service",
    nl: "Dienstverantwoordelijke",
  },
  {
    key: "Responsable de soins",
    fr: "Responsable de soins",
    nl: "Zorgverantwoordelijke",
  },
  {
    key: "Responsable département facilitaire",
    fr: "Responsable département facilitaire",
    nl: "Verantwoordelijke facilitair departement",
  },
  {
    key: "Responsable d'équipe",
    fr: "Responsable d'équipe",
    nl: "Ploegverantwoordelijke - Ploegbaas",
  },
  {
    key: "Responsable d'équipe Cofely",
    fr: "Responsable d'équipe Cofely",
    nl: "Ploegverantwoordelijke Cofely",
  },
  {
    key: "Responsable des achats / facturation",
    fr: "Responsable des achats / facturation",
    nl: "Aankoopverantwoordelijke / facturatie",
  },
  {
    key: "Responsable des achats et des matériaux",
    fr: "Responsable des achats et des matériaux",
    nl: "Aankoop en materiaalverantwoordelijke",
  },
  {
    key: "Responsable des achats",
    fr: "Responsable des achats",
    nl: "Aankoopverantwoordelijke",
  },
  {
    key: "Responsable des ateliers électricité et préventif lits",
    fr: "Responsable des ateliers électricité et préventif lits",
    nl: "Verantwoordelijk voor de elektriciteits- en bedpreventiewerkplaatsen",
  },
  {
    key: "Responsable des DAV Isosel - entretien",
    fr: "Responsable des DAV Isosel - entretien",
    nl: "Verantwoordelijke KUA's Isosel - Onderhoud",
  },
  {
    key: "Responsable des DAV ISOSL",
    fr: "Responsable des DAV ISOSL",
    nl: "Verantwoordelijke KUA's ISOSL",
  },
  {
    key: "Responsable des grands comptes",
    fr: "Responsable des grands comptes",
    nl: "Key accountmanager",
  },
  {
    key: "Responsable des infrastructures",
    fr: "Responsable des infrastructures",
    nl: "Infrastructuurverantwoordelijke",
  },
  {
    key: "Responsable des opérations",
    fr: "Responsable des opérations",
    nl: "Verantwoordelijke verrichtingen",
  },
  {
    key: "Responsable des prestations externalisées - logistique",
    fr: "Responsable des prestations externalisées - logistique",
    nl: "Verantwoordelijke externe prestaties  - Logistiek",
  },
  {
    key: "Responsable des services généraux - Direction de la logistique",
    fr: "Responsable des services généraux - Direction de la logistique",
    nl: "Verantwoordelijke algemene diensten - Directie logistiek",
  },
  {
    key: "Responsable des services ménagers",
    fr: "Responsable des services ménagers",
    nl: "Verantwoordelijke dienst schoonmaak",
  },
  {
    key: "Responsable des services sechniques et hôteliers",
    fr: "Responsable des services techniques et hôteliers",
    nl: "Verantwoordelijke technische dienst en hotelerie",
  },
  {
    key: "Responsable des sites CHU",
    fr: "Responsable des sites CHU",
    nl: "Verantwoordelijke van de sites CHU",
  },
  {
    key: "Responsable des travaux",
    fr: "Responsable des travaux",
    nl: "Verantwoordelijke van de werken",
  },
  {
    key: "Responsable d'exploitation informatique",
    fr: "Responsable d'exploitation informatique",
    nl: "IT-bedieningsbeheerder",
  },
  {
    key: "Responsable DIL-Département des infrastrutures et de la logistique",
    fr: "Responsable DIL-Département des infrastrutures et de la logistique",
    nl: "Verantwoordelijke van de DIL-afdeling van de afdeling Infrastructuur en logistiek",
  },
  {
    key: "Responsable du département Administration générale",
    fr: "Responsable du département Administration générale",
    nl: "Verantwoordelijke van de afdeling algemeen beheer",
  },
  {
    key: "Responsable du département des installations",
    fr: "Responsable du département des installations",
    nl: "Verantwoordelijke installatie afdeling",
  },
  {
    key: "Responsable du personnel",
    fr: "Responsable du personnel",
    nl: "Personeelsverantwoordelijke",
  },
  {
    key: "Responsable du pôle logistique et du patrimoine",
    fr: "Responsable du pôle logistique et du patrimoine",
    nl: "Verantwoordelijke van de afdeling logistiek en erfgoed",
  },
  {
    key: "Responsable du service",
    fr: "Responsable du service",
    nl: "Afdelingsverantwoordelijke",
  },
  {
    key: "Responsable du service logistique",
    fr: "Responsable du service logistique",
    nl: "Verantwoordelijke afdeling logistiek",
  },
  {
    key: "Responsable du site Molière Longchamps",
    fr: "Responsable du site Molière Longchamps",
    nl: "Site verantwoordelijke Molière Longchamps",
  },
  {
    key: "Responsable du site",
    fr: "Responsable du site",
    nl: "Site verantwoordelijke",
  },
  {
    key: "Responsable électriciens",
    fr: "Responsable électriciens",
    nl: "Verantwoordelijke elektriekers",
  },
  {
    key: "Responsable électrique",
    fr: "Responsable électrique",
    nl: "Elektrisch verantwoordelijke",
  },
  {
    key: "Responsable électromécaniciens",
    fr: "Responsable électromécaniciens",
    nl: "Verantwoordelijke elektromechaniekers",
  },
  {
    key: "Responsable Elis",
    fr: "Responsable Elis",
    nl: "Verantwoordelijke Elis",
  },
  {
    key: "Responsable entretien",
    fr: "Responsable entretien",
    nl: "Verantwoordelijke onderhoud",
  },
  {
    key: "Responsable entretien technique",
    fr: "Responsable entretien technique",
    nl: "Verantwoordelijke technisch onderhoud",
  },
  {
    key: "Responsable exploitation & déploiement DSI",
    fr: "Responsable exploitation & déploiement DSI",
    nl: "Operations & IT deployment manager",
  },
  {
    key: "Responsable factures du service achats",
    fr: "Responsable factures du service achats",
    nl: "Verantwoordelijke facturen en aankoopdienst",
  },
  {
    key: "Responsable financier",
    fr: "Responsable financier",
    nl: "Financieel verantwoordelijke",
  },
  {
    key: "Responsable fonction linge - achats et logistique",
    fr: "Responsable fonction linge - achats et logistique",
    nl: "Verantwoordelijke linnendienst - aankoop en logistiek",
  },
  {
    key: "Responsable gestion administrative",
    fr: "Responsable gestion administrative",
    nl: "Verantwoordelijke administratief beheer",
  },
  {
    key: "Responsable gestion DAV et membres du personnel",
    fr: "Responsable gestion DAV et membres du personnel",
    nl: "Verantwoordelijke KUA en personeelsleden",
  },
  {
    key: "Responsable gestion information",
    fr: "Responsable gestion information",
    nl: "Verantwoordelijke informatiebeheer",
  },
  {
    key: "Responsable hôtelier - achats et logistique",
    fr: "Responsable hôtelier - achats et logistique",
    nl: "Verantwoordelijke hotelerie - aankoop en logistiek",
  },
  {
    key: "Responsable hôtelier",
    fr: "Responsable hôtelier",
    nl: "Verantwoordelijke hotelerie",
  },
  {
    key: "Responsable housekeeping et service technique",
    fr: "Responsable housekeeping et service technique",
    nl: "Verantwoordelijke housekeeping en technische dienst",
  },
  {
    key: "Responsable housekeeping",
    fr: "Responsable housekeeping",
    nl: "Verantwoordelijke housekeeping",
  },
  {
    key: "Responsable hygiène et environnement",
    fr: "Responsable hygiène et environnement",
    nl: "Verantwoordelijke hygiëne en omgeving",
  },
  {
    key: "Responsable informatique",
    fr: "Responsable informatique",
    nl: "Verantwoordelijke informatica",
  },
  {
    key: "Responsable informatique CAP",
    fr: "Responsable informatique CAP",
    nl: "Verantwoordelijke informatica CAP",
  },
  {
    key: "Responsable informatique Lavotel",
    fr: "Responsable informatique Lavotel",
    nl: "Verantwoordelijke informatica Lavotel",
  },
  {
    key: "Responsable infrastructure et techniques",
    fr: "Responsable infrastructure et techniques",
    nl: "Verantwoordelijke infrastructuur en techniek",
  },
  {
    key: "Responsable lingerie",
    fr: "Responsable lingerie",
    nl: "Verantwoordelijke linnendienst",
  },
  {
    key: "Responsable lingerie - Responsable de l'équipe locale",
    fr: "Responsable lingerie - Responsable de l'équipe locale",
    nl: "Verantwoordelijke linnendienst - Verantwoordelijke lokaal team",
  },
  {
    key: "Responsable lingerie (Malysse All-in)",
    fr: "Responsable lingerie (Malysse All-in)",
    nl: "Verantwoordelijke linnendienst (Malysse All-in)",
  },
  {
    key: "Responsable lingerie (remplace Linda)",
    fr: "Responsable lingerie (remplace Linda)",
    nl: "Verantwoordelijke linnendienst (vervangt Linda)",
  },
  {
    key: "Responsable lingerie et entretien",
    fr: "Responsable lingerie et entretien",
    nl: "Verantwoordelijke linnendienst en onderhoud",
  },
  {
    key: "Responsable lingerie GZA - achats et logistique",
    fr: "Responsable lingerie GZA - achats et logistique",
    nl: "Verantwoordelijke linnendienst GZA - aankopen en logistiek",
  },
  {
    key: "Responsable lingerie Servitex",
    fr: "Responsable lingerie Servitex",
    nl: "Verantwoordelijke linenndienst Servitex",
  },
  {
    key: "Responsable location vêtement ou vêtement nouveau puçé, Web module vêtement commande",
    fr: "Responsable location vêtement ou vêtement nouveau puçé, Web module vêtement commande",
    nl: "Verantwoordelijke voor het huren van kleding of kledij met nieuwe chips, Webmodule kleding bestellen",
  },
  {
    key: "Responsable logistique",
    fr: "Responsable logistique",
    nl: "Logistiek verantwoordelijke",
  },
  {
    key: "Responsable logistique - Adjoint responsable achats",
    fr: "Responsable logistique - Adjoint responsable achats",
    nl: "Logistiek verantwoordelijke - Assistent verantwoordelijke aankopen",
  },
  {
    key: "Responsable logistique - Coordinatrice",
    fr: "Responsable logistique - Coordinatrice",
    nl: "Logistiek verantwoordelijke - Coördinatrice",
  },
  {
    key: "Responsable magasin et DAV",
    fr: "Responsable magasin et DAV",
    nl: "Verantwoordelijke magazijn en KUA",
  },
  {
    key: "Responsable maintenance",
    fr: "Responsable maintenance",
    nl: "Verantwoordelijke onderhoud",
  },
  {
    key: "Responsable maintenance de Brie Comte Robert et Nangis",
    fr: "Responsable maintenance de Brie Comte Robert et Nangis",
    nl: "Verantwoordelijke onderhoud van Brie Comte Robert en Nangis",
  },
  {
    key: "Responsable maintenance Elis Watrelos",
    fr: "Responsable maintenance Elis Watrelos",
    nl: "Verantwoordeljike onderhoud Elis Watrelos",
  },
  {
    key: "Responsable maintenance et entretien - One Monte-Carlo",
    fr: "Responsable maintenance et entretien - One Monte-Carlo",
    nl: "Verantwoordelijke onderhoud - One Monte-Carlo",
  },
  {
    key: "Responsable maintenance et sécurité",
    fr: "Responsable maintenance et sécurité",
    nl: "Verantwoordelijke onderhoud en beveiliging",
  },
  {
    key: "Responsable maintenance RLD Boulogne s/ Mer",
    fr: "Responsable maintenance RLD Boulogne sur Mer",
    nl: "Verantwoordelijke onderhoud RLD Boulogne sur Mer",
  },
  {
    key: "Responsable matériel informatique de la SBM",
    fr: "Responsable matériel informatique de la SBM",
    nl: "Verantwoordelijke informaticamateriaal van SBM",
  },
  {
    key: "Responsable ménage - Soins",
    fr: "Responsable ménage - Soins",
    nl: "Verantwoordelijke schoonmaak - zorg",
  },
  {
    key: "Responsable nettoyage",
    fr: "Responsable nettoyage",
    nl: "Verantwoordelijke schoonmaak",
  },
  {
    key: "Responsable operations & projets",
    fr: "Responsable operations & projets",
    nl: "Verantwoordelijke verrichtingen en projecten",
  },
  {
    key: "Responsable paie",
    fr: "Responsable paie",
    nl: "Verantwoordelijke betalingen",
  },
  {
    key: "Responsable Pôle Marchés Publics - Direction des achats logistique et équipement",
    fr: "Responsable Pôle Marchés Publics - Direction des achats logistique et équipement",
    nl: "Verantwoordelijke van de afdeling overheidsopdrachten - Verantwoordelijke aankoop van logistiek en materieel",
  },
  {
    key: "Responsable production Elis Rouen Normandie",
    fr: "Responsable production Elis Rouen Normandie",
    nl: "Verantwoordelijke productie Elis Rouen Normandie",
  },
  {
    key: "Responsable projet DAV - logistique",
    fr: "Responsable projet DAV - logistique",
    nl: "Verantwoordelijke KUA project - logistiek",
  },
  {
    key: "Responsable projet informatique",
    fr: "Responsable projet informatique",
    nl: "Verantwoordelijke projecten informatica",
  },
  {
    key: "Responsable projet",
    fr: "Responsable projet",
    nl: "Projectverantwoordelijke",
  },
  {
    key: "Responsable projet Cleanlease Fortex",
    fr: "Responsable projet Cleanlease Fortex",
    nl: "Projectverantwoordelijke Cleanlease Fortex",
  },
  {
    key: "Responsable secteur operations Wallonie",
    fr: "Responsable secteur operations Wallonie",
    nl: "Verantwoordelijke sectorale operaties Wallonië",
  },
  {
    key: "Responsable service achats",
    fr: "Responsable service achats",
    nl: "Verantwoordelijke aankoopdienst",
  },
  {
    key: "Responsable service administratif",
    fr: "Responsable service administratif",
    nl: "Verantwoordelijke afdministratieve dienst",
  },
  {
    key: "Responsable service administratif (Depairon)",
    fr: "Responsable service administratif (Depairon)",
    nl: "Verantwoordelijke afdministratieve dienst (Depairon)",
  },
  {
    key: "Responsable service de nettoyage",
    fr: "Responsable service de nettoyage",
    nl: "Verantwoordelijke dienst schoonmaak",
  },
  {
    key: "Responsable service des magasins & des flux logistiques",
    fr: "Responsable service des magasins & des flux logistiques",
    nl: "Verantwoordelijke van de dienst Warehouse & Logistics Flow",
  },
  {
    key: "Responsable service logistique Elis",
    fr: "Responsable service logistique Elis",
    nl: "Verantwoordelijke logistieke dienst Elis",
  },
  {
    key: "Responsable service nettoyage et entretien",
    fr: "Responsable service nettoyage et entretien",
    nl: "Verantwoordelijke dienst schoonmaak en onderhoud",
  },
  {
    key: "Responsable services economiques - achats",
    fr: "Responsable services economiques - achats",
    nl: "Verantwoordelijke economische dienst en aankoopdienst",
  },
  {
    key: "Responsable services facilitaires",
    fr: "Responsable services facilitaires",
    nl: "Verantwoordelijke facilitaire dienst",
  },
  {
    key: "Responsable services hôteliers (Responsable service facilitair)",
    fr: "Responsable services hôteliers (Responsable service facilitair)",
    nl: "Hotel Services Manager (Facilities Manager)",
  },
  {
    key: "Responsable services hôteliers",
    fr: "Responsable services hôteliers",
    nl: "Hotel Services Manager",
  },
  {
    key: "Responsable système MCS (nouveau systéme rapport)",
    fr: "Responsable système MCS (nouveau systéme rapport)",
    nl: "Systeembeheerder MCS (nieuw rapportagesysteem)",
  },
  {
    key: "Responsable technique",
    fr: "Responsable technique",
    nl: "Technisch verantwoordelijke",
  },
  {
    key: "Responsable technique (Contremaître Maintenance)",
    fr: "Responsable technique (Contremaître Maintenance)",
    nl: "Technisch verantwoordelijke (Onderhoudsvoorman)",
  },
  {
    key: "Responsable technique adjoint",
    fr: "Responsable technique adjoint",
    nl: "Adjunct technisch verantwoordelijke",
  },
  {
    key: "Responsable technique Bracops",
    fr: "Responsable technique Bracops",
    nl: "Technisch verantwoordelijke Bracops",
  },
  {
    key: "Responsable technique du campus",
    fr: "Responsable technique du campus",
    nl: "Technisch verantwoordelijke van de campus",
  },
  {
    key: "Responsable technique du site",
    fr: "Responsable technique du site",
    nl: "Technisch verantwoordelijke van de site",
  },
  {
    key: "Responsable technique et des infrastructures",
    fr: "Responsable technique et des infrastructures",
    nl: "Technisch verantwoordelijke en verantwoordelijke infrastructuur",
  },
  {
    key: "Responsable technique régional",
    fr: "Responsable technique régional",
    nl: "Regionaal technisch verantwoordelijke",
  },
  {
    key: "Responsable technique réseau",
    fr: "Responsable technique réseau",
    nl: "Technisch verantwoordelijke netwerk",
  },
  {
    key: "Responsable technique SBM",
    fr: "Responsable technique SBM",
    nl: "Technisch verantwoordelijke SBM",
  },
  {
    key: "Responsable technique secteur maintenance électrique",
    fr: "Responsable technique secteur maintenance électrique",
    nl: "Technisch verantwoordelijke sector elektrisch onderhoud",
  },
  {
    key: "Responsable technique Site CHR",
    fr: "Responsable technique Site CHR",
    nl: "Technisch verantwoordelijke site CHR",
  },
  {
    key: "Responsable technique Anett",
    fr: "Responsable technique Anett",
    nl: "Technisch verantwoordelijke Anett",
  },
  {
    key: "Responsable travaux",
    fr: "Responsable travaux",
    nl: "Verantwoordelijke werkzaamheden",
  },
  {
    key: "Responsable VPN",
    fr: "Responsable VPN",
    nl: "Verantwoordelijke VPN",
  },
  {
    key: "Responsable blanchisserie externe",
    fr: "Responsable blanchisserie externe",
    nl: "Verantwoordelijke externe wasserij",
  },
  {
    key: "Responsable DAV",
    fr: "Responsable DAV",
    nl: "Verantwoordelijke KUA",
  },
  {
    key: "Responsable Elis",
    fr: "Responsable Elis",
    nl: "Verantwoordelijke Elis",
  },
  {
    key: "Responsable gouvernantes",
    fr: "Responsable gouvernantes",
    nl: "Verantwoordelijke gouvernantes",
  },
  {
    key: "Responsable hôtelier",
    fr: "Responsable hôtelier",
    nl: "Verantwoordelijke hotelerie",
  },
  {
    key: "Responsable informatique",
    fr: "Responsable informatique",
    nl: "Verantwoordelijke informatica",
  },
  {
    key: "Responsable informatique de la SBM",
    fr: "Responsable informatique de la SBM",
    nl: "Verantwoordelijke informatica SBM",
  },
  {
    key: "Responsable intendance",
    fr: "Responsable intendance",
    nl: "Verantwoordelijke stewardship",
  },
  {
    key: "Responsable lingerie",
    fr: "Responsable lingerie",
    nl: "Verantwoordelijke linnendienst",
  },
  {
    key: "Responsable logistique",
    fr: "Responsable logistique",
    nl: "Logistiek verantwoordelijke",
  },
  {
    key: "Responsable maintenance et sécurité",
    fr: "Responsable maintenance et sécurité",
    nl: "Verantwoordelijke onderhoud en beveiliging",
  },
  {
    key: "Responsable technique",
    fr: "Responsable technique",
    nl: "Technisch verantwoordelijke",
  },
  {
    key: "Responsable technique Elis",
    fr: "Responsable technique Elis",
    nl: "Technisch verantwoordelijke Elis",
  },
  {
    key: "Responsable-adjoint des Marchés Publics & Assurances",
    fr: "Responsable-adjoint des Marchés Publics & Assurances",
    nl: "Plaatsvervangend verantwoordelijke publieke markten & verzekeringen",
  },
  {
    key: "Responsable-adjoint du service technique",
    fr: "Responsable-adjoint du service technique",
    nl: "Plaatsvervangend technisch verantwoordelijke",
  },
  {
    key: "Responsables informatique",
    fr: "Responsables informatique",
    nl: "Verantwoordelijken informatica",
  },
  {
    key: "SAV",
    fr: "SAV",
    nl: "Dienst na aankoop",
  },
  {
    key: "Seconde assistante gouvernante générale - Lingère",
    fr: "Seconde assistante gouvernante générale - Lingère",
    nl: "Tweede assistent-gouverneur-generaal - Linnendame",
  },
  {
    key: "Secrétaire de la direction des services généraux",
    fr: "Secrétaire de la direction des services généraux",
    nl: "Secretaris van de algemene dienstendirectie",
  },
  {
    key: "Secrétaire service technique",
    fr: "Secrétaire service technique",
    nl: "Secretaris technische dienst",
  },
  {
    key: "Secrétaire services facilitaire",
    fr: "Secrétaire services facilitaire",
    nl: "Secretaris facilitaire dienst",
  },
  {
    key: "Secrétaire technique",
    fr: "Secrétaire technique",
    nl: "Technisch secretaris",
  },
  {
    key: "Secrétariat de la direction des achats",
    fr: "Secrétariat de la direction des achats",
    nl: "Secretariaat directie aankoopdienst",
  },
  {
    key: "Secrétariat exécutif",
    fr: "Secrétariat exécutif",
    nl: "Uitvoerend secretaris",
  },
  {
    key: "Sécurité",
    fr: "Sécurité",
    nl: "Beveiliging",
  },
  {
    key: "Service achats",
    fr: "Service achats",
    nl: "Aankoopdienst",
  },
  {
    key: "Service achats - Site IMC",
    fr: "Service achats - Site IMC",
    nl: "Aankoopdienst - site IMC",
  },
  {
    key: "Service Biomédical",
    fr: "Service Biomédical",
    nl: "Biomedische dienst",
  },
  {
    key: "Service client",
    fr: "Service client",
    nl: "Klantendienst",
  },
  {
    key: "Service commercial - gestionnaire de compte",
    fr: "Service commercial - gestionnaire de compte",
    nl: "Verkoopsdienst - accountbeheerder",
  },
  {
    key: "Service des achats - gère les contrats",
    fr: "Service des achats - gère les contrats",
    nl: "Aankoopdienst - beheerd de contracten",
  },
  {
    key: "Service du linge CPAS de Bruxelles",
    fr: "Service du linge CPAS de Bruxelles",
    nl: "Linnendienst CPAS Brussel",
  },
  {
    key: "Service économique et financier",
    fr: "Service économique et financier",
    nl: "Economische en financiële dienst",
  },
  {
    key: "Service facilitaire",
    fr: "Service facilitaire",
    nl: "Facilitaire dienst",
  },
  {
    key: "Service facilitaire de personel",
    fr: "Service facilitaire de personel",
    nl: "Facilitaire personeelsdienst",
  },
  {
    key: "Service gardiennage",
    fr: "Service gardiennage",
    nl: "Dienst bewaking",
  },
  {
    key: "Service informatique",
    fr: "Service informatique",
    nl: "Dienst informatica",
  },
  {
    key: "Service resources humaine",
    fr: "Service resources humaine",
    nl: "Personeelsdienst",
  },
  {
    key: "Service technique",
    fr: "Service technique",
    nl: "Technische dienst",
  },
  {
    key: "SIM Responsable systèmes et réseau - informaticien",
    fr: "SIM Responsable systèmes et réseau - informaticien",
    nl: "SIM-systemen en netwerkbeheerder - informaticus",
  },
  {
    key: "Stagiaire - Direction technique",
    fr: "Stagiaire - Direction technique",
    nl: "Stagiair - Technische directie",
  },
  {
    key: "Suivi quotidien du DAV",
    fr: "Suivi quotidien du DAV",
    nl: "Dagelijks toezicht KUA",
  },
  {
    key: "Superviseur de la blanchisserie",
    fr: "Superviseur de la blanchisserie",
    nl: "Wasserijtoezichthouder",
  },
  {
    key: "Support DAV",
    fr: "Support DAV",
    nl: "KUA support",
  },
  {
    key: "Système de badges uzb - sécurité",
    fr: "Système de badges uzb - sécurité",
    nl: "Badgessysteem UZB - beveiliging",
  },
  {
    key: "Technicien",
    fr: "Technicien",
    nl: "Technieker",
  },
  {
    key: "Technicien - Directeur des départements de supports",
    fr: "Technicien - Directeur des départements de supports",
    nl: "Technieker - Directeur support dienst",
  },
  {
    key: "Technicien - Responsable des sites CHU",
    fr: "Technicien - Responsable des sites CHU",
    nl: "Technieker - verantwoordelijke sites CHU",
  },
  {
    key: "Technicien de la blanchisserie",
    fr: "Technicien de la blanchisserie",
    nl: "Technieker van de wasserij",
  },
  {
    key: "Technicien en charge de la maintenance",
    fr: "Technicien en charge de la maintenance",
    nl: "Technieker toegewezen aan het onderhoud",
  },
  {
    key: "Technicien Engie",
    fr: "Technicien Engie",
    nl: "Technieker Engie",
  },
  {
    key: "Technicien prévention",
    fr: "Technicien prévention",
    nl: "Technieker preventie",
  },
  {
    key: "Technicien supérieur hospitalier",
    fr: "Technicien supérieur hospitalier",
    nl: "Superieur ziekehuistechnieker",
  },
  {
    key: "Technicien - informaticien",
    fr: "Technicien - informaticien",
    nl: "Technieker - informaticus",
  },
  {
    key: "Technicien, électricien de Chwapi",
    fr: "Technicien, électricien de Chwapi",
    nl: "Technieker, elektrieker Chwapi",
  },
  {
    key: "Techniciens",
    fr: "Techniciens",
    nl: "Techniekers",
  },
  {
    key: "Transport, Logistique",
    fr: "Transport, Logistique",
    nl: "Transport, logistiek",
  },
  {
    key: "Vigile",
    fr: "Vigile",
    nl: "Vigil",
  },
  {
    key: "Voir notes",
    fr: "Voir notes",
    nl: "Zie nota's",
  },
  {
    key: "Wifi pour EB",
    fr: "Wifi pour EB",
    nl: "Wifi voor EB",
  },
  {
    key: "serial",
    fr: "serial",
    nl: "Serienummer",
  },
  {
    key: "software",
    fr: "Programme",
    nl: "Programma",
  },
  {
    key: "database",
    fr: "Base de données",
    nl: "Database",
  },
  {
    key: "chipreader",
    fr: "Lecteur de puces",
    nl: "Chiplezer",
  },
  {
    key: "compressor",
    fr: "Compresseur",
    nl: "Compressor",
  },
  {
    key: "hangerRecup",
    fr: "Récuperation des cintres",
    nl: "Kapstokrecuperatie",
  },
  {
    key: "Aucun contrat",
    fr: "Aucun contrat",
    nl: "Geen contract",
  },
  {
    key: "Basic 2",
    fr: "Basic 2",
    nl: "Basic 2",
  },
  {
    key: "Basic 1",
    fr: "Basic 1",
    nl: "Basic 1",
  },
  {
    key: "Rien, convoyeur gauche-droite",
    fr: "Rien, convoyeur gauche-droite",
    nl: "Geen, baan links-rechts",
  },
  {
    key: "Aucun",
    fr: "Aucun",
    nl: "Geen",
  },
  {
    key: "Sur serveur",
    fr: "Sur serveur",
    nl: "Op server",
  },
  {
    key: "Locale",
    fr: "Locale",
    nl: "Lokaal",
  },
  {
    key: "Sur serveur avec synchro foxpro",
    fr: "Sur serveur avec synchro foxpro",
    nl: "Op server met synchronisatie foxpro",
  },
  {
    key: "Sur serveur SQL",
    fr: "Sur serveur SQL",
    nl: "Op SQL server",
  },
  {
    key: "Sur serveur - base de données partagé",
    fr: "Sur serveur - base de données partagé",
    nl: "Op server - gedeelde database",
  },
  {
    key: "Sur serveur commun (Moxa)",
    fr: "Sur serveur commun (Moxa)",
    nl: "Op gemeenschappelijke server (Moxa)",
  },
  {
    key: "Sur serveur avec replication SQL",
    fr: "Sur serveur avec replication SQL",
    nl: "Op server met SQL replicatie",
  },
  {
    key: "Sur serveur avec Moxa",
    fr: "Sur serveur avec Moxa",
    nl: "Op server met Moxa",
  },
  {
    key: "Aucune",
    fr: "Aucune",
    nl: "Geen",
  },
  {
    key: "Code barre",
    fr: "Code barre",
    nl: "Barcode",
  },
  {
    key: "HF - Scemtec antenne en Tagsys decoder",
    fr: "HF - Scemtec antenne et Tagsys decodeur",
    nl: "HF - Scemtec antenne en Tagsys decoder",
  },
  {
    key: "EB dans le folder",
    fr: "EB dans le folder",
    nl: "EB in de folder",
  },
  {
    key: "EB - pour DAV et Trappe de sale",
    fr: "EB - pour DAV et Trappe de sale",
    nl: "EB - voor KUA en vuilschuif",
  },
  {
    key: "Centralisé",
    fr: "Centralisé",
    nl: "Gecentraliseerd",
  },
  {
    key: "Glissière",
    fr: "Glissière",
    nl: "Afglijlat",
  },
  {
    key: "Multilevel",
    fr: "Multilevel",
    nl: "Multilevel",
  },
  {
    key: "Convoyeur 01",
    fr: "Convoyeur 01",
    nl: "Baan 01",
  },
  {
    key: "Convoyeur 02",
    fr: "Convoyeur 02",
    nl: "Baan 02",
  },
  {
    key: "Convoyeur 06",
    fr: "Convoyeur 06",
    nl: "Baan 06",
  },
  {
    key: "Trappe de sale 08",
    fr: "Trappe de sale 08",
    nl: "Vuilschuif 08",
  },
  {
    key: "Convoyeur 11",
    fr: "Convoyeur 11",
    nl: "Baan 11",
  },
  {
    key: "Trappe de sale 18",
    fr: "Trappe de sale 18",
    nl: "Vuilschuif 18",
  },
  {
    key: "Convoyeur 21",
    fr: "Convoyeur 21",
    nl: "Baan 21",
  },
  {
    key: "Convoyeur 22",
    fr: "Convoyeur 22",
    nl: "Baan 22",
  },
  {
    key: "Trappe de sale 28",
    fr: "Trappe de sale 28",
    nl: "Vuilschuif 28",
  },
  {
    key: "Trappe de sale 38",
    fr: "Trappe de sale 38",
    nl: "Vuilschuif 38",
  },
  {
    key: "Trappe de sale 09",
    fr: "Trappe de sale 09",
    nl: "Vuilschuif 09",
  },
  {
    key: "Trappe de sale 19",
    fr: "Trappe de sale 19",
    nl: "Vuilschuif 19",
  },
  {
    key: "Convoyeur 26",
    fr: "Convoyeur 26",
    nl: "Baan 26",
  },
  {
    key: "Trappe de sale 29",
    fr: "Trappe de sale 29",
    nl: "Vuilschuif 29",
  },
  {
    key: "Convoyeur 31",
    fr: "Convoyeur 31",
    nl: "Baan 31",
  },
  {
    key: "Trappe de sale 10",
    fr: "Trappe de sale 10",
    nl: "Vuilschuif 10",
  },
  {
    key: "Convoyeur 12",
    fr: "Convoyeur 12",
    nl: "Baan 12",
  },
  {
    key: "Trappe de sale 81",
    fr: "Trappe de sale 81",
    nl: "Vuilschuif 81",
  },
  {
    key: "Trappe de sale 91",
    fr: "Trappe de sale 91",
    nl: "Vuilschuif 91",
  },
  {
    key: "Convoyeur 03",
    fr: "Convoyeur 03",
    nl: "Baan 03",
  },
  {
    key: "Trappe de sale 11",
    fr: "Trappe de sale 11",
    nl: "Vuilschuif 11",
  },
  {
    key: "Trappe de sale 14",
    fr: "Trappe de sale 14",
    nl: "Vuilschuif 14",
  },
  {
    key: "Trappe de sale 15",
    fr: "Trappe de sale 15",
    nl: "Vuilschuif 15",
  },
  {
    key: "Convoyeur 04",
    fr: "Convoyeur 04",
    nl: "Baan 04",
  },
  {
    key: "Trappe de sale 07",
    fr: "Trappe de sale 07",
    nl: "Vuilschuif 07",
  },
  {
    key: "Convoyeur 16",
    fr: "Convoyeur 16",
    nl: "Baan 16",
  },
  {
    key: "Convoyeur 13",
    fr: "Convoyeur 13",
    nl: "Baan 13",
  },
  {
    key: "Convoyeur 14",
    fr: "Convoyeur 14",
    nl: "Baan 14",
  },
  {
    key: "Trappe de sale 17",
    fr: "Trappe de sale 17",
    nl: "Vuilschuif 17",
  },
  {
    key: "Chargement",
    fr: "Chargement",
    nl: "Belading",
  },
  {
    key: "Anomalies automatique",
    fr: "Anomalies automatique",
    nl: "Automatische anomalieën",
  },
  {
    key: "N° de portes",
    fr: "N° de portes",
    nl: "Aantal leveringsdeuren",
  },
  {
    key: "Extraction",
    fr: "Extraction",
    nl: "Extractie",
  },
  {
    key: "N° de sorties",
    fr: "N° de sorties",
    nl: "Aantal uitgangen",
  },
  {
    key: "Transfert",
    fr: "Transfert",
    nl: "Transfert",
  },
  {
    key: "Livraison",
    fr: "Livraison",
    nl: "Levering",
  },
  {
    key: "Bi-directionnel",
    fr: "Bi-directionnel",
    nl: "Bi-directioneel",
  },
  {
    key: "Bi-directionnel L - R",
    fr: "Bi-directionnel L - R",
    nl: "Bi-directioneel L - R",
  },
  {
    key: "Bi-directionnel R - L",
    fr: "Bi-directionnel R - L",
    nl: "Bi-directioneel R- L",
  },
  {
    key: "Poumon",
    fr: "Poumon",
    nl: "Buffer",
  },
  {
    key: "Oui",
    fr: "Oui",
    nl: "Ja",
  },
  {
    key: "Non",
    fr: "Non",
    nl: "Nee",
  },
  {
    key: "Pneumatique",
    fr: "Pneumatique",
    nl: "Pneumatisch",
  },
  {
    key: "Vérin",
    fr: "Vérin",
    nl: "Cilinder",
  },
  {
    key: "Tapis",
    fr: "Tapis",
    nl: "Tapijt",
  },
  {
    key: "S (chaîne tronçonneuse)",
    fr: "S (chaîne tronçonneuse)",
    nl: "S (kettingzaag)",
  },
  {
    key: "Manuel",
    fr: "Manuel",
    nl: "Manueel",
  },
  {
    key: "écarteur",
    fr: "écarteur",
    nl: "Spreider",
  },
  {
    key: "Gravitaire",
    fr: "Gravitaire",
    nl: "Gravitair",
  },
  {
    key: "Nom",
    fr: "Nom",
    nl: "Naam",
  },
  {
    key: "Convoyeur",
    fr: "Convoyeur",
    nl: "Baan",
  },
  {
    key: "info",
    fr: "info",
    nl: "Info",
  },
  {
    key: "donnée",
    fr: "donnée",
    nl: "Gegeven",
  },
  {
    key: "Teamviewer nom",
    fr: "Teamviewer nom",
    nl: "Teamviewer naam",
  },
  {
    key: "Teamviewer ID",
    fr: "Teamviewer ID",
    nl: "Teamviewer ID",
  },
  {
    key: "Teamviewer MDP",
    fr: "Teamviewer MDP",
    nl: "Teamviewer wachtwoord",
  },
  {
    key: "Connexion VPN",
    fr: "Connexion VPN",
    nl: "Connectie VPN",
  },
  {
    key: "VNC sur bureau",
    fr: "VNC sur bureau",
    nl: "VNC op bureaublad",
  },
  {
    key: "Demande d'accès",
    fr: "Demande d'accès",
    nl: "Aanvraag toegang",
  },
  {
    key: "Adresse Web",
    fr: "Adresse Web",
    nl: "Web adres",
  },
  {
    key: "Nom d'utilisateur",
    fr: "Nom d'utilisateur",
    nl: "Gebruikersnaam",
  },
  {
    key: "Mot de passe",
    fr: "Mot de passe",
    nl: "Wachtwoord",
  },
  {
    key: "VNC dans dossier raccourcis",
    fr: "VNC dans dossier raccourcis",
    nl: "VNC in map raccourcis",
  },
  {
    key: "Administrateur délégué",
    fr: "Administrateur délégué",
    nl: "Algemeen directeur",
  },
  {
    key: "Chez sous-traitant",
    fr: "Chez sous-traitant",
    nl: "Bij onderaannemer",
  },
  {
    key: "Commercial - Gestionnaire de compte",
    fr: "Commercial - Gestionnaire de compte",
    nl: "Commercieel - accountbeheerder",
  },
  {
    key: "Coordinatrice administrative - Département compresseurs",
    fr: "Coordinatrice administrative - Département compresseurs",
    nl: "Administratieve coördinator - Compressorafdeling",
  },
  {
    key: "Délégué commercial",
    fr: "Délégué commercial",
    nl: "Commercieel verantwoordelijke",
  },
  {
    key: "Développeur",
    fr: "Développeur",
    nl: "Ontwikkelaar",
  },
  {
    key: "Développeur & SAT",
    fr: "Développeur & SAT",
    nl: "Ontwikkelaar & SAT",
  },
  {
    key: "Directeur commercial",
    fr: "Directeur commercial",
    nl: "Commercieel directeur",
  },
  {
    key: "Directeur et commercial",
    fr: "Directeur et commercial",
    nl: "Directeur en commercieel",
  },
  {
    key: "Direction - Responsable commercial",
    fr: "Direction - Responsable commercial",
    nl: "Directie - commercieel verantwoordelijke",
  },
  {
    key: "Indépendant - technicien",
    fr: "Indépendant - technicien",
    nl: "Zelfstandige - technieker",
  },
  {
    key: "Informaticien - développeur",
    fr: "Informaticien - développeur",
    nl: "Informaticus - ontwikkelaar",
  },
  {
    key: "Ingénieur commercial",
    fr: "Ingénieur commercial",
    nl: "Commercieel ingenieur",
  },
  {
    key: "Programmeur PLC",
    fr: "Programmeur PLC",
    nl: "PLC programmeerder",
  },
  {
    key: "Représentant du service clientèle",
    fr: "Représentant du service clientèle",
    nl: "Klantenservicemedewerker",
  },
  {
    key: "Responsable développeurs",
    fr: "Responsable développeurs",
    nl: "Verantwoordelijke ontwikkelaars",
  },
  {
    key: "Responsable du service clientèle",
    fr: "Responsable du service clientèle",
    nl: "Verantwoordelijke klantenservice",
  },
  {
    key: "Responsable projets",
    fr: "Responsable projets",
    nl: "Verantwoordelijke projecten",
  },
  {
    key: "Responsable SAT",
    fr: "Responsable SAT",
    nl: "Verantwoordelijke SAT",
  },
  {
    key: "SAT",
    fr: "SAT",
    nl: "SAT",
  },
  {
    key: "Service après vente",
    fr: "Service après vente",
    nl: "Dienst na aankoop",
  },
  {
    key: "Service après vente - Devis (entre autres)",
    fr: "Service après vente - Devis (entre autres)",
    nl: "Dienst na aankoop - offertes (onderandere)",
  },
  {
    key: "Service après vente - Garantie",
    fr: "Service après vente - Garantie",
    nl: "Dienst na aankoop - garantie",
  },
  {
    key: "Support développeur",
    fr: "Support développeur",
    nl: "Support ontwikkelaar",
  },
  {
    key: "Support sales",
    fr: "Support sales",
    nl: "Support sales",
  },
  {
    key: "Support technique",
    fr: "Support technique",
    nl: "Technische support",
  },
  {
    key: "Technicien en qualité des produits",
    fr: "Technicien en qualité des produits",
    nl: "Technieker in productqualiteit",
  },
  {
    key: "Sign in to start your session",
    fr: "Connectez-vous pour commencer votre session",
    nl: "Log in om uw sessie te starten",
  },
  {
    key: "Login",
    fr: "Nom d'utilisateur",
    nl: "Gebruikersnaam",
  },
  {
    key: "Password",
    fr: "Mot de passe",
    nl: "Paswoord",
  },
  {
    key: "Remember Me (not yet implemented)",
    fr: "Se souvenir de moi (pas encore mis en œuvre)",
    nl: "Onthoud mij (nog niet geïmplementeerd)",
  },
  {
    key: "Register a new membership (not yet implemented)",
    fr: "Enregistrer une nouvelle adhésion (pas encore mis en œuvre)",
    nl: "Registreer een nieuw lidmaatschap (nog niet geïmplementeerd)",
  },
  {
    key: "Sign In",
    fr: "s'identifier",
    nl: "Aanmelden",
  },
  {
    key: "not connected",
    fr: "non connecté",
    nl: "Niet verbonden",
  },
  {
    key: "administration",
    fr: "Administration",
    nl: "Administratie",
  },
  {
    key: "suppliers",
    fr: "Fournisseurs",
    nl: "Leveranciers",
  },
  {
    key: "agenda",
    fr: "Agenda",
    nl: "Agenda",
  },
  {
    key: "heures",
    fr: "Heures",
    nl: "Uren",
  },
  {
    key: "vacances",
    fr: "Vacances",
    nl: "Vakanties",
  },
  {
    key: "todo list",
    fr: "Liste Todo",
    nl: "Todo lijst",
  },
  {
    key: "Home",
    fr: "Acceuil",
    nl: "Startpagina",
  },
  {
    key: "Customers's list",
    fr: "Liste de clients",
    nl: "Klantenlijst",
  },
  {
    key: "General informations",
    fr: "Données générales",
    nl: "Algemene gegevens",
  },
  {
    key: "Contacts",
    fr: "Contacts",
    nl: "Contacten",
  },
  {
    key: "Search",
    fr: "Chercher",
    nl: "Zoeken",
  },
  {
    key: "datas",
    fr: "Données",
    nl: "Gegevens",
  },
  {
    key: "conveyors",
    fr: "Convoyeurs",
    nl: "Machines",
  },
  {
    key: "logbook",
    fr: "Logbook",
    nl: "Logbook",
  },
  {
    key: "todo",
    fr: "Todo",
    nl: "Todo",
  },
  {
    key: "remote maintenance",
    fr: "Connexion à distance",
    nl: "Verbinding vanop afstand",
  },
  {
    key: "extra",
    fr: "extra",
    nl: "extra",
  },
  {
    key: "start",
    fr: "Démarrage",
    nl: "Opstart",
  },
  {
    key: "warranty",
    fr: "Fin de garantie",
    nl: "Einde garantie",
  },
  {
    key: "U",
    fr: "U",
    nl: "U",
  },
  {
    key: 1,
    fr: 1,
    nl: 1,
  },
  {
    key: 2,
    fr: 2,
    nl: 2,
  },
  {
    key: 3,
    fr: 3,
    nl: 3,
  },
  {
    key: 4,
    fr: 4,
    nl: 4,
  },
  {
    key: 5,
    fr: 5,
    nl: 5,
  },
  {
    key: 6,
    fr: 6,
    nl: 6,
  },
  {
    key: 7,
    fr: 7,
    nl: 7,
  },
  {
    key: 8,
    fr: 8,
    nl: 8,
  },
  {
    key: 9,
    fr: 9,
    nl: 9,
  },
  {
    key: 10,
    fr: 10,
    nl: 10,
  },
  {
    key: "telemaintenance",
    fr: "Téléassistance",
    nl: "Teleassistentie",
  },
  {
    key: "sur place",
    fr: "Sur place",
    nl: "Ter plaatsen",
  },
  {
    key: "visite préventive",
    fr: "Visite préventive",
    nl: "Preventief onderhoud",
  },
  {
    key: "description",
    fr: "Description",
    nl: "Beschrijving",
  },
  {
    key: "replaced parts",
    fr: "Pièces remplacé",
    nl: "Vervangen onderdelen",
  },
  {
    key: "parts to replace",
    fr: "Pièces à remplacer",
    nl: "Te vervangen onderdelen",
  },
  {
    key: "new logbook",
    fr: "Nouveau logbook",
    nl: "Nieuw logbook",
  },
  {
    key: "enregistrer",
    fr: "Enregistrer",
    nl: "Opslaan",
  },
  {
    key: "createdAt",
    fr: "Créé le",
    nl: "Gemaakt op",
  },
  {
    key: "createdBy",
    fr: "Créé par",
    nl: "Gemaakt door",
  },
  {
    key: "responsible",
    fr: "Responsable",
    nl: "Verantwoordelijke",
  },
  {
    key: "task",
    fr: "Tâche",
    nl: "Taak",
  },
  {
    key: "deadline",
    fr: "Délai",
    nl: "Termijn",
  },
  {
    key: "completion",
    fr: "Complété",
    nl: "Voltooid",
  },
  {
    key: "dateOfCompletion",
    fr: "Date d'achèvement",
    nl: "Datum van voltooiing",
  },
  {
    key: "closer",
    fr: "Completeur",
    nl: "Voltooier",
  },
  {
    key: "add",
    fr: "Ajouter",
    nl: "Toevoegen",
  },
  {
    key: "status",
    fr: "Statut",
    nl: "Status",
  },
  {
    key: "undefined",
    fr: "Non défini",
    nl: "Ongedefinieerd",
  },
  {
    key: "Week",
    fr: "Semaine",
    nl: "Week",
  },
  {
    key: "Date selection",
    fr: "sélection de la date",
    nl: "datum selectie",
  },
  {
    key: "submit dates",
    fr: "Confirmer dates",
    nl: "Datums bevestigen",
  },
  {
    key: "planning",
    fr: "Planning",
    nl: "Planning",
  },
  {
    key: "private",
    fr: "Privé",
    nl: "Privé",
  },
  {
    key: "holiday",
    fr: "Vacances annuelles",
    nl: "Jaarlijkse vakantie",
  },
  {
    key: "medical off",
    fr: "Congé de maladie",
    nl: "Ziekteverlof",
  },
  {
    key: "off",
    fr: "OFF",
    nl: "OFF",
  },
  {
    key: "technical off",
    fr: "Chômage technique",
    nl: "Technisch werkloos",
  },
  {
    key: "unpaid holiday",
    fr: "Congé sans solde",
    nl: "Onbetaald verlof",
  },
  {
    key: "national holiday",
    fr: "Jour férié",
    nl: "Feestdag",
  },
  {
    key: "recup international holiday",
    fr: "Récup jour férié",
    nl: "Recuperatie feestdag",
  },
  {
    key: "locked",
    fr: "Verrouillé",
    nl: "vergrendeld",
  },
  {
    key: "from",
    fr: "De",
    nl: "Van",
  },
  {
    key: "to",
    fr: "à",
    nl: "tot",
  },
  {
    key: "De",
    fr: "De",
    nl: "Van",
  },
  {
    key: "à",
    fr: "à",
    nl: "tot",
  },
  {
    key: "Pause",
    fr: "Pause",
    nl: "Pauze",
  },
  {
    key: "Full Day",
    fr: "Toute la journée",
    nl: "De hele dag",
  },
  {
    key: "site",
    fr: "Site",
    nl: "Site",
  },
  {
    key: "all",
    fr: "Tous",
    nl: "Allemaal",
  },
  {
    key: "new event",
    fr: "Nouveau événement",
    nl: "Nieuw evenement",
  },
  {
    key: "delete",
    fr: "Supprimer",
    nl: "Verwijderen",
  },
  {
    key: "save",
    fr: "Sauvegarder",
    nl: "Opslaan",
  },
  {
    key: "edit",
    fr: "Modifier",
    nl: "Wijzigen",
  },
  {
    key: "close",
    fr: "Fermer",
    nl: "Sluiten",
  },
  {
    key: "modify for",
    fr: "Modifier pour",
    nl: "Wijzigen voor",
  },
  {
    key: "only me and this specific day",
    fr: "seulement moi et ce jour précis",
    nl: "alleen ik en deze specifieke dag",
  },
  {
    key: "whole event",
    fr: "tout l'événement",
    nl: "volledig evenement",
  },
  {
    key: "date",
    fr: "Date",
    nl: "Datum",
  },
  {
    key: "edit event",
    fr: "Modifier événement",
    nl: "Evenement wijzigen",
  },
  {
    key: "Title",
    fr: "Titre",
    nl: "Titel",
  },
  {
    key: "my hours",
    fr: "Mes heures",
    nl: "Mijn uren",
  },
  {
    key: "colleagues hours",
    fr: "Heures des collègues",
    nl: "Uren van de collega's",
  },
  {
    key: "total",
    fr: "Total",
    nl: "Totaal",
  },
  {
    key: "month",
    fr: "Mois",
    nl: "Maand",
  },
  {
    key: "hours",
    fr: "Heures",
    nl: "Uren",
  },
  {
    key: "summary",
    fr: "Résumé",
    nl: "Overzicht",
  },
  {
    key: "days",
    fr: "Jours",
    nl: "Dagen",
  },
  {
    key: "minutes",
    fr: "Minutes",
    nl: "Minuten",
  },
  {
    key: "initialTimeCredit",
    fr: "crédit heures initial",
    nl: "Aanvangsuren",
  },
];

//const translation_table2 = JSON.parse(readFileSync('./assets/translation.json'));
let translation_table = {};
translation_table1.map((item) => {
  translation_table[item.key] = {
    fr: item.fr,
    nl: item.nl,
  };
});
let alwaysReloadTicket = 0;
let shouldReloadTicket = false;
let emitter = "";
let poller = "";
export const getTranslations = () => {
  return fetch("/api/translations/get").then((response) => {
    if (response.ok) {
      return response.json().then((result) => {
        const columns = Object.keys(result[0]).map((key) => {
          return { dataField: key, text: key };
        });
        const datas = result.map((entry) => {
          entry.id = entry.key;
          return entry;
        });
        const toReturn = {
          columns: columns,
          data: datas,
        };
        translation_table1 = result;
        translation_table1.map((item) => {
          translation_table[item.key.toString()] = {
            fr: item.fr,
            nl: item.nl,
          };
        });
        //console.log(translation_table);
        return parseForTable(toReturn);
      });
    } else {
      toastr.options = {
        progressBar: true,
        timeOut: "5000",
      };
      response.json().then((body) => {
        console.log(body);
        toastr.error(body);
      });
    }
  });
};

export const patchTranslations = (translations) => {
  return fetch("/api/translations/patch", {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(translations),
  }).then((rawResponse) => {
    if (rawResponse.ok) {
      return rawResponse.json();
    } else {
      toastr.options = {
        progressBar: true,
        timeOut: "5000",
      };
      console.log(rawResponse);
      rawResponse.json().then((body) => {
        console.log(body);
        toastr.error(body);
      });
    }
  });
};

export const MomentDateString = (date) => {
  return moment(date).format("DD/MM/YYYY");
};
export const MomentDateTimeString = (date, lineFeed) => {
  if (lineFeed) return moment(date).format("DD/MM/YYYY \n HH:mm");
  return moment(date).format("DD/MM/YYYY HH:mm");
};

export const parseTime = (minutes) => {
  return (
    minTwoDigits(Math.floor((minutes * 60) / 3600).toFixed(0)) +
    ":" +
    minTwoDigits(Math.floor(((minutes * 60) % 3600).toFixed(0) / 60).toFixed(0)) +
    ":" +
    minTwoDigits(Math.floor(((minutes * 60) % 3600).toFixed(0) % 60).toFixed(0))
  );
};
export const minTwoDigits = (n) => {
  return n < 10 ? "0" + n : n;
};
export const ShouldReloadTicket = (poller) => {
  if (shouldReloadTicket && emitter !== poller) {
    emitter = "";
    shouldReloadTicket = false;
    return true;
  }
  return false;
};
export const ShouldTimedReloadTicket = (poller) => {
  if (alwaysReloadTicket > 0) {
    if (alwaysReloadTicket > 1) {
      if (poller !== "ticketPillsList") alwaysReloadTicket--;
      return false;
    } else {
      if (poller !== "ticketPillsList") alwaysReloadTicket = 20;
      return true;
    }
  }
  return false;
};
export const shouldAlwaysReloadTicket = (value) => {
  alwaysReloadTicket = value ? 20 : 0;
};

export const NotifyReloadTicket = (theEmitter) => {
  emitter = theEmitter;
  shouldReloadTicket = true;
};
export const Translator = (key) => {
  try {
    if (key.label) {
      key = key.label;
    }
  } catch (error) { }
  if (typeof translation_table[key] !== "undefined") {
    return translation_table[key][state.language];
  } else {
    if (("" + key).search(/[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}.[0-9]{3}Z/g) === 0) {
      const date = moment(key);
      if (date.isValid()) {
        return moment(key).format("DD/MM/YYYY HH:mm:ss");
      }
    }
    if (key === "") {
      return "";
    } else {
      //console.log("Not translated : " + key);
      return key + "*";
    }
  }
};
let state = { language: "fr" };
export const store = () => {
  const getLogged = () => {
    return state.isLogged;
  };
  const getUser = () => {
    return state.User;
  };
  const getCookies = () => {
    return state.Cookies;
  };
  const setLanguage = (lang) => {
    store().getCookies().set("language", lang, { maxAge: 604800, sameSite: "lax" });
    console.log("var: " + lang);
    console.log("cookie: " + store().getCookies().get("language"));
    state.language = lang;
  };
  const getLanguage = () => {
    return state.language;
  };
  const setConfirmation = (shouldConfirm) => {
    state.confirmation = shouldConfirm;
  };
  const getConfirmation = () => {
    return state.confirmation;
  };
  return {
    state: state,
    getLogged: getLogged,
    getUser: getUser,
    getCookies: getCookies,
    setLanguage: setLanguage,
    getLanguage: getLanguage,
    getConfirmation: getConfirmation,
    setConfirmation: setConfirmation,
  };
};

export const filterOptions = (
  candidate,
  input
) => {
  if (input) {
    return `${candidate.label}`
      .toUpperCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .includes(
        input
          .toUpperCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
      );
  }
  return true;
};

export const parseForTable = (datas, hideHeaders) => {
  //console.log("parsing", datas.columns);
  const doNotTranslateHeaders = [
    "key",
    "phone1",
    "phone2",
    "phone",
    "postal",
    "mail",
    "address",
    "name",
    "hours",
    "from",
    "month",
    "to",
    "start",
    "end",
    "fr",
    "nl",
    "value",
    "totalTime",
    "description",
    "user",
    "site",
    "day",
    "workTime",
    "relatedTo",
    "customer",
    "priceExclTax",
    "priceInclTax",
    "number",
    "markAsPaid",
  ];
  const fetchedColumns = datas.columns.map((column) => {
    //console.log("parsing columns " + column.dataField);
    const renderHtml = (html) => {
      return { __html: html };
    };
    return {
      dataField: column.dataField,
      text: column.text ? Translator(column.text) : Translator(column.dataField),
      sort: true,
      formatter: column.formatter ? column.formatter : (cell) => {
        if (column.dataField === "paid") {
          return <div>{cell}</div>;
        }
        return (
          <div
            dangerouslySetInnerHTML={renderHtml(
              doNotTranslateHeaders.find((value) => column.dataField === value) ? cell : Translator(cell)
            )}
          ></div>
        );
      },
      align: (cell, row, rowIndex, colIndex) => {
        if (cell && cell.type === "button") {
          return 'center'
        } else {
          return 'left'
        }

      },
      hidden: column.dataField === "id" ? true : column.hidden ? true : false,
      headerStyle: hideHeaders ? { display: "none" } : column.headerStyle,
      footer: column.footer,
      footerStyle: column.footerStyle,
      sortFunc: (a, b, order, dataField, rowA, rowB) => {
        if (dataField === "from" || dataField === "to" || dataField === "start" || dataField === "end") {
          if (order === "asc") {
            if (moment(a, "DD/MM/YYYY hh:mm").isBefore(moment(b, "DD/MM/YYYY hh:mm"))) return -1;
            if (moment(a, "DD/MM/YYYY hh:mm").isAfter(moment(b, "DD/MM/YYYY hh:mm"))) return 1;
            return 0;
          }
          if (moment(a, "DD/MM/YYYY hh:mm").isBefore(moment(b, "DD/MM/YYYY hh:mm"))) return +1;
          if (moment(a, "DD/MM/YYYY hh:mm").isAfter(moment(b, "DD/MM/YYYY hh:mm"))) return -1;
          return 0;
        } else if (dataField === "day") {
          if (order === "asc") {
            if (moment(a, "DD/MM/YYYY").isBefore(moment(b, "DD/MM/YYYY"))) return -1;
            if (moment(a, "DD/MM/YYYY").isAfter(moment(b, "DD/MM/YYYY"))) return 1;
            return 0;
          }
          if (moment(a, "DD/MM/YYYY").isBefore(moment(b, "DD/MM/YYYY"))) return 1;
          if (moment(a, "DD/MM/YYYY").isAfter(moment(b, "DD/MM/YYYY"))) return -1;
          return 0;
        } else if (dataField === "month") {
          if (order === "asc") {
            if (moment(a, "MM/YYYY").isBefore(moment(b, "MM/YYYY"))) return -1;
            if (moment(a, "MM/YYYY").isAfter(moment(b, "MM/YYYY"))) return 1;
            return 0;
          }
          if (moment(a, "MM/YYYY").isBefore(moment(b, "MM/YYYY"))) return 1;
          if (moment(a, "MM/YYYY").isAfter(moment(b, "MM/YYYY"))) return -1;
          return 0;
        } else {
          if (order === "asc") {
            return b <= a ? 1 : -1;
          }
          return b <= a ? -1 : 1; // desc
        }
      },
      sortCaret: (order, column) => {
        switch (order) {
          case "asc":
            return (
              <span>
                &nbsp;<i className="fas fa-sort-down"></i>
              </span>
            );
          case "desc":
            return (
              <span>
                &nbsp;<i className="fas fa-sort-up"></i>
              </span>
            );
          default:
            return (
              <span>
                &nbsp;<i className="fas fa-sort"></i>
              </span>
            );
        }
      },
    };
  });
  const fetchedDatas = datas.data;
  return { columns: fetchedColumns, data: fetchedDatas };
};

export const computeWorkTime = (userId) => {
  return fetchParameters().then((params) => {
    const startDataParam = params.find((param) => param.type === "startDate");
    return fetchUserEvents(userId).then((result) => {
      let workTimeMonths = {};
      let workTimeDays = {};
      let workMoment = new moment(startDataParam.value, "DD/MM/YYYY");
      const startWorkMoment = new moment(workMoment);
      const today = new moment();
      const nbDays = today.diff(workMoment, "days") + 1;
      const todayEvent = result.find((userEvent) => moment(userEvent.from).isSame(moment(), "days"));
      //console.log(nbDays);
      for (let i = 0; i < nbDays.toFixed(0); i++) {
        //console.log("substracting");
        if (workMoment.day() > 0 && workMoment.day() < 6) {
          if (workTimeMonths[workMoment.format("MM/YYYY")]) {
            workTimeMonths[workMoment.format("MM/YYYY")] = workTimeMonths[workMoment.format("MM/YYYY")] - 456;
          } else {
            workTimeMonths[workMoment.format("MM/YYYY")] = -456;
          }
          workTimeDays[workMoment.format("DD/MM/YYYY")] = -456;
        } else {
          workTimeDays[workMoment.format("DD/MM/YYYY")] = 0;
        }
        workMoment.add(1, "days");
      }
      //console.log(workTimeMonths)
      if (moment().day() > 0 && moment().day() < 6) {
        if (!todayEvent) {
          //console.log("not counting today");
          if (workTimeMonths[moment().format("MM/YYYY")]) {
            workTimeMonths[moment().format("MM/YYYY")] = workTimeMonths[moment().format("MM/YYYY")] + 456;
          } else {
            workTimeMonths[moment().format("MM/YYYY")] = 0;
          }
        }
      }
      let fullDayMemory = [];
      result.map((userEvent) => {
        if (!workTimeMonths[moment(userEvent.from).format("MM/YYYY")]) {
          workTimeMonths[moment(userEvent.from).format("MM/YYYY")] = 0;
        }
        if (!workTimeDays[moment(userEvent.from).format("DD/MM/YYYY")]) {
          workTimeDays[moment(userEvent.from).format("DD/MM/YYYY")] = 0;
        }
        if (
          !(moment(userEvent.from).day() > 0 && moment(userEvent.from).day() < 6) &&
          userEvent.Event.type !== "customer"
        ) {
          //If event is during weekend and not of customer type, ignore duration
          //console.log("weekend or not customer");
        } else {
          //If event is OFF, do not count time as worked
          //If event is fullDay, count 7h36 (456 minutes)
          //If event is something else than customer count 456
          //If event is Customer and not fullDay, count actual time
          let timeToReduce = 0;
          if (userEvent.Event.type === "off") {
            //If off is in the future, then count the time to reduce. If not this is not necessary because time counts itself
            if (moment(userEvent.to).isAfter(moment())) timeToReduce = userEvent.fullDay ? 456 : userEvent.workTime;
            workTimeMonths[moment(userEvent.from).format("MM/YYYY")] =
              workTimeMonths[moment(userEvent.from).format("MM/YYYY")] - timeToReduce;
            workTimeDays[moment(userEvent.from).format("DD/MM/YYYY")] =
              workTimeDays[moment(userEvent.from).format("DD/MM/YYYY")] - timeToReduce;
          } else if (
            moment(userEvent.to).isBefore(new moment().hours(23)) &&
            moment(userEvent.from).isAfter(startWorkMoment) &&
            userEvent.Event.type !== "private" &&
            userEvent.Event.type !== "info"
          ) {
            let coef = 1;
            if (moment(userEvent.from).day() === 0) coef = 2;
            if (moment(userEvent.from).day() === 6) coef = 1.5;
            const returnFullDay = (date) => {
              if (fullDayMemory.includes(moment(date).format("DD/MM/YYYY"))) {
                return 0;
              } else {
                fullDayMemory.push(moment(date).format("DD/MM/YYYY"));
                return 456;
              }
            };
            const timeToAdd =
              (userEvent.fullDay
                ? returnFullDay(userEvent.from)
                : userEvent.Event.type === "customer"
                  ? userEvent.workTime
                  : 456) * coef;
            workTimeDays[moment(userEvent.from).format("DD/MM/YYYY")] =
              workTimeDays[moment(userEvent.from).format("DD/MM/YYYY")] + timeToAdd;
            workTimeMonths[moment(userEvent.from).format("MM/YYYY")] =
              workTimeMonths[moment(userEvent.from).format("MM/YYYY")] + timeToAdd;
          }
        }
      });
      return { workTimeMonths, workTimeDays };
      //Mapping around time to fill table ready display AND compute the full user work time
      let datas = [];
      let fullTotal = 0;
      Object.keys(workTimeMonths).map((month) => {
        datas.push({
          id: month,
          month: month,
          year: month.substr(3, 4),
          hours:
            (workTimeMonths[month] < 0 ? "- " : "") +
            Math.floor(Math.abs(workTimeMonths[month] / 456)) +
            " jours et " +
            Math.floor(Math.abs((workTimeMonths[month] % 456).toFixed(0) / 60)).toFixed(0) +
            "h et " +
            ((Math.abs(workTimeMonths[month]) % 456).toFixed(0) % 60).toFixed(0) +
            " minutes",
          rawTime: workTimeMonths[month],
        });
        fullTotal += workTimeMonths[month];
      });
      const parsed = parseForTable({
        data: datas,
        columns: [
          {
            dataField: "month",
            text: Translator("month"),
          },
          {
            dataField: "hours",
            text: Translator("hours"),
          },
          {
            dataField: "id",
            text: Translator("id"),
            hidden: true,
          },
          {
            dataField: "year",
            text: Translator("year"),
            hidden: true,
          },
        ],
      });

      let years = [];
      let total = 0;
      parsed.data.map((a) => {
        if (!years.find((year) => year.value === a.year)) {
          years.push({ value: a.year, active: false + "" === a.year, desc: a.year, accreditation: 3 });
        }
      });
      years.push({ value: "total", active: true, desc: Translator("total"), accreditation: 3 });
      let selectedYearData = [];
      parsed.data.map((data) => {
        selectedYearData.push(data);
        total = total + data.rawTime;
      });
      this.setState({
        data: selectedYearData,
        fullData: parsed.data,
        monthsColumns: parsed.columns,
        isFetching: false,
        total: total,
        fullTotal: fullTotal,
        yearTabs: years,
      });
    });
  });
};
