import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { Translator, parseForTable, store } from "../../tools/Tools";
import {
  fetchCustomers,
  fakeFetchCustomers,
  postNewCustomer,
  deleteCustomers,
  searchContact,
} from "../../tools/Api_Customers";
import BSTable from "../../components/BSTable";
import { v4 as uuidv4 } from 'uuid';
import Card from "../../components/Card";

class Customers extends Component {
  constructor(props) {
    super(props);
    this.path = props.match.path;
    this.push = props.history.push;
    this.searchContactFieldTimeout = null;
    this.state = {
      ...this.props.location.state,
      columns: [],
      data: [],
      deletingMode: false,
      searchedContact: [],
      collapsed: true,
      searchedText: "",
    };
  }

  componentDidMount() {
    this.setState({ isFetching: true });
    this.reload(false);
  }

  reload(creatingMode) {
    fetchCustomers().then((fetched) => {
      if (fetched) {
        const customers = {
          columns: fetched.columns,
          data: fetched.data.map((cust) => {
            cust.relatedTo = cust.relatedTo.length;
            return cust;
          }),
        };
        this.setState(() => {
          return { ...parseForTable(customers), isFetching: false, creatingMode: creatingMode };
        });
      }
    });
  }
  handleCreateButton(e) {
    e.preventDefault();
    this.setState((state) => {
      return {
        creatingMode: !state.creatingMode,
        newCustomerTemp: { activeClient: true },
      };
    });
  }
  deleteCallback(ids) {
    deleteCustomers(ids).then(() => {
      this.setState({ isFetching: true });
      this.reload(false);
    });
  }
  handlePostNewCustomer(newCustomer) {
    this.setState({ isFetching: true });
    postNewCustomer(newCustomer).then(
      (content) => {
        this.reload(false);
      },
      (error) => this.setState({ isFetching: false })
    );
  }
  customCreateForm() {
    const displayCallout = () => {
      const newCustomer = this.state.newCustomerTemp;
      let callOutText = "";
      if (newCustomer) {
        if (!newCustomer.name) {
          callOutText += " - " + Translator("name");
        }
        if (!newCustomer.rue) {
          callOutText += " - " + Translator("rue");
        }
        if (!newCustomer.number) {
          callOutText += " - " + Translator("number");
        }
        if (!newCustomer.cp) {
          callOutText += " - " + Translator("cp");
        }
        if (!newCustomer.ville) {
          callOutText += " - " + Translator("ville");
        }
        if (callOutText.length === 0) {
          callOutText = "All Good !";
        } else {
          callOutText += "- required";
        }
      } else {
        callOutText =
          Translator("name") +
          " - " +
          Translator("rue") +
          " - " +
          Translator("number") +
          " - " +
          Translator("cp") +
          " - " +
          Translator("ville") +
          " " +
          " required";
      }

      const callOut =
        callOutText === "All Good !" ? (
          <div
            id="validationCallout"
            className="callout callout-success mb-2 mr-sm-2 tada animated"
            style={{
              height: "calc(2.25rem + 2px)",
              padding: "0.375rem 0.75rem",
            }}
          >
            {callOutText}
          </div>
        ) : (
          <div
            id="validationCallout"
            className="callout callout-danger mb-2 mr-sm-2 shake animated"
            style={{
              height: "calc(2.25rem + 2px)",
              padding: "0.375rem 0.75rem",
            }}
          >
            {callOutText}
          </div>
        );

      return callOut;
    };
    return (
      <div className={this.creatingMode ? "info-box slideOutUp animated" : "info-box slideInDown animated"}>
        <span className="info-box-icon bg-info">
          <i className="fas fa-user-plus"></i>
        </span>
        <div className="info-box-content">
          <form className="form-inline col-sm-12">
            <div className="input-group mb-2 mr-sm-2">
              <input
                type="text"
                className="form-control"
                placeholder={Translator("name")}
                onChange={(e) => {
                  this.setState({ newCustomerTemp: { ...this.state.newCustomerTemp, name: e.target.value } });
                }}
              ></input>
            </div>
          </form>
          <form className="form-inline col-sm-12">
            <div className="input-group mb-2 mr-sm-2">
              <input
                type="text"
                className="form-control"
                placeholder={Translator("rue")}
                onChange={(e) => {
                  this.setState({ newCustomerTemp: { ...this.state.newCustomerTemp, rue: e.target.value } });
                }}
              ></input>
            </div>
            <div className="input-group mb-2 mr-sm-2">
              <input
                type="text"
                className="form-control"
                placeholder={Translator("n°")}
                onChange={(e) => {
                  const newValue = e.target.value.match(/[0-9]/gi);
                  e.target.value = newValue ? newValue.toString().replace(/,/gi, "") : "";
                  this.setState({ newCustomerTemp: { ...this.state.newCustomerTemp, number: e.target.value } });
                }}
              ></input>
            </div>
            <div className="input-group mb-2 mr-sm-2">
              <input
                type="text"
                className="form-control"
                placeholder={Translator("code postal")}
                onChange={(e) => {
                  const newValue = e.target.value.match(/[0-9]/gi);
                  e.target.value = newValue ? newValue.toString().replace(/,/gi, "") : "";
                  this.setState({ newCustomerTemp: { ...this.state.newCustomerTemp, cp: e.target.value } });
                }}
              ></input>
            </div>
            <div className="input-group mb-2 mr-sm-2">
              <input
                type="text"
                className="form-control"
                placeholder={Translator("ville")}
                onChange={(e) => {
                  this.setState({ newCustomerTemp: { ...this.state.newCustomerTemp, ville: e.target.value } });
                }}
              ></input>
            </div>
            <div className="input-group mb-2 mr-sm-2">
              <select
                className="custom-select"
                defaultValue="belgique"
                onChange={(e) => {
                  this.setState({ newCustomerTemp: { ...this.state.newCustomerTemp, country: e.target.value } });
                }}
              >
                <option value="belgique">{Translator("belgique")}</option>
                <option value="france">{Translator("france")}</option>
                <option value="luxembourg">{Translator("luxembourg")}</option>
                <option value="suisse">{Translator("suisse")}</option>
                <option value="italie">{Translator("italie")}</option>
                <option value="allemagne">{Translator("allemagne")}</option>
                <option value="nederland">{Translator("nederland")}</option>
              </select>
            </div>
          </form>
          <form className="form-inline col-sm-12">
            <div className="input-group mb-2 mr-sm-2">
              <input
                style={{ width: "10%" }}
                type="text"
                className="form-control"
                placeholder={Translator("phone")}
                onChange={(e) => {
                  const newValue = e.target.value.match(/[0-9+ ]/gi);
                  e.target.value = newValue ? newValue.toString().replace(/,/gi, "") : "";
                  this.setState({ newCustomerTemp: { ...this.state.newCustomerTemp, phone: e.target.value } });
                }}
              ></input>
            </div>
            <div className="input-group mb-2 mr-sm-2">
              <select
                className="custom-select"
                defaultValue="none"
                onChange={(e) => {
                  this.setState({ newCustomerTemp: { ...this.state.newCustomerTemp, type: e.target.value } });
                }}
              >
                <option value="hopital">{Translator("hopital")}</option>
                <option value="hotel">{Translator("hotel")}</option>
                <option value="blanchisserie">{Translator("blanchisserie")}</option>
                <option value="pressing">{Translator("pressing")}</option>
                <option value="industrie">{Translator("industrie")}</option>
              </select>
            </div>
            <div className="input-group mb-2 mr-sm-2">
              <select
                className="custom-select"
                defaultValue="none"
                onChange={(e) => {
                  this.setState({ newCustomerTemp: { ...this.state.newCustomerTemp, contract: e.target.value } });
                }}
              >
                <option value="basic1">{Translator("basic1")}</option>
                <option value="basic2">{Translator("basic2")}</option>
                <option value="omnium">{Translator("omnium")}</option>
                <option value="conveyor">{Translator("conveyor")}</option>
                <option value="warranty">{Translator("warranty")}</option>
                <option value="none">{Translator("none")}</option>
              </select>
            </div>
            <div className="input-group mb-2 mr-2">
              <div className="input-group-prepend">
                <div className="input-group-text">
                  <input
                    type="checkbox"
                    defaultChecked={true}
                    className="form-check-input"
                    onChange={(e) => {
                      this.setState({
                        newCustomerTemp: { ...this.state.newCustomerTemp, activeClient: e.target.checked },
                      });
                    }}
                  ></input>
                </div>
              </div>
              <label type="text" className="form-control text-truncate">
                {Translator("activeClient")}
              </label>
            </div>
          </form>
          <form className="form-inline col-sm-12">
            <button
              className="btn btn-info btn mb-2 mr-sm-2"
              onClick={(e) => {
                e.preventDefault();
                const newCustomer = this.state.newCustomerTemp;
                const rue = newCustomer.rue ? newCustomer.rue : "";
                const number = newCustomer.number ? newCustomer.number : "";
                const cp = newCustomer.cp ? newCustomer.cp : "";
                const ville = newCustomer.ville ? newCustomer.ville : "";
                const address = rue + " " + number + ", " + cp + " " + ville;
                const activeClient = newCustomer.activeClient ? newCustomer.activeClient : false;
                const newCustomerToSend = {
                  id: uuidv4(),
                  name: newCustomer.name,
                  address: address,
                  country: newCustomer.country ? newCustomer.country : "belgique",
                  postal: cp,
                  phone: newCustomer.phone ? newCustomer.phone : "",
                  type: newCustomer.type ? newCustomer.type : "hopital",
                  contract: newCustomer.contract ? newCustomer.contract : "none",
                  activeClient: activeClient,
                };
                this.handlePostNewCustomer(newCustomerToSend);
              }}
              disabled={
                this.state.newCustomerTemp
                  ? this.state.newCustomerTemp.name &&
                    this.state.newCustomerTemp.number &&
                    this.state.newCustomerTemp.rue &&
                    this.state.newCustomerTemp.cp &&
                    this.state.newCustomerTemp.ville
                    ? false
                    : true
                  : true
              }
            >
              {Translator("enregistrer")}
            </button>
            {displayCallout()}
          </form>
        </div>
        <div className="ribbon-wrapper ribbon-xl">
          <div className="ribbon bg-success">{Translator("new customer")}</div>
        </div>
      </div>
    );
  }
  render() {
    //OnClick informations
    const path = this.path;
    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        this.push({
          pathname: `${path}/${row.name}`,
          state: {
            genericInfos: row,
          },
        });
      },
    };
    //Rendering
    return (
      <div>
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0 text-dark">
                  <i className="nav-icon fas fas fa-address-card"></i>
                  &nbsp; {Translator("customers")}
                </h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to={{ pathname: "../", state: this.props.location.state }}>{Translator("Home")}</Link>
                  </li>
                  <li className="breadcrumb-item active">{Translator("customers")}</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="container-fluid">
            <Card
              collapsed={this.state.collapsed}
              collapsible
              title={(() => {
                const handleKeyDown = (e) => {
                  if (e.key === "Escape") {
                    this.setState({ searchedText: "", searchedContact: [], collapsed: true });
                  }
                };
                return (
                  <span className="row">
                    <span className="col mr-2">{Translator("contact search")}</span>
                    <div className="input-group input-group-sm mb-2 mr-2 col">
                      <input
                        type="text"
                        className="form-control form-control-sm col"
                        placeholder={Translator("enter contact")}
                        value={this.state.searchedText}
                        onKeyDown={handleKeyDown}
                        onChange={(e) => {
                          const text = e.target.value;
                          this.setState({ searchedText: text });
                          const callback = (text) => {
                            if (text) {
                              searchContact(text).then((result) => {
                                this.setState({ searchedContact: result.data, collapsed: false });
                              });
                            } else {
                              this.setState({ searchedContact: [], collapsed: true });
                            }
                          };
                          clearTimeout(this.searchContactFieldTimeout);
                          this.searchContactFieldTimeout = setTimeout(callback, 200, text);
                        }}
                      ></input>
                      <div className="input-group-append">
                        <button
                          type="button"
                          className="btn btn-default mb-1"
                          onClick={(e) => {
                            e.preventDefault();
                            this.setState({ searchedText: "", searchedContact: [], collapsed: true });
                          }}
                        >
                          {" "}
                          <span style={{ color: "Tomato" }}>
                            <i className="fas fa-times"></i>
                          </span>
                        </button>
                      </div>
                    </div>
                  </span>
                );
              })()}
            >
              <div className="table-responsive">
                <table className="table table-borderd">
                  <thead>
                    <tr>
                      <th>{Translator("name")}</th>
                      <th>{Translator("position")}</th>
                      <th>{Translator("phone1")}</th>
                      <th>{Translator("phone2")}</th>
                      <th>{Translator("mail")}</th>
                      <th>{Translator("note")}</th>
                      <th>{Translator("customer")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.searchedContact.map((contact) => {
                      return (
                        <tr key={contact.id}>
                          <td>{contact.name}</td>
                          <td>{contact.position}</td>
                          <td>{contact.phone1}</td>
                          <td>{contact.phone2}</td>
                          <td>{contact.mail}</td>
                          <td>{contact.note}</td>
                          <td>{contact.Customer.name}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </Card>
            <BSTable
              columns={this.state.columns}
              data={this.state.data}
              rowEvents={rowEvents}
              title={Translator("customers's list")}
              creatingMode={this.state.creatingMode}
              cud={{ c: this.handleCreateButton.bind(this), u: false, d: true }}
              customCreateForm={this.customCreateForm.bind(this)}
              isFetching={this.state.isFetching}
              deleteCallback={this.deleteCallback.bind(this)}
              updateFromProps
            />
          </div>
        </section>
      </div>
    );
  }
}

export default withRouter(Customers);
