import React, { Component } from "react";
import Card from "../../components/Card";
import BSTable from "../../components/BSTable";
import isLoading from "../../components/Loading";
import { Translator, MomentDateString, parseForTable } from "../../tools/Tools";
import { v4 as uuidv4 } from 'uuid';
import GeneralInfos from "../../components/GeneralInfos";
import {
  fetchCustomerConveyors,
  deleteConveyor,
  postSite,
  postConveyor,
  postSpecifications,
  fetchCustomers,
} from "../../tools/Api_Customers";
import { withRouter } from "react-router-dom";

class CustomerConveyor extends Component {
  constructor(props) {
    super(props);
    this.generalInfos = props.generalInfos;
    this.state = {
      sites: {},
      creatingMode: props.creatingMode,
      newSiteTemp: { convCount: { 1: {} }, contract: "none" },
      siteState: {},
      relatedTos: [],
    };
  }

  componentDidMount() {
    this.reload();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.isFetching) {
      this.setState({ isFetching: false });
    }
    if (prevProps.creatingMode !== this.props.creatingMode) {
      this.setState({ creatingMode: this.props.creatingMode });
    }
  }

  reload() {
    this.setState({ isFetching: true });
    const siteState = this.state.siteState;
    let objectifiedResult = {};
    let relatedObjectifiedResult = {};

    fetchCustomers().then((customers) => {
      const relatedTo = customers.data.find((customer) => customer.id === this.generalInfos.id).relatedTo;
      const relatedTos = relatedTo.map((rel) => {
        return { name: rel.name, id: rel.id };
      });

      fetchCustomerConveyors(this.generalInfos.id).then((result) => {
        result.Sites.map((site) => {
          let conveyors = {};
          site.Conveyors.map((conveyor) => {
            conveyors = { ...conveyors, [conveyor.name]: { id: conveyor.id, specifications: conveyor.ConveyorSpecs } };
            return conveyor;
          });
          objectifiedResult = {
            ...objectifiedResult,
            [site.name]: {
              infos: {
                id: site.id,
                serial: site.serial,
                start: site.start,
                warranty: site.warranty,
                contract: site.contract,
                software: site.software,
                database: site.database,
                chipReader: site.chipReader,
                compressor: site.compressor,
                hangerRecup: site.hangerRecup,
              },
              id: site.id,
              conveyors: conveyors,
            },
          };
          return site;
        });
        result.Sites.map((site) => {
          siteState[site.name] = {};
          return site;
        });
        this.setState({
          sites: objectifiedResult,
          siteState: siteState,
          relatedTos: relatedTos,
        });
      });
    });
  }
  handleCreateButton(e) {
    e.preventDefault();
    this.setState((state) => {
      return {
        edit: true,
        data: [
          ...state.data,
          {
            id: uuidv4(),
            info: "Info",
            value: "Donnée",
          },
        ],
      };
    });
  }

  deleteConveyor(conveyorId) {
    deleteConveyor(conveyorId);
  }

  addSite(site) {
    postSite(this.generalInfos.id, site);
  }
  setSpecifications(conveyorId, specification) {
    console.log("Setting specifications for " + conveyorId);
    console.log(JSON.stringify(specification));
    postSpecifications(conveyorId, specification);
  }
  addConveyor(site, newConvName) {
    if (newConvName !== "") {
      const currentId = uuidv4();
      const convBody = { id: currentId, name: newConvName };
      postConveyor(this.state.sites[site].id, convBody);
      this.setState({
        sites: {
          ...this.state.sites,
          [site]: {
            ...this.state.sites[site],
            conveyors: {
              ...this.state.sites[site].conveyors,
              [newConvName]: { id: currentId, specifications: [] },
            },
          },
        },
        siteState: {
          ...this.state.siteState,
          [site]: { addingConveyor: false },
        },
      });
    } else {
      this.setState({
        siteState: {
          ...this.state.siteState,
          [site]: { addingConveyor: false },
        },
      });
    }
  }

  /*
   *  Create the addConveyor button and label for each site
   */

  renderButtons(key) {
    let newConvName = "";
    if (Object.keys(this.state.siteState).length > 0) {
      return (
        <span>
          {this.state.siteState[key].addingConveyor ? (
            <span>
              <input
                type="text"
                className="form-control"
                placeholder={Translator("conveyor")}
                onChange={(e) => {
                  newConvName = e.target.value;
                }}
              ></input>
              <button
                className={this.props.subCustomer ? "btn btn-light btn" : "btn btn-secondary btn"}
                onClick={(e) => {
                  e.preventDefault();
                  this.addConveyor(key, newConvName);
                }}
              >
                <i className="fas far fa-save"></i>
              </button>
            </span>
          ) : (
            <span>
              <button
                className={this.props.subCustomer ? "btn btn-light btn" : "btn btn-secondary btn"}
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({
                    siteState: {
                      ...this.state.siteState,
                      [key]: { addingConveyor: true },
                    },
                  });
                }}
              >
                <i className="fas fa-folder-plus"></i>
              </button>
            </span>
          )}
          {this.state.siteState[key].deletingConveyor ? (
            <button
              className={this.props.subCustomer ? "btn btn-light btn" : "btn btn-secondary btn"}
              onClick={(e) => {
                e.preventDefault();
                let filteredConveyors = {};
                Object.keys(this.state.sites[key].conveyors).map((conveyor) => {
                  console.log("IN DELETE CONVEYOR PARSE: " + JSON.stringify(conveyor));
                  if (!this.state.siteState[key].deletingConveyor[conveyor]) {
                    filteredConveyors = { ...filteredConveyors, [conveyor]: this.state.sites[key].conveyors[conveyor] };
                  } else {
                    this.deleteConveyor(this.state.sites[key].conveyors[conveyor].id);
                  }
                  return conveyor;
                });

                this.setState((state) => {
                  return {
                    sites: {
                      ...state.sites,
                      [key]: {
                        ...state.sites[key],
                        conveyors: filteredConveyors,
                      },
                    },
                    siteState: {
                      ...state.siteState,
                      [key]: { deletingConveyor: false },
                    },
                  };
                });
              }}
            >
              <i className="fas far fa-save"></i>
            </button>
          ) : (
            <button
              className={this.props.subCustomer ? "btn btn-light btn" : "btn btn-secondary btn"}
              onClick={(e) => {
                e.preventDefault();
                this.setState({
                  siteState: {
                    ...this.state.siteState,
                    [key]: { deletingConveyor: {} },
                  },
                });
              }}
            >
              <i className="fas fa-folder-minus"></i>
            </button>
          )}
        </span>
      );
    }
  }
  /*
   * Parse sites and their conveyors
   */
  renderConveyors() {
    if (this.state.sites) {
      return (
        <div>
          <div className="row">
            {this.props.subCustomer && Object.keys(this.state.sites).length ? (
              <span>
                <h3 className="text-muted">{this.generalInfos.name}</h3>
                <hr></hr>
              </span>
            ) : null}
          </div>
          <div className="row">
            {Object.keys(this.state.sites).map((key) => {
              return (
                <div key={key} className={`col-md-12`}>
                  <Card
                    title={key}
                    type={this.props.subCustomer ? "light" : "secondary"}
                    tools={this.renderButtons(key)}
                    collapsible
                  >
                    <div className="row">
                      <div className="col-md-3">
                        <GeneralInfos
                          generalInfos={this.state.sites[key].infos}
                          infoType="site"
                          parentReload={this.reload.bind(this)}
                        ></GeneralInfos>
                      </div>
                      <div className="col-md-9">
                        <div className="row">
                          {Object.keys(this.state.sites[key].conveyors)
                            .sort()
                            .map((kkey) => {
                              const colSize =
                                12 / Object.keys(this.state.sites[key].conveyors).length < 6
                                  ? 6
                                  : 12 / Object.keys(this.state.sites[key].conveyors).length;

                              const datas = this.state.sites[key].conveyors[kkey].specifications
                                ? this.state.sites[key].conveyors[kkey].specifications.sort((a, b) => {
                                  if (a.info < b.info) return 1;
                                  if (a.info > b.info) return -1;
                                  return 0;
                                })
                                : false;
                              const columns = datas ? [{ dataField: "info" }, { dataField: "value" }] : [];
                              const getData = () => {
                                return { columns: columns, data: datas };
                              };
                              const obj = parseForTable(getData(), true);
                              return (
                                <div key={kkey} className={`col-md-${colSize}`}>
                                  {this.state.siteState[key].deletingConveyor ? (
                                    <div className="container-fluid">
                                      <div className="row">
                                        <button
                                          className="btn btn-light btn col-md-12 bounceIn animated"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            this.setState({
                                              siteState: {
                                                ...this.state.siteState,
                                                [key]: {
                                                  deletingConveyor: {
                                                    ...this.state.siteState[key].deletingConveyor,
                                                    [kkey]: !this.state.siteState[key].deletingConveyor[kkey],
                                                  },
                                                },
                                              },
                                            });
                                          }}
                                        >
                                          Delete
                                        </button>
                                      </div>
                                    </div>
                                  ) : null}
                                  <div>
                                    <BSTable
                                      key={kkey}
                                      fetchDatas={getData}
                                      columns={obj.columns}
                                      data={obj.data}
                                      title={Translator(kkey)}
                                      cud={{ c: this.handleCreateButton, u: true, d: false }}
                                      postCallback={(newDatas) => {
                                        this.setSpecifications(this.state.sites[key].conveyors[kkey].id, newDatas);
                                      }}
                                      theme="light"
                                      icon=""
                                      hideSearch
                                    ></BSTable>
                                    {this.state.siteState[key].deletingConveyor ? (
                                      this.state.siteState[key].deletingConveyor[kkey] ? (
                                        <div className="ribbon-wrapper ribbon-lg">
                                          <div className="ribbon bg-danger">{Translator("to delete")}</div>
                                        </div>
                                      ) : null
                                    ) : null}
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              );
            })}
          </div>
        </div>
      );
    }
  }
  handleNewSiteConveyors() {
    return Object.keys(this.state.newSiteTemp.convCount).map((key) => {
      return (
        <div key={key} className="input-group mb-2 mr-sm-2">
          <div className="input-group-prepend">
            <div className="input-group-text">{Translator("conveyor")}</div>
          </div>
          <input
            type="text"
            className="form-control"
            placeholder={Translator("conveyor number")}
            onChange={(e) => {
              const newConvName = e.target.value;
              let newConvcount = { ...this.state.newSiteTemp.convCount, [key]: { newConvName } };
              if (Object.keys(this.state.newSiteTemp.convCount).length === parseInt(key, 10)) {
                newConvcount = { ...newConvcount, [parseInt(key, 10) + 1]: {} };
              }
              this.setState((state) => {
                return {
                  newSiteTemp: {
                    ...state.newSiteTemp,
                    convCount: newConvcount,
                  },
                };
              });
            }}
          ></input>
        </div>
      );
    });
  }
  customCreateForm() {
    const displayRequiredCallout = () => {
      let callOutText = "";
      if (this.state.newSiteTemp) {
        if (!this.state.newSiteTemp.name) {
          callOutText += Translator("site name") + " - required";
        }
      } else {
        callOutText = Translator("site name") + " required";
      }

      const callOut =
        callOutText === "" ? null : (
          <div
            id="validationCallout1"
            className="callout callout-danger mb-2 mr-sm-2 shake animated"
            style={{
              height: "calc(2.25rem + 2px)",
              padding: "0.375rem 0.75rem",
            }}
          >
            {callOutText}
          </div>
        );

      return callOut;
    };
    const displayCallout = () => {
      let callOutText = "";
      if (this.state.newSiteTemp) {
        if (!this.state.newSiteTemp.serial) {
          callOutText += " - " + Translator("serial number");
        }
        if (!this.state.newSiteTemp.start) {
          callOutText += " - " + Translator("start date");
        }
        if (!this.state.newSiteTemp.warranty) {
          callOutText += " - " + Translator("end of warranty");
        }
        if (!this.state.newSiteTemp.database) {
          callOutText += " - " + Translator("database");
        }
        if (callOutText.length === 0) {
          callOutText = "All Good !";
        } else {
          callOutText += " " + Translator("are not filled");
        }
      } else {
        callOutText =
          Translator("serial number") +
          " - " +
          Translator("start date") +
          " - " +
          Translator("end of warranty") +
          " - " +
          Translator("database") +
          " " +
          Translator("are not filled");
      }
      const callOut =
        callOutText === "All Good !" && this.state.newSiteTemp.name ? (
          <div
            id="validationCallout2"
            className="callout callout-success mb-2 mr-sm-2 tada animated"
            style={{
              height: "calc(2.25rem + 2px)",
              padding: "0.375rem 0.75rem",
            }}
          >
            {callOutText}
          </div>
        ) : callOutText !== "All Good !" || this.state.newSiteTemp.name ? (
          <div
            id="validationCallout2"
            className="callout callout-warning mb-2 mr-sm-2 shake animated"
            style={{
              height: "calc(2.25rem + 2px)",
              padding: "0.375rem 0.75rem",
            }}
          >
            {callOutText}
          </div>
        ) : null;

      return callOut;
    };
    return (
      <div className={this.state.creatingMode ? "info-box slideInDown animated" : "info-box"}>
        <span className="info-box-icon bg-info">
          <i className="fas fa-user-plus"></i>
        </span>
        <div className="info-box-content">
          <form className="form-inline col-sm-12">
            <div className="input-group mb-2 mr-sm-2">
              <input
                type="text"
                className="form-control"
                placeholder={Translator("site name")}
                onChange={(e) => {
                  this.setState({ newSiteTemp: { ...this.state.newSiteTemp, name: e.target.value } });
                }}
              ></input>
            </div>
            <div className="input-group mb-2 mr-sm-2">
              <input
                type="text"
                className="form-control"
                placeholder={Translator("serial number")}
                onChange={(e) => {
                  this.setState({ newSiteTemp: { ...this.state.newSiteTemp, serial: e.target.value } });
                }}
              ></input>
            </div>
            <div className="input-group mb-2 mr-sm-2">
              <div className="input-group-prepend">
                <div className="input-group-text">{Translator("start date")}</div>
              </div>
              <input
                type="date"
                className="form-control"
                placeholder={Translator("start date")}
                onChange={(e) => {
                  this.setState({
                    newSiteTemp: { ...this.state.newSiteTemp, start: MomentDateString(e.target.valueAsDate) },
                  });
                }}
              ></input>
            </div>
            <div className="input-group mb-2 mr-sm-2">
              <div className="input-group-prepend">
                <div className="input-group-text">{Translator("end of warranty")}</div>
              </div>
              <input
                type="date"
                className="form-control"
                placeholder={Translator("end of warranty")}
                onChange={(e) => {
                  this.setState({
                    newSiteTemp: { ...this.state.newSiteTemp, warranty: MomentDateString(e.target.valueAsDate) },
                  });
                }}
              ></input>
            </div>
          </form>

          <form className="form-inline col-sm-12">
            <div className="input-group mb-2 mr-sm-2">
              <div className="input-group-prepend">
                <div className="input-group-text">{Translator("contract")}</div>
              </div>
              <select
                className="custom-select"
                defaultValue="none"
                onChange={(e) => {
                  console.log(e.target.value);
                  this.setState({ newSiteTemp: { ...this.state.newSiteTemp, contract: e.target.value } });
                }}
              >
                <option value="basic1">{Translator("basic1")}</option>
                <option value="basic2">{Translator("basic2")}</option>
                <option value="omnium">{Translator("omnium")}</option>
                <option value="none">{Translator("none")}</option>
              </select>
            </div>
            <div className="input-group mb-2 mr-sm-2">
              <input
                type="text"
                className="form-control"
                placeholder={Translator("database")}
                onChange={(e) => {
                  this.setState({ newSiteTemp: { ...this.state.newSiteTemp, database: e.target.value } });
                }}
              ></input>
            </div>
            {this.handleNewSiteConveyors()}
          </form>
          <form className="form-inline col-sm-12">
            <button
              className="btn btn-info btn mb-2 mr-sm-2"
              onClick={(e) => {
                e.preventDefault();
                let newSite = this.state.newSiteTemp;
                newSite.id = uuidv4();
                const newConveyors = {};
                Object.keys(this.state.newSiteTemp.convCount).map((key) => {
                  const name = this.state.newSiteTemp.convCount[key].newConvName;
                  if (name) {
                    newConveyors[name] = { id: uuidv4(), specifications: [] };
                  }
                });
                newSite.conveyors = Object.keys(newConveyors).map((key) => {
                  return {
                    id: uuidv4(),
                    name: key,
                  };
                });
                this.addSite(newSite);
                this.props.triggerCreatingMode();
                this.setState({
                  newSiteTemp: { convCount: { 1: {} } },
                  sites: {
                    ...this.state.sites,
                    [newSite.name]: {
                      id: newSite.id,
                      infos: {
                        serial: newSite.serial,
                        start: newSite.start,
                        warranty: newSite.warranty,
                        contract: newSite.contract,
                        database: newSite.database,
                      },
                      conveyors: newConveyors,
                    },
                  },
                  creatingMode: false,
                  siteState: { ...this.state.siteState, [newSite.name]: {} },
                });
                this.reload();
              }}
              disabled={this.state.newSiteTemp ? (this.state.newSiteTemp.name ? false : true) : true}
            >
              {Translator("enregistrer")}
            </button>
            {displayRequiredCallout()}
            {displayCallout()}
          </form>
        </div>
        <div className="ribbon-wrapper ribbon-lg">
          <div className="ribbon bg-success">{Translator("new site")}</div>
        </div>
      </div>
    );
  }
  render() {
    return (
      <div>
        {this.state.creatingMode ? this.customCreateForm() : null}
        {this.renderConveyors()}
        {isLoading(this.state.isFetching)}
        {this.props.subCustomer
          ? null
          : this.state.relatedTos.map((rel) => {
            return (
              <CustomerConveyor
                key={rel.id}
                className="fadeIn animated"
                generalInfos={{ name: rel.name, id: rel.id }}
                subCustomer={true}
              ></CustomerConveyor>
            );
          })}
      </div>
    );
  }
}

export default withRouter(CustomerConveyor);
