import React, { useState } from "react";
import { NavLink, withRouter, Link } from "react-router-dom";
import { Translator } from "./tools/Tools";
import { store } from "./tools/Tools";
import { fetchUsers, fetchPendingUserEvents } from "./tools/Api_Customers";

const Sidebar = (props) => {
  const isLogged = store().getLogged();
  // Notification system for holidays requests
  const [pending, setPending] = useState(0); // integer state
  const [polling, setPolling] = useState(false); // boolean state
  if (!isLogged && pending > 0) {
    setPending(0);
  }
  const loadUserHolidays = () => {
    if (store().getLogged() && store().getUser().accreditation < 2) {
      fetchUsers().then((users) => {
        let ids = store().getUser().userId;
        users.data.map((user) => (ids = ids + "," + user.id));
        fetchPendingUserEvents(ids).then((events) => {
          let mainEvents = {};
          events.map((event) => {
            mainEvents[event.EventId] = 1;
          });
          setPending(Object.keys(mainEvents).length);
        });
      });
    }
  };
  if (isLogged && pending === 0) {
    loadUserHolidays();
  }
  if (!polling) {
    setPolling((polling) => true);
    setInterval(() => {
      loadUserHolidays();
    }, 45000);
  }
  return (
    <aside className="main-sidebar sidebar-dark-primary elevation-4">
      <Link
        to={{
          pathname: "/",
        }}
        className="brand-link"
      >
        <img src="/assets/dist/img/eblogotrans.png" alt="EB Logo" className="brand-image" style={{ opacity: ".8" }} />
        <span className="brand-text font-weight-light">Eurobrevets</span>
      </Link>

      <div className="sidebar" id="collapseDiv">
        <div className="user-panel mt-3 pb-3 mb-3 d-flex">
          <div className="image">
            <img
              src={isLogged ? "/assets/dist/img/" + store().getUser().shortName + "160x160.jpg" : null}
              className="img-circle elevation-2"
              alt="User"
            />
          </div>
          <div className="info">
            <Link
              to={{
                pathname: "/",
              }}
              className="d-block"
            >
              {isLogged ? store().getUser().firstName : Translator("not connected")}
            </Link>
            {isLogged
              ? (() => {
                switch (store().getUser().accreditation) {
                  case 0:
                    return <h4>Dev admin</h4>;
                  case 1:
                    return <h4>Manager</h4>;
                  case 2:
                    return <h4>IT</h4>;
                  case 3:
                    return <h4>Secretary</h4>;
                  case 4:
                    return <h4>Tech</h4>;
                }
              })()
              : null}
          </div>
        </div>
        <nav className="mt-2">
          {isLogged ? (
            <ul
              className="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              {store().getUser().accreditation < 2 ? (
                <li className="nav-item">
                  <NavLink
                    to={{ pathname: "/administration", state: props.history.location.state }}
                    className="nav-link"
                    activeStyle={{
                      fontWeight: "bold",
                      color: "#FFFFFF",
                    }}
                  >
                    <i className="nav-icon fas fas fa-sliders-h"></i>
                    <p>&nbsp;{Translator("administration")}</p>
                  </NavLink>
                </li>
              ) : null}
              <li className="nav-item has-treeview">
                <NavLink
                  to={{ pathname: "/customers", state: props.history.location.state }}
                  className="nav-link"
                  activeStyle={{
                    fontWeight: "bold",
                    color: "#FFFFFF",
                  }}
                >
                  <i className="nav-icon fas fas fa-address-card"></i>
                  <p>&nbsp;{Translator("customers")}</p>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to={{ pathname: "/suppliers", state: props.history.location.state }}
                  className="nav-link"
                  activeStyle={{
                    fontWeight: "bold",
                    color: "#FFFFFF",
                  }}
                >
                  <i className="nav-icon fas fas fa-truck-moving"></i>
                  <p>&nbsp;{Translator("suppliers")}</p>
                </NavLink>
              </li>
              {/*store().getUser().accreditation < 4 ? (
                <li className="nav-item">
                  <NavLink
                    to={{ pathname: "/invoices", state: props.history.location.state }}
                    className="nav-link"
                    activeStyle={{
                      fontWeight: "bold",
                      color: "#FFFFFF",
                    }}
                  >
                    <i className="nav-icon far fa-money-bill-alt"></i>
                    <p>&nbsp;{Translator("invoicing")}</p>
                  </NavLink>
                </li>
                  ) : null*/}
              <li className="nav-item">
                <NavLink
                  to={{ pathname: "/agenda", state: props.history.location.state }}
                  className="nav-link"
                  activeStyle={{
                    fontWeight: "bold",
                    color: "#FFFFFF",
                  }}
                >
                  <i className="nav-icon fa-solid fa-calendar-days"></i>
                  <p>&nbsp;{Translator("agenda")}</p>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to={{ pathname: "/heures", state: props.history.location.state }}
                  className="nav-link"
                  activeStyle={{
                    fontWeight: "bold",
                    color: "#FFFFFF",
                  }}
                >
                  <i className="nav-icon fas fa-user-clock"></i>
                  <p>&nbsp;{Translator("heures")}</p>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to={{ pathname: "/vacances", state: props.history.location.state }}
                  className="nav-link"
                  activeStyle={{
                    fontWeight: "bold",
                    color: "#FFFFFF",
                  }}
                >
                  {pending > 0 ? <span className="badge badge-info left">{pending}</span> : null}
                  <i className="nav-icon fas fa-umbrella-beach"></i>
                  <p>&nbsp;{Translator("vacances")}</p>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to={{ pathname: "/todolist", state: props.history.location.state }}
                  className="nav-link"
                  activeStyle={{
                    fontWeight: "bold",
                    color: "#FFFFFF",
                  }}
                >
                  <i className="nav-icon fas fa-tasks"></i>
                  <p>&nbsp;{Translator("todo list")}</p>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to={{ pathname: "/projects", state: props.history.location.state }}
                  className="nav-link"
                  activeStyle={{
                    fontWeight: "bold",
                    color: "#FFFFFF",
                  }}
                >
                  <i className="nav-icon fas fa-project-diagram"></i>
                  <p>&nbsp;{Translator("projects")}</p>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to={{ pathname: "/tickets", state: props.history.location.state }}
                  className="nav-link"
                  activeStyle={{
                    fontWeight: "bold",
                    color: "#FFFFFF",
                  }}
                >
                  <i className="nav-icon fa-solid fa-ticket"></i>
                  <p>&nbsp;{Translator("tickets")}</p>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to={{ pathname: "/checklists", state: props.history.location.state }}
                  className="nav-link"
                  activeStyle={{
                    fontWeight: "bold",
                    color: "#FFFFFF",
                  }}
                >
                  <i className="nav-icon fa-solid fa-clipboard-list"></i>
                  <p>&nbsp;{Translator("checklists")}</p>
                </NavLink>
              </li>
            </ul>
          ) : (
            ""
          )}
        </nav>
      </div>
    </aside>
  );
};

export default withRouter(Sidebar);
