import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import Card from "../../components/Card";
import { Translator, store } from "../../tools/Tools";
import SupplierData from "./SupplierData";

class Supplier extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tabs: [{ title: Translator("datas"), active: true }],
      generalInformations: this.props.history.location.state ? this.props.history.location.state.genericInfos : null,
      user: store().getUser(),
      addingSite: false,
      addingLogbook: false,
      addingRemote: false,
    };
    if (this.state.generalInformations === null) this.props.history.push("/Suppliers");
  }

  handleClickTab(tab) {
    this.setState((state) => {
      return {
        addingSite: false,
        addingLogbook: false,
        tabs: state.tabs.map((stateTab) => {
          return {
            title: stateTab.title,
            active: stateTab.title === tab.title,
          };
        }),
      };
    });
  }

  generateTabs() {
    return (
      <ul className="nav nav-tabs">
        {this.state.tabs.map((tab) => {
          return (
            <li
              key={tab.title}
              className={tab.active ? "nav-link active" : "nav-link"}
              onClick={this.handleClickTab.bind(this, tab)}
            >
              {tab.title}
            </li>
          );
        })}
      </ul>
    );
  }
  triggerAddingSite() {
    this.setState((state) => {
      return { addingSite: !state.addingSite };
    });
  }
  triggerAddingLogbook() {
    this.setState((state) => {
      return { addingLogbook: !state.addingLogbook };
    });
  }
  triggerAddingRemote() {
    this.setState((state) => {
      return { addingRemote: !state.addingRemote };
    });
  }
  generateTabRelatedContent() {
    switch (this.state.tabs.find((tab) => tab.active).title) {
      case Translator("datas"):
        return <SupplierData className="fadeIn animated" generalInfos={this.state.generalInformations}></SupplierData>;
      default:
        return null;
    }
  }
  renderButtons() {
    if (this.state.tabs.find((tab) => tab.active).title === Translator("conveyors")) {
      return (
        <div>
          <button
            className="btn btn"
            onClick={(e) => {
              e.preventDefault();
              this.setState((state) => {
                return { addingSite: !state.addingSite };
              });
            }}
          >
            <span className="fa-stack" style={{ verticalAlign: "top" }}>
              <i className="fas fa-folder fa-stack-2x" style={{ color: "#6c757d" }}></i>
              <i className="fas fa-plus fa-stack-1x fa-inverse"></i>
            </span>
          </button>
        </div>
      );
    }
    if (this.state.tabs.find((tab) => tab.active).title === Translator("remote maintenance")) {
      return (
        <div>
          <button
            className="btn btn"
            onClick={(e) => {
              e.preventDefault();
              this.setState((state) => {
                return { addingRemote: !state.addingRemote };
              });
            }}
          >
            <span className="fa-stack" style={{ verticalAlign: "top" }}>
              <i className="fas fa-desktop fa-stack-2x fa-inverse" style={{ color: "#6c757d" }}></i>
              <i className="fas fa-plus fa-stack-1x" style={{ color: "#6c757d" }}></i>
            </span>
          </button>
        </div>
      );
    }
    if (this.state.tabs.find((tab) => tab.active).title === Translator("logbook")) {
      return (
        <div>
          <button
            className="btn btn"
            onClick={(e) => {
              e.preventDefault();
              this.setState((state) => {
                return { addingLogbook: !state.addingLogbook };
              });
            }}
          >
            {this.state.addingLogbook ? (
              <i className="fas fa-undo"></i>
            ) : (
              <span className="fa-stack" style={{ verticalAlign: "top" }}>
                <i className="fas fa-file fa-stack-2x" style={{ color: "#6c757d" }}></i>
                <i className="fas fa-plus fa-stack-1x fa-inverse"></i>
              </span>
            )}
          </button>
        </div>
      );
    }
  }
  render() {
    return this.props.history.location.state ? (
      <div>
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0 text-dark">{this.state.generalInformations.name}</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to={{ pathname: "../", state: this.props.location.state }}>{Translator("Home")}</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to={{ pathname: "/Suppliers", state: this.props.location.state }}>
                      {Translator("Suppliers")}
                    </Link>
                  </li>
                  <li className="breadcrumb-item active">{this.state.generalInformations.name}</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="container-fluid">
            <Card title={this.generateTabs()} tools={this.renderButtons()}>
              {this.generateTabRelatedContent()}
            </Card>
          </div>
        </section>
      </div>
    ) : null;
  }
}
export default withRouter(Supplier);
