import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import Card from "../../components/Card";
import { Translator, store } from "../../tools/Tools";
import { v4 as uuidv4 } from 'uuid';
import Select from "react-select";
import { fetchCustomers, fetchCustomerConveyors, postProject, patchProject } from "../../tools/Api_Customers";
import { id } from "date-fns/locale";

class ProjectForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: props.title,
      periodInsertCount: 1,
      periods: [],
      customersOptionTable: [],
      siteOptionTable: [],
      temporaryInsertedProject: { ProjectPeriods: [] },
      lastSelectedDate: null
    };
    if (props.project) {
      console.log(JSON.stringify(props.project));
    }
  }
  componentDidMount() {
    this.reload();
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.project && this.props.project.CustomerId &&
      ((this.props.project.CustomerId &&
        this.props.project.CustomerId !== prevProps.project.CustomerId)
        || ((this.props.project.SiteId &&
          this.props.project.SiteId !== prevProps.project.SiteId)
          || !this.props.project.SiteId && prevProps.project.SiteId))

    ) {
      fetchCustomerConveyors(this.props.project.CustomerId).then((result) => {
        const customerSite = result.Sites.map((site) => {
          return {
            value: site.id,
            label: site.name,
          };
        });
        customerSite.push({
          value: "all",
          label: Translator("all"),
        });
        this.setState({
          siteOptionTable: customerSite,
          periods: [],
          temporaryInsertedProject: {
            ...this.state.temporaryInsertedProject,
            id: this.props.project.id,
            ProjectPeriods: this.props.project.ProjectPeriods,
            projectsCustomer: this.props.project.CustomerId,
          },
        });
        this.props.project.ProjectPeriods.map((pp) => {
          pp.from = moment(pp.from).format("DD/MM/YYYY");
          pp.to = moment(pp.to).format("DD/MM/YYYY");
          this.addPeriod(pp);
        });
      });
      this.reload();
    }
  }
  reload(selected) {
    if (this.props.reload) this.props.reload(selected);
    if (!this.props.project || Object.keys(this.props.project).length === 0) {
      fetchCustomers()
        .then((fetchedCustomers) => {
          if (fetchedCustomers) {
            this.setState({
              temporaryInsertedProject: { ProjectPeriods: [] },
              periods: [],
              customersOptionTable: fetchedCustomers.data.map((customer) => {
                return { value: customer.id, label: customer.name };
              }),
            });
          }
        })
        .then(() => {
          this.addPeriod();
        });
    } else {
      this.setState({
        customersOptionTable: [{ value: this.props.project.CustomerId, label: this.props.project.Customer.name }],
        selectedCustomer: { value: this.props.project.CustomerId, label: this.props.project.Customer.name },
      });
    }
  }
  addPeriod(pp) {
    if (this.state.temporaryInsertedProject.projectsCustomer || pp) {
      let periods = this.state.periods;
      let id;
      const tmpPeriods = this.state.temporaryInsertedProject.ProjectPeriods || this.props.project.ProjectPeriods;
      if (!pp) {
        id = uuidv4();
        tmpPeriods.push({ id: id });
      } else {
        id = pp.id;
      }

      this.setState({
        temporaryInsertedProject: { ...this.state.temporaryInsertedProject, ProjectPeriods: tmpPeriods },
      });
      const options = [
        {
          value: "preparation",
          label: (
            <div
              className="bg-primary col mx-1 text-truncate"
              style={{
                border: "1px",
                borderRadius: "0.5em",
              }}
            >
              {Translator("preparation")}
              <i className="fas fa-database mx-1"></i>
            </div>
          ),
        },
        {
          value: "delivery",
          label: (
            <div
              className="bg-navy col mx-1 text-truncate"
              style={{
                border: "1px",
                borderRadius: "0.5em",
              }}
            >
              {Translator("delivery")}
              <i className="fas fa-truck-moving mx-1"></i>
            </div>
          ),
        },
        {
          value: "assembly",
          label: (
            <div
              className="bg-warning col mx-1 text-truncate"
              style={{
                border: "1px",
                borderRadius: "0.5em",
              }}
            >
              {Translator("assembly")}
              <i className="fas fa-tools mx-1"></i>
            </div>
          ),
        },
        {
          value: "tests",
          label: (
            <div
              className="bg-fuchsia col mx-1 text-truncate"
              style={{
                border: "1px",
                borderRadius: "0.5em",
              }}
            >
              {Translator("tests")}
              <i className="fas fa-power-off mx-1"></i>
            </div>
          ),
        },
        {
          value: "startup",
          label: (
            <div
              className="bg-danger col mx-1 text-truncate"
              style={{
                border: "1px",
                borderRadius: "0.5em",
              }}
            >
              {Translator("startup")}
              <i className="fas fa-flag-checkered mx-1"></i>
            </div>
          ),
        },
        {
          value: "training",
          label: (
            <div
              className="bg-success col mx-1 text-truncate"
              style={{
                border: "1px",
                borderRadius: "0.5em",
              }}
            >
              {Translator("training")}
              <i className="fas fa-graduation-cap mx-1"></i>
            </div>
          ),
        },
        {
          value: "upgrade software",
          label: (
            <div
              className="bg-purple col mx-1 text-truncate"
              style={{
                border: "1px",
                borderRadius: "0.5em",
              }}
            >
              {Translator("upgrade software")}
              <i className="fas fa-upload mx-1"></i>
            </div>
          ),
        },
        {
          value: "upgrade hardware",
          label: (
            <div
              className="bg-orange col mx-1 text-truncate"
              style={{
                border: "1px",
                borderRadius: "0.5em",
              }}
            >
              {Translator("upgrade hardware")}
              <i className="fas fa-rocket mx-1"></i>
            </div>
          ),
        },
        {
          value: "maintenance",
          label: (
            <div
              className="bg-info col mx-1 text-truncate"
              style={{
                border: "1px",
                borderRadius: "0.5em",
              }}
            >
              {Translator("maintenance")}
              <i className="fas fa-oil-can mx-1"></i>
            </div>
          ),
        },
        {
          value: "waiting",
          label: (
            <div
              className="bg-teal col mx-1 text-truncate"
              style={{
                border: "1px",
                borderRadius: "0.5em",
              }}
            >
              {Translator("waiting")}
              <i className="fas fa-question mx-1"></i>
            </div>
          ),
        },
      ];
      periods.push(
        <div key={id} className="p-2 my-2" style={{ border: "1px solid whitesmoke" }}>
          <div className="form-group row">
            <div className="col">
              <label htmlFor="periodName">{Translator("projectPeriod type")}</label>
              <Select
                defaultValue={options.find((opt) => {
                  if (pp) return opt.value === pp.type;
                })}
                options={options}
                onChange={(selectedOption) => {
                  let ProjectPeriods = this.state.temporaryInsertedProject.ProjectPeriods;
                  let projectPeriodIndex = ProjectPeriods.findIndex((pP) => pP.id === id);
                  ProjectPeriods[projectPeriodIndex].type = selectedOption.value;
                  this.setState({
                    temporaryInsertedProject: {
                      ...this.state.temporaryInsertedProject,
                      ProjectPeriods: ProjectPeriods,
                    },
                  });
                }}
              ></Select>
            </div>
            <div className="col" style={{ textAlign: "right" }}>
              <button
                className="btn btn-default"
                onClick={(e) => {
                  e.preventDefault();
                  const newPeriods = this.state.periods.filter((p) => p.key !== id);
                  const tmpPeriods = this.state.temporaryInsertedProject.ProjectPeriods.filter((p) => p.id !== id);
                  /*                   console.log(newPeriods);
                  console.log(this.state.periods);
                  console.log(this.state.temporaryInsertedProject.ProjectPeriods);
                  console.log(tmpPeriods); */
                  this.setState({
                    periods: newPeriods,
                    temporaryInsertedProject: { ...this.state.temporaryInsertedProject, ProjectPeriods: tmpPeriods },
                  });
                }}
              >
                <span style={{ color: "Tomato" }}>
                  <i className="fas fa-times"></i>
                </span>
              </button>
            </div>
          </div>
          <div className="form-group row">
            <div className="col">
              <label htmlFor="from">{Translator("from")}</label>
              <input
                className="form-control"
                defaultValue={pp ? moment(pp.from, "DD/MM/YYYY").format("YYYY-MM-DD") : this.state.lastSelectedDate ? this.state.lastSelectedDate : null}
                type="date"
                id={id + "fromDate"}
                onChange={(e) => {
                  let ProjectPeriods = this.state.temporaryInsertedProject.ProjectPeriods;
                  let projectPeriodIndex = this.state.temporaryInsertedProject.ProjectPeriods.findIndex(
                    (pP) => pP.id === id
                  );
                  //console.log("changing from date");
                  ProjectPeriods[projectPeriodIndex].from = moment(e.target.valueAsDate).format("DD/MM/YYYY");
                  if (!ProjectPeriods[projectPeriodIndex].to || moment(ProjectPeriods[projectPeriodIndex].to, "DD/MM/YYYY").isBefore(moment(ProjectPeriods[projectPeriodIndex].from, "DD/MM/YYYY"))) {
                    //console.log("changing to date with it");
                    ProjectPeriods[projectPeriodIndex].to = moment(e.target.valueAsDate).format("DD/MM/YYYY");
                  }
                  const minDate = moment(ProjectPeriods[projectPeriodIndex].from, "DD/MM/YYYY").format("YYYY-MM-DD");
                  if (document.getElementById(id + "toDate")) {
                    document.getElementById(id + "toDate").setAttribute("min", minDate);
                    if (moment(document.getElementById(id + "toDate").value, "YYYY-MM-DD").isBefore(moment(ProjectPeriods[projectPeriodIndex].from, "DD/MM/YYYY"))) {
                      document.getElementById(id + "toDate").setAttribute("value", minDate);
                      document.getElementById(id + "toDate").value = minDate;
                    }
                  }
                  this.setState({
                    temporaryInsertedProject: {
                      ...this.state.temporaryInsertedProject,
                      ProjectPeriods: ProjectPeriods,
                    },
                    lastSelectedDate: minDate
                  });
                }}
              ></input>
            </div>
            <div className="col">
              <label htmlFor="to">{Translator("to")}</label>
              <input
                className="form-control"
                type="date"
                defaultValue={pp ? moment(pp.to, "DD/MM/YYYY").format("YYYY-MM-DD") : this.state.lastSelectedDate ? this.state.lastSelectedDate : null}
                id={id + "toDate"}
                onChange={(e) => {
                  let ProjectPeriods = this.state.temporaryInsertedProject.ProjectPeriods;
                  let projectPeriodIndex = this.state.temporaryInsertedProject.ProjectPeriods.findIndex(
                    (pP) => pP.id === id
                  );
                  ProjectPeriods[projectPeriodIndex].to = moment(e.target.valueAsDate).format("DD/MM/YYYY");
                  if (!ProjectPeriods[projectPeriodIndex].from) {
                    ProjectPeriods[projectPeriodIndex].from = moment(e.target.valueAsDate).format("DD/MM/YYYY");
                  }
                  const maxDate = moment(ProjectPeriods[projectPeriodIndex].to, "DD/MM/YYYY").format("YYYY-MM-DD");
                  if (document.getElementById(id + "fromDate")) {
                    document.getElementById(id + "fromDate").setAttribute("max", maxDate);
                    document.getElementById(id + "fromDate").setAttribute("value", maxDate);
                  }

                  this.setState({
                    temporaryInsertedProject: {
                      ...this.state.temporaryInsertedProject,
                      ProjectPeriods: ProjectPeriods,
                    },
                    lastSelectedDate: maxDate
                  });
                }}
              ></input>
            </div>
            <div className="col">
              <label htmlFor="workersCount">{Translator("workforce count")}</label>
              <input
                className="form-control"
                type="number"
                min={0}
                defaultValue={
                  pp
                    ? pp.workersCount
                    : this.state.temporaryInsertedProject
                      ? this.state.temporaryInsertedProject.workersCount
                      : 0
                }
                id="workersCount"
                onChange={(e) => {
                  let ProjectPeriods = this.state.temporaryInsertedProject.ProjectPeriods;
                  let projectPeriodIndex = ProjectPeriods.findIndex((pP) => pP.id === id);
                  ProjectPeriods[projectPeriodIndex].workersCount = e.target.value;
                  this.setState({
                    temporaryInsertedProject: {
                      ...this.state.temporaryInsertedProject,
                      ProjectPeriods: ProjectPeriods,
                    },
                  });
                }}
              ></input>
            </div>
          </div>
          <div className="form-group row">
            <div className="col">
              <textarea
                className="form-control"
                id="workersCount"
                defaultValue={pp ? (pp.note ? pp.note : "") : ""}
                onChange={(e) => {
                  let ProjectPeriods = this.state.temporaryInsertedProject.ProjectPeriods;
                  let projectPeriodIndex = this.state.temporaryInsertedProject.ProjectPeriods.findIndex(
                    (pP) => pP.id === id
                  );
                  ProjectPeriods[projectPeriodIndex].note = e.target.value;
                  this.setState({
                    temporaryInsertedProject: {
                      ...this.state.temporaryInsertedProject,
                      ProjectPeriods: ProjectPeriods,
                    },
                  });
                }}
              ></textarea>
            </div>
          </div>
        </div>
      );
      this.setState({ periods: periods, lastSelectedDate: pp ? moment(pp.to, "DD/MM/YYYY").format("YYYY-MM-DD") : this.state.lastSelectedDate });
    }
  }
  postProject() {
    postProject(this.state.temporaryInsertedProject).then((resp) => {
      this.reload(resp);
    });
  }
  patchProject() {
    patchProject(this.state.temporaryInsertedProject).then((resp) => {
      //console.log(resp);
      this.reload(resp);
    });
  }
  submitButtonDisableState() {
    const tmpPro = this.state.temporaryInsertedProject;
    let disable = false;
    if (!tmpPro.projectsCustomer) {
      disable = true;
    }
    tmpPro.ProjectPeriods.map((pp) => {
      if (!pp.type || !pp.from || !pp.to) {
        disable = true;
      }
    });
    return disable;
  }
  renderBody() {
    return (
      <Card title={this.props.title ? this.props.title : Translator("insert project")} tools={this.props.tools}>
        <div className="container-fluid">
          <div className="form-group row">
            <div className="col">
              <label htmlFor="projectName">{Translator("project customer")}</label>
              <Select
                noOptionsMessage={() => {
                  return <label>{Translator("none")}</label>;
                }}
                value={this.state.selectedCustomer}
                onChange={(selectedOption) => {
                  fetchCustomerConveyors(selectedOption.value).then((result) => {
                    const customerSite = result.Sites.map((site) => {
                      return {
                        value: site.id,
                        label: site.name,
                      };
                    });
                    customerSite.push({
                      value: "all",
                      label: Translator("all"),
                    });
                    const periods = [];
                    this.setState({
                      selectedCustomer: selectedOption,
                      temporaryInsertedProject: {
                        ProjectPeriods: [],
                        projectsCustomer: selectedOption.value,
                      },
                      siteOptionTable: customerSite,
                      periods: periods,
                    });
                  });
                }}
                options={this.state.customersOptionTable}
              />
              <div className="form-group row">
                <div className="col">
                  <label htmlFor="periodName">{Translator("projectPeriod site")}</label>
                  <Select
                    noOptionsMessage={() => {
                      return <label>{Translator("none")}</label>;
                    }}
                    value={this.state.siteOptionTable.find((site) => {
                      if (this.state.project) return this.state.project.siteId === site.id;
                    })}
                    onChange={(selectedOption) => {
                      if (selectedOption.value !== "all") {
                        this.setState({
                          temporaryInsertedProject: {
                            ...this.state.temporaryInsertedProject,
                            siteId: selectedOption.value,
                          },
                        });
                      }
                    }}
                    options={this.state.siteOptionTable}
                  />
                </div>
              </div>
            </div>
          </div>
          {this.state.periods.map((period) => period)}
          <hr></hr>
          <div className="form-group row">
            <div className="col">
              <button
                className="btn btn-default"
                onClick={(e) => {
                  this.addPeriod();
                }}
              >
                {Translator("add period")}
              </button>
            </div>

            <div className="col">
              <button
                className="btn btn-primary float-right"
                data-dismiss="modal"
                aria-label="Close"
                disabled={this.submitButtonDisableState()}
                onClick={() => {
                  if (this.props.project) {
                    this.patchProject();
                  } else {
                    this.postProject();
                  }
                }}
              >
                {Translator("submit")}
              </button>
            </div>
          </div>
        </div>
      </Card>
    );
  }
  render() {
    if (this.props.modal) {
      return (
        <div
          className="modal fade"
          id="exampleModal"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-body m-n2">{this.renderBody()}</div>
            </div>
          </div>
        </div>
      );
    } else {
      return this.renderBody();
    }
  }
}

export default withRouter(ProjectForm);
