import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import Card from "../components/Card";
import { store, MomentDateString, Translator, computeWorkTime } from "../tools/Tools";
import Holidays from "date-holidays";
import moment from "moment";
import {
  fetchCustomers,
  fetchUsers,
  fetchEvents,
  getProjectList,
  patchProjectList,
  fetchUserTodo,
  fetchPendingProjects
} from "../tools/Api_Customers";
import HeuresCard from "./Heures/HeuresCard";
import RichText from "../components/RichText";
import TodoList from "./Todolist/TodoList";
import ProjectsOverview from "./Projects/ProjectsOverview";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      conveyors: [],
      events: [],
      total: 0,
      user: store().getUser(),
      projectList: "",
      editProjectList: false,
    };
  }
  componentDidMount() {
    this.reload();
  }
  fetchUserTodo() {
    fetchUserTodo(store().getUser().UserId);
  }
  reload() {
    fetchUsers().then((users) => {
      fetchCustomers().then((fetchedCustomers) => {
        fetchEvents(
          moment().startOf("days").format("YYYY-MM-DD"),
          moment().startOf("days").add(1, "days").format("YYYY-MM-DD"),
          [store().getUser().userId]
        ).then((events) => {
          //console.log(events)
          computeWorkTime(store().getUser().userId).then((resultWorkTime) => {
            let fullTotal = 0;
            Object.keys(resultWorkTime.workTimeMonths).map((month) => {
              fullTotal += resultWorkTime.workTimeMonths[month];
            });
            getProjectList().then((projectList) => {
              fetchPendingProjects().then(
                (fetchedProjects) => {
                  this.setState({
                    editProjectList: false,
                    projectList: projectList.text,
                    total: fullTotal,
                    user: users.data.find((user) => user.id === store().getUser().userId),
                    events: events,
                    projects: fetchedProjects,
                    customersOptionTable: fetchedCustomers.data.map((customer) => {
                      return { value: customer.id, label: customer.name };
                    }),
                  });
                },
                (reject) => {
                  console.log(reject);
                }
              );
            });
          });
        });
      });
    });
  }
  patchProjectList() {
    patchProjectList({ text: this.state.projectList }).then((result) => {
      this.reload();
    });
  }
  returnEventDesc(event, period) {
    if (
      event.type === "off" ||
      event.type === "unpaid" ||
      event.type === "holiday" ||
      event.type === "medical off" ||
      event.type === "technical off" ||
      event.type === "recup national holiday"
    ) {
      return Translator(event.type);
    }
    if (!period.title) {
      if (!event.title) {
        if (this.state.customersOptionTable.find((cust) => cust.value === event.CustomerId)) {
          return (
            <b>
              &nbsp;<i className="fas fa-chevron-right"></i>&nbsp;<i className="nav-icon fas fas fa-address-card"></i>
              &nbsp;
              {this.state.customersOptionTable.find((cust) => cust.value === event.CustomerId).label}
            </b>
          );
        } else {
          return event.type;
        }
      } else {
        return event.title;
      }
    } else {
      return period.title;
    }
  }
  onProjectListChange(content) {
    this.setState({ projectList: content });
  }

  render() {
    let hd = Holidays();
    hd.init("BE");
    moment.locale(store().getLanguage());
    //console.log(store().getUser())
    const renderEditButton = () => {
      if (store().getUser().accreditation < 2) {
        return (
          <button
            className={`btn`}
            onClick={(e) => {
              e.preventDefault();
              if (this.state.editProjectList) {
                this.patchProjectList();
              } else {
                this.setState((state) => {
                  return { editProjectList: !state.editProjectList };
                });
              }
            }}
          >
            {this.state.editProjectList ? <i className="fas far fa-save"> </i> : <i className="fas fa-edit"> </i>}
          </button>
        );
      }
    };
    const renderHtml = (html) => {
      return { __html: html };
    };

    return (
      <div>
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0 text-dark">Dashboard</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to={{ pathname: "../" }}>{Translator("Home")}</Link>
                  </li>
                  <li className="breadcrumb-item active">Dashboard v0</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col">
                <div className="row">
                  <div className="col">
                    <Card title={Translator("my day")}>
                      <ul>
                        {this.state.events.map((event) => {
                          const compare = (a, b) => {
                            if (moment(a.time).isBefore(moment(b.time))) return -1;
                            if (moment(a.time).isAfter(moment(b.time))) return 1;
                            return 0;
                          };
                          event.UserEvents.sort(compare);
                          return (
                            <li key={event.id}>
                              {event.UserEvents.map((period) => {
                                if (period.UserId === store().getUser().userId) {
                                  return (
                                    <div key={period.id}>
                                      {period.fullDay ? (
                                        <b>&nbsp;{this.returnEventDesc(event, period)}</b>
                                      ) : (
                                        <div>
                                          <small>{moment(period.from).format("HH:mm")}</small>
                                          <small>
                                            &nbsp;<i className="fas fa-chevron-right"></i>&nbsp;
                                          </small>
                                          <small>{moment(period.to).format("HH:mm")}</small>
                                          {period.break !== "" && period.break !== "00:00" ? (
                                            <small>
                                              {" "}
                                              <i className="fas fa-mug-hot"></i> {period.break}
                                            </small>
                                          ) : null}
                                          <small>&nbsp;{this.returnEventDesc(event, period)}</small>
                                        </div>
                                      )}
                                    </div>
                                  );
                                }
                              })}
                            </li>
                          );
                        })}
                      </ul>
                    </Card>
                  </div>
                </div>
                <div className="row">
                  {" "}
                  <div className="col">
                    <Card title={Translator("my hours")}>
                      <HeuresCard
                        small={true}
                        user={this.state.user}
                        total={this.state.total}
                        withInitialCredit
                      ></HeuresCard>
                    </Card>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <Card title={Translator("EB Links")}>
                      <p>
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            window.open(
                              "https://docs.google.com/spreadsheets/d/17fWlH7wsBjcU1nzJH9mJfwCihco3cEQDONFotpT_0R0/edit?usp=sharing",
                              "_blank"
                            );
                          }}
                        >
                          {Translator("link to call forwarding")}
                        </a>
                      </p>
                      <p>
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            window.open(
                              "https://www.google.com/maps/d/edit?mid=1NCl3nBKSwKfqWBTlH0vmZjvHIgI&usp=sharing",
                              "_blank"
                            );
                          }}
                        >
                          {Translator("link to eb customer's map")}
                        </a>
                      </p>
                    </Card>
                  </div>
                </div>
              </div>
              <div className="col">
                <TodoList user={store().getUser().userId} fetchTodos={fetchUserTodo}></TodoList>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <ProjectsOverview projects={this.state.projects ? this.state.projects : []}></ProjectsOverview>
              </div>
            </div>
            <div className="row">
              <div className="col">
                {/*<Card title={Translator("project list")} tools={renderEditButton()}>
                  {this.state.editProjectList ? (
                    <RichText
                      title={Translator("extra informations")}
                      defaultValue={this.state.projectList}
                      developped
                      onChange={this.onProjectListChange.bind(this)}
                    ></RichText>
                  ) : (
                    <div dangerouslySetInnerHTML={renderHtml(this.state.projectList)}></div>
                  )}
                  </Card>*/}
                <Card title={Translator("anomaly list")}>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }} >
                    <iframe src="/assets/dist/img/Messages d'erreurs DAV - 2021.pdf" width="820" height="1080" allow="autoplay"></iframe>
                  </div>
                </Card>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default withRouter(Home);
