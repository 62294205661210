import React, { Component } from "react";
import moment from "moment";

class Card extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: this.props.collapsed ? true : false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.collapsed != this.props.collapsed) {
      this.setState({ collapsed: this.props.collapsed });
    }
  }
  handleCardType = () => {
    switch (this.props.type) {
      case "primary":
        return "card card-primary" + (this.state.collapsed ? " collapsed-card" : "");
      case "secondary":
        return "card card-secondary" + (this.state.collapsed ? " collapsed-card" : "");
      case "dark":
        return "card  card-dark" + (this.state.collapsed ? " collapsed-card" : "");
      case "danger":
        return "card card-danger" + (this.state.collapsed ? " collapsed-card" : "");
      case "warning":
        return "card card-warning" + (this.state.collapsed ? " collapsed-card" : "");
      case "info":
        return "card card-info" + (this.state.collapsed ? " collapsed-card" : "");
      case "navy":
        return "card card-navy" + (this.state.collapsed ? " collapsed-card" : "");
      case "gray-dark":
        return "card card-gray-dark" + (this.state.collapsed ? " collapsed-card" : "");
      case "gray":
        return "card card-gray" + (this.state.collapsed ? " collapsed-card" : "");
      case "olive":
        return "card card-olive" + (this.state.collapsed ? " collapsed-card" : "");
      case "light":
        return "card card-light" + (this.state.collapsed ? " collapsed-card" : "");
      case "black":
        return "card card-black" + (this.state.collapsed ? " collapsed-card" : "");
      case "orange":
        return "card card-orange" + (this.state.collapsed ? " collapsed-card" : "");
      default:
        return "card" + (this.state.collapsed ? " collapsed-card" : "");
    }
  };
  toolsAndCollapsible = () => {
    return (
      <div className="input-group mt-1">
        <form className="form-inline">
          {this.props.tools ? this.props.tools : null}
          {this.props.collapsible ? (
            <button
              type="button"
              data-card-widget={"collapse"}
              className={`btn${this.props.type ? "-" + this.props.type : ""} btn`}
              onClick={this.toggleCollapse.bind(this)}
            >
              <i className={this.state.collapsed ? "fas fa-chevron-down" : "fas fa-chevron-up"}></i>
            </button>
          ) : null}
        </form>
      </div>
    );
  };
  toggleCollapse() {
    this.setState((state) => {
      return { collapsed: !state.collapsed };
    });
  }

  render() {
    const negativeMargin = this.props.slim ? " py-2 mb-n1" : "";
    //console.log(negativeMargin);
    return (
      <div className={this.handleCardType()}>
        <div className={`card-header ` + (this.props.tabbedHeader ? ` card-tabs p-0 pl-1 pt-1` : ``) + negativeMargin}>
          {this.props.tabbedHeader ? (
            this.props.title ? (
              this.props.title
            ) : null
          ) : (
            <h3 className="card-title">{this.props.title ? this.props.title : null}</h3>
          )}
          <div className="card-tools m-n1">{this.toolsAndCollapsible()}</div>
          {this.props.header ? this.props.header : null}
        </div>
        <div className={`card-body` + negativeMargin}>{this.props.children}</div>
        {this.props.footer ? <div className="card-footer">{this.props.footer}</div> : null}
        {this.props.isFetching ? (
          <div className="overlay">
            <i className="fas fa-2x fa-sync-alt fa-spin"></i>
            {this.props.isFetching}
          </div>
        ) : null}
      </div>
    );
  }
}

export default Card;
