import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Translator, store, parseForTable } from "../../tools/Tools";
import { fetchCustomer, patchCustomerExtraInfos, fetchCustomerEvents, fetchUsers } from "../../tools/Api_Customers";
import RichText from "../../components/RichText";
import Card from "../../components/Card";
import moment from "moment";
import BSTable from "../../components/BSTable";
import { editCustomerChecklistInstance, fetchChecklistModules, fetchChecklistTypes, fetchCustomerChecklistInstance, fetchCustomerChecklistInstances, fetchCustomerChecklistModels, fetchMachineTypes, patchCustomerChecklistModel, postChecklistModule, postCustomerChecklistInstance, postCustomerChecklistModel, deleteCustomerChecklistModel, deleteChecklistInstance } from "../../tools/Api_Checklists";
import ChecklistModelCreationForm from "./ChecklistModelCreationForm";
import ChecklistInstanceForm from "./ChecklistInstanceForm";
import { v4 as uuidv4 } from 'uuid';


class CustomerChecklists extends Component {
  //#region lifecycle methods
  constructor(props) {
    super(props);
    this.state = {
      id: props.generalInfos.id,
      models: { columns: [], data: [] },
      machineTypes: { columns: [], data: [] },
      checklistTypes: { columns: [], data: [] },
      checklistModules: { columns: [], data: [] },
      customerChecklistInstances: { columns: [], data: [] },
      filteredCustomerChecklistInstances: { columns: [], data: [] },
      newInstanceFriendlyName: null,
      modelSelected: null,
      users: { data: [] },
      checklistInstance: null,
      checklistInstanceArrayKey: uuidv4(),
      modelsTableKey: uuidv4(),
      hideCompleted: true,
      hideWithLogbook: true,
      pendingTodos: []
    };
  }

  componentDidMount() {
    this.reload();
  }
  //#endregion

  //#region fetching and parsing datas from API into component state


  reload() {
    console.log("reloading")
    this.fetchTodos()
    this.fetchUsers()
    this.fetchCustomerChecklistModels()
    this.fetchChecklistTypes()
    this.fetchMachineTypes()
    this.fetchChecklistModules()
    this.fetchCustomerChecklistInstances()
  }

  fetchUsers() {
    fetchUsers().then(users => {
      this.setState({ users: users })
    })
  }

  fetchTodos() {
    this.props.fetchTodos(this.props.generalInfos.id).then(todos => {
      console.log(todos)
      this.setState({ pendingTodos: todos.filter(todo => todo.status < 100) })
    })
  }

  fetchCustomerChecklistModels() {
    fetchCustomerChecklistModels(this.state.id).then(models => {
      console.log(models)
      const columns = []
      if (models !== undefined && models.length > 0) {
        for (let i = 0; i < 1; i++) {
          Object.keys(models[0]).forEach(col => {
            columns.push({ dataField: col, hidden: (col === "ChecklistModules" || col === "ChecklistTypeId") })
          })
        }
      }
      const parsed = parseForTable({ columns: columns, data: models })
      this.setState({ models: parsed, modelsTableKey: uuidv4(), modelSelected: null })
    })
  }

  fetchMachineTypes() {
    fetchMachineTypes().then(machineTypes => {
      const columns = []
      if (machineTypes !== undefined && machineTypes.length > 0) {
        for (let i = 0; i < 1; i++) {
          Object.keys(machineTypes[0]).forEach(col => {
            columns.push({ dataField: col })
          })
        }
      }
      const parsed = parseForTable({ columns: columns, data: machineTypes })
      this.setState({ machineTypes: parsed })
    })
  }

  fetchChecklistTypes() {
    fetchChecklistTypes().then(checklistTypes => {
      const columns = []
      if (checklistTypes !== undefined && checklistTypes.length > 0) {
        for (let i = 0; i < 1; i++) {
          Object.keys(checklistTypes[0]).forEach(col => {
            columns.push({ dataField: col })
          })
        }
      }
      const parsed = parseForTable({ columns: columns, data: checklistTypes })
      this.setState({ checklistTypes: parsed })
    })
  }

  fetchCustomerChecklistInstance(checklistInstanceId) {
    fetchCustomerChecklistInstance(checklistInstanceId).then(instance => {
      this.setState({ checklistInstance: instance })
    })
  }

  fetchCustomerChecklistInstances() {
    fetchCustomerChecklistInstances(this.state.id).then(instances => {
      const newInstances = instances.map(foundInstance => {
        let instance = {}
        instance.id = foundInstance.id
        instance.work = <button className="btn btn-default"
          data-toggle="modal"
          data-target="#modal-checklistInstance"
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            this.fetchCustomerChecklistInstance(instance.id)
          }}><i className="fa-solid fa-pencil"></i><br></br>{Translator("display")}</button>
        //set the date for this instance, if updatedAt equals createdAt, it means the checklist instance wasnt'used yet, hence we se the update date to blank
        if (foundInstance.updatedAt !== foundInstance.createdAt) {
          instance.updatedAt = foundInstance.updatedAt
        } else {
          instance.updatedAt = ""
        }

        //add friendly name of this instance
        instance.name = foundInstance.name

        //add model name of this instance
        const foundModel = this.state.models.data.find(model => {
          return model.id === foundInstance.CustomerChecklistModelId
        })
        if (foundModel) {
          instance.model = foundModel.name
        }
        else {
          instance.model = Translator("none")
        }

        instance.createdAt = foundInstance.createdAt
        //add creator name of this instance
        instance.createdBy = this.state.users.data.find(user => {
          return user.id === foundInstance.CreatorId
        })
        if (instance.createdBy) {
          instance.createdBy = instance.createdBy.shortName
        }
        else {
          instance.createdBy = Translator("nobody")
        }

        //add updator name of this instance
        instance.savedBy = this.state.users.data.find(user => {
          return user.id === foundInstance.UpdatorId
        })
        if (instance.savedBy) {
          instance.savedBy = instance.savedBy.firstName + " "
            + instance.savedBy.lastName
        } else {
          instance.savedBy = Translator("none")
        }

        instance.completed = foundInstance.completed

        //add eventual logbook
        instance.logbook = foundInstance.LogbookId


        return instance
      })

      const columns = []
      if (newInstances !== undefined && newInstances.length > 0) {
        for (let i = 0; i < 1; i++) {
          Object.keys(newInstances[0]).forEach(col => {
            if (col === "work") {
              columns.push({
                dataField: col, formatter:
                  (cellContent, row) => {
                    return cellContent
                  },
              })
            }
            else if (col === "logbook") {
              columns.push({ dataField: col, hidden: true })

            } else {

              columns.push({ dataField: col })
            }
          })
        }
      }
      const parsed = parseForTable({ columns: columns, data: newInstances })
      this.setState({ filteredCustomerChecklistInstances: { data: parsed.data.filter(instance => (!this.state.hideCompleted || !instance.completed) && (!this.state.hideCompleted || !instance.completed)), columns: parsed.columns }, customerChecklistInstances: parsed, checklistInstanceArrayKey: uuidv4() })

    })
  }

  fetchChecklistModules() {
    fetchChecklistModules().then(checklistModules => {
      const columns = []
      const typesFormatter = (cell, row) => {
        let returnString = ""
        row.ChecklistTypes.forEach(type => {
          if (!returnString) {
            returnString = type.name
          }
          else {
            returnString += type.name + ", "
          }
        })
        return returnString
      }
      if (checklistModules !== undefined && checklistModules.length > 0) {
        for (let i = 0; i < 1; i++) {
          Object.keys(checklistModules[0]).forEach(col => {
            if (col === "MachineType") {
              columns.push({ dataField: "MachineType.name", text: "machineType" })
            } else if (col === "ChecklistTypes") {
              columns.push({ dataField: "ChecklistTypes", formatter: typesFormatter })
            } else {
              columns.push({ dataField: col, hidden: col === "ChecklistTasks" || col === "MachineTypeId" })
            }
          })
        }
      }
      const parsed = parseForTable({
        columns: columns, data: checklistModules
      })
      this.setState({ checklistModules: parsed })
    })
  }


  //#endregion

  //#region submit datas to API
  submitModel(modelToSubmit, editingMode) {
    console.log(this.state.id)
    if (editingMode) {
      return patchCustomerChecklistModel(modelToSubmit).then(result => {
        if (result) {
          $('#modal-model').modal('hide')
          this.reload();
        }
        return result;
      })
    } else {
      return postCustomerChecklistModel(modelToSubmit, this.state.id).then(result => {
        if (result) {
          $('#modal-model').modal('hide')
          this.reload();
        }
        return result;
      })
    }
  }

  async createInstance() {
    const result = await postCustomerChecklistInstance({ creatorId: store().getUser().userId, name: this.state.newInstanceFriendlyName }, this.state.modelSelected.id);
    if (result) {
      this.reload();
    }
    return result;
  }

  async submitChecklistInstance(checklistInstanceToSubmit) {
    const result = await editCustomerChecklistInstance(store().getUser().userId, checklistInstanceToSubmit);
    if (result) {
      this.reload();
    }
    return result;
  }
  //#endregion

  //#region for API delete methods
  handleDeleteCustomerChecklistModel(id) {
    deleteCustomerChecklistModel(id).then((result) => {
      this.reload();
    })
  }

  handleDeleteChecklistInstance(id) {
    return deleteChecklistInstance(id).then(result => {
      this.reload();
      return result;
    })
  }

  //#endregion
  //#region create logbook
  goToLogbook(customerChecklist) {
    this.props.history.push({
      pathname: `/customers/` + this.props.generalInfos.name,
      state: {
        from: this.props.history.location.pathname,
        genericInfos: this.props.generalInfos,
        checklistId: customerChecklist.id,
        addingLogbook: true,
        //Parsing payload to create predefined logbook text
        logbookContent: (() => {
          let result = "";
          result += (
            "<div><h5><b><u>" +
            Translator("Checklist") + " " + customerChecklist.name +
            " :</u></b></h5>")

          customerChecklist.ChecklistModuleInstances.forEach(module => {
            //if at least 1 task in the module is marked for logbook then we display this module.
            if (module.CustomerChecklistTaskInstances.some(task => task.markedForLogbook)) {
              result += ("<div><h6><b><u> Module : " + module.name + "</u></b></h6><ul>")
              module.CustomerChecklistTaskInstances.forEach(task => {
                //display only task marked for logbook
                if (task.markedForLogbook) {
                  result += ("<li><b>" + Translator(task.taskText) + "</b>  " + (task.UserId ? "(" + this.state.users.data.find(user => {
                    return user.id === task.UserId
                  }).shortName + ")" : "") + "<ul><li>" + Translator('answer') + ": <i>" + Translator(task.answer) + "</i></li>" + (task.comment ? "<li>" + Translator('comment') + ": <i>" + Translator(task.comment) + "</i></li>" : " ") + "</ul></li>")
                }
              })
              result += "</ul></div>"
            }
          })
          result += "<hr></hr></div>";


          //result += "</ul>";
          return result;
        })(),
        logbookUsers: customerChecklist.Attendees.map((attendee) => {
          return attendee;
        }),
        logbook: true,
      },
    });
  }



  //#endregion

  //#region cancel creating modes callbacks

  cancelModelCreation() {
    this.setState({ modelCreatingMode: false, modelEditingMode: false })
  }
  cancelChecklistInstance() {
    this.setState({ checklistInstance: null })
  }
  //#endregion

  //#region render modal methods
  renderNewChecklistInstanceModal() {
    return (
      <div
        className="modal fade"
        id="modal-new-checklistInstance"
        data-backdrop="static"
        tabIndex="-1"
        role="dialog"
        data-keyboard="false"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                {Translator("new " + (this.state.modelSelected ? this.state.modelSelected.name : "") + " instance")}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({ newInstanceFriendlyName: null })
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="info-box slideInDown animated">
                <span className="info-box-icon bg-info">
                  <span className="fa-stack">
                    <i className="fa-solid fa-rectangle-list"></i>
                  </span>
                </span>
                <div className="table-responsive">
                  <div className="info-box-content">
                    <label>{Translator("optionnaly add a friendly name")}</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={Translator("friendly name")}
                      onChange={(e) => {
                        if (e.target.value.trim().length !== 0) {
                          this.setState({ newInstanceFriendlyName: e.target.value })
                        }
                      }}
                      value={this.state.newInstanceFriendlyName || ""}
                    ></input></div>
                </div >
                <div className="ribbon-wrapper">
                  <div className="ribbon bg-success">{Translator("new instance")}</div>
                </div>
              </div >
            </div >
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-default"
                data-dismiss="modal"
                aria-label="Close"
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({ newInstanceFriendlyName: null })
                }}
              >{Translator("cancel")}</button>
              <button
                className="btn btn-default"
                data-dismiss="modal"
                onClick={() => {
                  this.createInstance()
                  this.setState({ newInstanceFriendlyName: null })
                }}
              >{Translator("create instance")}</button>

            </div>
          </div >
        </div>
      </div>
    );
  }

  renderNewModelModal() {
    return (
      <div
        className="modal fade"
        id="modal-model"
        data-backdrop="static"
        tabIndex="-1"
        role="dialog"
        data-keyboard="false"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl" role="document">
          <ChecklistModelCreationForm
            machineTypes={this.state.machineTypes.data}
            checklistTypes={this.state.checklistTypes.data}
            payload={this.state.modelEditingMode ? {
              ...this.state.modelSelected, Modules: this.state.modelSelected.ChecklistModules.map(module => {
                module.sortingOrder = module.ChecklistModelModule.sortingOrder; return module
              })
            } : null}
            creatingMode={this.state.modelCreatingMode}
            editingMode={this.state.modelEditingMode}
            modules={this.state.checklistModules.data}
            cancelModelCreation={this.cancelModelCreation.bind(this)}
            submitCallback={this.submitModel.bind(this)}
          ></ChecklistModelCreationForm>
        </div>
      </div>
    );
  }

  renderChecklistInstanceModal() {
    return (
      <div
        className="modal fade"
        id="modal-checklistInstance"
        data-backdrop="static"
        tabIndex="-1"
        role="dialog"
        data-keyboard="false"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl" role="document">
          <React.StrictMode>
            <ChecklistInstanceForm
              checklistInstance={this.state.checklistInstance}
              customer={this.props.generalInfos}
              users={this.state.users.data.map(user => { return { id: user.id, shortName: user.shortName } })}
              pendingTodos={this.state.pendingTodos}
              cancelChecklistInstance={this.cancelChecklistInstance.bind(this)}
              submitCallback={this.submitChecklistInstance.bind(this)}
              deleteCallback={this.handleDeleteChecklistInstance.bind(this)}
              goToLogbook={this.goToLogbook.bind(this)}
            ></ChecklistInstanceForm>
          </React.StrictMode>
        </div>
      </div>
    );
  }
  //#endregion

  //#region handlers for BSTables buttons

  /*
  * Handler methods for Modules table
  */

  handleCreateModelButton(e) {
    e.preventDefault();
    console.log("creating module")
    $('#modal-model').modal('show');
    this.setState({ modelCreatingMode: true, modelEditingMode: false })
  }

  deleteModelCallback() {
    this.handleDeleteCustomerChecklistModel(this.state.modelSelected.id)
    //Visually remove selected task from table
    const models = {
      data: this.state.models.data.filter(model => model.id !== this.state.modelSelected.id), columns: this.state.models.columns
    }

    this.setState({ models: models })
  }

  handleEditModel(e) {
    e.preventDefault();
    if (this.state.modelSelected) {
      $('#modal-model').modal('show');
      this.setState({ modelCreatingMode: false, modelEditingMode: true })
    }
  }
  modelRowEvents = {
    onClick: (e, row, rowIndex) => {
      if (this.state.modelSelected) {
        if (row.id === this.state.modelSelected.id) {
          this.setState({ modelSelected: null })
        } else {
          this.setState({ modelSelected: row })
        }
      } else {
        this.setState({ modelSelected: row })
      }
    },
  };

  //#endregion

  render() {
    moment.locale(store().getLanguage());
    return (
      <section className="content">
        {this.renderNewModelModal()}
        {this.renderNewChecklistInstanceModal()}
        {this.renderChecklistInstanceModal()}
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <BSTable
                key={this.state.modelsTableKey}
                title={Translator("models")}
                hideHeaders
                hideSearch
                columns={this.state.models.columns.filter(col => col.dataField !== "createdAt" && col.dataField !== "updatedAt" && col.dataField !== "CustomerId")}
                data={this.state.models.data}
                clickToSelect
                rowEvents={this.modelRowEvents}
                creatingMode={this.state.modelCreatingMode}
                cud={{ c: this.handleCreateModelButton.bind(this), u: this.state.modelSelected, d: true }}
                isFetching={this.state.isFetching}
                deleteCallback={this.deleteModelCallback.bind(this)}
                handleEdit={this.handleEditModel.bind(this)} //should be a function to edit
                theme={"primary"}
                paginate
              >
              </BSTable>
            </div>
            <div className="col-6">
              <Card title={Translator("Currently selected: ") + (this.state.modelSelected ? this.state.modelSelected.name : Translator("none"))}>
                <div className="row justify-content-center">
                  <button
                    type="button"
                    className="btn btn-default"
                    data-toggle="modal"
                    data-target="#modal-new-checklistInstance"
                    onClick={e => {
                      e.preventDefault();
                    }}
                    disabled={!this.state.modelSelected}><i className="nav-icon fa-solid fa-clipboard-list mr-2"></i>
                    {Translator("new instance")}</button>
                </div>
                <hr></hr>
                <div className="row">
                  <div className="col">
                    {this.state.modelSelected ? (<div>
                      <h5>{this.state.modelSelected.name}</h5>
                      <ul>
                        <li>
                          {Translator("Serial number")}:{this.state.modelSelected.serial}
                        </li>
                        <li>
                          {Translator("Checklist type")}:{this.state.checklistTypes.data.find(ct => ct.id === this.state.modelSelected.ChecklistTypeId).name}
                        </li>
                      </ul>
                      <h5>{Translator("Modules")}</h5>
                      <ul>
                        {this.state.modelSelected.ChecklistModules.sort((a, b) => {
                          if (a.sortingOrder > b.sortingOrder)
                            return 1
                          if (a.sortingOrder < b.sortingOrder)
                            return -1
                          return 0
                        }).map(a => {
                          return (<li key={a.id}>{a.name}</li>)
                        })}
                      </ul>
                    </div>) : null}
                  </div>
                </div>
              </Card>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <BSTable
                key={this.state.checklistInstanceArrayKey}
                title={Translator("registered checklists")}
                header={<div className="fa-pull-right">
                  <input className="mr-1" type="checkbox" id="hideCompletedCheckbox"
                    defaultChecked={this.state.hideCompleted}
                    onChange={(e => {
                      if (e.target.checked) {
                        this.setState({ filteredCustomerChecklistInstances: { data: this.state.customerChecklistInstances.data.filter(instance => !instance.completed && (!this.state.hideWithLogbook || !instance.logbook)), columns: this.state.customerChecklistInstances.columns }, hideCompleted: true })
                      } else {
                        this.setState({ filteredCustomerChecklistInstances: { data: this.state.customerChecklistInstances.data.filter(instance => !this.state.hideWithLogbook || !instance.logbook), columns: this.state.customerChecklistInstances.columns }, checklistInstanceArrayKey: uuidv4(), hideCompleted: false })
                      }
                    })}></input><label htmlFor="hideCompletedCheckbox">{Translator("hide completed")}</label>
                  &nbsp;
                  <input className="mr-1" type="checkbox" id="hideWithLogbookCheckbox"
                    defaultChecked={this.state.hideWithLogbook}
                    onChange={(e => {
                      if (e.target.checked) {
                        this.setState({ filteredCustomerChecklistInstances: { data: this.state.customerChecklistInstances.data.filter(instance => !instance.logbook && (!this.state.hideCompleted || !instance.completed)), columns: this.state.customerChecklistInstances.columns }, hideWithLogbook: true })
                      } else {
                        this.setState({ filteredCustomerChecklistInstances: { data: this.state.customerChecklistInstances.data.filter(instance => !this.state.hideCompleted || !instance.completed), columns: this.state.customerChecklistInstances.columns }, checklistInstanceArrayKey: uuidv4(), hideWithLogbook: false })
                      }
                    })}></input><label htmlFor="hideWithLogbookCheckbox">{Translator("hide with logbook")}</label>
                </div>}
                hideHeaders
                columns={this.state.customerChecklistInstances.columns}
                data={this.state.filteredCustomerChecklistInstances.data}
                cud={{ c: false, u: false, d: false }}
                clickToSelect
                paginate
                rowStyle={(row) => {
                  const style = {}
                  if (row.completed) {
                    style.backgroundColor = "#cccccc"
                  }
                  return style
                }}
                defaultSorted={[{ dataField: "createdAt", order: "desc" }, { dataField: "updatedAt", order: "desc" }]}
                theme={"info"}
              >
              </BSTable>
            </div>
          </div>
        </div>
      </section>

    );

    /* return <RichText title={Translator("extra informations")} defaultValue={this.state.extraInfos}
         developped></RichText> */
  }
}

export default withRouter(CustomerChecklists);
