import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { Translator, parseForTable, store } from "../../tools/Tools";
import { fetchSuppliers, postNewCustomer, deleteCustomers } from "../../tools/Api_Customers";
import BSTable from "../../components/BSTable";
import { v4 as uuidv4 } from 'uuid';

class Suppliers extends Component {
  constructor(props) {
    super(props);
    this.path = props.match.path;
    this.push = props.history.push;
    this.state = {
      ...this.props.location.state,
      columns: [],
      data: [],
      deletingMode: false,
    };
  }

  componentDidMount() {
    this.setState({ isFetching: true });
    fetchSuppliers().then((fetched) => {
      const variable = fetched
        ? this.setState(() => {
          return { ...parseForTable(fetched), isFetching: false };
        })
        : null;
    });
  }

  handleCreateButton(e) {
    e.preventDefault();
    this.setState((state) => {
      return {
        creatingMode: !state.creatingMode,
        newSupplierTemp: null,
      };
    });
  }
  deleteCallback(ids) {
    deleteCustomers(ids).then(() => {
      this.setState({ isFetching: true });
      fetchSuppliers().then((fetched) => {
        const brol = fetched
          ? this.setState(() => {
            return { ...parseForTable(fetched), isFetching: false, creatingMode: false };
          })
          : null;
      });
    });
  }
  handlepostNewCustomer(newSupplier) {
    this.setState({ isFetching: true });
    postNewCustomer(newSupplier).then(
      (content) => {
        fetchSuppliers().then((fetched) => {
          this.setState(() => {
            return { ...parseForTable(fetched), isFetching: false, creatingMode: false };
          });
        });
      },
      (error) => this.setState({ isFetching: false })
    );
  }
  customCreateForm() {
    const displayCallout = () => {
      const newSupplier = this.state.newSupplierTemp;
      let callOutText = "";
      if (newSupplier) {
        if (!newSupplier.name) {
          callOutText += " - " + Translator("name");
        }
        if (!newSupplier.rue) {
          callOutText += " - " + Translator("rue");
        }
        if (!newSupplier.number) {
          callOutText += " - " + Translator("number");
        }
        if (!newSupplier.cp) {
          callOutText += " - " + Translator("cp");
        }
        if (!newSupplier.ville) {
          callOutText += " - " + Translator("ville");
        }
        if (callOutText.length === 0) {
          callOutText = "All Good !";
        } else {
          callOutText += "- required";
        }
      } else {
        callOutText =
          Translator("name") +
          " - " +
          Translator("rue") +
          " - " +
          Translator("number") +
          " - " +
          Translator("cp") +
          " - " +
          Translator("ville") +
          " " +
          " required";
      }

      const callOut =
        callOutText === "All Good !" ? (
          <div
            id="validationCallout"
            className="callout callout-success mb-2 mr-sm-2 tada animated"
            style={{
              height: "calc(2.25rem + 2px)",
              padding: "0.375rem 0.75rem",
            }}
          >
            {callOutText}
          </div>
        ) : (
          <div
            id="validationCallout"
            className="callout callout-danger mb-2 mr-sm-2 shake animated"
            style={{
              height: "calc(2.25rem + 2px)",
              padding: "0.375rem 0.75rem",
            }}
          >
            {callOutText}
          </div>
        );

      return callOut;
    };
    return (
      <div className={this.creatingMode ? "info-box slideOutUp animated" : "info-box slideInDown animated"}>
        <span className="info-box-icon bg-info">
          <i className="fas fa-user-plus"></i>
        </span>
        <div className="info-box-content">
          <form className="form-inline col-sm-12">
            <div className="input-group mb-2 mr-sm-2">
              <input
                type="text"
                className="form-control"
                placeholder={Translator("name")}
                onChange={(e) => {
                  this.setState({ newSupplierTemp: { ...this.state.newSupplierTemp, name: e.target.value } });
                }}
              ></input>
            </div>
          </form>
          <form className="form-inline col-sm-12">
            <div className="input-group mb-2 mr-sm-2">
              <input
                type="text"
                className="form-control"
                placeholder={Translator("rue")}
                onChange={(e) => {
                  this.setState({ newSupplierTemp: { ...this.state.newSupplierTemp, rue: e.target.value } });
                }}
              ></input>
            </div>
            <div className="input-group mb-2 mr-sm-2">
              <input
                type="text"
                className="form-control"
                placeholder={Translator("n°")}
                onChange={(e) => {
                  const newValue = e.target.value;
                  e.target.value = newValue ? newValue.toString().replace(/,/gi, "") : "";
                  this.setState({ newSupplierTemp: { ...this.state.newSupplierTemp, number: e.target.value } });
                }}
              ></input>
            </div>
            <div className="input-group mb-2 mr-sm-2">
              <input
                type="text"
                className="form-control"
                placeholder={Translator("code postal")}
                onChange={(e) => {
                  const newValue = e.target.value;
                  e.target.value = newValue ? newValue.toString().replace(/,/gi, "") : "";
                  this.setState({ newSupplierTemp: { ...this.state.newSupplierTemp, cp: e.target.value } });
                }}
              ></input>
            </div>
            <div className="input-group mb-2 mr-sm-2">
              <input
                type="text"
                className="form-control"
                placeholder={Translator("ville")}
                onChange={(e) => {
                  this.setState({ newSupplierTemp: { ...this.state.newSupplierTemp, ville: e.target.value } });
                }}
              ></input>
            </div>
            <div className="input-group mb-2 mr-sm-2">
              <select
                className="custom-select"
                defaultValue="belgique"
                onChange={(e) => {
                  this.setState({ newSupplierTemp: { ...this.state.newSupplierTemp, country: e.target.value } });
                }}
              >
                <option value="belgique">{Translator("belgique")}</option>
                <option value="france">{Translator("france")}</option>
                <option value="luxembourg">{Translator("luxembourg")}</option>
                <option value="suisse">{Translator("suisse")}</option>
                <option value="italie">{Translator("italie")}</option>
                <option value="allemagne">{Translator("allemagne")}</option>
                <option value="nederland">{Translator("nederland")}</option>
                <option value="grande-bretagne">{Translator("grande-bretagne")}</option>
                <option value="espagne">{Translator("espagne")}</option>
              </select>
            </div>
          </form>
          <form className="form-inline col-sm-12">
            <div className="input-group mb-2 mr-sm-2">
              <input
                style={{ width: "10%" }}
                type="text"
                className="form-control"
                placeholder={Translator("phone")}
                onChange={(e) => {
                  const newValue = e.target.value.match(/[0-9+ ]/gi);
                  e.target.value = newValue ? newValue.toString().replace(/,/gi, "") : "";
                  this.setState({ newSupplierTemp: { ...this.state.newSupplierTemp, phone: e.target.value } });
                }}
              ></input>
            </div>
          </form>
          <form className="form-inline col-sm-12">
            <button
              className="btn btn-info btn mb-2 mr-sm-2"
              onClick={(e) => {
                e.preventDefault();
                const newSupplier = this.state.newSupplierTemp;
                const rue = newSupplier.rue ? newSupplier.rue : "";
                const number = newSupplier.number ? newSupplier.number : "";
                const cp = newSupplier.cp ? newSupplier.cp : "";
                const ville = newSupplier.ville ? newSupplier.ville : "";
                const address = rue + " " + number + ", " + cp + " " + ville;
                const newSupplierToSend = {
                  id: uuidv4(),
                  name: newSupplier.name,
                  address: address,
                  postal: cp,
                  country: newSupplier.country ? newSupplier.country : "belgique",
                  phone: newSupplier.phone ? newSupplier.phone : "",
                  type: newSupplier.type ? newSupplier.type : "supplier",
                  contract: newSupplier.contract ? newSupplier.contract : "none",
                  activeClient: false,
                };
                this.handlepostNewCustomer(newSupplierToSend);
              }}
              disabled={
                this.state.newSupplierTemp
                  ? this.state.newSupplierTemp.name &&
                    this.state.newSupplierTemp.number &&
                    this.state.newSupplierTemp.rue &&
                    this.state.newSupplierTemp.cp &&
                    this.state.newSupplierTemp.ville
                    ? false
                    : true
                  : true
              }
            >
              {Translator("enregistrer")}
            </button>
            {displayCallout()}
          </form>
        </div>
        <div className="ribbon-wrapper ribbon-xl">
          <div className="ribbon bg-success">{Translator("new Supplier")}</div>
        </div>
      </div>
    );
  }

  render() {
    //OnClick informations
    const path = this.path;
    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        this.push({
          pathname: `${path}/${row.name}`,
          state: {
            genericInfos: row,
          },
        });
      },
    };
    //Rendering
    return (
      <div>
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0 text-dark">
                  <i className="nav-icon fas fas fa-address-card"></i>
                  &nbsp; {Translator("Suppliers")}
                </h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to={{ pathname: "../", state: this.props.location.state }}>{Translator("Home")}</Link>
                  </li>
                  <li className="breadcrumb-item active">{Translator("Suppliers")}</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="container-fluid">
            <BSTable
              columns={this.state.columns}
              data={this.state.data}
              rowEvents={rowEvents}
              title={Translator("Suppliers's list")}
              creatingMode={this.state.creatingMode}
              cud={{ c: this.handleCreateButton.bind(this), u: false, d: true }}
              customCreateForm={this.customCreateForm.bind(this)}
              theme="default"
              isFetching={this.state.isFetching}
              deleteCallback={this.deleteCallback.bind(this)}
              updateFromProps
            />
          </div>
        </section>
      </div>
    );
  }
}

export default withRouter(Suppliers);
