import React from "react";
import { withRouter, Link } from "react-router-dom";
import {
  store,
  Translator,
  MomentDateString,
  MomentDateTimeString,
  parseForTable,
  computeWorkTime,
  ShouldReloadTicket,
  NotifyReloadTicket,
  parseTime,
} from "../../tools/Tools";
import {
  editTicketPeriod,
  fetchTicket,
  fetchUsers,
  editTicketDescription,
  editTicketContact,
  closeTicket,
  handleTicket,
  deleteTicket,
} from "../../tools/Api_Customers";
import Card from "../../components/Card";
import RichText from "../../components/RichText";
import moment from "moment";

class TicketDetail extends React.Component {
  constructor(props) {
    super(props);
    const path = props.history.location.pathname.split("/");
    this.shouldUpdate = true;
    this.state = {
      ticketNumber: path[path.length - 1],
      ticket: { number: "", Customer: { name: "" }, TicketPeriods: [] },
      isFetching: false,
      sortingDirection: "ascending",
      editTable: {},
      users: [],
      totalWorkTime: 0,
    };
    setInterval(() => {
      if (this.shouldUpdate) {
        if (ShouldReloadTicket("ticketDetail")) {
          console.log("should reload");
          this.reload();
        }
      }
      if (this.state.ticket.running && this.shouldUpdate) {
        const ticketUpdated = this.state.ticket.TicketPeriods.map((tp) => {
          if (!tp.endDateTime) {
            tp.workTime = moment.duration(moment().diff(tp.startDateTime)).asMinutes();
          }
          return tp;
        });
        this.setState({ tickets: ticketUpdated, totalWorkTime: this.state.totalWorkTime + 1 / 60 });
      }
    }, 1000);
  }

  componentWillMount() {
    this.setState({ isFetching: true });
    this.reload();
  }
  componentWillUnmount() {
    this.shouldUpdate = false;
  }
  reload() {
    if (this.shouldUpdate) {
      fetchUsers().then((users) => {
        fetchTicket(this.state.ticketNumber).then((ticket) => {
          let ticketWorkTime = 0;
          let running = false;
          ticket.TicketPeriods.forEach((tp) => {
            ticketWorkTime += tp.workTime;
            //console.log(tp.endDateTime);
            if (!tp.endDateTime) {
              ticketWorkTime += moment.duration(moment().diff(tp.startDateTime)).asMinutes();
              tp.workTime = moment.duration(moment().diff(tp.startDateTime)).asMinutes();
              running = true;
            }
          });
          ticket.running = running;
          this.setState({ ticket: ticket, isFetching: false, users: users.data, totalWorkTime: ticketWorkTime });
        });
      });
    }
  }

  closeTicket(ticketId, userId) {
    NotifyReloadTicket("ticketDetail");
    closeTicket(ticketId, userId).then(() => {
      this.reload();
    });
  }
  handleTicket(ticketId, userId) {
    NotifyReloadTicket("ticketDetail");
    handleTicket(ticketId, userId).then(() => {
      this.reload();
    });
  }
  deleteTicket() {
    deleteTicket(this.state.ticket.id).then(() => {
      this.props.history.goBack();
    });
  }
  DateSort(toSort, direction) {
    const compare = (a, b) => {
      if (direction === "descending") {
        if (moment(a.startDateTime).isBefore(moment(b.startDateTime))) return this.state.dateSort ? -1 : 1;
        if (moment(a.startDateTime).isAfter(moment(b.startDateTime))) return this.state.dateSort ? 1 : -1;
        return 0;
      } else {
        if (moment(a.startDateTime).isBefore(moment(b.startDateTime))) return this.state.dateSort ? 1 : -1;
        if (moment(a.startDateTime).isAfter(moment(b.startDateTime))) return this.state.dateSort ? -1 : 1;
        return 0;
      }
    };

    let sorted = toSort;
    sorted.sort(compare);
    return sorted;
  }
  editTicketDescription(ticketId, description) {
    this.setState({ isFetching: true });
    editTicketDescription(ticketId, description).then(() => {
      fetchTicket(this.state.ticketNumber).then((ticket) => {
        this.setState({ ticket: ticket, isFetching: false });
      });
    });
  }
  editTicketContact(ticketId, contact) {
    this.setState({ isFetching: true });
    editTicketContact(ticketId, contact).then(() => {
      fetchTicket(this.state.ticketNumber).then((ticket) => {
        this.setState({ ticket: ticket, isFetching: false });
      });
    });
  }
  editTicketPeriodDescription(id, description) {
    this.setState({ isFetching: true });
    editTicketPeriod(id, description).then(() => {
      fetchTicket(this.state.ticketNumber).then((ticket) => {
        this.setState({ ticket: ticket, isFetching: false });
      });
    });
  }

  render() {
    const translatePriority = (priority) => {
      switch (priority) {
        case "yellow":
        case "low_priority":
          return "warning";
        case "priority":
        case "orange":
          return "orange";
        case "asap":
        case "red":
          return "danger";
      }
    };
    const renderHtml = (html) => {
      return { __html: html };
    };
    console.log(this.state.ticket)
    const tpSorted = this.DateSort(this.state.ticket.TicketPeriods, this.state.sortingDirection);
    return (
      <div>
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className={this.state.ticket.closed || this.state.ticket.running ? "col-sm-4" : "col-sm-6"}>
                <div className="row">
                  <h1 className="m-0 text-dark">
                    <div className="col">
                      <div className="row">
                        <i className="fas fa-ticket-alt"></i>&nbsp;
                        {Translator("ticket") + " " + this.state.ticketNumber}
                      </div>
                      <div className="row">
                        <i className="fas fa-address-card"></i>&nbsp;
                        <Link
                          to={{
                            pathname: "../customers/" + this.state.ticket.Customer.name,
                            state: {
                              genericInfos: {
                                id: this.state.ticket.Customer.id,
                                name: this.state.ticket.Customer.name,
                              },
                            },
                          }}
                        >
                          {this.state.isFetching ? null : this.state.ticket.Customer.name}
                        </Link>
                      </div>
                    </div>
                  </h1>
                  <button
                    className="btn btn-default row mx-1"
                    onClick={() => {
                      this.props.history.goBack();
                    }}
                  >
                    <i className="fas fa-long-arrow-alt-left fa-2x col"></i>
                    <div className="col">{Translator("go back to ticket list")}</div>
                  </button>
                </div>
              </div>
              {this.state.ticket.closed ? (
                <div className="col-sm-4">
                  <button className="btn btn-block btn-outline-danger disabled">{Translator("closed")}</button>
                </div>
              ) : this.state.ticket.running ? (
                <div className="col-sm-4">
                  <button className="btn btn-block btn-outline-success disabled">{Translator("running")}</button>
                </div>
              ) : null}

              <div className={this.state.ticket.closed || this.state.ticket.running ? "col-sm-4" : "col-sm-6"}>
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to={{ pathname: "../", state: this.props.history.state }}>{Translator("Home")}</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to={{ pathname: "../tickets", state: this.props.history.state }}>
                      {Translator("Tickets")}
                    </Link>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="container-fluid">
            <Card
              title={
                <div className="row">
                  <button className="btn btn-block btn-outline-secondary disabled col">
                    <b>{Translator(this.state.ticket.priority + " code")}</b>
                  </button>
                  <div className="col">
                    {this.state.ticket.Assignee ? (
                      Translator("last update by") +
                      " " +
                      this.state.ticket.Assignee.shortName +
                      " " +
                      Translator("at") +
                      " " +
                      MomentDateTimeString(this.state.ticket.updatedAt)
                    ) : (
                      <div className="row">
                        <div className="col">{Translator("not handled yet")}</div>
                        <div className="col">
                          <button
                            className="btn btn-default"
                            onClick={() => this.handleTicket(this.state.ticket.id, store().getUser().userId)}
                          >
                            {Translator("handle now")}
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              }
              /*" " +
                (this.state.ticket.Assignee
                  ? Translator("last update by") +
                    " " +
                    this.state.ticket.Assignee.shortName +
                    " " +
                    Translator("at") +
                    " " +
                    MomentDateTimeString(this.state.ticket.updatedAt)
                  : null)
              }*/
              tools={
                <h5>
                  {" "}
                  <span>
                    {Translator("total time spent on this ticket:") + " "}
                    <span className="badge badge-secondary">{parseTime(this.state.totalWorkTime)}</span>
                    &nbsp;
                    <button
                      type="button"
                      className="btn btn-default"
                      onClick={() => {
                        this.state.sortingDirection === "ascending"
                          ? this.setState({ sortingDirection: "descending" })
                          : this.setState({ sortingDirection: "ascending" });
                      }}
                    >
                      {this.state.sortingDirection === "ascending" ? (
                        <i className="fas fa-arrow-down"></i>
                      ) : (
                        <i className="fas fa-arrow-up"></i>
                      )}
                    </button>
                  </span>{" "}
                </h5>
              }
              isFetching={this.state.isFetching}
              type={translatePriority(this.state.ticket.priority)}
            >
              {this.state.isFetching ? null : (
                <div>
                  <h4>
                    {(this.state.ticket.Site ? this.state.ticket.Site.name + ": " : " ") +
                      Translator("ticket created at") +
                      " " +
                      MomentDateTimeString(this.state.ticket.createdAt) +
                      " " +
                      Translator("by") +
                      " " +
                      this.state.ticket.Author.firstName +
                      " " +
                      this.state.ticket.Author.lastName}
                  </h4>
                  <hr></hr>
                  <span className="info-box">
                    <span className="info-box-icon bg-olive">
                      <i className="fas fa-id-card-alt ml-2"></i>&nbsp;
                    </span>
                    <div className="info-box-content">
                      <h4>{Translator("contact person for this ticket")}</h4>
                      <span className="d-flex justify-content-between">
                        <div
                          className="info-box-text"
                          dangerouslySetInnerHTML={
                            !this.state.editTicketContact ? renderHtml(this.state.ticket.contact) : null
                          }
                        >
                          {this.state.editTicketContact ? (
                            <RichText
                              defaultValue={this.state.ticket.contact}
                              onChange={(value) => {
                                this.setState({ editTicketContact: value });
                              }}
                              developped
                            ></RichText>
                          ) : null}
                        </div>
                        <div>
                          <button
                            className="btn btn-default"
                            onClick={(e) => {
                              e.preventDefault();
                              if (this.state.editTicketContact)
                                this.editTicketContact(this.state.ticket.id, this.state.editTicketContact);
                              this.setState({ editTicketContact: !this.state.editTicketContact });
                            }}
                          >
                            {this.state.editTicketContact ? (
                              <i className="fas fa-save"></i>
                            ) : (
                              <i className="fas fa-edit"></i>
                            )}
                            {this.state.editTicketContact ? Translator("save") : Translator("edit")}
                          </button>
                        </div>
                      </span>
                    </div>
                  </span>
                  <span className="info-box">
                    <span className="info-box-icon bg-primary">
                      <i className="nav-icon far fa-sticky-note ml-2"></i>&nbsp;
                    </span>
                    <div className="info-box-content">
                      <h4>{Translator("ticket initial description")}</h4>
                      <span className="d-flex justify-content-between">
                        {this.state.editDescription ? (
                          <div>
                            <RichText
                              defaultValue={this.state.ticket.description}
                              onChange={(value) => {
                                this.setState({ editedTicketDescription: value });
                              }}
                              developped
                            ></RichText>{" "}
                          </div>
                        ) : null}
                        <div
                          dangerouslySetInnerHTML={
                            !this.state.editDescription ? renderHtml(this.state.ticket.description) : null
                          }
                        ></div>
                        <div>
                          <button
                            className="btn btn-default"
                            onClick={(e) => {
                              e.preventDefault();
                              if (this.state.editDescription)
                                this.editTicketDescription(this.state.ticket.id, this.state.editedTicketDescription);
                              this.setState({ editDescription: !this.state.editDescription });
                            }}
                          >
                            {this.state.editDescription ? (
                              <i className="fas fa-save"></i>
                            ) : (
                              <i className="fas fa-edit"></i>
                            )}
                            {this.state.editDescription ? Translator("save") : Translator("edit")}
                          </button>
                        </div>
                      </span>
                    </div>
                  </span>
                  <hr></hr>
                  <h4 className="d-flex justify-content-between">
                    <span>
                      <i className="fas fa-wrench"></i>&nbsp;
                      {Translator("steps of ticket resolution")}
                    </span>
                    <div className="btn-group">
                      <button
                        className="btn btn-default"
                        onClick={() => {
                          this.closeTicket(this.state.ticket.id, store().getUser().userId);
                          this.props.history.push({
                            pathname: `/customers/` + this.state.ticket.Customer.name,
                            state: {
                              from: this.props.history.location.pathname,
                              genericInfos: {
                                id: this.state.ticket.Customer.id,
                                name: this.state.ticket.Customer.name,
                              },
                              ticketId: this.state.ticket.id,
                              addingLogbook: true,
                              logbookContent: (() => {
                                let result = "";
                                result +=
                                  "<div><h5><b><u>" +
                                  Translator("Customer issue explanation") +
                                  " :</u></b></h5>" +
                                  this.state.ticket.description +
                                  "<hr></hr><h5><u><b>" +
                                  Translator("Resolution steps") +
                                  " :</b></u></h5></div><ul>";

                                this.state.ticket.TicketPeriods.forEach((tp) => {
                                  if (tp.description)
                                    result +=
                                      "<li><u><b>" +
                                      this.state.users.find((x) => x.id === tp.UserId).shortName + "</b> <small><i>" + moment(tp.createdAt).format('DD/MM HH:mm') + " " + Translator("to") + " " + (tp.endDateTime ? moment(tp.endDateTime).format('DD/MM HH:mm') : moment().format('DD/MM HH:mm')) + "</small></u></i> :" +
                                      tp.description +
                                      "</li>";
                                });
                                result += "</ul>";
                                return result;
                              })(),
                              logbookUsers: this.state.ticket.TicketPeriods.map((tp) => {
                                return tp.UserId;
                              }),
                              logbook: true,
                            },
                          });
                        }}
                      >
                        {this.state.ticket.closed
                          ? Translator("generate logbook")
                          : Translator("close & generate logbook")}
                      </button>
                      <button
                        className="btn btn-default"
                        onClick={(e) => {
                          e.preventDefault();
                          if (this.state.ticket.closed) {
                            this.handleTicket(this.state.ticket.id, store().getUser().userId);
                          } else {
                            this.closeTicket(this.state.ticket.id, store().getUser().userId);
                          }
                        }}
                      >
                        {this.state.ticket.closed ? Translator("reopen ticket") : Translator("close ticket")}
                      </button>
                    </div>
                  </h4>
                  <hr></hr>

                  {tpSorted.map((tp) => {
                    return (
                      <div
                        className={
                          `row ` +
                          (this.state.sortingDirection === "ascending" ? "slideInDown" : "slideInUp") +
                          ` animated`
                        }
                        key={tp.id}
                      >
                        <div className="col-6 col-lg-9">
                          <div className="row">
                            <div className="col">
                              <RichText
                                title={
                                  <div>
                                    <i className="fas fa-user">&nbsp;</i>
                                    {Translator("taken care of by") +
                                      " " +
                                      this.state.users.find((x) => x.id === tp.UserId).shortName +
                                      " " +
                                      Translator("at")}
                                    &nbsp;
                                    <i className="fas fa-clock">&nbsp;</i>
                                    &nbsp;
                                    {MomentDateTimeString(tp.startDateTime) +
                                      (tp.endDateTime
                                        ? " " + Translator("to") + " " + MomentDateTimeString(tp.endDateTime)
                                        : "")}
                                  </div>
                                }
                                developped
                                defaultValue={tp.description}
                                onChange={(value) => {
                                  this.setState({ tps: { ...this.state.tps, [tp.id]: value } });
                                }}
                                readOnly={!this.state.editTable[tp.id]}
                              ></RichText>
                            </div>
                          </div>
                        </div>
                        <div className="col-6 col-lg-3">
                          <div className="timeline">
                            <div className="time-label mb-n1">
                              <span>{MomentDateTimeString(tp.startDateTime, true)}</span>
                            </div>
                            <div className=" my-n1">
                              <img
                                src={
                                  `/assets/dist/img/` +
                                  this.state.users.find((x) => x.id === tp.UserId).shortName +
                                  `160x160.jpg`
                                }
                                className="img-circle ml-3"
                                alt="User"
                                style={{ width: "2rem" }}
                              />

                              <div className="timeline-item mb-n1">
                                <Card
                                  title={parseTime(tp.workTime)}
                                  type={"light"}
                                  slim
                                  tools={this.state.users.find((x) => x.id === tp.UserId).shortName}
                                >
                                  <div className="row" style={{ textAlign: "center" }}>
                                    <div className="col col-lg-5">
                                      <small>{MomentDateTimeString(tp.startDateTime, true)}</small>
                                    </div>
                                    <div className="col col-lg-2">
                                      <small>
                                        <i className="fas fa-arrow-right"></i>
                                      </small>
                                    </div>
                                    <div className="col col-lg-5">
                                      <small>{MomentDateTimeString(tp.endDateTime, true)}</small>
                                    </div>
                                  </div>
                                  <div className="row" style={{ textAlign: "center" }}>
                                    <div className="col">
                                      <button
                                        className="btn btn-default"
                                        disabled={
                                          Object.entries(this.state.editTable).filter((x) => {
                                            return x[1];
                                          }).length > 0 && !this.state.editTable[tp.id]
                                        }
                                        onClick={() => {
                                          if (this.state.editTable[tp.id] && this.state.tps && this.state.tps[tp.id]) {
                                            this.editTicketPeriodDescription(tp.id, this.state.tps[tp.id]);
                                            this.setState({
                                              editTable: {
                                                ...this.state.editTable,
                                                [tp.id]: false,
                                              },
                                              tps: { ...this.state.tps, [tp.id]: undefined },
                                            });
                                          }
                                          this.setState({
                                            editTable: {
                                              ...this.state.editTable,
                                              [tp.id]: !this.state.editTable[tp.id],
                                            },
                                          });
                                        }}
                                      >
                                        {this.state.editTable[tp.id] ? (
                                          <i className="fas fa-save"></i>
                                        ) : (
                                          <i className="fas fa-edit"></i>
                                        )}
                                        &nbsp;
                                        {this.state.editTable[tp.id] ? Translator("save") : Translator("edit")}
                                      </button>
                                    </div>
                                  </div>
                                </Card>
                              </div>
                            </div>
                            <div className="time-label my-n1">
                              <span>
                                {tp.endDateTime ? MomentDateTimeString(tp.endDateTime) : Translator("still running")}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
              <div className="row">
                <div className="col-1">
                  <button
                    className="btn btn-app bg-secondary"
                    onClick={() => {
                      this.setState({ deleteConfirmation: !this.state.deleteConfirmation });
                    }}
                  >
                    <i className="fas fa-trash-alt"></i>
                    {this.state.deleteConfirmation ? Translator("cancel deletion") : Translator("delete ticket")}
                  </button>
                </div>
                {this.state.deleteConfirmation ? (
                  <span className="info-box col-4 slideInRight animated">
                    <span className="info-box-icon bg-danger">
                      <i className="fas fa-exclamation-triangle ml-2"></i>&nbsp;
                    </span>
                    <div className="info-box-content row mx-1">
                      <div className="col">
                        <div className="row">
                          <h4>{Translator("Delete ticket")}</h4>
                        </div>
                        <div className="info-box-text row">
                          {Translator("Are you sure you want to delete this ticket ?")}
                        </div>
                      </div>
                      <div className="col ml-5">
                        <button
                          className="btn btn-app bg-danger"
                          onClick={() => {
                            this.deleteTicket();
                          }}
                        >
                          <i className="fas fa-trash-alt"></i>
                          {Translator("delete")}
                        </button>
                      </div>
                    </div>
                  </span>
                ) : null}
              </div>
            </Card>
          </div>
        </section >
      </div >
    );
  }
}

export default withRouter(TicketDetail);
