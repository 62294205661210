import React, { useState, useEffect } from "react";
import "./globals";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Content from "./Content";
import "./App.css";
import { withRouter, useHistory } from "react-router-dom";
import { store, getTranslations } from "./tools/Tools";
import Cookies from "universal-cookie";
import "moment/locale/fr";
import "moment/locale/nl";
import isLoading from "./components/Loading";

const App = () => {
  const history = useHistory();
  const [translationFetched, setTranslationFetched] = useState(false);

  store().state.Cookies = new Cookies();
  const language = store().state.Cookies.get("language") ? store().state.Cookies.get("language") : "fr";
  store().setLanguage(language);
  if (store().getCookies().get("isLogged") === "true" || store().state.isLogged === "true") {
    console.log("user was logged");
    store().state.User = store().getCookies().get("loggedUser");
    store().state.isLogged = true;
  }
  if (!translationFetched) {
    getTranslations().then(() => {
      console.log("Translation loaded");
      setTranslationFetched(true);
    });
  }
  //console.log(store().getUser());
  const toggleLogged = () => {
    store().state.isLogged = false;
    store().state.User = null;
    store().getCookies().set("isLogged", "false", { path: "/", sameSite: "lax" });
    store().getCookies().set("loggedUser", {}, { path: "/", sameSite: "lax" });
    console.log("store logged", store().state.isLogged);
    console.log("cookies logged", store().getCookies().get("isLogged"));
    //history.location.state = { isLogged: false, }
    history.push({ pathname: "/" });
  };

  useEffect(() => {
    return history.listen((location) => {
      console.log(location);
      console.log(`You changed the page to: ${location.pathname}`);
    });
  }, [history]);

  return (
    <div className="wrapper">
      {translationFetched ? (
        <div>
          <Header logFunction={toggleLogged} />
          <Sidebar />
          <Content />
          <footer className="main-footer">
            <div className="float-right d-none d-sm-block">
              <b>Version</b> 1.3.0
            </div>
            <strong>
              Copyright &copy; 2023 <a href="http://www.eurobrevets.be">Eurobrevets</a>.
            </strong>{" "}
            All rights reserved.
          </footer>
        </div>
      ) : (
        <div style={{ display: "flex", alignItems: "center", minHeight: "100%", minHeight: "100vh" }}>
          <div className="container" style={{ textAlign: "center" }}>
            {isLoading(true)}
          </div>
        </div>
      )}
    </div>
  );
};

export default withRouter(App);
