import React, { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { Translator, parseForTable } from "../tools/Tools";
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import cellEditFactory from "react-bootstrap-table2-editor";
import Card from "./Card";
import isLoading from "./Loading";

class BSTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: props.columns
        ? props.columns.length > 0
          ? props.columns
          : [{ dataField: "none", text: Translator("no data") }]
        : [{ dataField: "none", text: Translator("no data") }],
      data: props.data ? props.data : [],
      isFetching: props.isFetching,
      cud: props.cud ? props.cud : { c: false, u: false, d: false },
    };
    this.fetchDatas = props.fetchDatas;
    this.rowEvents = props.rowEvents;
    this.theme = props.theme ? props.theme : "default";
    this.icon = props.icon !== null && props.icon !== undefined ? props.icon : "";
    this.hideHeaders = props.hideHeaders ? "none" : null;
    this.hideSearch = props.hideSearch ? props.hideSearch : false;
    this.customCreateForm = this.props.customCreateForm ? this.props.customCreateForm.bind(this) : () => "";
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.data.length !== this.state.data.length) {
      if (this.state.data.length > 0) {
        const columns = Object.keys(this.state.data[0]).map((key) => {
          return { dataField: key };
        });
        this.setState(parseForTable({ columns: columns, data: this.state.data }));
      }
    }
    if (prevProps.cud != this.props.cud) {
      this.setState({ cud: this.props.cud });
    }
    if (prevProps.data.length !== this.props.data.length) {
      if (this.props.data.length > 0) {
        const columns = Object.keys(this.props.data[0]).map((key) => {
          return { dataField: key };
        });
        this.setState(parseForTable({ columns: columns, data: this.props.data }));
      } else {
        this.setState(parseForTable({ columns: this.state.columns, data: this.props.data }));
      }
    }
    if (prevState.isFetching) {
      this.setState({ isFetching: false });
    }
  }

  renderButtons() {
    const renderSaveButton = () => {
      return this.state.edit ? (
        <button
          className={`btn btn-warning btn`}
          onClick={(e) => {
            e.preventDefault();
            console.log(this.props.data);
            console.log(this.state.data);
            console.log(this.node.getNewestState().data);
            if (this.props.postCallback) {
              this.props.postCallback(this.node.getNewestState().data);
              /*               this.props.updateFromProps
                ? this.props.postCallback(this.props.data)
                : this.props.postCallback(this.state.data); */
            }
            this.setState({
              //data: newDatas,
              edit: false,
              deletingMode: false,
            });
          }}
        >
          <i className="far fa-save fa-pull-right"></i>
        </button>
      ) : this.state.deletingMode ? (
        <button
          className={`btn btn-warning btn`}
          onClick={(e) => {
            e.preventDefault();
            const newDatas = this.node.table.props.data.filter((value) => {
              if (!this.node.selectionContext.selected.includes(value.id)) {
                return value;
              }
              return null;
            });
            let ids = "";
            this.node.selectionContext.selected.map((entry) => (ids += entry + ","));
            if (ids.length > 0) ids = ids.substring(0, ids.length - 1);
            if (this.props.deleteCallback) {
              this.props.deleteCallback(ids);
            }
            this.setState({
              edit: false,
              data: newDatas,
              deletingMode: false,
            });
          }}
        >
          <i className="far fa-save fa-pull-right"></i>
        </button>
      ) : (
        /*<button className={`btn btn-${this.theme} btn`} disabled>
          <i className="far fa-save fa-pull-right"></i>
        </button>*/null
      );
    };
    return (
      <div className="btn-group">
        {this.state.cud.u ? (
          <button
            className={`btn btn-${this.theme} btn`}
            onClick={(e) => {
              e.preventDefault();
              if (this.props.handleEdit) {
                console.log("custome edit")
                this.props.handleEdit(e);
              } else {
                this.setState((state) => {
                  return {
                    edit: !state.edit,
                  };
                });
              }
            }}
          >
            <i className={this.state.edit ? "fas fa-undo" : "fas fa-edit"}></i>
          </button>
        ) : null}
        {this.state.cud.c ? (
          <button className={`btn btn-${this.theme} btn`}
            onClick={this.state.cud.c.bind(this)}>
            <i className={this.state.creatingMode ? "fas fa-undo" : `fas fa-${this.icon}plus`}></i>
          </button>
        ) : null}
        {this.state.cud.d ? (
          <button
            className={`btn btn-${this.theme} btn`}
            onClick={(e) => {
              e.preventDefault();
              this.setState((state) => {
                return {
                  edit: false,
                  deletingMode: !state.deletingMode,
                };
              });
            }}
          >
            <i className={this.state.deletingMode ? "fas fa-undo" : `fas fa-${this.icon}minus`}></i>
          </button>
        ) : null}
        {renderSaveButton()}
      </div>
    );
  }

  toggleEdit() {
    if (this.state.edit) {
      return {
        cellEdit: cellEditFactory({
          mode: "dbclick",
          blurToSave: true,
          autoSelectText: true,
        }),
      };
    }
  }


  selectRow() {
    if (this.props.clickToSelectMultiple) {
      return {
        selectRow: {
          mode: "checkbox",
          clickToSelect: true,
          style: { backgroundColor: "#EE3333" },

          onSelect: (row, isSelect, rowIndex, e) => {
            this.props.rowEvents.onClick(e, row, rowIndex)
          },

          onSelectAll: (isSelect, rows, e) => {
            if (this.props.rowEvents.onSelectAll) {
              this.props.rowEvents.onSelectAll(e, rows, isSelect)
            }
          }

        },
      };
    }

    if (this.props.clickToSelect) {
      return {
        selectRow: {
          mode: "checkbox",
          clickToSelect: true,
          hideSelectColumn: true,
          style: { backgroundColor: "#EE3333" },
          onSelect: (row, isSelect, rowIndex, e) => {
            if (isSelect) {
              this.setState({ selected: [row.id] })
            } else {
              this.setState({ selected: [] })
            }
          },
          selected: this.state.selected ? this.state.selected : []
        },
      };
    } else {
      return {
        selectRow: {
          mode: "checkbox",
          clickToEdit: true,
          clickToSelect: this.state.deletingMode,
          hideSelectColumn: !this.state.deletingMode,
          selected: [],
          style: { backgroundColor: "#EE3333" },
        },
      };
    }
  }

  setRowEvents() {
    return this.props.rowEvents
      ? {
        rowEvents: this.props.rowEvents,
      }
      : null;
  }

  displayCreateForm() {
    return this.state.creatingMode || this.props.creatingMode ? this.customCreateForm() : null;
  }

  render() {
    const customMatchFunc = ({ searchText, value, column, row }) => {
      if (column.dataField !== "id"
        && column.dataField !== "createdAt"
        && column.dataField !== "updatedAt") {
        if (typeof value !== "undefined") {
          if (`${value}`
            .toUpperCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .includes(
              searchText
                .toUpperCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
            )) {
          }
          return `${value}`
            .toUpperCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .includes(
              searchText
                .toUpperCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
            );
        }
      }
      return false;
    };
    if (this.props.columns) {
      if (this.props.columns.length > 1 || this.state.columns.length > 1) {
        //merge state and props, to handle new datas received by props, but also new data created by the Create button to this very BSTable state
        let consolidatedStateAndPropsDatas = this.state.data;
        //remove when datas received from API and correctly updated
        if (consolidatedStateAndPropsDatas.length === 0) {
          this.props.data.map((data) => {
            if (!consolidatedStateAndPropsDatas.some((e) => e.id === data.id)) {
              consolidatedStateAndPropsDatas.push(data);
            }
          });
        }
        if (this.props.updateFromProps) {
          consolidatedStateAndPropsDatas = JSON.parse(JSON.stringify(this.props.data));
        }
        //console.log(this.props.columns.length > 1 ? this.props.columns : this.state.columns)
        //console.log(this.state.columns, this.state.data);
        //console.log(this.props.data);
        //console.log(JSON.stringify(consolidatedStateAndPropsDatas))
        //make sure we have a full copy of the state and not update the references directly
        //const copiedState = JSON.parse(JSON.stringify(consolidatedStateAndPropsDatas))

        return (
          <ToolkitProvider
            key="propsTable"
            keyField="id"
            data={consolidatedStateAndPropsDatas}
            columns={this.props.columns.length > 1 ? this.props.columns : this.state.columns}
            search={{ onColumnMatch: customMatchFunc }}
          >
            {(props) => {
              return (
                <div className="fadeIn animated">
                  {this.displayCreateForm()}
                  <Card
                    type={this.theme}
                    title={this.props.title ? this.props.title : "Tableau"}
                    header={this.props.header}
                    tools={this.renderButtons()}
                    isFetching={this.props.isFetching}
                  >
                    {!this.hideSearch ? <SearchBar {...props.searchProps} /> : null}
                    <div className="table-responsive" style={{ overflowX: "hidden" }}>
                      <BootstrapTable
                        {...props.baseProps}
                        {...this.toggleEdit()}
                        {...this.selectRow()}
                        {...this.setRowEvents()}
                        ref={(n) => (this.node = n)}
                        hiddenHeaders
                        expandRow={this.props.expandRow}
                        defaultSorted={this.props.defaultSorted}
                        rowStyle={this.props.rowStyle}
                        pagination={this.props.paginate ? paginationFactory({ showTotal: true, hidePageListOnlyOnePage: true, hideSizePerPage: false, sizePerPage: 10 }) : undefined}
                        wrapperClasses="table-responsive"

                      />
                    </div>
                  </Card>
                </div >
              );
            }
            }
          </ToolkitProvider >
        );
      } else {
        return (
          <ToolkitProvider
            key="stateTable"
            keyField="id"
            data={this.state.data}
            columns={this.state.columns}
            search={{ onColumnMatch: customMatchFunc }}

          >
            {(props) => {
              return (
                <div className="fadeIn animated">
                  {this.displayCreateForm()}
                  <Card
                    type={this.theme}
                    title={this.props.title ? this.props.title : "Tableau"}
                    header={this.props.header}
                    tools={this.renderButtons()}
                    isFetching={this.props.isFetching}
                  >
                    {!this.hideSearch ? <SearchBar {...props.searchProps} /> : null}
                    <div className="table-responsive">
                      <BootstrapTable
                        {...props.baseProps}
                        {...this.toggleEdit()}
                        {...this.selectRow()}
                        {...this.setRowEvents()}
                        ref={(n) => (this.node = n)}
                        hiddenHeaders
                        expandRow={this.props.expandRow}
                        wrapperClasses="table-responsive"
                      />
                    </div>
                  </Card>
                </div>
              );
            }}
          </ToolkitProvider>
        );
      }
    } else {
      return null;
    }
  }
}
const { SearchBar } = Search;
export default BSTable;
