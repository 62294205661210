import React from "react";
import { Translator } from "../../tools/Tools";
import { withRouter } from "react-router-dom";

const HeuresCard = (props) => {
  const total =
    0 +
    (props.total ? props.total : 0) +
    (props.withInitialCredit && props.user.timeInitialCredit ? props.user.timeInitialCredit : 0);
  if (props.small) {
    return (
      <table
        className={
          total < 0 ? "table table-borderless text-warning bg-danger" : "table table-borderless text-warning bg-success"
        }
        style={{ border: "2px solid black", borderRadius: "0.3em", borderCollapse: "separate" }}
      >
        <tbody className="container">
          <tr className="row text-center">
            <td className="col-lg">{total < 0 ? "-" : "+"}</td>
            <td className="col-lg">
              <span>{Math.floor(Math.abs(total / 456))}</span>
              {Translator("d")}
            </td>
            <td className="col-lg">
              <span>
                {Math.floor(Math.abs((total % 456).toFixed(0) / 60))
                  .toFixed(0)
                  .padStart(2, "0")}
              </span>
              {Translator("h")}
            </td>
            <td className="col-lg">
              <span>{((Math.abs(total) % 456).toFixed(0) % 60).toFixed(0)}</span>
              {Translator("m")}
            </td>
          </tr>
        </tbody>
      </table>
    );
  } else {
    return (
      <div className="card bg-light">
        <div className="card-header text-muted border-bottom-0">{Translator("summary") + " " + total}</div>
        <div className="card-body pt-0">
          <div className="row">
            <div className="col-lg-5">
              <h2 className="lead my-2">
                <b>{props.user.firstName + " " + props.user.lastName}</b>
              </h2>
              <p className="my-4">
                <b>{Translator("days")}: </b>
                <span>{Math.floor(Math.abs(total / 456))}</span>
              </p>
              <p className="my-4">
                <b>{Translator("hours")}: </b>
                <span>{Math.floor(Math.abs((total % 456).toFixed(0) / 60)).toFixed(0)}</span>
              </p>
              <p className="my-4">
                <b>{Translator("minutes")}: </b> <span>{((Math.abs(total) % 456).toFixed(0) % 60).toFixed(0)}</span>
              </p>
              <p className="text-muted my-4">
                <b>{Translator("initialTimeCredit")}: </b>{" "}
                {props.user.timeInitialCredit ? props.user.timeInitialCredit : 0}&nbsp;{Translator("m")}
              </p>
            </div>
            <div></div>
            <div className="col-lg-7 text-center">
              <table
                className={
                  total < 0
                    ? "table table-borderless my-5 text-warning bg-danger"
                    : "table table-borderless my-5 text-warning bg-success"
                }
                style={{ border: "3px solid black", borderRadius: "0.5em", borderCollapse: "separate" }}
              >
                <tbody className="container">
                  <tr className="row">
                    <td className="col-sm">
                      <h1>{total < 0 ? "-" : "+"}</h1>
                    </td>
                    <td className="col-sm">
                      <h1>
                        <span>{Math.floor(Math.abs(total / 456))}</span>
                        {Translator("d")}
                      </h1>
                    </td>
                    <td className="col-sm">
                      <h1>
                        <span>{Math.floor(Math.abs((total % 456).toFixed(0) / 60)).toFixed(0)}</span>
                        {Translator("h")}
                      </h1>
                    </td>
                    <td className="col-sm">
                      <h1>
                        <span>{((Math.abs(total) % 456).toFixed(0) % 60).toFixed(0)}</span>
                        {Translator("m")}
                      </h1>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
};
export default withRouter(HeuresCard);
