import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import Card from "../../components/Card";
import BSTable from "../../components/BSTable";
import { Translator, store, getTranslations, patchTranslations } from "../../tools/Tools";
import moment from "moment";
import Holidays from "date-holidays";
import { MomentDateString } from "../../tools/Tools";
import { fetchUsers, postEvent, fetchParameters, patchParameter, patchUser } from "../../tools/Api_Customers";
import { v4 as uuidv4 } from 'uuid';

class Administration extends Component {
  constructor(props) {
    super(props);
    let nationalHolidays = {};
    this.hd = Holidays();
    this.hd.init("BE");
    this.hd.getHolidays(moment().format("YYYY")).map((jour) => {
      if (jour.type === "public") {
        nationalHolidays[jour.name] = { checked: false, date: jour.date };
      }
    });

    this.state = {
      startDate: null,
      users: [],
      toggle: true,
      conveyors: [],
      year: moment().format("YYYY"),
      selectedNationalHolidays: nationalHolidays,
      hotlinePrices: [],
    };
    this.cud = {
      c: (e) => {
        e.preventDefault();
        console.log(this.state.translations);
        let newDatas = [{ id: uuidv4(), key: "Delete " + uuidv4() + " and insert key here", fr: "", nl: "" }];
        const newArray = newDatas.concat(this.state.translations.data);
        console.log(newArray);
        this.setState({ translations: { columns: this.state.translations.columns, data: newArray } });
      },
      u: true,
      d: true,
    };
  }

  componentDidMount() {
    this.reload();
  }
  reload() {
    this.setState({ isFetching: true });
    fetchUsers().then((users) => {
      fetchParameters().then((params) => {
        getTranslations().then((translations) => {
          const startDataParam = params.find((param) => param.type === "startDate");
          let hotlinePrices = {};
          const filteredParams = params
            .filter((param) => param.type.startsWith("hotlinePrice"))
            .map((hp) => {
              hotlinePrices[hp.type] = hp.value;
              return { type: hp.type, value: hp.value };
            });
          let translationKeys = [];
          let doubleKeys = [];
          translations.data.map((obj) => {
            if (translationKeys.includes(obj.key)) {
              if (!doubleKeys.includes(obj.key)) doubleKeys.push(obj.key);
            } else {
              translationKeys.push(obj.key);
            }
          });
          this.setState({
            fetchedUsers: JSON.parse(JSON.stringify(users.data)),
            users: users.data,
            fetchedStartDate: startDataParam.value,
            startDate: startDataParam.value,
            translations: translations,
            doubleKeys: doubleKeys,
            hotlinePrices: hotlinePrices,
            isFetching: false,
          });
        });
      });
    });
  }

  patchTranslations(datas) {
    this.setState({ isFetching: true });
    patchTranslations(datas).then(() => {
      this.reload();
    });
  }
  deleteTranslations(datas) {
    this.setState({ isFetching: true });
    const keys = datas.split(",");
    const payload = this.state.translations.data.filter((obj) => {
      return !keys.includes(obj.key);
    });
    patchTranslations(payload).then(() => {
      this.reload();
    });
  }
  postEvent() {
    console.log(this.state.selectedNationalHolidays);
    Promise.all(
      Object.keys(this.state.selectedNationalHolidays).map((key) => {
        const prom = new Promise((resolve, reject) => {
          resolve();
        });
        //console.log(this.state.selectedNationalHolidays[key].checked)
        if (this.state.selectedNationalHolidays[key].checked) {
          let payload = {
            isRequest: false,
            break: "00:00",
            eventType: "national holiday",
            fullDay: true,
            users: this.state.users.map((user) => user.id),
            title: key,
          };
          payload.start = moment(this.state.selectedNationalHolidays[key].date).format("DD/MM/YYYY HH:mm");
          payload.end = moment(this.state.selectedNationalHolidays[key].date).format("DD/MM/YYYY HH:mm");
          return postEvent(payload);
        } else {
          return prom;
        }
      })
    ).then((results) => {
      console.log(results);
    });
  }
  render() {
    moment.locale(store().getLanguage());
    return (
      <div>
        <div className="content-header">
          <div className="container-fluid">
            <div className="row">
              <div className="col">
                <Card title={Translator("agenda options")}>
                  <div className="row">
                    <div className="col">
                      <button
                        className="btn btn-default"
                        onClick={(e) => {
                          e.preventDefault();
                          let nationalHolidays = {};
                          this.hd.getHolidays(moment().format("YYYY")).map((jour) => {
                            if (jour.type === "public") {
                              nationalHolidays[jour.name] = { checked: false, date: jour.date };
                            }
                          });
                          this.setState({ year: moment().format("YYYY"), selectedNationalHolidays: nationalHolidays });
                        }}
                      >
                        {Translator("see national holidays for ") + moment().format("YYYY")}
                      </button>
                    </div>
                    <div className="col">
                      <button
                        className="btn btn-default"
                        onClick={(e) => {
                          e.preventDefault();

                          let nationalHolidays = {};
                          this.hd.getHolidays(moment().add(1, "years").format("YYYY")).map((jour) => {
                            if (jour.type === "public") {
                              nationalHolidays[jour.name] = { checked: false, date: jour.date };
                            }
                          });
                          this.setState({
                            year: moment().add(1, "years").format("YYYY"),
                            selectedNationalHolidays: nationalHolidays,
                          });
                        }}
                      >
                        {Translator("see national holidays for ") + moment().add(1, "years").format("YYYY")}
                      </button>
                    </div>
                  </div>
                  <hr></hr>
                  <div className="row">
                    <button
                      className="btn btn-default col"
                      onClick={(e) => {
                        e.preventDefault();
                        let newObject = {};
                        Object.keys(this.state.selectedNationalHolidays).map((key) => {
                          newObject[key] = {
                            checked: this.state.toggle,
                            date: this.state.selectedNationalHolidays[key].date,
                          };
                        });
                        this.setState({ selectedNationalHolidays: newObject, toggle: !this.state.toggle });
                      }}
                    >
                      {Translator("select / unselect all")}
                    </button>
                  </div>
                  <hr></hr>
                  <div className="col">
                    {this.hd.getHolidays(this.state.year).map((jourF) => {
                      if (jourF.type === "public") {
                        return (
                          <div key={jourF.name} className="row">
                            <div className="col-6">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  checked={this.state.selectedNationalHolidays[jourF.name].checked}
                                  type="checkbox"
                                  onChange={() => {
                                    this.setState({
                                      selectedNationalHolidays: {
                                        ...this.state.selectedNationalHolidays,
                                        [jourF.name]: {
                                          ...this.state.selectedNationalHolidays[jourF.name],
                                          checked: !this.state.selectedNationalHolidays[jourF.name].checked,
                                        },
                                      },
                                    });
                                  }}
                                ></input>
                                <label className="form-check-label">{Translator(jourF.name)}</label>
                              </div>
                            </div>
                            <div className="col-6">
                              {moment(jourF.date).format("dddd") + " " + MomentDateString(moment(jourF.date))}
                            </div>
                          </div>
                        );
                      }
                    })}
                  </div>
                  <hr></hr>

                  <div className="row">
                    <button
                      className="btn btn-default col"
                      onClick={(e) => {
                        e.preventDefault();
                        this.postEvent();
                      }}
                    >
                      {Translator("add to agenda")}
                    </button>
                  </div>
                </Card>
              </div>
              <div className="row col">
                <div className="col">
                  <Card title={Translator("holiday & hours options")}>
                    <h3>
                      <u>{moment(this.state.fetchedStartDate, "DD/MM/YYYY").format("dddd DD MMMM YYYY")}</u>
                    </h3>
                    <hr></hr>
                    <table className="table bordered">
                      <thead>
                        <tr>
                          <th style={{ border: "solid 2px", textAlign: "center", backgroundColor: "#EEEEEE" }}>
                            {moment(this.state.startDate, "DD/MM/YYYY").startOf("week").format("dd")}
                          </th>
                          <th style={{ border: "solid 2px", textAlign: "center", backgroundColor: "#EEEEEE" }}>
                            {moment(this.state.startDate, "DD/MM/YYYY").startOf("week").add(1, "days").format("dd")}
                          </th>
                          <th style={{ border: "solid 2px", textAlign: "center", backgroundColor: "#EEEEEE" }}>
                            {moment(this.state.startDate, "DD/MM/YYYY").startOf("week").add(2, "days").format("dd")}
                          </th>
                          <th style={{ border: "solid 2px", textAlign: "center", backgroundColor: "#EEEEEE" }}>
                            {moment(this.state.startDate, "DD/MM/YYYY").startOf("week").add(3, "days").format("dd")}
                          </th>
                          <th style={{ border: "solid 2px", textAlign: "center", backgroundColor: "#EEEEEE" }}>
                            {moment(this.state.startDate, "DD/MM/YYYY").startOf("week").add(4, "days").format("dd")}
                          </th>
                          <th style={{ border: "solid 2px", textAlign: "center", backgroundColor: "#EEEEEE" }}>
                            {moment(this.state.startDate, "DD/MM/YYYY").startOf("week").add(5, "days").format("dd")}
                          </th>
                          <th style={{ border: "solid 2px", textAlign: "center", backgroundColor: "#EEEEEE" }}>
                            {moment(this.state.startDate, "DD/MM/YYYY").startOf("week").add(6, "days").format("dd")}
                          </th>
                        </tr>
                        <tr>
                          <th style={{ border: "solid 2px", backgroundColor: "#EEEEEE" }}>
                            {moment(this.state.startDate, "DD/MM/YYYY").startOf("week").format("DD/MM")}
                          </th>
                          <th style={{ border: "solid 2px", backgroundColor: "#EEEEEE" }}>
                            {moment(this.state.startDate, "DD/MM/YYYY").startOf("week").add(1, "days").format("DD/MM")}
                          </th>
                          <th style={{ border: "solid 2px", backgroundColor: "#EEEEEE" }}>
                            {moment(this.state.startDate, "DD/MM/YYYY").startOf("week").add(2, "days").format("DD/MM")}
                          </th>
                          <th style={{ border: "solid 2px", backgroundColor: "#EEEEEE" }}>
                            {moment(this.state.startDate, "DD/MM/YYYY").startOf("week").add(3, "days").format("DD/MM")}
                          </th>
                          <th style={{ border: "solid 2px", backgroundColor: "#EEEEEE" }}>
                            {moment(this.state.startDate, "DD/MM/YYYY").startOf("week").add(4, "days").format("DD/MM")}
                          </th>
                          <th style={{ border: "solid 2px", backgroundColor: "#EEEEEE" }}>
                            {moment(this.state.startDate, "DD/MM/YYYY").startOf("week").add(5, "days").format("DD/MM")}
                          </th>
                          <th style={{ border: "solid 2px", backgroundColor: "#EEEEEE" }}>
                            {moment(this.state.startDate, "DD/MM/YYYY").startOf("week").add(6, "days").format("DD/MM")}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td
                            style={{
                              border: "solid 2px",
                              textAlign: "center",
                              backgroundColor: moment(this.state.startDate, "DD/MM/YYYY")
                                .startOf("week")
                                .add(0, "days")
                                .isSame(moment(this.state.startDate, "DD/MM/YYYY"), "days")
                                ? "green"
                                : "",
                            }}
                          >
                            &nbsp;
                          </td>
                          <td
                            style={{
                              border: "solid 2px",
                              textAlign: "center",
                              backgroundColor: moment(this.state.startDate, "DD/MM/YYYY")
                                .startOf("week")
                                .add(1, "days")
                                .isSame(moment(this.state.startDate, "DD/MM/YYYY"), "days")
                                ? "green"
                                : "",
                            }}
                          >
                            &nbsp;
                          </td>
                          <td
                            style={{
                              border: "solid 2px",
                              textAlign: "center",
                              backgroundColor: moment(this.state.startDate, "DD/MM/YYYY")
                                .startOf("week")
                                .add(2, "days")
                                .isSame(moment(this.state.startDate, "DD/MM/YYYY"), "days")
                                ? "green"
                                : "",
                            }}
                          >
                            &nbsp;
                          </td>
                          <td
                            style={{
                              border: "solid 2px",
                              textAlign: "center",
                              backgroundColor: moment(this.state.startDate, "DD/MM/YYYY")
                                .startOf("week")
                                .add(3, "days")
                                .isSame(moment(this.state.startDate, "DD/MM/YYYY"), "days")
                                ? "green"
                                : "",
                            }}
                          >
                            &nbsp;
                          </td>
                          <td
                            style={{
                              border: "solid 2px",
                              textAlign: "center",
                              backgroundColor: moment(this.state.startDate, "DD/MM/YYYY")
                                .startOf("week")
                                .add(4, "days")
                                .isSame(moment(this.state.startDate, "DD/MM/YYYY"), "days")
                                ? "green"
                                : "",
                            }}
                          >
                            &nbsp;
                          </td>
                          <td
                            style={{
                              border: "solid 2px",
                              textAlign: "center",
                              backgroundColor: moment(this.state.startDate, "DD/MM/YYYY")
                                .startOf("week")
                                .add(5, "days")
                                .isSame(moment(this.state.startDate, "DD/MM/YYYY"), "days")
                                ? "green"
                                : "",
                            }}
                          >
                            &nbsp;
                          </td>
                          <td
                            style={{
                              border: "solid 2px",
                              textAlign: "center",
                              backgroundColor: moment(this.state.startDate, "DD/MM/YYYY")
                                .startOf("week")
                                .add(6, "days")
                                .isSame(moment(this.state.startDate, "DD/MM/YYYY"), "days")
                                ? "green"
                                : "",
                            }}
                          >
                            &nbsp;
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <hr></hr>
                    <div className="form-inline">
                      <div className="input-group mb-2 mr-sm-2 col">
                        <div className="input-group-prepend">
                          <div className="input-group-text">
                            <label>{Translator("count hours from")}</label>
                          </div>
                        </div>
                        <input
                          type="date"
                          value={moment(this.state.startDate, "DD/MM/YYYY").format("YYYY-MM-DD")}
                          className="form-control"
                          onChange={(e) => {
                            this.setState({ startDate: moment(e.target.valueAsDate).format("DD/MM/YYYY") });
                          }}
                        ></input>
                        <button
                          className="btn btn-default"
                          onClick={(e) => {
                            e.preventDefault();
                            patchParameter({ type: "startDate", value: this.state.startDate }).then(() =>
                              this.reload()
                            );
                          }}
                        >
                          {Translator("save")}
                        </button>
                      </div>
                    </div>
                  </Card>
                </div>
                <div className="col-12">
                  <Card title={Translator("hotline prices")}>
                    {Translator("Define default hotline prices")}
                    {Object.keys(this.state.hotlinePrices).map((price) => {
                      return (
                        <div className="form-inline" key={price}>
                          <div className="input-group mb-2 mr-sm-2 col">
                            <div className="input-group-prepend">
                              <div className="input-group-text">
                                <label>{Translator(price)}</label>
                              </div>
                            </div>
                            <input
                              type="number"
                              value={this.state.hotlinePrices[price]}
                              className="form-control"
                              onChange={(e) => {
                                console.log(e.target.value);
                                this.setState({
                                  hotlinePrices: { ...this.state.hotlinePrices, [price]: e.target.value },
                                });
                              }}
                            ></input>
                            <button
                              className="btn btn-default"
                              onClick={(e) => {
                                e.preventDefault();
                                patchParameter({ type: price, value: this.state.hotlinePrices[price] }).then(() =>
                                  this.reload()
                                );
                              }}
                            >
                              {Translator("save")}
                            </button>
                          </div>
                        </div>
                      );
                    })}
                  </Card>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <Card title={Translator("users options")}>
                  <div className="col">
                    <div className="ribbon-wrapper ribbon-xl">
                      <div className={"ribbon bg-danger"}>In progress ...</div>
                    </div>
                    <div className="col">
                      <b>
                        <u>{Translator("Add user")}</u>
                      </b>
                    </div>
                    <hr></hr>
                    <form className="form-inline col-sm-12">
                      <div className="col">
                        <div className="row">
                          <div className="col">
                            <div className="input-group mb-2 mr-sm-2">
                              <div className="input-group-prepend">
                                <div className="input-group-text">
                                  <label>{Translator("firstName")}</label>
                                </div>
                              </div>
                              <input
                                type="text"
                                className="form-control"
                                defaultValue={"test"}
                                onChange={(e) => { }}
                              ></input>
                            </div>
                          </div>
                          <div className="col">
                            <div className="input-group mb-2 mr-sm-2">
                              <div className="input-group-prepend">
                                <div className="input-group-text">
                                  <label>{Translator("lastName")}</label>
                                </div>
                              </div>
                              <input
                                type="text"
                                className="form-control"
                                defaultValue={"test"}
                                onChange={(e) => { }}
                              ></input>
                            </div>
                          </div>{" "}
                          <div className="col">
                            <div className="input-group mb-2 mr-sm-2">
                              <div className="input-group-prepend">
                                <div className="input-group-text">
                                  <label>{Translator("login")}</label>
                                </div>
                              </div>
                              <input
                                type="text"
                                className="form-control"
                                defaultValue={"test"}
                                onChange={(e) => { }}
                              ></input>
                            </div>
                          </div>{" "}
                          <div className="col">
                            <div className="input-group mb-2 mr-sm-2">
                              <div className="input-group-prepend">
                                <div className="input-group-text">
                                  <label>{Translator("level")}</label>
                                </div>
                              </div>
                              <input
                                type="text"
                                className="form-control"
                                defaultValue={"test"}
                                onChange={(e) => { }}
                              ></input>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <div className="input-group mb-2 mr-sm-2">
                              <div className="input-group-prepend">
                                <div className="input-group-text">
                                  <label>{Translator("email")}</label>
                                </div>
                              </div>
                              <input
                                type="text"
                                className="form-control"
                                defaultValue={"test"}
                                onChange={(e) => { }}
                              ></input>
                            </div>
                          </div>
                          <div className="col">
                            <div className="input-group mb-2 mr-sm-2">
                              <div className="input-group-prepend">
                                <div className="input-group-text">
                                  <label>{Translator("password")}</label>
                                </div>
                              </div>
                              <input
                                type="password"
                                className="form-control"
                                defaultValue={"test"}
                                onChange={(e) => { }}
                              ></input>
                            </div>
                          </div>{" "}
                          <div className="col">
                            <div className="input-group mb-2 mr-sm-2">
                              <div className="input-group-prepend">
                                <div className="input-group-text">
                                  <label>{Translator("repeat password")}</label>
                                </div>
                              </div>
                              <input
                                type="password"
                                className="form-control"
                                defaultValue={"test"}
                                onChange={(e) => { }}
                              ></input>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-2">
                        <button
                          className="btn btn-default"
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                        >
                          {Translator("save")}
                        </button>
                      </div>{" "}
                    </form>
                    <hr></hr>
                    <div className="row" key={"title"}>
                      <div className="col">
                        <b>
                          <u>{Translator("name")}</u>
                        </b>
                      </div>
                      <div className="col">
                        <b>
                          <u>{Translator("initial time credit")}</u>
                        </b>
                      </div>
                      <div className="col">
                        <b>
                          <u>{Translator("holidays per year")}</u>
                        </b>
                      </div>
                      <div className="col">
                        <b>
                          <u></u>
                        </b>
                      </div>
                    </div>
                    <hr></hr>
                    {this.state.users.map((user) => {
                      const constUser = this.state.fetchedUsers.find((fUser) => fUser.id === user.id);
                      return (
                        <div className="row" key={user.id}>
                          <form className="form-inline col-sm-12">
                            <div className="col">{user.firstName + " " + user.lastName}&nbsp;:</div>
                            <div className="col">
                              <div className="input-group mb-2 mr-sm-2">
                                <div className="input-group-prepend">
                                  <div className="input-group-text">
                                    <label>{constUser.timeInitialCredit}</label>
                                  </div>
                                </div>
                                <input
                                  type="number"
                                  className="form-control"
                                  defaultValue={user.timeInitialCredit}
                                  onChange={(e) => {
                                    let tmpUsers = this.state.users.map((stateUser) => {
                                      if (stateUser.id === user.id) stateUser.timeInitialCredit = e.target.value;
                                      return stateUser;
                                    });
                                    this.setState({ users: tmpUsers });
                                  }}
                                ></input>
                              </div>
                            </div>
                            <div className="col">
                              <div className="input-group mb-2 mr-sm-2">
                                <div className="input-group-prepend">
                                  <div className="input-group-text">
                                    <label>{constUser.holidaysMax}</label>
                                  </div>
                                </div>
                                <input
                                  type="number"
                                  className="form-control"
                                  defaultValue={user.holidaysMax}
                                  onChange={(e) => {
                                    let tmpUsers = this.state.users.map((stateUser) => {
                                      if (stateUser.id === user.id) stateUser.holidaysMax = e.target.value;
                                      return stateUser;
                                    });
                                    this.setState({ users: tmpUsers });
                                  }}
                                ></input>
                              </div>
                            </div>

                            <div className="col">
                              <button
                                className="btn btn-default"
                                onClick={(e) => {
                                  e.preventDefault();
                                  patchUser(this.state.users.find((stateUser) => stateUser.id === user.id)).then(
                                    (result) => {
                                      this.reload();
                                    }
                                  );
                                }}
                              >
                                {Translator("save")}
                              </button>
                            </div>
                          </form>
                        </div>
                      );
                    })}
                  </div>
                </Card>
              </div>
            </div>
            <div className="row">
              {this.state.doubleKeys && this.state.doubleKeys.length > 0 ? (
                <div className="alert alert-warning">
                  <h5>
                    <i className="icon fas fa-exclamation-triangle"></i> Alert!
                  </h5>
                  {Translator("The following keys are defined more than once : ")}
                  <ul>
                    {this.state.doubleKeys.map((double) => (
                      <li>{double}</li>
                    ))}
                  </ul>
                </div>
              ) : null}

              {this.state.translations ? (
                <BSTable
                  tite={Translator("translations")}
                  data={this.state.translations.data}
                  columns={this.state.translations.columns}
                  cud={this.cud}
                  postCallback={this.patchTranslations.bind(this)}
                  deleteCallback={this.deleteTranslations.bind(this)}
                  updateFromProps={true}
                  isFetching={this.state.isFetching}
                ></BSTable>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Administration);
