import React, { Component } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import Home from "./pages/Home";
import Account from "./pages/Account/Account";
import Customers from "./pages/Customers/Customers";
import Agenda from "./pages/Agenda/Agenda";
import Heures from "./pages/Heures/Heures";
import Vacances from "./pages/Vacances/Vacances";
import Customer from "./pages/Customers/Customer";
import Suppliers from "./pages/Suppliers/Suppliers";
import Supplier from "./pages/Suppliers/Supplier";
import Projects from "./pages/Projects/Projects";
import Invoices from "./pages/Invoices/Invoices";
import TicketDetail from "./pages/Tickets/TicketDetail";
import Login from "./pages/Login";
import { store } from "./tools/Tools";
import UserTodoList from "./pages/Todolist/UserTodoList";
import Administration from "./pages/Admin/Administration";
import TicketPage from "./pages/Tickets/TicketPage";
import Checklists from "./pages/Checklists/Checklists";

class Content extends Component {
  render() {
    const Content = () => (
      <Switch>
        <Route exact path="/" component={Home}></Route>
        <Route path="/account" component={Account}></Route>
        <Route path="/administration">
          <Route exact path="/administration" component={Administration}></Route>
        </Route>
        <Route path="/customers">
          <Route exact path="/customers" component={Customers}></Route>
          <Route path="/customers/:id" component={Customer}></Route>
          <Route path="/customers/:id/:number" component={TicketDetail}></Route>
        </Route>
        <Route path="/suppliers">
          <Route exact path="/suppliers" component={Suppliers}></Route>
          <Route path="/suppliers/:id" component={Supplier}></Route>
        </Route>
        <Route path="/invoices" component={Invoices}></Route>
        <Route path="/agenda" component={Agenda}></Route>
        <Route path="/heures" component={Heures}></Route>
        <Route path="/vacances" component={Vacances}></Route>
        <Route path="/todolist" component={UserTodoList}></Route>
        <Route path="/projects" component={Projects}></Route>
        <Route path="/tickets">
          <Route exact path="/tickets" component={TicketPage}></Route>
          <Route path="/tickets/:number" component={TicketDetail}></Route>
        </Route>
        <Route path="/checklists" component={Checklists}></Route>

        <Route
          path="*"
          component={() => (
            <div>
              <h1>Error 404</h1>
            </div>
          )}
        ></Route>
      </Switch>
    );
    if (store().getLogged()) {
      //console.log("LOGGED");
      return (
        <div className="content-wrapper">
          <Switch>
            <Content />
          </Switch>
        </div>
      );
    } else {
      console.log("NOT LOGGED");
      return (
        <div className="content-wrapper">
          <Route exact path="/" component={Login}></Route>
        </div>
      );
    }
  }
}

export default withRouter(Content);
