import React, { Component } from 'react'
import moment from 'moment'
import { Translator } from '../../tools/Tools'
import Card from '../../components/Card'

export default class ProjectTextOverview extends Component {
    render() {
        //console.log(this.props)
        const sortedProjectPeriodsProjects = this.props.projects.map(p => {
            p.ProjectPeriods = p.ProjectPeriods.sort((a, b) => {
                if (moment(a.from).isBefore(moment(b.from))) {
                    return -1
                }
                if (moment(b.from).isBefore(moment(a.from))) {
                    return 1
                } else {
                    return 0
                }
            })
            return p;
        })

        const filteredProjects = sortedProjectPeriodsProjects.filter(p => {
            return p.ProjectPeriods.length > 0
        });
        const sortedProjects = filteredProjects.sort((a, b) => {
            if (moment(a.ProjectPeriods[0].from).isBefore(moment(b.ProjectPeriods[0].from))) {
                return -1
            }
            if (moment(b.ProjectPeriods[0].from).isBefore(moment(a.ProjectPeriods[0].from))) {
                return 1
            } else {
                return 0
            }

        })
        var cpt = 0;
        return (
            <Card title={<span><i className="nav-icon fas fa-project-diagram"></i>&nbsp;{Translator("Upcoming Projects")}</span>}>
                <ul>
                    {
                        sortedProjects.map((p) => {
                            return (<div key={p.id}>{
                                p.ProjectPeriods.map(pp => {
                                    if (moment(pp.to).isAfter(moment())) {
                                        cpt = 1;
                                        return (<li key={pp.id}>{moment(pp.from).format("DD/MM/YYYY")} - {moment(pp.to).format("DD/MM/YYYY")}: {Translator(pp.type)} <i className="fas fa-users ml-2"></i> {pp.workersCount}
                                            &emsp;<span><b>{p.Customer.name} {p.Site ? p.Site.name : " "}</b>&emsp;<i>{pp.note}</i></span></li>)
                                    }
                                    cpt = 0;
                                })
                            }{cpt === 1 ? <br></br> : null}</div>
                            )
                        })
                    }
                </ul >
            </Card>
        )
    }
}
