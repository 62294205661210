import React, { useState } from "react";
import { withRouter, useHistory } from "react-router-dom";
import { postLogin } from "../tools/Api_Customers";
import { store, Translator } from "../tools/Tools";

const Login = () => {
  let history = useHistory();

  const doLogin = (login, password) => {
    const credentials = { login: login, password: password };
    console.log("do login");
    postLogin(credentials).then((response) => {
      if (response.ok) {
        response.json().then((result) => {
          console.log(result)

          store().state.isLogged = true;
          store().state.User = result;
          store().setLanguage(result.language);
          if (remember) {
            store().getCookies().set("isLogged", "true", { maxAge: 604800, sameSite: "lax" });
            store().getCookies().set("loggedUser", result, { maxAge: 604800, sameSite: "lax" });
          } else {
            store().getCookies().remove("isLogged");
            store().getCookies().remove("loggedUser");
          }
          //history.location.state = { isLogged:true,
          //    user : result.shortName, userId : result.userId, userLvl : result.accreditation }
          console.log("logging, go to homepage");
          history.push({ pathname: "/" });
        });
      } else {
        response.json().then((result) => {
          errorMessage = result.message;
          console.log(errorMessage);
        });
      }
    });
  };

  let errorMessage = "";
  const [remember, setRemember] = useState(store().getCookies().get("remember") === "true");
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");

  return (
    <div className="hold-transition login-page">
      <div className="login-box">
        <div className="login-logo">
          <a href="../../index2.html">
            <b>Admin</b>LTE
          </a>
        </div>
        <div className="card">
          <form className="card-body login-card-body">
            <p className="login-box-msg">{Translator("Sign in to start your session")}</p>
            <p className="login-box-msg">{errorMessage}</p>
            <div>
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  value={login}
                  placeholder={Translator("Login")}
                  onChange={(e) => setLogin(e.target.value)}
                ></input>
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-key"></span>
                  </div>
                </div>
              </div>
              <div className="input-group mb-3">
                <input
                  type="password"
                  className="form-control"
                  value={password}
                  placeholder={Translator("Password")}
                  onChange={(e) => setPassword(e.target.value)}
                ></input>
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-lock"></span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-8">
                  <div className="icheck-primary">
                    <input
                      type="checkbox"
                      id="remember"
                      checked={remember}
                      onChange={(e) => {
                        store().getCookies().set("remember", !remember, { maxAge: 604800, sameSite: "lax" });
                        setRemember(!remember);
                      }}
                    ></input>
                    <label htmlFor="remember">{Translator("Remember Me")}</label>
                  </div>
                </div>
                <div className="col-4">
                  <button
                    className="btn btn-primary btn-block"
                    onClick={(e) => {
                      e.preventDefault();
                      doLogin(login, password);
                    }}
                  >
                    Sign In
                  </button>
                </div>
              </div>
            </div>
            <p className="mb-0">
              <a
                href="register.html"
                className="text-center"
                onClick={(e) => {
                  e.preventDefault();
                  history.push({ pathname: "/register" });
                }}
              >
                {Translator("Register a new membership (not yet implemented)")}
              </a>
            </p>
          </form>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Login);
