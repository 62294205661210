import React from "react";
import { MomentDateString } from "../../tools/Tools";

const Todo = (props) => {
  const customer = props.customers.find((customer) => {
    return customer.id === props.todo.CustomerId;
  });
  const author = props.users.find((user) => {
    return user.id === props.todo.authorId;
  });
  const responsible = props.users.find((user) => {
    return user.id === props.todo.responsibleId;
  });
  return (
    <tr
      key={props.todo.id}
      bgcolor={
        props.selected
          ? "#EEEEEE"
          : props.todo.status === 100
          ? "#DDAA99"
          : props.todo.status === 0
          ? "#FFFFFF"
          : "#FFCCBB"
      }
      onClick={(e) => {
        e.preventDefault();
        props.onSelect(props.todo);
      }}
    >
      <td>{MomentDateString(props.todo.createdAt)}</td>
      <td>{props.todo.Customer.label}</td>
      <td>{props.todo.author.label}</td>
      <td>{props.todo.responsible.label}</td>
      <td>{props.todo.task}</td>
      <td>
        {props.todo.deadline ? (props.todo.deadline.length > 0 ? MomentDateString(props.todo.deadline) : "") : ""}
      </td>
      <td>
        <div className="progress">
          <div
            className="progress-bar"
            style={{ width: props.todo.status + "%", color: "black", backgroundColor: "light blue" }}
          >
            {props.todo.status}%
          </div>
        </div>
      </td>
      <td>{props.todo.status === 100 ? MomentDateString(props.todo.closingDate) : null}</td>
      <td>{props.todo.closerId ? props.todo.closer.label : null}</td>
    </tr>
  );
};

export default Todo;
