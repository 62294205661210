import React, { Component } from "react";
import Card from "./Card";
import { Translator, MomentDateString } from "../tools/Tools";
import moment from "moment";

class LogbookEntry extends Component {
  render() {
    let theme = "";
    switch (this.props.type) {
      case "sur place":
        theme = "primary";
        break;
      case "telemaintenance":
        theme = "secondary";
        break;
      case "visite":
        theme = "olive";
        break;
      default:
        break;
    }
    const renderEditButton = () => {
      return (
        <button
          data-toggle="modal"
          data-target="#modal-redaction"
          data-keyboard="false"
          className={`btn btn-${theme} btn`}
          onClick={(e) => {
            e.preventDefault();
            return this.props.editCallback(this.props.id);
          }}
        >
          <i className="fas fa-edit"></i>
        </button>
      );
    };
    const renderHtml = (html) => {
      return { __html: html };
    };
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-2">
            <dl>
              <dt>{MomentDateString(moment(this.props.date))}</dt>
              <dd>
                {this.props.author.map((author) => author.shortName + " ")} - {Translator(this.props.type)}
              </dd>
            </dl>
          </div>
          <div className="col-md-10">
            <Card title={Translator(this.props.type)} type={theme} tools={renderEditButton()}>
              <dl>
                <dt>{Translator("description")}</dt>
                <dd dangerouslySetInnerHTML={renderHtml(this.props.description)}></dd>
                {this.props.replaced ? (
                  <div>
                    <hr></hr>
                    <dt>{Translator("parts replaced")}</dt>
                    <dd dangerouslySetInnerHTML={renderHtml(this.props.replaced)}></dd>
                  </div>
                ) : null}
                {this.props.toReplace ? (
                  <div>
                    <hr></hr>
                    <dt>{Translator("parts to replace")}</dt>
                    <dd dangerouslySetInnerHTML={renderHtml(this.props.toReplace)}></dd>
                  </div>
                ) : null}
              </dl>
            </Card>
          </div>
        </div>
      </div>
    );
  }
}

export default LogbookEntry;
