import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  fetchCustomer,
  patchCustomerConnectivityInfos,
  deleteCustomerConnectivityInfos,
} from "../../tools/Api_Customers";
import Card from "../../components/Card";
import BSTable from "../../components/BSTable";
import RichText from "../../components/RichText";
import { Translator, parseForTable } from "../../tools/Tools";
import { v4 as uuidv4 } from 'uuid';

class CustomerRemote extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.generalInfos.id,
      creatingMode: props.creatingMode,
      remoteInfos: [],
      columns: [
        { dataField: "id", text: "id", hidden: true, sort: true, headerStyle: { display: "none" } },
        { dataField: "host", text: "host", sort: true, hidden: true, headerStyle: { display: "none" } },
        { dataField: "type", text: "type", sort: true, headerStyle: { display: "none" } },
        { dataField: "value", text: "value", sort: true, headerStyle: { display: "none" } },
      ],
    };
  }
  componentDidMount() {
    this.fetchInformations();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.isFetching) {
      this.setState({ isFetching: false });
    }
    if (prevProps.creatingMode !== this.props.creatingMode) {
      this.setState({ creatingMode: this.props.creatingMode });
    }
  }

  fetchInformations() {
    fetchCustomer(this.state.id).then((cust) => {
      const fetchedInfos = cust.data[0].CustomerRemoteConnectivities;
      let hasTV = false;
      let hasVPN = false;
      let Teamviewer = {};
      let VPN = [];
      fetchedInfos.map((info) => {
        if (info.type) {
          if (info.type.toUpperCase().includes("TEAMVIEWER") || info.type.toUpperCase().includes("VNC")) {
            if (Teamviewer[info.host]) {
              Teamviewer[info.host].push({ id: info.id, host: info.host, type: info.type, value: info.value });
            } else {
              Teamviewer[info.host] = [{ id: info.id, host: info.host, type: info.type, value: info.value }];
            }
            hasTV = true;
          }
          if (info.type.toUpperCase().includes("VPN")) {
            VPN.push(info);
            hasVPN = true;
          }
        }
      });
      this.setState({
        remoteInfos: cust.data[0].CustomerRemoteConnectivities,
        edit: false,
        hasTV: hasTV,
        hasVPN: hasVPN,
        Teamviewer: Teamviewer,
        VPN: VPN,
        creatingMode: false,
        currentHost: null,
        currentType: null,
        currentValue: null,
      });
    });
  }
  patchCustomerConnectivityInfos(datas) {
    const payload = { id: this.state.id, infos: datas };
    console.log(payload);
    patchCustomerConnectivityInfos(payload).then(() => {
      this.fetchInformations();
    });
  }

  deleteCustomerConnectivityInfos(datas) {
    console.log(datas);
    const payload = { id: this.state.id, infos: datas };
    console.log(payload);
    deleteCustomerConnectivityInfos(payload).then((res) => {
      console.log(res);
      this.fetchInformations();
    });
  }
  onChange(content) {
    this.setState({ extraInfos: content });
  }
  handleCreateButton(key, e) {
    e.preventDefault();
    console.log(key);
    /*         this.setState((state) => {
                    return ({
                        edit: true,
                        data: [...state.data, {
                            id: uuidv4(),
                            type: 'Info',
                            value: 'Donnée'
                        }]
                    })
                }) */
    this.setState({ creatingMode: true, currentHost: key });
  }
  postCallback(datas) {
    let newDatas = datas.map((data) => {
      return { id: data.id, host: data.host, type: data.type, value: data.value };
    });
    this.patchCustomerConnectivityInfos(newDatas);
  }
  deleteCallback(ids) {
    const datas = ids.split(",");
    this.deleteCustomerConnectivityInfos(datas);
  }

  render() {
    const renderHtml = (html) => {
      return { __html: html };
    };

    const renderEditButton = (id, type, host) => {
      return (
        <div>
          <button
            className={`btn`}
            onClick={(e) => {
              e.preventDefault();
              this.deleteCustomerConnectivityInfos([id]);
            }}
          >
            <i className="fas fa-trash-alt"></i>
          </button>
          <button
            className={`btn`}
            onClick={(e) => {
              e.preventDefault();
              if (this.state.edit) {
                const datas = { id: id, host: host, type: type, value: this.state.extraInfos };
                this.patchCustomerConnectivityInfos([datas]);
              } else {
                this.setState((state) => {
                  return { edit: !state.edit };
                });
              }
            }}
          >
            {this.state.edit ? <i className="fas far fa-save"></i> : <i className="fas fa-edit"> </i>}
          </button>
        </div>
      );
    };

    const renderCreateForm = () => {
      let callOutText = "";
      const displayRequiredCallout = () => {
        if (this.state.currentType) {
          if (
            !this.state.currentType.toUpperCase().includes("TEAMVIEWER") &&
            !this.state.currentType.toUpperCase().includes("VPN") &&
            !this.state.currentType.toUpperCase().includes("VNC")
          ) {
            callOutText += Translator("Type must include either 'Teamviewer', 'VNC' or  'VPN'");
          }
        } else {
          callOutText = Translator("Type");
        }
        if (!this.state.currentHost || this.state.currentHost === "") {
          callOutText += Translator(" - Host");
        }
        callOutText += " required";

        const callOut =
          callOutText === " required" ? null : (
            <div
              id="validationCallout1"
              className="callout callout-danger mb-2 mr-sm-2 shake animated"
              style={{
                height: "calc(2.25rem + 2px)",
                padding: "0.375rem 0.75rem",
              }}
            >
              {callOutText}
            </div>
          );

        return callOut;
      };
      return (
        <div className="info-box slideInDown animated">
          <span className="info-box-icon">
            <span>
              <img
                style={{ height: 40, width: 40, verticalAlign: "top" }}
                src="/assets/dist/img/TeamViewer_Logo_Icon_Only.svg.png"
              ></img>
            </span>
          </span>
          <div className="info-box-content">
            <form className="form-inline col-sm-12">
              <div className="input-group mb-2 mr-sm-2">
                <input
                  type="text"
                  className="form-control"
                  placeholder={Translator("host")}
                  value={this.state.currentHost ? this.state.currentHost : ""}
                  onChange={(e) => {
                    this.setState({ currentHost: e.target.value });
                  }}
                ></input>
              </div>
              <div className="input-group mb-2 mr-sm-2">
                <input
                  type="text"
                  className="form-control"
                  placeholder={Translator("type")}
                  onChange={(e) => {
                    this.setState({ currentType: e.target.value });
                  }}
                ></input>
              </div>
              <div className="input-group mb-2 mr-sm-2">
                <input
                  type="text"
                  className="form-control"
                  placeholder={Translator("value")}
                  onChange={(e) => {
                    this.setState({ currentValue: e.target.value });
                  }}
                ></input>
              </div>
              <button
                className="btn btn-default mb-2 mr-sm-2"
                disabled={
                  !this.state.currentHost ||
                  this.state.currentHost === "" ||
                  !this.state.currentType ||
                  (!this.state.currentType.toUpperCase().includes("TEAMVIEWER") &&
                    !this.state.currentType.toUpperCase().includes("VPN") &&
                    !this.state.currentType.toUpperCase().includes("VNC"))
                }
                onClick={(e) => {
                  e.preventDefault();
                  const datas = {
                    id: uuidv4(),
                    host: this.state.currentHost,
                    type: this.state.currentType,
                    value: this.state.currentValue,
                  };
                  console.log(datas);
                  this.patchCustomerConnectivityInfos([datas]);
                }}
              >
                {Translator("enregistrer")}
              </button>
              {displayRequiredCallout()}
            </form>
          </div>
        </div>
      );
    };
    return (
      <div>
        {this.state.creatingMode ? renderCreateForm() : null}
        {this.state.hasTV ? (
          <Card title="Teamviewer" type="primary">
            <div className="row">
              {Object.keys(this.state.Teamviewer).map((key) => {
                console.log(this.state.Teamviewer[key]);
                const newTableInfos = parseForTable(
                  { data: this.state.Teamviewer[key], columns: this.state.columns },
                  true
                );
                return (
                  <span key={key} className="col-4">
                    <BSTable
                      hideSearch
                      cud={{ c: this.handleCreateButton.bind(this, key), u: true, d: true }}
                      title={(() => {
                        return (
                          <span>
                            <img
                              style={{ height: 20, width: 20 }}
                              src="/assets/dist/img/TeamViewer_Logo_Icon_Only.svg.png"
                            ></img>
                            &nbsp;{key}
                          </span>
                        );
                      })()}
                      columns={newTableInfos.columns}
                      data={newTableInfos.data}
                      hideHeaders={true}
                      postCallback={this.postCallback.bind(this)}
                      deleteCallback={this.deleteCallback.bind(this)}
                      //updateFromProps
                      theme="light"
                      defaultSorted={[{ dataField: "type", order: "asc" }]}
                    ></BSTable>
                  </span>
                );
              })}
            </div>
          </Card>
        ) : null}
        {this.state.hasVPN ? (
          <div>
            {this.state.VPN.map((info) => {
              return (
                <Card
                  key={info.id}
                  title={info.type + " " + info.host}
                  tools={renderEditButton(info.id, info.type, info.host)}
                >
                  {this.state.edit ? (
                    <RichText
                      title={Translator("Edit VPN informations")}
                      defaultValue={info.value}
                      developped
                      onChange={this.onChange.bind(this)}
                    ></RichText>
                  ) : (
                    <div dangerouslySetInnerHTML={renderHtml(info.value)}></div>
                  )}
                </Card>
              );
            })}
          </div>
        ) : null}
      </div>
    );
  }
}

export default withRouter(CustomerRemote);
