import React from "react";

const isLoading = (isLoading) => {
  return isLoading ? (
    <div className="overlay">
      <i className="fas fa-2x fa-sync-alt fa-spin"></i>
    </div>
  ) : null;
};

export default isLoading;
