import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { store, Translator, parseForTable, computeWorkTime } from "../../tools/Tools";
import Card from "../../components/Card";
import moment from "moment";
import BSTable from "../../components/BSTable";
import {
  fetchUsers,
  fetchUserEvents,
  postEvent,
  fetchPendingUserEvents,
  deleteEvent,
  acceptEvent,
} from "../../tools/Api_Customers";
import Select from "react-select";
import HeuresCard from "../Heures/HeuresCard";

class Vacances extends Component {
  constructor(props) {
    super(props);
    const month = { value: moment().month(), label: moment().format("MMMM") };
    this.canUpdate = true;
    this.state = {
      totalEventCount: 0,
      request: {
        type: "off",
        fullDay: true,
        start: new Date(),
        end: new Date(),
      },
      month: month,
      headers: [],
      usersRows: [],
      headers1: [],
      usersRows1: [],
      headers2: [],
      usersRows2: [],
      pendingEvents: [],
      upcomingEvents: [],
      users: [],
      tabs: [
        {
          value: "my holidays",
          active: true,
          accreditation: 3,
          desc: Translator("my holidays"),
        },
        {
          value: "admin",
          active: false,
          accreditation: 1,
          desc: Translator("admin holidays"),
        },
      ],
      userTabs: [],
      eventTypes: [
        {
          value: "holiday",
          active: false,
          accreditation: 4,
          desc: Translator("holiday"),
        },
        {
          value: "medical off",
          active: false,
          accreditation: 4,
          desc: Translator("medical off"),
        },
        {
          value: "off",
          active: false,
          accreditation: 4,
          desc: Translator("off"),
        },
        {
          value: "unpaid",
          active: false,
          accreditation: 4,
          desc: Translator("unpaid holiday"),
        },
        {
          value: "recup national holiday",
          active: false,
          accreditation: 4,
          desc: Translator("recup national holiday"),
        },
      ],
    };
  }

  componentDidMount() {
    this.reload();
  }
  componentWillUnmount() {
    this.canUpdate = false;
  }

  reload() {
    this.setState({ isFetching: true });
    fetchUsers().then((users) => {
      let userTabs = [];
      let userState = [];
      let upcomingEvents = [];
      let count = 0;
      Promise.all(
        users.data.map((user) => {
          if (user.accreditation !== 99) {
            userState.push(user);
            if (
              store().getUser().userId == user.id ||
              this.state.tabs.find((tab) => tab.active && tab.value === "admin")
            ) {
              user.holidaysCount = 0;
              //console.log("Computing work time for "+ user.shortName);
              return computeWorkTime(user.id).then((resultWorkTime) => {
                let fullTotal = 0;
                Object.keys(resultWorkTime.workTimeMonths).map((month) => {
                  fullTotal += resultWorkTime.workTimeMonths[month];
                });
                user.total = fullTotal;
              });
            }
          }
        })
      ).then(() => {
        let ids = store().getUser().userId;
        if (this.state.tabs.find((tab) => tab.active && tab.value === "admin")) {
          users.data.map((user) => (ids = ids + "," + user.id));
        }
        fetchPendingUserEvents(ids).then((events) => {
          if (userTabs.length > 0) userTabs[0].active = true;
          Promise.all(
            userState.map((user) => {
              //console.log("Fetching events for "+ user.shortName);
              return fetchPendingUserEvents(user.id).then((fetchedPendingEvents) => {
                const pendingEvents = fetchedPendingEvents.map((pEvent) => {
                  pEvent.isRequest = true;
                  return pEvent;
                });
                return fetchUserEvents(user.id).then((confirmedEvents) => {
                  const prom = new Promise((resolve, reject) => {
                    if (pendingEvents.length > 0) {
                      const arr = [...pendingEvents, ...confirmedEvents];
                      //console.log(user.shortName, arr);
                      resolve([...pendingEvents, ...confirmedEvents]);
                    } else {
                      //console.log(user.shortName, confirmedEvents);
                      resolve(confirmedEvents);
                    }
                  });
                  return prom;
                });
              });
            })
          ).then((values) => {
            const filteredValues = values.map((value) => {
              count = count + value.length;
              return value.filter((event) => {
                if (
                  [
                    "off",
                    "holiday",
                    "medical off",
                    "recup national holiday",
                    "unpaid",
                    "national holiday",
                    "technical off",
                    "small unemployement",
                  ].includes(event.Event.type)
                ) {
                  //if (!event.Event.isRequest) {
                  //GATHER FUTURE ABSENCES
                  if (event.UserId === store().getUser().userId && moment(event.from).isAfter(moment())) {
                    upcomingEvents.push(event);
                  }
                  //COUNT REMAINING HOLIDAYS TO TAKE
                  if (event.Event.type === "holiday" && moment(event.from).isSame(moment(), "year")) {
                    const index = userState.findIndex((user) => user.id === event.UserId);
                    userState[index].holidaysCount = userState[index].holidaysCount + 1;
                  }
                  return true;
                  //}
                }
              });
            });
            for (let i = 0; i < userState.length; i++) {
              userState[i].events = filteredValues[i];
            }
            if (this.canUpdate) {
              this.setState({
                ...this.state,
                totalEventCount: count,
                userTabs: userTabs,
                users: userState,
                pendingEvents: events,
                upcomingEvents: upcomingEvents,
                isFetching: false,
                request: {
                  type: "off",
                  fullDay: true,
                  start: moment().format("DD/MM/YYYY HH:mm"),
                  end: moment().format("DD/MM/YYYY HH:mm"),
                },
                selectedRow: null,
              });
            } else {
              console.log("do not update because component is unmounted");
            }
          });
        });
      });
    });
  }
  componentDidUpdate(prevProps, prevState) {
    moment.locale(store().getLanguage());
    if (
      prevState.headers.length === 0 ||
      prevState.month.value != this.state.month.value ||
      prevState.users.length !== this.state.users.length ||
      prevState.totalEventCount != this.state.totalEventCount
    ) {
      let headers = [];
      let headers1 = [];
      let headers2 = [];
      let usersRows = [];
      let usersRows1 = [];
      let usersRows2 = [];

      let monthStart1 = moment().month(this.state.month.value).add(1, "month").startOf("month");
      monthStart1.add(3, "hour");
      const monthEnd1 = moment().month(this.state.month.value).add(1, "month").endOf("month");
      headers1 = [
        {
          th: (
            <th className="p-1 text-center" style={{ backgroundColor: "lightgray" }} key={"users"}>
              {monthStart1.format("MMMM")}
            </th>
          ),
        },
      ];

      while (monthStart1.isBefore(monthEnd1)) {
        const toReturn = {
          th: (
            <th className="p-0 text-center" style={{ backgroundColor: "lightgray" }} key={monthStart1.dayOfYear()}>
              <small>{monthStart1.format("DD")}</small>
            </th>
          ),
        };
        monthStart1.add(1, "day");
        //if (monthStart.isBefore(monthEnd)){
        headers1.push(toReturn);
        //}
      }
      monthStart1 = moment().month(this.state.month.value).add(1, "month").startOf("month");
      usersRows1 = this.state.users.map((user) => {
        let events = [];
        if (user.events) {
          events = user.events;
        }
        //events = [...events,this.state.pendingEvents.filter(uEvent=>uEvent.userId===user.id)];
        //console.log(user.shortName, events);
        //console.log(user.shortName, this.state.pendingEvents);
        return [
          <td className="p-0 text-center" style={{ backgroundColor: "lightgray" }} events={events} key={user.id}>
            <small>{user.shortName}</small>
          </td>,
        ];
      });
      while (monthStart1.isBefore(monthEnd1)) {
        usersRows1.map((row) => {
          let tdColor = "";
          if (monthStart1.day() === 0 || monthStart1.day() === 6) tdColor = "gray";
          let rowContent = "";
          if (row[0].props.events) {
            if (row[0].props.events.length > 0) {
              row[0].props.events.map((event) => {
                if (event.from) {
                  if (moment(event.from).isSame(monthStart1, "day")) {
                    tdColor = event.isRequest ? "tomato" : "green";
                    rowContent = event.isRequest ? "?" : "";
                  }
                }
              });
            }
          }
          row.push(
            <td className="p-0 text-center" style={{ backgroundColor: tdColor }} key={row[0] + monthStart1.dayOfYear()}>
              {rowContent}
            </td>
          );
        });
        monthStart1.add(1, "day");
      }

      let monthStart = moment().month(this.state.month.value).startOf("month");
      monthStart.add(3, "hour");
      const monthEnd = moment().month(this.state.month.value).endOf("month");
      headers = [
        {
          th: (
            <th className="p-1 text-center" style={{ backgroundColor: "lightgray" }} key={"users"}>
              {monthStart.format("MMMM")}
            </th>
          ),
        },
      ];

      while (monthStart.isBefore(monthEnd)) {
        const toReturn = {
          th: (
            <th className="p-0 text-center" style={{ backgroundColor: "lightgray" }} key={monthStart.dayOfYear()}>
              <small>{monthStart.format("DD")}</small>
            </th>
          ),
        };
        monthStart.add(1, "day");
        //if (monthStart1.isBefore(monthEnd1)){
        headers.push(toReturn);
        //}
      }
      monthStart = moment().month(this.state.month.value).startOf("month");
      usersRows = this.state.users.map((user) => {
        let events = [];
        if (user.events) {
          events = user.events;
        }
        return [
          <td className="p-0 text-center" style={{ backgroundColor: "lightgray" }} events={events} key={user.id}>
            {user.shortName}
          </td>,
        ];
      });

      while (monthStart.isBefore(monthEnd)) {
        usersRows.map((row) => {
          let tdColor = "";
          if (monthStart.day() === 0 || monthStart.day() === 6) tdColor = "gray";
          let rowContent = "";
          if (row[0].props.events) {
            if (row[0].props.events.length > 0) {
              row[0].props.events.map((event) => {
                if (event.from) {
                  if (moment(event.from).isSame(monthStart, "day")) {
                    tdColor = event.isRequest ? "tomato" : "green";
                    rowContent = event.isRequest ? "?" : "";
                  }
                }
              });
            }
          }
          row.push(
            <td className="p-0 text-center" style={{ backgroundColor: tdColor }} key={row[0] + monthStart.dayOfYear()}>
              {rowContent}
            </td>
          );
        });
        monthStart.add(1, "day");
      }

      let monthStart2 = moment().month(this.state.month.value).add(2, "month").startOf("month");
      monthStart2.add(3, "hour");
      const monthEnd2 = moment().month(this.state.month.value).add(2, "month").endOf("month");
      headers2 = [
        {
          th: (
            <th className="p-1 text-center" style={{ backgroundColor: "lightgray" }} key={"users"}>
              {monthStart2.format("MMMM")}
            </th>
          ),
        },
      ];

      while (monthStart2.isBefore(monthEnd2)) {
        const toReturn = {
          th: (
            <th className="p-0 text-center" style={{ backgroundColor: "lightgray" }} key={monthStart2.dayOfYear()}>
              <small>{monthStart2.format("DD")}</small>
            </th>
          ),
        };
        monthStart2.add(1, "day");
        //if (monthStart2.isBefore(monthEnd2)){
        headers2.push(toReturn);
        //}
      }
      monthStart2 = moment().month(this.state.month.value).add(2, "month").startOf("month");
      usersRows2 = this.state.users.map((user) => {
        let events = [];
        if (user.events) {
          events = user.events;
        }
        return [
          <td className="p-0 text-center" style={{ backgroundColor: "lightgray" }} events={events} key={user.id}>
            {user.shortName}
          </td>,
        ];
      });

      while (monthStart2.isBefore(monthEnd2)) {
        usersRows2.map((row) => {
          let tdColor = "";
          if (monthStart2.day() === 0 || monthStart2.day() === 6) tdColor = "gray";
          let rowContent = "";
          if (row[0].props.events) {
            if (row[0].props.events.length > 0) {
              row[0].props.events.map((event) => {
                if (event.from) {
                  if (moment(event.from).isSame(monthStart2, "day")) {
                    tdColor = event.isRequest ? "tomato" : "green";
                    rowContent = event.isRequest ? "?" : "";
                  }
                }
              });
            }
          }
          row.push(
            <td className="p-0 text-center" style={{ backgroundColor: tdColor }} key={row[0] + monthStart2.dayOfYear()}>
              {rowContent}
            </td>
          );
        });
        monthStart2.add(1, "day");
      }

      this.setState({
        headers: headers,
        usersRows: usersRows,
        headers1: headers1,
        usersRows1: usersRows1,
        headers2: headers2,
        usersRows2: usersRows2,
      });
    }
  }

  postEvent(event) {
    this.setState({ isFetching: true });
    postEvent(event, true).then((result) => {
      this.reload();
    });
  }
  acceptEvent(eventId) {
    this.setState({ isFetching: true });
    acceptEvent(eventId).then((result) => {
      this.reload();
    });
  }
  deleteEvent(eventsIds) {
    this.setState({ isFetching: true });
    deleteEvent(eventsIds).then((result) => {
      this.reload();
    });
  }
  renderHolidaysPage(accreditation) {
    if (accreditation > 1) {
      return (
        <div>
          <h1>Demande de congés</h1>
        </div>
      );
    } else {
      return <h1>Validation des congés</h1>;
    }
  }

  generateTabs() {
    return (
      <ul className="nav nav-tabs">
        {this.state.tabs.map((tab) => {
          if (store().getUser().accreditation <= tab.accreditation) {
            return (
              <li
                key={tab.value}
                className={tab.active ? "nav-link active" : "nav-link"}
                onClick={this.handleClickTab.bind(this, tab)}
              >
                {tab.desc}
              </li>
            );
          }
        })}
      </ul>
    );
  }
  generateTabRelatedContent() {
    switch (this.state.tabs.find((tab) => tab.active).value) {
      case "my holidays":
        return <div className="col">{this.renderMyHolidays()}</div>;
        break;
      case "admin":
        return <div className="col">{this.renderAdminHolidays()}</div>;
        break;
      default:
        return null;
    }
  }

  renderAdminHolidays() {
    let delta = 1;
    if (this.state.selectedRow) {
      const from = moment(this.state.selectedRow.from, "DD/MM/YYYY HH:mm");
      const to = moment(this.state.selectedRow.to, "DD/MM/YYYY HH:mm");
      delta = to.diff(from, "days") + 1;
    }

    return (
      <div>
        <div className="row">
          <div className="col">
            <Card type="info" title={Translator("Remaining days to take in ") + moment().format("YYYY")}>
              <div className="row">
                {this.state.users.map((user) => {
                  if (user.holidaysMax > 0) {
                    return (
                      <div key={user.id} className="col-3">
                        <Card title={user.shortName} isFetching={this.state.isFetching}>
                          {this.renderRemaining(user.holidaysCount, user.holidaysMax)}
                          <div className="mt-3 pb-3 mb-3 d-flex">
                            <div className="image">
                              <img
                                src={"/assets/dist/img/" + user.shortName + "160x160.jpg"}
                                className="img-circle elevation-2"
                                alt="User"
                                style={{ width: "4rem" }}
                              />
                            </div>
                          </div>
                          <HeuresCard user={user} total={user.total} small={true} withInitialCredit></HeuresCard>
                        </Card>
                      </div>
                    );
                  }
                })}
              </div>
            </Card>
          </div>
        </div>
        <div className="row">
          <div className="col-9">{this.renderPending(this.state.pendingEvents)}</div>
          <div className="col-3">
            <Card title={Translator("selected")}>
              {this.state.selectedRow ? (
                <div>
                  <dl>
                    <h4>{Translator(this.state.selectedRow.name)}</h4>
                    <dt>{Translator("request type")}</dt>
                    <dd>{Translator(this.state.selectedRow.type)}</dd>
                    <dt>{Translator("from")}</dt>
                    <dd>{this.state.selectedRow.from}</dd>
                    <dt>{Translator("to")}</dt>
                    <dd>{this.state.selectedRow.to}</dd>
                    <dd>{delta + " " + Translator("days")}</dd>
                  </dl>
                  <div className="row">
                    <button
                      className="btn btn-success col"
                      onClick={(e) => {
                        e.preventDefault();
                        this.acceptEvent(this.state.selectedRow.id);
                      }}
                    >
                      {Translator("accept")}
                    </button>
                    <button
                      className="btn btn-danger col"
                      onClick={(e) => {
                        e.preventDefault();
                        this.deleteEvent(this.state.selectedRow.id);
                      }}
                    >
                      {Translator("refuse")}
                    </button>
                  </div>
                </div>
              ) : (
                "nothing selected"
              )}
            </Card>
          </div>
        </div>
      </div>
    );
  }
  renderRemaining(count, max) {
    return (
      <div className="text-center">
        {max > 0 ? (
          <h1>
            {max - count}/{max}
          </h1>
        ) : (
          <h1>{Translator("n/a")}</h1>
        )}
      </div>
    );
  }
  renderPending() {
    let data = [
      { id: "1", type: "OFF", from: "123", to: "123" },
      { id: "2", type: "holidays", from: "123", to: "123" },
      { id: "3", type: "OFF", from: "123", to: "123" },
    ];
    let columns = [];
    if (this.state.pendingEvents) {
      data = [];
      this.state.pendingEvents.map((userEvent) => {
        if (userEvent.Event) {
          if (!data.find((data) => data.id === userEvent.Event.id)) {
            const usr = this.state.users.find((user) => user.id === userEvent.UserId);
            data.push({
              id: userEvent.Event.id,
              type: userEvent.Event.type,
              from: moment(userEvent.from).format("DD/MM/YYYY HH:mm"),
              to: moment(userEvent.to).format("DD/MM/YYYY HH:mm"),
              name: usr.firstName + " " + usr.lastName,
              days: 1,
            });
          } else {
            const index = data.findIndex((data) => data.id === userEvent.Event.id);
            console.log("adding 1 to days count");
            data[index].days = data[index].days + 1;
            if (moment(userEvent.from).isBefore(moment(data[index].from, "DD/MM/YYYY HH:mm"))) {
              data[index].from = moment(userEvent.from).format("DD/MM/YYYY HH:mm");
            }
            if (moment(userEvent.to).isAfter(moment(data[index].to, "DD/MM/YYYY HH:mm"))) {
              data[index].to = moment(userEvent.to).format("DD/MM/YYYY HH:mm");
            }
          }
        }
      });
      if (data.length > 0) {
        columns = [
          { dataField: "id", text: "id" },
          { dataField: "type", text: "type" },
          { dataField: "from", text: "from" },
          { dataField: "to", text: "to" },
          { dataField: "days", text: "days" },
        ];
        if (this.state.tabs.find((tab) => tab.active && tab.value === "admin")) {
          columns.push({ dataField: "name", text: "name" });
        }
      }
    }
    const datas = { data: data, columns: columns };
    const parsedDatas = parseForTable(datas);
    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        this.setState({ ...this.state, selectedRow: row });
      },
    };
    return (
      <div className="text-center">
        <BSTable
          theme="warning"
          isFetching={this.state.isFetching}
          title={Translator("Pending holidays request")}
          data={parsedDatas.data}
          columns={parsedDatas.columns}
          hideSearch={true}
          cud={{ c: false, u: false, d: true }}
          updateFromProps
          deleteCallback={this.deleteEvent.bind(this)}
          rowEvents={rowEvents}
        ></BSTable>
      </div>
    );
  }
  renderUpcoming() {
    let data = [
      { id: "1", type: "OFF", from: "123", to: "123" },
      { id: "2", type: "holidays", from: "123", to: "123" },
      { id: "3", type: "OFF", from: "123", to: "123" },
    ];
    let columns = [];
    if (this.state.upcomingEvents) {
      data = [];
      this.state.upcomingEvents.map((userEvent) => {
        if (userEvent.Event) {
          if (!data.find((data) => data.id === userEvent.Event.id)) {
            const usr = this.state.users.find((user) => user.id === userEvent.UserId);
            data.push({
              id: userEvent.Event.id,
              type: userEvent.Event.type,
              from: moment(userEvent.from).format("DD/MM/YYYY HH:mm"),
              to: moment(userEvent.to).format("DD/MM/YYYY HH:mm"),
              name: usr.firstName + " " + usr.lastName,
              days: 1,
            });
          } else {
            const index = data.findIndex((data) => data.id === userEvent.Event.id);
            data[index].days = data[index].days + 1;
            if (moment(userEvent.from).isBefore(moment(data[index].from, "DD/MM/YYYY HH:mm"))) {
              data[index].from = moment(userEvent.from).format("DD/MM/YYYY HH:mm");
            }
            if (moment(userEvent.to).isAfter(moment(data[index].to, "DD/MM/YYYY HH:mm"))) {
              data[index].to = moment(userEvent.to).format("DD/MM/YYYY HH:mm");
            }
          }
        }
      });
      if (data.length > 0) {
        columns = [
          { dataField: "id", text: "id" },
          { dataField: "type", text: "type" },
          {
            dataField: "from",
            text: "from",
            sortFunc: (a, b, order, dataField, rowA, rowB) => {
              console.log("sorting");
              if (order === "asc") {
                if (moment(a.from).isBefore(moment(b.from))) return -1;
                if (moment(a.to).isAfter(moment(b.to))) return 1;
                return 0;
              }
              if (moment(a.from).isBefore(moment(b.from))) return 1;
              if (moment(a.to).isAfter(moment(b.to))) return -1;
              return 0;
            },
          },
          {
            dataField: "to",
            text: "to",
          },
          { dataField: "days", text: "days" },
        ];
      }
      if (this.state.tabs.find((tab) => tab.active && tab.value === "admin")) {
        columns.push({ dataField: "name", text: "name" });
      }
    }
    const datas = { data: data, columns: columns };
    const parsedDatas = parseForTable(datas);

    return (
      <div className="text-center">
        <BSTable
          theme="navy"
          isFetching={this.state.isFetching}
          title={Translator("Upcoming holidays")}
          data={parsedDatas.data}
          columns={parsedDatas.columns}
          hideSearch={true}
          defaultSorted={[{ dataField: "from", order: "asc" }]}
        ></BSTable>
      </div>
    );
  }
  renderPostEventForm() {
    const styles = {
      container: (base) => ({
        ...base,
        flex: 4,
      }),
    };
    return (
      <form className="form-inline">
        <div className="container-fluid">
          {this.state.isFetching ? null : (
            <div>
              <div className="row">
                <div className="input-group mb-2 mr-sm-2 col-sm-12">
                  <Select
                    styles={styles}
                    noOptionsMessage={() => {
                      return <label>{Translator("none")}</label>;
                    }}
                    defaultValue={{ value: "off", label: Translator("off") }}
                    onChange={(selectedOption) => {
                      this.setState({ request: { ...this.state.request, type: selectedOption.value } });
                    }}
                    options={[
                      { value: "off", label: Translator("off") },
                      { value: "holiday", label: Translator("holiday") + " - " + Translator("ignore WE") },
                      { value: "unpaid", label: Translator("unpaid holiday") + " - " + Translator("ignore WE") },
                      { value: "medical off", label: Translator("medical off") + " - " + Translator("ignore WE") },
                      { value: "technical off", label: Translator("technical off") + " - " + Translator("ignore WE") },
                      { value: "recup national holiday", label: Translator("recup national holiday") },
                      { value: "small unemployement", label: Translator("small unemployement") },
                    ]}
                  ></Select>
                </div>
              </div>
              <div className="row">
                <div className="input-group mb-2 mr-sm-2 col">
                  <div className="input-group-prepend">
                    <div className="input-group-text">
                      <span>{Translator("period")}</span>
                    </div>
                  </div>
                  <input
                    type="date"
                    className="form-control"
                    value={moment(this.state.request.start, "DD-MM-YYYY HH:mm").format("YYYY-MM-DD")}
                    //max={moment(this.state.request.end, "DD-MM-YYYY HH:mm").format("YYYY-MM-DD")}
                    placeholder={Translator("start date")}
                    onChange={(e) => {
                      //console.log(this.state.request.start);
                      //console.log(moment(this.state.request.start, "DD-MM-YYYY HH:mm").format("YYYY-MM-DD"));
                      //console.log(e.target.valueAsDate);
                      if (
                        moment(e.target.valueAsDate, "DD-MM-YYYY HH:mm").isAfter(
                          moment(this.state.request.end, "DD-MM-YYYY HH:mm")
                        )
                      ) {
                        this.setState({
                          request: { ...this.state.request, start: e.target.valueAsDate, end: e.target.valueAsDate },
                        });
                      } else {
                        this.setState({ request: { ...this.state.request, start: e.target.valueAsDate } });
                      }
                    }}
                  ></input>
                  <input
                    type="date"
                    className="form-control"
                    value={moment(this.state.request.end, "DD-MM-YYYY HH:mm").format("YYYY-MM-DD")}
                    placeholder={Translator("end date")}
                    min={moment(this.state.request.start, "DD-MM-YYYY HH:mm").format("YYYY-MM-DD")}
                    onChange={(e) => {
                      console.log(e.target.valueAsDate);

                      //if (moment(e.target.valueAsDate).isBefore(moment(this.state.request.start))) {
                      //this.setState({
                      //  request: { ...this.state.request, start: e.target.valueAsDate, end: e.target.valueAsDate },
                      //});
                      //} else {
                      //  if(moment(e.target.valueAsDate, "DD-MM-YYYY HH:mm").isBefore(moment(this.state.request.start, "DD-MM-YYYY HH:mm"))){
                      //this.setState({request: { ...this.state.request, start: e.target.valueAsDate, end: e.target.valueAsDate }});
                      //}else{
                      this.setState({ request: { ...this.state.request, end: e.target.valueAsDate } });
                      //}
                      //}
                    }}
                  ></input>
                </div>
              </div>
              <div className="row">
                <div className="input-group mb-2 mr-sm-2 col">
                  <div className="input-group-prepend">
                    <div className="input-group-text">
                      <span>{Translator("Horaires")}</span>
                    </div>
                  </div>
                  <input
                    type="time"
                    disabled={this.state.request.fullDay}
                    defaultValue={"09:00"}
                    className="form-control"
                    placeholder={Translator("start time")}
                    onChange={(e) => {
                      this.setState({ request: { ...this.state.request, startTime: e.target.value } });
                    }}
                  ></input>
                  <input
                    type="time"
                    disabled={this.state.request.fullDay}
                    defaultValue={"17:00"}
                    className="form-control"
                    placeholder={Translator("end time")}
                    onChange={(e) => {
                      this.setState({ request: { ...this.state.request, endTime: e.target.value } });
                    }}
                  ></input>
                </div>
              </div>
              <div className="row">
                <div key="fullDay" className="input-group mb-2 mr-md-2 col" onChange={() => {}}>
                  <div className="input-group-prepend">
                    <div className="input-group-text">
                      <input
                        type="checkbox"
                        defaultChecked={true}
                        onChange={(e) => {
                          this.setState({ request: { ...this.state.request, fullDay: !this.state.request.fullDay } });
                        }}
                      ></input>
                    </div>
                  </div>
                  <label type="text" className="form-control text-truncate">
                    {Translator("Full Day")}
                  </label>
                </div>
                <div className="row">
                  <div className="input-group mb-2 mr-md-2 col">
                    <button
                      className="btn btn-default"
                      onClick={(e) => {
                        e.preventDefault();
                        let payload = {
                          isRequest: true,
                          break: "00:00",
                          eventType: this.state.request.type,
                          fullDay: this.state.request.fullDay,
                          users: [store().getUser().userId],
                        };
                        let start = moment(this.state.request.start, "DD/MM/YYYY HH:mm").hour(9).minutes(0);
                        let end = moment(this.state.request.end, "DD/MM/YYYY HH:mm").hour(17).minutes(0);
                        if (this.state.request.startTime) {
                          const tmpStartTime = moment(this.state.request.startTime, "HH:mm");
                          start.hour(tmpStartTime.hour()).minute(tmpStartTime.minute());
                        }
                        if (this.state.request.endTime) {
                          const tmpEndTime = moment(this.state.request.endTime, "HH:mm");
                          end.hour(tmpEndTime.hour()).minute(tmpEndTime.minute());
                        }
                        payload.start = start.format("DD/MM/YYYY HH:mm");
                        payload.end = end.format("DD/MM/YYYY HH:mm");
                        console.log(payload);
                        this.postEvent(payload);
                      }}
                    >
                      {Translator("send")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </form>
    );
  }
  renderMyHolidays() {
    const user = this.state.users.find((user) => user.id === store().getUser().userId);
    const total = user ? user.total : 0;
    return (
      <div>
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <Card title={Translator("Leave request")} isFetching={this.state.isFetching}>
                {this.renderPostEventForm()}
              </Card>
            </div>
            <div className="col">
              <Card type="info" title={Translator("Remaining days to take in ") + moment().format("YYYY")}>
                {this.renderRemaining(
                  this.state.users.find((user) => user.id === store().getUser().userId)
                    ? this.state.users.find((user) => user.id === store().getUser().userId).holidaysCount
                    : 20,
                  store().getUser().holidaysMax
                )}
              </Card>
            </div>
            <div className="col">
              {user ? <HeuresCard user={user} total={total} withInitialCredit></HeuresCard> : null}
            </div>
          </div>
          <div className="row">
            <div className="col">{this.renderPending(this.state.pendingEvents)}</div>
            <div className="col">{this.renderUpcoming()}</div>
          </div>
        </div>
      </div>
    );
  }
  handleClickTab(tab) {
    const newTabs = this.state.tabs.map((stateTab) => {
      stateTab.active = stateTab.value === tab.value;
      return stateTab;
    });
    if (tab.value === "my hours") {
      this.computeWorkTime(store().getUser().userId);
    } else {
      this.reload();
      //reload requests for all users
    }
    this.setState({
      tabs: newTabs,
    });
  }
  renderMonthSelect() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        borderBottom: "1px dotted pink",
        width: state.selectProps.width,
        color: state.isSelected ? "white" : "black",
      }),
      singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = "opacity 300ms";

        return { ...provided, opacity, transition };
      },
    };
    return (
      <div className="row">
        <div className="col">{Translator("Holidays overview")}</div>
        <div className="col">
          <div style={{ minWidth: "160px" }}>
            <Select
              styles={customStyles}
              noOptionsMessage={() => {
                return <label>{Translator("none")}</label>;
              }}
              value={this.state.month}
              onChange={(selectedOption) => {
                this.setState({ month: selectedOption });
              }}
              options={[
                { value: 0, label: moment().month(0).format("MMMM") },
                { value: 1, label: moment().month(1).format("MMMM") },
                { value: 2, label: moment().month(2).format("MMMM") },
                { value: 3, label: moment().month(3).format("MMMM") },
                { value: 4, label: moment().month(4).format("MMMM") },
                { value: 5, label: moment().month(5).format("MMMM") },
                { value: 6, label: moment().month(6).format("MMMM") },
                { value: 7, label: moment().month(7).format("MMMM") },
                { value: 8, label: moment().month(8).format("MMMM") },
                { value: 9, label: moment().month(9).format("MMMM") },
                { value: 10, label: moment().month(10).format("MMMM") },
                { value: 11, label: moment().month(11).format("MMMM") },
              ]}
            />
          </div>
        </div>
      </div>
    );
  }
  render3MonthsOverview() {
    return (
      <div className="table-responsive">
        <table className="table table-bordered">
          <thead>
            <tr>{this.state.headers.map((t) => t.th)}</tr>
          </thead>
          <tbody>
            {this.state.usersRows.map((row) => {
              return <tr key={row[0].key}>{row.map((td) => td)}</tr>;
            })}
          </tbody>
        </table>
        <hr></hr>
        <table className="table table-bordered">
          <thead>
            <tr>{this.state.headers1.map((t) => t.th)}</tr>
          </thead>
          <tbody>
            {this.state.usersRows1.map((row) => {
              return <tr key={row[0].key}>{row.map((td) => td)}</tr>;
            })}
          </tbody>
        </table>
        <hr></hr>
        <table className="table table-bordered">
          <thead>
            <tr>{this.state.headers2.map((t) => t.th)}</tr>
          </thead>
          <tbody>
            {this.state.usersRows2.map((row) => {
              return <tr key={row[0].key}>{row.map((td) => td)}</tr>;
            })}
          </tbody>
        </table>
      </div>
    );
  }
  render() {
    return (
      <div>
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0 text-dark">{Translator("Vacances")}</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to={{ pathname: "../", state: this.props.history.state }}>{Translator("Home")}</Link>
                  </li>
                  <li className="breadcrumb-item active">{Translator("Vacances")}</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="container-fluid">
            <Card title={this.generateTabs()}>
              <div className="row">{this.generateTabRelatedContent()}</div>
            </Card>
            <Card type="olive" title={this.renderMonthSelect()} isFetching={this.state.isFetching}>
              {this.render3MonthsOverview()}
            </Card>
          </div>
        </section>
      </div>
    );
  }
}

export default withRouter(Vacances);
