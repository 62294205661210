import React, { Component } from "react";
import Todo from "./Todo";
import Card from "../../components/Card";
import GeneralInfos from "../../components/GeneralInfos";
import { fetchCustomers, fetchUsers } from "../../tools/Api_Customers";
import { Translator, store, MomentDateString } from "../../tools/Tools";
import Select from "react-select";
import moment from "moment";

class TodoList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      todos: [],
      users: [],
      customers: [],
      generalInfos: {},
      selectedTask: {},
      currentCustomer: this.props.customer,
      currentUser: this.props.user,
      filter: "",
      pendingOnly: true,
      dateSort: false,
      statusSort: false,
      showInfo: true,
      isLoading: true,
    };
    this.fetchTodos = props.fetchTodos;
  }

  componentDidMount() {
    this.reload();
  }
  reload(createdTodoId) {
    this.fetchTodos(this.props.customer || this.props.user).then((todos) => {
      fetchCustomers().then((customers) => {
        fetchUsers().then((users) => {
          let resCustomers = customers.data;
          console.log(customers)
          if (this.props.customer) {
            resCustomers = customers.data.filter((customer) => {
              return customer.id === this.props.customer;
            });
          }
          const todoA = todos.map((todo) => {
            const author = users.data.find((user) => user.id === todo.authorId);
            const responsible = users.data.find((user) => user.id === todo.responsibleId);
            const closer = users.data.find((user) => user.id === todo.closerId);
            const customer = resCustomers.find((customer) => customer.id === todo.CustomerId);

            todo.author = { id: author.id, label: author.shortName };
            todo.responsible = { id: responsible.id, label: responsible.shortName };
            todo.Customer = { id: customer.id, label: customer.name };
            if (closer) todo.closer = { id: closer.id, label: closer.shortName };
            return todo;
          });
          const selectedTodo = todoA.find((todo) => todo.id === createdTodoId);

          if (createdTodoId) {
            this.setState({
              users: users.data,
              customers: resCustomers,
              todos: todoA,
              selectedTask: selectedTodo,
              newTask: false,
              isLoading: false,
            });
          } else {
            this.setState({ users: users.data, customers: resCustomers, todos: todoA, isLoading: false });
          }
        });
      });
    });
  }
  renderButtons() {
    const task = {
      authorId: store().getUser().userId,
      task: "",
      description: "",
      status: 0,
      Customer: this.state.currentCustomer ? this.state.currentCustomer : "",
      responsible: "",
      deadline: "",
    };
    return (
      <div>
        <button
          type="button"
          disabled={this.state.newTask}
          className="btn btn-secondary btn"
          data-toggle="modal"
          data-target="#modal-confirm"
          data-keyboard="false"
          onClick={(e) => this.selectTask(task)}
        >
          {Translator("add")}
        </button>
      </div>
    );
  }
  selectTask(task) {
    if (task.id) {
      this.setState({ selectedTask: task, newTask: false });
    } else {
      this.setState({ selectedTask: task, newTask: true });
    }
  }

  renderCustomerFilter() {
    if (!this.state.currentCustomer) {
      const customersOptionsTable = this.state.customers.map((customer) => {
        return { value: customer.id, label: customer.name };
      });
      return (
        <div className="col">
          <div className="row">
            <Select
              className="col"
              isClearable
              noOptionsMessage={() => {
                return <label>{Translator("none")}</label>;
              }}
              placeholder={Translator("customer")}
              onChange={(selectedOption) => {
                this.setState({ customerFilter: selectedOption });
              }}
              options={customersOptionsTable}
            />
          </div>
        </div>
      );
    }
  }
  renderReponsibleFilter() {
    if (!this.state.currentUser) {
      const usersOptionsTable = this.state.users.map((user) => {
        return { value: user.id, label: user.shortName };
      });
      return (
        <div className="col">
          <div className="input-group mb-2 mr-2">
            <Select
              className="col"
              isClearable
              noOptionsMessage={() => {
                return <label>{Translator("none")}</label>;
              }}
              placeholder={Translator("responsible")}
              onChange={(selectedOption) => {
                this.setState({ responsibleFilter: selectedOption });
              }}
              options={usersOptionsTable}
              value={this.state.responsibleFilter}
            />
          </div>
        </div>
      );
    }
  }
  handleKeyDown = (e) => {
    if (e.key === "Escape") {
      this.setState({ filter: "" });
    }
  };
  renderTextFilter() {
    return (
      <div className="input-group mb-2 mr-2 col">
        <input
          type="text"
          className="form-control col"
          value={this.state.filter}
          placeholder={Translator("search")}
          onKeyDown={this.handleKeyDown}
          onChange={(e) => this.setState({ filter: e.target.value })}
        ></input>
        <div className="input-group-append">
          <button
            className="btn btn-default"
            onClick={(e) => {
              e.preventDefault();
              this.setState({ filter: "" });
            }}
          >
            <span style={{ color: "Tomato" }}>
              <i className="fas fa-times"></i>
            </span>
          </button>
        </div>
      </div>
    );
  }
  renderPendingOnly() {
    return (
      <div className="input-group mb-2 mr-2 col">
        <div className="input-group-prepend">
          <div className="input-group-text">
            <input
              type="checkbox"
              checked={this.state.pendingOnly}
              onChange={() => {
                this.setState({ pendingOnly: !this.state.pendingOnly });
              }}
            ></input>
          </div>
        </div>
        <label type="text" className="form-control text-truncate">
          {Translator("pending only")}
        </label>
      </div>
    );
  }
  renderFilters() {
    return (
      <div className="row">
        {this.renderTextFilter()}
        {this.renderCustomerFilter()}
        {this.renderReponsibleFilter()}
        {this.renderPendingOnly()}
      </div>
    );
  }

  toggleDateSort() {
    const compare = (a, b) => {
      if (moment(a.createdAt).isBefore(moment(b.createdAt))) return this.state.dateSort ? -1 : 1;
      if (moment(a.createdAt).isAfter(moment(b.createdAt))) return this.state.dateSort ? 1 : -1;
      return 0;
    };

    let sortedTodos = this.state.todos;
    sortedTodos.sort(compare);
    this.setState({ todos: sortedTodos, dateSort: !this.state.dateSort });
  }
  toggleStatusSort() {
    const compare = (a, b) => {
      if (moment(a.status).isBefore(moment(b.status))) return this.state.statusSort ? -1 : 1;
      if (moment(a.status).isAfter(moment(b.status))) return this.state.statusSort ? 1 : -1;
      return 0;
    };

    let sortedTodos = this.state.todos;
    sortedTodos.sort(compare);
    this.setState({ todos: sortedTodos, statusSort: !this.state.statusSort });
  }

  render() {
    const userOptions = this.state.users.filter((user) => {
      if (this.props.user) {
        return user.id === this.props.user;
      } else {
        return true;
      }
    });
    const columns = {};
    const datas = this.state.todos;
    return (
      <div>
        <Card header={this.renderFilters()} tools={this.renderButtons()} isFetching={this.state.isLoading}>
          <div className="row">
            <div
              className={`table-responsive col-md${this.state.showInfo && this.state.selectedTask.authorId ? "-2" : "-11"
                }`}
            >
              <table className="table table-bordered">
                <thead>
                  <tr className="bg-gradient-light">
                    <th onClick={this.toggleDateSort.bind(this)}>
                      {Translator("createdAt")}
                      &nbsp;<i className={this.state.dateSort ? "fas fa-sort-up" : "fas fa-sort-down"}></i>
                    </th>
                    <th>{Translator("customer")}</th>
                    <th>{Translator("createdBy")}</th>
                    <th>{Translator("responsible")}</th>
                    <th>{Translator("task")}</th>
                    <th>{Translator("deadline")}</th>
                    <th onClick={this.toggleStatusSort.bind(this)}>
                      {Translator("status")}
                      &nbsp;<i className={this.state.statusSort ? "fas fa-sort-up" : "fas fa-sort-down"}></i>
                    </th>
                    <th>{Translator("dateOfCompletion")}</th>
                    <th>{Translator("closer")}</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.todos.map((todo) => {
                    const filter = this.state.filter
                      .toUpperCase()
                      .normalize("NFD")
                      .replace(/[\u0300-\u036f]/g, "");
                    const customerFilter = this.state.customerFilter;
                    const responsibleFilter = this.state.responsibleFilter;
                    let filtOk = true;
                    let custOk = true;
                    let respOk = true;
                    let finishedOk = true;
                    if (customerFilter) {
                      if (todo.Customer.label !== customerFilter.label) {
                        custOk = false;
                      }
                    }
                    if (responsibleFilter) {
                      if (todo.responsible.label !== responsibleFilter.label) {
                        respOk = false;
                      }
                    }
                    if (filter) {
                      if (
                        !(
                          (todo.task
                            ? todo.task
                              .toUpperCase()
                              .normalize("NFD")
                              .replace(/[\u0300-\u036f]/g, "")
                              .includes(filter)
                            : false) ||
                          (todo.description
                            ? todo.description
                              .toUpperCase()
                              .normalize("NFD")
                              .replace(/[\u0300-\u036f]/g, "")
                              .includes(filter)
                            : false) ||
                          (todo.Customer
                            ? todo.Customer.label
                              .toUpperCase()
                              .normalize("NFD")
                              .replace(/[\u0300-\u036f]/g, "")
                              .includes(filter)
                            : false) ||
                          (todo.responsible
                            ? todo.responsible.label
                              .toUpperCase()
                              .normalize("NFD")
                              .replace(/[\u0300-\u036f]/g, "")
                              .includes(filter)
                            : false) ||
                          (todo.author
                            ? todo.author.label
                              .toUpperCase()
                              .normalize("NFD")
                              .replace(/[\u0300-\u036f]/g, "")
                              .includes(filter)
                            : false) ||
                          (todo.deadline
                            ? MomentDateString(todo.deadline)
                              .toUpperCase()
                              .normalize("NFD")
                              .replace(/[\u0300-\u036f]/g, "")
                              .includes(filter)
                            : false) ||
                          (todo.createdAt
                            ? MomentDateString(todo.createdAt)
                              .toUpperCase()
                              .normalize("NFD")
                              .replace(/[\u0300-\u036f]/g, "")
                              .includes(filter)
                            : false) ||
                          (todo.closerId
                            ? todo.closer.label
                              .toUpperCase()
                              .normalize("NFD")
                              .replace(/[\u0300-\u036f]/g, "")
                              .includes(filter)
                            : false) ||
                          (todo.dateOfCompletion
                            ? MomentDateString(todo.dateOfCompletion)
                              .toUpperCase()
                              .normalize("NFD")
                              .replace(/[\u0300-\u036f]/g, "")
                              .includes(filter)
                            : false)
                        )
                      ) {
                        filtOk = false;
                      }
                    }
                    if (this.state.pendingOnly && todo.status === 100) {
                      finishedOk = false;
                    }
                    if (custOk && respOk && filtOk && finishedOk) {
                      return (
                        <Todo
                          onSelect={this.selectTask.bind(this)}
                          selected={this.state.selectedTask.id === todo.id}
                          key={todo.id}
                          todo={todo}
                          users={this.state.users}
                          customers={this.state.customers}
                        ></Todo>
                      );
                    }
                  })}
                </tbody>
              </table>
            </div>
            <div className={`col-md-1`}>
              <button
                className="btn btn-default btn-block h-100"
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({ showInfo: !this.state.showInfo });
                }}
              >
                <i className={this.state.showInfo ? "fas fa-caret-right" : "fas fa-caret-left"}></i>
              </button>
            </div>
            <div className={`col-md${this.state.showInfo && this.state.selectedTask.authorId ? "-9" : ""}`}>
              <div style={{ top: "4em", position: "sticky" }}>
                {this.state.showInfo && this.state.selectedTask.authorId ? (
                  <GeneralInfos
                    generalInfos={this.state.selectedTask}
                    infoEdit={this.state.selectedTask ? (this.state.selectedTask.CustomerId ? false : true) : false}
                    currentCustomer={this.state.currentCustomer}
                    customers={this.state.customers}
                    users={userOptions}
                    infoType="todo"
                    parentReload={this.reload.bind(this)}
                  ></GeneralInfos>
                ) : null}
              </div>
            </div>
          </div>
        </Card>
      </div>
    );
  }
}

export default TodoList;
