import React, { useState, useEffect, useRef, Component, FC } from "react";
import { withRouter } from "react-router-dom";
import Card from "../../components/Card";
import { store, Translator } from "../../tools/Tools";
import { useDrag } from "react-dnd";
import moment from "moment";

export const TicketWidget = (props) => {
  const [state, setstate] = useState({ collapsed: props.collapsed ? props.collapsed : false });
  const DropResult = { status: "" };
  const [{ isDragging }, drag] = useDrag(() => {
    return {
      item: props.ticket,
      type: props.ticket.assignee ? "assignedTicketWidget" : "unassignedTicketWidget",
      end: (item, monitor) => {
        const dropResult = monitor.getDropResult();
        if (item && dropResult) {
          props.changeStatus(props.ticket, dropResult.status);
          if (dropResult.status !== "active" && props.ticket.running) {
            props.pauseTicket(props.ticket.id, store().getUser().userId);
          }
          //alert(`You dropped ${item.number} into ${dropResult.name}!`);
        }
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
        handlerId: monitor.getHandlerId(),
      }),
    };
  });

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const prevProps = usePrevious(props);

  useEffect(() => {
    if (prevProps) {
      if (props.collapsed !== prevProps.collapsed) {
        setstate({ collapsed: props.collapsed });
      }
    }
  });
  const opacity = isDragging ? 0.4 : 1;

  const translatePriority = (priority) => {
    switch (priority) {
      case "yellow":
      case "low_priority":
        return "warning";
      case "orange":
      case "priority":
        return "orange";
      case "red":
      case "asap":
        return "danger";
    }
  };
  const renderHtml = (html) => {
    return { __html: html };
  };

  const renderContractOrRequester = () => {
    switch (props.ticket.type) {
      case "customer":
        return (
          <div className="row justify-content-between my-n2">
            <div className="col-4">
              {Translator("Contract")} : {Translator(props.ticket.contract)}
            </div>
            <div className="col-8">
              <div className="row justify-content-end">
                <div className="col-10" style={{ textAlign: "right" }}>
                  {props.ticket.contact}
                </div>
                <div className="col-1" style={{ textAlign: "right" }}>
                  <i className="fas fa-at"></i>
                </div>
              </div>
            </div>
          </div>
        );
      case "internal":
        return (
          <div className="row justify-content-between my-n2">
            <div className="col-4">
              {Translator("Requester")} : {props.ticket.author.firstName + " " + props.ticket.author.lastName}
            </div>
            <div className="col-8">
              <div className="row justify-content-end">
                <div className="col-10" style={{ textAlign: "right" }}>
                  {props.ticket.contact}
                </div>
                <div className="col-1" style={{ textAlign: "right" }}>
                  <i className="fas fa-at"></i>
                </div>
              </div>
            </div>
          </div>
        );
    }
  };
  return (
    <div ref={drag} className={`col-${props.colSize()}`} key={props.ticket.id}>
      <Card
        title={
          <div
            onClick={(e) => {
              e.preventDefault();
              if (state.collapsed === true) {
                setstate({ collapsed: false });
              } else {
                setstate({ collapsed: true });
              }
            }}
          >
            {props.ticket.number +
              " / " +
              props.ticket.customer.name +
              (props.ticket.site !== "no specific site*" ? ": " + props.ticket.site : " ")}
          </div>
        }
        type={translatePriority(props.ticket.priority)}
        collapsed={state.collapsed === true}
        tools={
          props.ticket.assignee ? (
            props.ticket.assignee === store().getUser().shortName || store().getUser().accreditation < 3 ? (
              <button
                className={`btn btn-block btn-${translatePriority(props.ticket.priority)}`}
                onClick={(e) => {
                  e.preventDefault();
                  props.releaseTicket(props.ticket.id);
                }}
                rel="tooltip"
                data-html="true"
                data-placement="bottom"
                title={Translator("release this ticket")}
              >
                {props.ticket.assignee}
                <img
                  src={`/assets/dist/img/${props.ticket.assignee}160x160.jpg`}
                  className="img-circle ml-1"
                  alt="User"
                  style={{ width: "1rem" }}
                />
              </button>
            ) : (
              <div>
                {props.ticket.assignee}
                <img
                  src={`/assets/dist/img/${props.ticket.assignee}160x160.jpg`}
                  className="img-circle ml-1"
                  alt="User"
                  style={{ width: "1rem" }}
                />
              </div>
            )
          ) : (
            <button
              className={`btn btn-block btn-${translatePriority(props.ticket.priority)}`}
              onClick={(e) => {
                e.preventDefault();
                props.handleTicket(props.ticket.id, store().getUser().userId);
              }}
              rel="tooltip"
              data-html="true"
              data-placement="bottom"
              title={Translator("handle this ticket")}
            >
              {" "}
              <i className="far fa-hand-paper"></i>
            </button>
          )
        }
      >
        <div className="container-fluid">
          {renderContractOrRequester()}
          <hr></hr>
          <div className="row text-truncate" style={{ maxHeight: "150px", maxWidth: "460px" }}>
            <div className="col-12" dangerouslySetInnerHTML={renderHtml(props.ticket.description)}></div>
          </div>
          <hr></hr>
          <div className="row justify-content-between my-n2">
            <div className="col-2">
              <button
                className="btn btn-block btn-default"
                onClick={(e) => {
                  e.preventDefault();
                  props.openDetails(props.ticket);
                }}
                rel="tooltip"
                data-html="true"
                data-placement="bottom"
                title={Translator("view ticket details")}
                style={{ minWidth: "45px" }}
              >
                <i className="far fa-eye"></i>
              </button>
            </div>
            <div className="col-2">
              {!props.ticket.running ? (
                <button
                  className="btn btn-block btn-default"
                  onClick={(e) => {
                    e.preventDefault();
                    props.handleTicket(props.ticket.id, store().getUser().userId);
                    if (props.ticket.status !== "active") props.changeStatus(props.ticket, "active");
                  }}
                  rel="tooltip"
                  data-html="true"
                  data-placement="bottom"
                  title={Translator("resume")}
                  style={{ minWidth: "45px" }}
                >
                  <i className="fas fa-play"></i>{" "}
                </button>
              ) : (
                <button
                  className="btn btn-block btn-default text-center"
                  onClick={(e) => {
                    e.preventDefault();
                    props.pauseTicket(props.ticket.id, store().getUser().userId);
                  }}
                  rel="tooltip"
                  data-html="true"
                  data-placement="bottom"
                  title={Translator("pause")}
                  style={{ minWidth: "65px" }}
                >
                  <span className="fa-stack col my-n1">
                    <i className="fas fa-pause fa-stack-1x"></i>
                    <i className="fas fa-circle-notch fa-spin fa-stack-2x"></i>
                  </span>
                </button>
              )}
            </div>

            <div className="col-2">
              {props.ticket.assignee ? (
                <button
                  className="btn btn-block btn-default"
                  onClick={(e) => {
                    e.preventDefault();
                    props.closeTicket(props.ticket, store().getUser().userId);
                  }}
                  rel="tooltip"
                  data-html="true"
                  data-placement="bottom"
                  title={Translator("close this ticket")}
                  style={{ minWidth: "45px" }}
                >
                  <i className="far fa-hand-rock"></i>
                </button>
              ) : null}
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};
export default withRouter(TicketWidget);
