import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Translator, parseForTable } from "../../tools/Tools";
import Card from "../../components/Card";
import BSTable from "../../components/BSTable";
import moment from "moment";
import Select, { components } from "react-select";
import { fetchInvoices } from "../../tools/Api_Customers";

export class Invoices extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filterText: "",
      yearFilter: { value: moment().year(), label: moment().year() },
      yearOptionTable: [],
      invoices: [{}],
    };
  }

  componentDidMount() {
    this.loadDatas();
  }
  loadDatas() {
    fetchInvoices(this.state.yearFilter.value).then((fetchedInvoices) => {
      const invoices = fetchedInvoices.map((invoice) => {
        invoice.customer = invoice.Customer.name;
        console.log(invoice.paid);
        delete invoice.Customer;
        const paid = (
          <div>
            <div>{Translator(invoice.paid)}</div>
            <button className="btn btn-default" onClick={(e) => console.log(invoice.number)}>
              test
            </button>
          </div>
        );
        delete invoice.paid;
        invoice.paid = paid;

        return invoice;
      });
      this.setState({ invoices: invoices });
    });
  }
  renderCardToolsButton() {
    return (
      <div className="btn-group col  mb-2 mt-1">
        <button className="btn btn-default" onClick={(e) => e.preventDefault()}>
          {Translator("new invoice")}
        </button>
        <button className="btn btn-default" onClick={(e) => e.preventDefault()}>
          {Translator("new proforma")}
        </button>
      </div>
    );
  }
  renderYearFilter = () => {
    const DropdownIndicator = (props) => {
      return (
        components.DropdownIndicator && (
          <components.DropdownIndicator {...props}>
            <i className="fas fa-caret-down"></i>
          </components.DropdownIndicator>
        )
      );
    };
    return (
      <Select
        className="col  mb-2 mt-1 "
        components={{ DropdownIndicator }}
        options={this.state.yearOptionTable}
        isClearable
        placeholder={this.state.yearFilter.label}
        value={this.state.yearFilter}
        onChange={(selectedYear) => {
          this.setState({
            yearFilter: selectedYear ? selectedYear : { value: "all", label: Translator("all") },
          });
          this.reloadTickets();
        }}
      />
    );
  };
  renderPaidInvoicesFilter() {
    return (
      <div
        className="input-group col mb-2 mt-1"
        style={{ minWidth: "120px" }}
        onClick={() => {
          //this.setState({ logbookFilter: !this.state.logbookFilter });
        }}
      >
        <div className="input-group-prepend">
          <div className="input-group-text">
            <input type="checkbox" checked={false} onChange={(e) => {}}></input>
          </div>
        </div>
        <label type="text" className="form-control text-truncate">
          {Translator("paid")}
        </label>
      </div>
    );
  }
  renderUnremindedInvoicesFilter() {
    return (
      <div
        className="input-group col mb-2 mt-1"
        style={{ minWidth: "180px" }}
        onClick={() => {
          //this.setState({ logbookFilter: !this.state.logbookFilter });
        }}
      >
        <div className="input-group-prepend">
          <div className="input-group-text">
            <input type="checkbox" checked={false} onChange={(e) => {}}></input>
          </div>
        </div>
        <label type="text" className="form-control text-truncate">
          {Translator("unreminded")}
        </label>
      </div>
    );
  }
  renderFilters() {
    return (
      <div className="row">
        {this.renderPaidInvoicesFilter()}
        {this.renderUnremindedInvoicesFilter()}
        {this.renderYearFilter()}
      </div>
    );
  }
  renderTools() {
    return <div className="row">{this.renderCardToolsButton()}</div>;
  }
  render() {
    const datas = this.state.invoices[0]
      ? parseForTable({
          data: this.state.invoices,
          columns: Object.keys(this.state.invoices[0]).map((key) => {
            return { dataField: key, text: key };
          }),
        })
      : { data: [], columns: [] };
    return (
      <div>
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0 text-dark">
                  <i className="nav-icon far fa-money-bill-alt"></i>
                  &nbsp;
                  {Translator("Invoices")}
                </h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to={{ pathname: "../", state: this.props.history.state }}>{Translator("Home")}</Link>
                  </li>
                  <li className="breadcrumb-item active">{Translator("Invoices")}</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="container-fluid">
            <Card title={this.renderFilters()} tools={this.renderTools()}>
              <div className="row">
                <div className="col">
                  <BSTable title={Translator("invoices")} data={datas.data} columns={datas.columns}></BSTable>
                </div>
                <div className="col">
                  <Card title={Translator("proforma")}></Card>
                </div>
              </div>
            </Card>
          </div>
        </section>
      </div>
    );
  }
}

export default Invoices;
