import React, { useEffect, useState } from "react";
import { filterOptions, store, Translator } from "../../tools/Tools";
import Select from "react-select";
import Creatable from "react-select/creatable";
import RichText from "../../components/RichText";
import Card from "../../components/Card";
import BSTable from "../../components/BSTable";
import { set } from "date-fns";
import { render } from "react-dom";
import { el } from "date-fns/locale";
import moment from "moment/moment";
import $ from "jquery";
import toastr from "toastr";

const ChecklistInstanceForm = (props) => {
    const [attendees, setAttendees] = useState(null)
    const [checkListInstancePayload, setChecklistInstancePayload] = useState(null)
    const [moduleList, setModuleList] = useState(null)
    const [checkForStoredDatas, setCheckForStoredDatas] = useState(false)
    const [showDebugPayload, setShowDebugPayload] = useState(false)
    const [triggerCollapse, setTriggerCollapse] = useState(false)
    const [pendingTodos, setPendingTodos] = useState([])
    const [inputWidth, setInputWidth] = useState(7)

    useEffect(() => {
        if (props.checklistInstance && JSON.stringify(props.checklistInstance.Attendees.map(attendee => { return { id: attendee.id, shortName: attendee.shortName } })) !== JSON.stringify(attendees) && attendees === null) {
            //console.log("use effect for props payload")
            setAttendees([...props.checklistInstance.Attendees])
        }
        if (props.checklistInstance && JSON.stringify(props.checklistInstance.ChecklistModuleInstances) !== JSON.stringify(moduleList) && moduleList === null) {
            //console.log("use effect for props payload")
            setModuleList([...props.checklistInstance.ChecklistModuleInstances])
        }
        if (props.checklistInstance && JSON.stringify(props.checklistInstance) !== JSON.stringify(checkListInstancePayload) && checkListInstancePayload === null) {
            //console.log("use effect for props payload")
            setChecklistInstancePayload({ ...props.checklistInstance })
        }
        if (props.pendingTodos && JSON.stringify(props.pendingTodos) !== JSON.stringify(pendingTodos)) {
            setPendingTodos(props.pendingTodos)
        }


    })

    const saveToLocalStorage = () => {
        setCheckForStoredDatas(true)
        let toSavePayload = checkListInstancePayload
        toSavePayload.updatedAt = moment();
        localStorage.setItem(props.checklistInstance.id, JSON.stringify(toSavePayload))
    }

    const deleteLocalStorage = () => {
        localStorage.removeItem(props.checklistInstance.id)
    }

    const resetForm = () => {
        props.cancelChecklistInstance();
        setAttendees(null)
        setChecklistInstancePayload(null)
        setModuleList(null)
    }

    const renderLocalStorageStatus = () => {
        if (!checkForStoredDatas) {
            const storedPayload = JSON.parse(localStorage.getItem(props.checklistInstance.id))
            if (storedPayload) {
                if (storedPayload.updatedAt > props.checklistInstance.updatedAt) {
                    return <div>
                        <div className="card-body">
                            <div className="alert alert-warning alert-dismissible">
                                <h5><i className="icon fas fa-exclamation-triangle"></i>{Translator("Important : Auto Save")}</h5>
                                {Translator("The auto-saved datas on your device are more recent than datas from the server, do you wish to reload these datas ? If you modify anything here, the autosave will be override")}
                                <button className="btn btn-block btn-outline-danger"
                                    onClick={e => {
                                        setChecklistInstancePayload(storedPayload)
                                        setModuleList(storedPayload.ChecklistModuleInstances)
                                        setAttendees(storedPayload.Attendees)
                                        deleteLocalStorage()
                                        setCheckForStoredDatas(true)
                                    }}>Reload from cookie</button>
                            </div>
                        </div>
                    </div>
                }
            }
        }
    }
    const renderChecklist = () => {
        return <div style={{ pointerEvents: props.checklistInstance.completed ? 'none' : 'auto' }}>
            <h1>{props.checklistInstance.CustomerChecklistModel.name} - {props.customer.name}</h1>
            {props.checklistInstance.name ? <h2>{props.checklistInstance.name}</h2> : null}
            <div className="row">
                <div className="col-sm-4"></div>
                {props.checklistInstance.completed ? <div className="col-sm-4">
                    <button className="btn btn-block btn-outline-danger disabled">{Translator("closed")}</button>
                </div> : null}
                <div className="col-sm-4"></div>
            </div>
            <hr></hr>
            <h3>{Translator("users on this checklist")}</h3>
            <form className="form-inline col-sm-12">
                <span className="input-group mb-2 mr-2 row">
                    <i className="fas fa-users fa-2x mx-2 pt-1"></i>
                    {renderUsersCheckBox()}
                </span>
            </form>
            {renderLocalStorageStatus()}
            <hr></hr>
            {pendingTodos.length > 0 ? <div>
                <h3>{Translator("Pending todos for this customer")}</h3>

                {Translator("Existing following pending todos for this customer, please check todolist if needed.")} <br></br><ul>
                    {pendingTodos.map(todo => {
                        return (<li key={todo.id}><b>{todo.status}%</b>&nbsp;{todo.task}</li>)
                    })}</ul></div> : ""}
            <hr></hr>
            {renderModules()}
            <hr></hr>
            <button className="btn bg-gradient-secondary" onClick={e => {
                setShowDebugPayload(!showDebugPayload)
            }}>Debug</button>
            {showDebugPayload ? <div><h2>debug payload preview</h2>
                {JSON.stringify(checkListInstancePayload)}</div> : null}
        </div>
    }

    const renderModules = () => {

        return (<div>
            {(moduleList || []).sort((a, b) => {
                if (a.sortingOrder > b.sortingOrder)
                    return 1
                if (a.sortingOrder < b.sortingOrder)
                    return -1
                return 0
            }).map(moduleInstance => {
                console.log(moduleInstance)
                return (<div key={moduleInstance.id}>
                    <h4 onClick={(e) => {
                        moduleInstance.collapsed = !moduleInstance.collapsed
                        setTriggerCollapse(!triggerCollapse)
                    }}>{moduleInstance.collapsed ? "+  " + moduleInstance.name : "-  " + moduleInstance.name}</h4>
                    {!moduleInstance.collapsed ? moduleInstance.CustomerChecklistTaskInstances.sort((a, b) => {
                        if (a.sortingOrder > b.sortingOrder)
                            return 1
                        if (a.sortingOrder < b.sortingOrder)
                            return -1
                        return 0
                    }).map(task => {
                        return (
                            <div key={task.id} id={moduleInstance.id}>
                                <div className="row my-1">
                                    <div className={"input-group col-xl-5 col-md-12"} >
                                        <div className="input-group-prepend" onClick={(e) => {
                                            let module = moduleInstance
                                            module.CustomerChecklistTaskInstances = module.CustomerChecklistTaskInstances.map(aTask => {
                                                if (aTask.id === task.id) {
                                                    aTask.complete = !aTask.complete
                                                    if (aTask.complete) {
                                                        aTask.completion = 100
                                                    } else {
                                                        aTask.completion = 0
                                                    }
                                                }
                                                return aTask;
                                            })
                                            let tmpModuleList = moduleList.map(aModule => {
                                                if (aModule.id === module.id) {
                                                    aModule.CustomerChecklistTaskInstances = module.CustomerChecklistTaskInstances
                                                }
                                                return aModule;
                                            })
                                            setModuleList(tmpModuleList)
                                            saveToLocalStorage()
                                        }}>
                                            <div className="input-group-text">
                                                <input type="checkbox"
                                                    checked={task.complete}
                                                    onChange={(e => { })}
                                                ></input>
                                            </div>
                                        </div>
                                        <input type="text" className="form-control text-truncate mr-n1" style={{ maxWidth: "75px" }} value={task.completion}
                                            onChange={(e) => {
                                                const numValue = e.target.value || 0
                                                //console.log(numValue, /^[0-9]*$/.test(numValue))
                                                if (/^[0-9]*$/.test(numValue)) {
                                                    let module = moduleInstance
                                                    module.CustomerChecklistTaskInstances = module.CustomerChecklistTaskInstances.map(aTask => {
                                                        if (aTask.id === task.id) {
                                                            aTask.completion = Math.min(numValue, 100)
                                                            aTask.complete = numValue > 99
                                                        }
                                                        return aTask;
                                                    })
                                                    let tmpModuleList = moduleList.map(aModule => {
                                                        if (aModule.id === module.id) {
                                                            aModule.CustomerChecklistTaskInstances = module.CustomerChecklistTaskInstances
                                                        }
                                                        return aModule;
                                                    })
                                                    setModuleList(tmpModuleList)
                                                    saveToLocalStorage()
                                                }

                                            }}
                                        ></input>
                                        <span type="text" className="form-control text-truncate"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                toastr.info(Translator(task.taskHowTo), Translator("How to"),
                                                    {
                                                        positionClass: "toast-top-full-width",
                                                        closeButton: true,
                                                        timeOut: 10000,
                                                        extendedTimeOut: 10000,
                                                        tapToDismiss: true,
                                                        enableHtml: true
                                                    });

                                            }}
                                        >{Translator(task.taskText)}</span>
                                    </div>

                                    <div className={"col-xl-7 col-md-12 row"} >
                                        {task.ChecklistTask.hasCustomAnswers ?
                                            <Select
                                                className={"col-xl-5 col-md-12"}
                                                options={task.ChecklistTask.ChecklistTaskAnswers.map(answer => {
                                                    return { value: answer.name, label: Translator(answer.name), sortingOrder: answer.sortingOrder }
                                                }).sort((a, b) => {
                                                    if (a.sortingOrder > b.sortingOrder)
                                                        return 1
                                                    if (a.sortingOrder < b.sortingOrder)
                                                        return -1
                                                    return 0
                                                })
                                                }
                                                value={task.ChecklistTask.ChecklistTaskAnswers.map(answer => {
                                                    return { value: answer.name, label: Translator(answer.name), sortingOrder: answer.sortingOrder }
                                                }).find(option => option.value === task.answer)}
                                                onChange={(selectedOption) => {
                                                    let module = moduleInstance
                                                    module.CustomerChecklistTaskInstances = module.CustomerChecklistTaskInstances.map(aTask => {
                                                        if (aTask.id === task.id) {
                                                            aTask.answer = selectedOption.value
                                                        }
                                                        return aTask;
                                                    })
                                                    let tmpModuleList = moduleList.map(aModule => {
                                                        if (aModule.id === module.id) {
                                                            aModule.CustomerChecklistTaskInstances = module.CustomerChecklistTaskInstances
                                                        }
                                                        return aModule;
                                                    })
                                                    setModuleList(tmpModuleList)
                                                    saveToLocalStorage()
                                                }}
                                                menuPortalTarget={document.body}
                                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                            >
                                            </Select> :
                                            <div className={"col-xl-5 col-md-12"}>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    style={{ width: "100%" }}
                                                    placeholder={Translator("free answer...")}
                                                    value={task.answer || ''}
                                                    onChange={(e) => {
                                                        let module = moduleInstance
                                                        module.CustomerChecklistTaskInstances = module.CustomerChecklistTaskInstances.map(aTask => {
                                                            if (aTask.id === task.id) {
                                                                aTask.answer = e.target.value
                                                            }
                                                            return aTask;
                                                        })
                                                        let tmpModuleList = moduleList.map(aModule => {
                                                            if (aModule.id === module.id) {
                                                                aModule.CustomerChecklistTaskInstances = module.CustomerChecklistTaskInstances
                                                            }
                                                            return aModule;
                                                        })
                                                        setModuleList(tmpModuleList)
                                                        saveToLocalStorage()
                                                    }}

                                                ></input>
                                            </div>
                                        }
                                        <div className={"col-xl-7 col-md-12 row"}>
                                            <input
                                                type="text"
                                                className={"form-control col-xl-6 col-md-7"}
                                                placeholder={Translator("...Comment")}
                                                value={task.comment || ''}
                                                onChange={(e) => {
                                                    let module = moduleInstance
                                                    module.CustomerChecklistTaskInstances = module.CustomerChecklistTaskInstances.map(aTask => {
                                                        if (aTask.id === task.id) {
                                                            aTask.comment = e.target.value
                                                        }
                                                        return aTask;
                                                    })
                                                    let tmpModuleList = moduleList.map(aModule => {
                                                        if (aModule.id === module.id) {
                                                            aModule.CustomerChecklistTaskInstances = module.CustomerChecklistTaskInstances
                                                        }
                                                        return aModule;
                                                    })
                                                    setModuleList(tmpModuleList)
                                                    saveToLocalStorage()
                                                }}
                                            ></input>
                                            <span className="col-xl-1 mx-1 col-md-2" onClick={e => {
                                                let module = moduleInstance
                                                module.CustomerChecklistTaskInstances = module.CustomerChecklistTaskInstances.map(aTask => {
                                                    if (aTask.id === task.id) {
                                                        aTask.markedForTodo = !aTask.markedForTodo
                                                    }
                                                    return aTask;
                                                })
                                                let tmpModuleList = moduleList.map(aModule => {
                                                    if (aModule.id === module.id) {
                                                        aModule.CustomerChecklistTaskInstances = module.CustomerChecklistTaskInstances
                                                    }
                                                    return aModule;
                                                })
                                                setModuleList(tmpModuleList)
                                                saveToLocalStorage()
                                            }}>
                                                <i className="fa-solid fa-circle-check fa-border" style={{
                                                    "--fa-border-padding": "0.5em",
                                                    "--fa-border-radius": "100%",
                                                    "border": task.markedForTodo ? `solid` : ``,
                                                    "borderColor": task.markedForTodo ? "lightgreen" : ``
                                                }}></i>
                                            </span>
                                            <span className="col-xl-1 mx-1 col-md-2" onClick={e => {
                                                let module = moduleInstance
                                                module.CustomerChecklistTaskInstances = module.CustomerChecklistTaskInstances.map(aTask => {
                                                    if (aTask.id === task.id) {
                                                        aTask.markedForLogbook = !aTask.markedForLogbook
                                                    }
                                                    return aTask;
                                                })
                                                let tmpModuleList = moduleList.map(aModule => {
                                                    if (aModule.id === module.id) {
                                                        aModule.CustomerChecklistTaskInstances = module.CustomerChecklistTaskInstances
                                                    }
                                                    return aModule;
                                                })
                                                setModuleList(tmpModuleList)
                                                saveToLocalStorage()
                                            }}>
                                                <i className="fa-solid fa-book fa-border"
                                                    style={{
                                                        "--fa-border-padding": "0.5em",
                                                        "--fa-border-radius": "100%",
                                                        "border": task.markedForLogbook ? `solid` : ``,
                                                        "borderColor": task.markedForLogbook ? `lightseagreen` : ``
                                                    }}></i>
                                            </span>
                                            <Select
                                                className={"col-xl-3 ml-1 col-md-12"}
                                                options={attendees ? attendees.map(attendee => {
                                                    return {
                                                        value: attendee.id, label: props.users.find(user => user.id === attendee.id).shortName
                                                    }
                                                }) : []}
                                                value={{ value: task.UserId, label: (props.users.find(user => user.id === task.UserId) || { shortName: "" }).shortName }}
                                                onChange={(selectedOption) => {
                                                    let module = moduleInstance
                                                    module.CustomerChecklistTaskInstances = module.CustomerChecklistTaskInstances.map(aTask => {
                                                        if (aTask.id === task.id) {
                                                            aTask.UserId = selectedOption.value
                                                        }
                                                        return aTask;
                                                    })
                                                    let tmpModuleList = moduleList.map(aModule => {
                                                        if (aModule.id === module.id) {
                                                            aModule.CustomerChecklistTaskInstances = module.CustomerChecklistTaskInstances
                                                        }
                                                        return aModule;
                                                    })
                                                    setModuleList(tmpModuleList)
                                                    saveToLocalStorage()

                                                }}
                                                menuPortalTarget={document.body}
                                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                            >
                                            </Select>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        )
                    }) : null}
                </div>
                )
            })
            }
        </div >
        )
    }
    const renderUsersCheckBox = () => {

        return props.users.map((user) => {
            return (
                <div key={user.id} className="input-group mb-2 mr-2"
                    onClick={(e) => {
                        if (attendees.find(attendee => attendee.id === user.id)) {
                            let tmpCheckListInstancePayload = checkListInstancePayload
                            tmpCheckListInstancePayload.Attendees = attendees.filter(el => el.id !== user.id)
                            setChecklistInstancePayload(tmpCheckListInstancePayload)
                            setAttendees(attendees.filter(el => el.id !== user.id))
                        } else {
                            let tmpCheckListInstancePayload = checkListInstancePayload
                            tmpCheckListInstancePayload.Attendees = [...attendees, user]
                            setChecklistInstancePayload(tmpCheckListInstancePayload)
                            setAttendees([...attendees, user])
                        }
                        saveToLocalStorage()
                    }} style={{ width: "auto" }}>
                    <div className="input-group-prepend">
                        <div className="input-group-text">
                            <input
                                type="checkbox"
                                checked={attendees ? (attendees.find(attendee => attendee.id === user.id) ? true : false) : false}
                                onChange={e => {

                                }}

                            ></input>
                        </div>
                    </div>
                    <span type="text" className="form-control text-truncate">
                        {user.shortName}
                    </span>
                </div>
            );
        });
    }
    //#region bottom buttons
    const renderModelModalFooterButtons = () => {
        //Display the save and complete button, to save the checklist instance
        const renderSaveButton = () => {
            return (
                <button
                    className="btn btn-primary btn mr-2 "
                    data-dismiss="modal"
                    onClick={(e) => {
                        e.preventDefault();
                        let tmpCheckListInstancePayload = checkListInstancePayload
                        tmpCheckListInstancePayload.Attendees = checkListInstancePayload.Attendees.map(attendee => attendee.id)
                        tmpCheckListInstancePayload.updatedAt = moment()
                        props.submitCallback(tmpCheckListInstancePayload).then(success => {
                            if (success) {
                                deleteLocalStorage();
                                resetForm();
                            }
                        });

                    }}
                    disabled={(checkListInstancePayload || { completed: false }).completed && store().getUser().accreditation > 2}
                >
                    {props.creatingMode ? Translator("save") : Translator("save")}
                </button>
            )
        }
        //Display the save and complete button, to save the checklist instance and mark it as completed
        const renderSaveAndCompleteButton = () => {
            return (
                <button
                    className="btn btn-warning btn  mr-2"
                    onClick={(e) => {
                        e.preventDefault();
                        const toastrText = (checkListInstancePayload || { completed: false }).completed ? "<br /><div>" + Translator("Are you sure you want to re-open this checklist ? Users will be able to edit again.") + "</div><br /><button type='button' id='confirmationButtonYes' class='btn btn-default mr-1'>" + Translator("Yes") + "</button> <button type='button' id='confirmationButtonCancel' class='btn btn-default mr-1'>" + Translator("Cancel") + "</button>" :
                            "<br /><div>" + Translator("Are you sure you want to finish this checklist ? Further modification won't be possible anymore unless an administrator re-open this checklist.") + "</div><br /><button type='button' id='confirmationButtonYes' class='btn btn-default mr-1'>" + Translator("Yes") + "</button> <button type='button' id='confirmationButtonCancel' class='btn btn-default mr-1'>" + Translator("Cancel") + "</button>"
                        toastr.info(toastrText, Translator("Confirmation"),
                            {
                                closeButton: false,
                                allowHtml: true,
                                timeOut: 0,
                                extendedTimeOut: 0,
                                tapToDismiss: false,
                                positionClass: "toast-top-center",
                                onShown: function (toast) {
                                    $("#confirmationButtonYes").on('click', (function () {
                                        let tmpCheckListInstancePayload = checkListInstancePayload
                                        tmpCheckListInstancePayload.Attendees = checkListInstancePayload.Attendees.map(attendee => attendee.id)
                                        tmpCheckListInstancePayload.updatedAt = moment()
                                        tmpCheckListInstancePayload.completed = !checkListInstancePayload.completed
                                        $('#modal-checklistInstance').modal('hide')
                                        toastr.clear();
                                        props.submitCallback(tmpCheckListInstancePayload).then(success => {
                                            //console.log("success !!", success)
                                            if (success) {
                                                deleteLocalStorage();
                                                resetForm();
                                                //props.goToLogbook(tmpCheckListInstancePayload)
                                            }
                                        });
                                    }));
                                    $("#confirmationButtonCancel").on('click', (function () {
                                        toastr.clear();
                                    }
                                    ))
                                }
                            });
                    }}
                    disabled={(checkListInstancePayload || { completed: false }).completed && store().getUser().accreditation > 2}
                >
                    {(checkListInstancePayload || { completed: false }).completed ? Translator("Re-open") : Translator("Save & Complete")}
                </button>)
        }
        //Display the "generate logbook" button for users with an accreditation smaller than 3 (IT & Admins)
        const renderLogbookButton = () => {
            if (store().getUser().accreditation < 3) {
                return (<button
                    className="btn btn-info btn mr-2"
                    data-dismiss="modal"
                    onClick={(e) => {
                        e.preventDefault();
                        let tmpCheckListInstancePayload = checkListInstancePayload
                        tmpCheckListInstancePayload.Attendees = checkListInstancePayload.Attendees.map(attendee => attendee.id)
                        tmpCheckListInstancePayload.updatedAt = moment()
                        resetForm();
                        setTimeout(() => { props.goToLogbook(tmpCheckListInstancePayload) }, 1500)

                    }}
                    disabled={(!(checkListInstancePayload || { completed: false }).completed) || (checkListInstancePayload || { LogbookId: false }).LogbookId}
                >
                    {(checkListInstancePayload || { LogbookId: false }).LogbookId ? Translator("existing logbook") : Translator("generate logbook")}
                </button>
                )
            } else {
                return null
            }
        }
        //Display the cancel button to close the form and discard unsaved modifications
        const renderCancelButton = () => {
            return (<button
                className="btn btn-info btn mr-2"
                data-dismiss="modal"
                onClick={(e) => {
                    e.preventDefault();
                    resetForm()
                }}
            >
                {Translator("Cancel")}
            </button>)
        }
        //Display the "Delete Checklist" button for users with an accreditation smaller than 3 (IT & Admins)
        const renderDeleteButton = () => {
            if (store().getUser().accreditation < 3) {
                return (<button
                    className="btn btn-danger btn  mr-2"
                    onClick={(e) => {
                        e.preventDefault();
                        const toastrText = "<br /><div>" + Translator("Are you sure you want to delete this checklist instance, you can't undo this.") + "</div><br /><button type='button' id='confirmationButtonYes' class='btn btn-default mr-1'>" + Translator("Yes") + "</button> <button type='button' id='confirmationButtonCancel' class='btn btn-default mr-1'>" + Translator("Cancel") + "</button>"
                        toastr.info(toastrText, Translator("Confirmation"),
                            {
                                closeButton: false,
                                allowHtml: true,
                                timeOut: 0,
                                extendedTimeOut: 0,
                                tapToDismiss: false,
                                positionClass: "toast-top-center",
                                onShown: function (toast) {
                                    $("#confirmationButtonYes").on('click', (function () {
                                        let tmpCheckListInstancePayload = checkListInstancePayload
                                        tmpCheckListInstancePayload.Attendees = checkListInstancePayload.Attendees.map(attendee => attendee.id)
                                        tmpCheckListInstancePayload.updatedAt = moment()
                                        tmpCheckListInstancePayload.completed = !checkListInstancePayload.completed
                                        $('#modal-checklistInstance').modal('hide')
                                        toastr.clear();
                                        props.deleteCallback(checkListInstancePayload.id).then(success => {
                                            if (success) {
                                                deleteLocalStorage();
                                                resetForm();
                                                //props.goToLogbook(tmpCheckListInstancePayload)
                                            }
                                        })
                                    }));
                                    $("#confirmationButtonCancel").on('click', (function () {
                                        toastr.clear();
                                    }
                                    ))
                                }
                            });
                    }}
                    disabled={store().getUser().accreditation > 2}
                >
                    {Translator("Delete this instance")}
                </button>)
            }
        }
        return (
            <form className="form-inline col-sm-12">
                <div className="input-group mb-2">
                    {renderSaveButton()}
                    {renderSaveAndCompleteButton()}
                    {renderLogbookButton()}
                    {renderCancelButton()}
                    {renderDeleteButton()}
                </div>
            </form >
        );

    }

    //#endregion
    return (
        <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title" id="staticBackdropLabel">
                    <i className="nav-icon fa-solid fa-clipboard-list mr-2"></i><i className="fa-solid fa-pencil mr-2"></i>{Translator("Checklist")}
                </h5>
                <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={(e) => {
                        e.preventDefault();
                        resetForm();
                    }}
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body" >
                <div className={"info-box slideInDown animated"}>
                    <div className="table-responsive">
                        <div className="info-box-content">
                            {props.checklistInstance ? renderChecklist() : null}
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal-footer">{renderModelModalFooterButtons()}</div>
        </div >
    );
};



export default ChecklistInstanceForm;
