import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import moment from "moment";
import { MomentDateString, MomentDateTimeString, store } from "../../tools/Tools";
import Card from "../../components/Card";
import { v4 as uuidv4 } from 'uuid';
import { Translator } from "../../tools/Tools";
import { DateRange } from "react-date-range";
import * as locales from "react-date-range/dist/locale";
import $ from "jquery";

import {
  fetchCustomers,
  fetchUsers,
  fetchEvents,
  postEvent,
  editEvent,
  editUserEvent,
  lockEvents,
  fetchCustomerConveyors,
  deleteUserEvent,
  deleteEvent,
} from "../../tools/Api_Customers";
import EventForm from "./EventForm";
import Weeks from "./Weeks";

class Agenda extends Component {
  constructor(props) {
    super(props);
    moment().isoWeekday(1);
    this.toggledColor = true;
    this.scrolled = false;
    let toDate = new Date();
    let fromDate = new Date();
    fromDate.setDate(fromDate.getDate() - 7);
    toDate.setDate(toDate.getDate() + 14);
    toDate = store().getCookies().get("from") ? new Date(store().getCookies().get("to")) : toDate;
    fromDate = store().getCookies().get("to") ? new Date(store().getCookies().get("from")) : fromDate;
    this.state = {
      agenda: [],
      customersOptionTable: [],
      siteOptionTable: [],
      from: null,
      to: null,
      users: [],
      customers: [],
      dateRange: [
        {
          startDate: fromDate,
          endDate: toDate,
          key: "selection",
        },
      ],
      dateRangeValidated: [
        {
          startDate: fromDate,
          endDate: toDate,
          key: "selection",
        },
      ],
      eventTypes: [
        {
          value: "planning",
          active: false,
          accreditation: 2,
          desc: Translator("planning"),
        },
        {
          value: "private",
          active: false,
          accreditation: 4,
          desc: Translator("private"),
        },
        {
          value: "customer",
          active: true,
          accreditation: 4,
          desc: Translator("customer"),
        },
        {
          value: "holiday",
          active: false,
          accreditation: 1,
          desc: Translator("holiday"),
        },
        {
          value: "medical off",
          active: false,
          accreditation: 1,
          desc: Translator("medical off"),
        },
        {
          value: "off",
          active: false,
          accreditation: 1,
          desc: Translator("off"),
        },
        {
          value: "technical off",
          active: false,
          accreditation: 1,
          desc: Translator("technical off"),
        },
        {
          value: "unpaid",
          active: false,
          accreditation: 1,
          desc: Translator("unpaid holiday"),
        },
        {
          value: "national holiday",
          active: false,
          accreditation: 1,
          desc: Translator("national holiday"),
        },
        {
          value: "recup national holiday",
          active: false,
          accreditation: 1,
          desc: Translator("recup national holiday"),
        },
        {
          value: "small unemployement",
          active: false,
          accreditation: 1,
          desc: Translator("small unemployement"),
        },
        {
          value: "info",
          active: false,
          accreditation: 4,
          desc: Translator("info"),
        },
      ],
      newEvent: {
        users: {},
        eventTypes: [
          {
            value: "planning",
            active: false,
            accreditation: 2,
            desc: Translator("planning"),
          },
          {
            value: "private",
            active: false,
            accreditation: 4,
            desc: Translator("private"),
          },
          {
            value: "customer",
            active: true,
            accreditation: 4,
            desc: Translator("customer"),
          },
          {
            value: "holiday",
            active: false,
            accreditation: 1,
            desc: Translator("holiday"),
          },
          {
            value: "medical off",
            active: false,
            accreditation: 1,
            desc: Translator("medical off"),
          },
          {
            value: "off",
            active: false,
            accreditation: 1,
            desc: Translator("off"),
          },
          {
            value: "technical off",
            active: false,
            accreditation: 1,
            desc: Translator("technical off"),
          },
          {
            value: "unpaid",
            active: false,
            accreditation: 1,
            desc: Translator("unpaid holiday"),
          },
          {
            value: "national holiday",
            active: false,
            accreditation: 1,
            desc: Translator("national holiday"),
          },
          {
            value: "recup national holiday",
            active: false,
            accreditation: 1,
            desc: Translator("recup national holiday"),
          },
          {
            value: "small unemployement",
            active: false,
            accreditation: 1,
            desc: Translator("small unemployement"),
          },
          {
            value: "info",
            active: false,
            accreditation: 4,
            desc: Translator("info"),
          },
        ],
      },
    };
  }

  componentDidMount() {
    this.setState({ isFetching: true });
    fetchCustomers().then((fetchedCustomers) => {
      if (fetchCustomers) {
        fetchUsers().then((fetchedUsers) => {
          if (fetchedUsers) {
            let eventUsers = {};
            fetchEvents(
              moment(this.state.dateRange[0].startDate).startOf("week").format("YYYY-MM-DD"),
              moment(this.state.dateRange[0].endDate).endOf("week").format("YYYY-MM-DD"),
              fetchedUsers.data.map((user) => user.id)
            ).then((fetchedEvents) => {
              this.userifyEvents(fetchedEvents);
              const compare = (a, b) => {
                if (a.shortName < b.shortName) return -1;
                if (a.shortName > b.shortName) return 1;
                return 0;
              };
              fetchedUsers.data.sort(compare);
              const buildState = {
                modal: false,
                customersOptionTable: fetchedCustomers.data.map((customer) => {
                  return { value: customer.id, label: customer.name };
                }),
                customers: fetchedCustomers.data,
                users: fetchedUsers.data.map((user) => {
                  if (
                    typeof store()
                      .getCookies()
                      .get(user.id + "checked") !== "undefined"
                  ) {
                    user.checked =
                      store()
                        .getCookies()
                        .get(user.id + "checked") === "true";
                  } else {
                    store()
                      .getCookies()
                      .set(user.id + "checked", "true", {
                        path: "/",
                        maxAge: 604800,
                        sameSite: "lax",
                      });
                    user.checked = true;
                  }
                  return user;
                }),
                fetchedEvents: fetchedEvents,
                newEvent: {
                  users: eventUsers,
                  eventTypes: JSON.parse(JSON.stringify(this.state.eventTypes)),
                  locked: false,
                  fullDay: true,
                },
                isFetching: false,
              };
              this.setState(buildState);
            });
          }
        });
      } else {
        this.setState({ isFetching: false });
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    //console.log(prevProps, prevState);
  }

  /*If an event is spread accross multiple users, the info about multiple users is containend in userEvents array, this method will parse all userEvents of a specific event to populate a user array directly into the event object
  (avoiding to have to parse userEvent array each time we need to know users associated to that event)*/
  userifyEvents(events) {
    events.map((event) => {
      event.users = [];
      if (event.UserEvents.length) {
        event.UserEvents.map((ue) => {
          event.users.push(ue.UserId);
        });
        /*  event.from = event.UserEvents[0].from;
                  event.to = event.UserEvents[0].to;
                  event.UserEvents.map((ue) => {
                    if (moment(ue.from).isBefore(moment(event.from))) event.from = ue.from;
                    if (moment(ue.to).isAfter(moment(event.to))) event.to = ue.to;
                  }); */
      }
    });
  }

  postEvent(event) {
    this.setState({ isFetching: true });
    postEvent(event, false).then((result) => {
      fetchEvents(
        moment(this.state.dateRange[0].startDate).startOf("week").format("YYYY-MM-DD"),
        moment(this.state.dateRange[0].endDate).endOf("week").format("YYYY-MM-DD"),
        this.state.users.map((user) => user.id)
      ).then((fetchedEvents) => {
        this.userifyEvents(fetchedEvents);

        this.setState({
          modal: false,
          fetchedEvents: fetchedEvents,
          isFetching: false,
          newEvent: {
            users: {},
            eventTypes: JSON.parse(JSON.stringify(this.state.eventTypes)),
            edit: false,
            locked: false,
            fullDay: true,
          },
        });
      });
    });
  }

  editEvent(event) {
    this.setState({ isFetching: true });
    editEvent(event).then((result) => {
      fetchEvents(
        moment(this.state.dateRange[0].startDate).startOf("week").format("YYYY-MM-DD"),
        moment(this.state.dateRange[0].endDate).endOf("week").format("YYYY-MM-DD"),
        this.state.users.map((user) => user.id)
      ).then((fetchedEvents) => {
        this.userifyEvents(fetchedEvents);

        this.setState({
          modal: false,
          fetchedEvents: fetchedEvents,
          isFetching: false,
          newEvent: {
            users: {},
            eventTypes: JSON.parse(JSON.stringify(this.state.eventTypes)),
            edit: false,
            locked: false,
            fullDay: true,
          },
        });
      });
    });
  }

  editUserEvent(userEvent) {
    this.setState({ isFetching: true });
    editUserEvent(userEvent).then((result) => {
      fetchEvents(
        moment(this.state.dateRange[0].startDate).startOf("week").format("YYYY-MM-DD"),
        moment(this.state.dateRange[0].endDate).endOf("week").format("YYYY-MM-DD"),
        this.state.users.map((user) => user.id)
      ).then((fetchedEvents) => {
        this.userifyEvents(fetchedEvents);

        this.setState({
          modal: false,
          fetchedEvents: fetchedEvents,
          isFetching: false,
          newEvent: {
            users: {},
            eventTypes: JSON.parse(JSON.stringify(this.state.eventTypes)),
            edit: false,
            locked: false,
            fullDay: true,
          },
        });
      });
    });
  }

  deleteUserEvent(userEventId) {
    this.setState({ isFetching: true });
    deleteUserEvent(userEventId).then((result) => {
      fetchEvents(
        moment(this.state.dateRange[0].startDate).startOf("week").format("YYYY-MM-DD"),
        moment(this.state.dateRange[0].endDate).endOf("week").format("YYYY-MM-DD"),
        this.state.users.map((user) => user.id)
      ).then((fetchedEvents) => {
        this.userifyEvents(fetchedEvents);

        this.setState({
          modal: false,
          fetchedEvents: fetchedEvents,
          isFetching: false,
          newEvent: {
            users: {},
            eventTypes: JSON.parse(JSON.stringify(this.state.eventTypes)),
            locked: false,
            edit: false,
            fullDay: true,
          },
        });
      });
    });
  }

  deleteEvent(eventId) {
    this.setState({ isFetching: true });
    deleteEvent(eventId).then((result) => {
      fetchEvents(
        moment(this.state.dateRange[0].startDate).startOf("week").format("YYYY-MM-DD"),
        moment(this.state.dateRange[0].endDate).endOf("week").format("YYYY-MM-DD"),
        this.state.users.map((user) => user.id)
      ).then((fetchedEvents) => {
        this.userifyEvents(fetchedEvents);

        this.setState({
          modal: false,
          fetchedEvents: fetchedEvents,
          isFetching: false,
          newEvent: {
            users: {},
            eventTypes: JSON.parse(JSON.stringify(this.state.eventTypes)),
            locked: false,
            edit: false,
            fullDay: true,
          },
        });
      });
    });
  }

  lockEvents(from, to, lock) {
    this.setState({ isFetching: true });
    lockEvents(from, to, lock).then((result) => {
      fetchEvents(
        moment(this.state.dateRange[0].startDate).startOf("week").format("YYYY-MM-DD"),
        moment(this.state.dateRange[0].endDate).endOf("week").format("YYYY-MM-DD"),
        this.state.users.map((user) => user.id)
      ).then((fetchedEvents) => {
        this.userifyEvents(fetchedEvents);

        this.setState({
          modal: false,
          fetchedEvents: fetchedEvents,
          isFetching: false,
          newEvent: {
            users: {},
            eventTypes: JSON.parse(JSON.stringify(this.state.eventTypes)),
            locked: false,
            edit: false,
            fullDay: true,
          },
        });
      });
    });
  }
  getWeek(delta) {
    const thisWeek = [];
    let i;
    const start = delta ? delta * 7 : 0;
    for (i = start; i < start + 7; i++) {
      thisWeek.push(moment().startOf("week").add("day", i));
    }
    return thisWeek;
  }

  toggleColor(value) {
    this.toggledColor = value ? value : !this.toggledColor;
    return this.toggledColor;
  }

  renderEventTypeButtons() {
    return (
      <div className="btn-group btn-group-toggle ml-n3 mr-n3 col-lg-12" data-toggle="buttons">
        {this.state.eventTypes.map((eventType) => {
          if (store().getUser().accreditation <= eventType.accreditation) {
            return (
              <label
                key={eventType.value}
                className={eventType.active ? "btn btn-secondary active" : "btn btn-secondary"}
              >
                {Translator(eventType.desc)}
                <input
                  type="radio"
                  name="options"
                  id="option1"
                  value={eventType.value}
                  onClick={(e) => {
                    if (e.target.value) {
                      this.setState({
                        newEvent: {
                          ...this.state.newEvent,
                          eventType: e.target.value,
                          edit: false,
                        },
                      });
                    }
                  }}
                  autoComplete="off"
                ></input>
              </label>
            );
          }
        })}
      </div>
    );
  }

  renderModal() {
    let user = "";
    let day = "";
    if (this.state.addEvent) {
      user = this.state.addEvent.user;
      day = MomentDateString(this.state.addEvent.day);
    }
    return (
      <EventForm
        isFetching={this.state.isFetching}
        date={day}
        user={user.shortName}
        users={this.state.users}
        newEvent={this.state.newEvent}
        minMaxDate={this.state.minMaxDate}
        siteOptionTable={this.state.siteOptionTable}
        postEventCallback={this.postEvent.bind(this)}
        editEventCallback={this.editEvent.bind(this)}
        editUserEventCallback={this.editUserEvent.bind(this)}
        deleteUserEventCallback={this.deleteUserEvent.bind(this)}
        deleteEventCallback={this.deleteEvent.bind(this)}
      ></EventForm>
    );
  }

  returnEventDesc(event, period) {
    if (
      event.type === "off" ||
      event.type === "unpaid" ||
      event.type === "holiday" ||
      event.type === "medical off" ||
      event.type === "technical off" ||
      event.type === "recup national holiday" ||
      event.type === "small unemployement"
    ) {
      return Translator(event.type);
    }
    if (event.type === "national holiday") {
      return Translator(event.title);
    }
    if (!period.title) {
      if (!event.title) {
        if (this.state.customersOptionTable.find((cust) => cust.value === event.CustomerId)) {
          return this.state.customersOptionTable.find((cust) => cust.value === event.CustomerId).label;
        } else {
          return event.type;
        }
      } else {
        return event.title;
      }
    } else {
      return period.title;
    }
  }

  returnEventSite(period) {
    if (period.Site) {
      return period.Site.name;
    }
  }
  renderTable(delta) {
    //console.log("render table start: " + moment().format("HH:mm:ss"));
    let thisWeek = false;
    if (moment().add("week", delta).startOf("week").isSame(moment(), "week")) {
      thisWeek = true;
    }
    const scrollTo = (ref) => {
      if (this.state.fetchedEvents) {
        if (!this.scrolled && ref && moment().add("week", delta).startOf("week").isSame(moment(), "week")) {
          this.scrolled = true;
          ref.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      }
    };
    const conditionalStyle = () => {
      if (moment().add("week", delta).startOf("week").isSame(moment(), "week")) {
        return { border: "3px solid orange", borderRadius: "0.5em", borderCollapse: "separate" };
      } else {
        return { border: "1px solid gray" };
      }
    };
    //console.log("render table returning: " + moment().format("HH:mm:ss"));
    return (
      <table className={`table table-bordered`} style={conditionalStyle()}>
        <thead>
          <tr className="bg-info color-palette" ref={scrollTo}>
            <th style={{ border: "2px double #068096", width: "7%" }}>
              {Translator("week")}&nbsp;{moment().add("week", delta).isoWeek()}
              {store().getUser().accreditation < 2 ? (
                <span>
                  <small
                    onClick={(e) => {
                      e.preventDefault();
                      const from = moment().add("week", delta).startOf("week").format("DD-MM-YYYY HH:mm");
                      const to = moment().add("week", delta).endOf("week").format("DD-MM-YYYY HH:mm");
                      this.lockEvents(from, to, true);
                    }}
                  >
                    {" "}
                    <i className="fas fa-unlock-alt"></i>{" "}
                  </small>
                  &nbsp;&nbsp;
                  <small
                    onClick={(e) => {
                      e.preventDefault();
                      const from = moment().add("week", delta).startOf("week").format("DD-MM-YYYY HH:mm");
                      const to = moment().add("week", delta).endOf("week").format("DD-MM-YYYY HH:mm");
                      this.lockEvents(from, to, false);
                    }}
                  >
                    {" "}
                    <i className="fas fa-lock-open"></i>
                  </small>
                </span>
              ) : null}
            </th>
            {this.getWeek(delta).map((day) => {
              if (day.day() === 0 || day.day() === 6) {
                return (
                  <th
                    style={{
                      border: "2px double #0791a7",
                      width: "9%",
                      backgroundColor: "#0691a7",
                    }}
                    key={MomentDateString(day)}
                  >
                    {moment(day).format("ddd DD/MM/YYYY")}
                  </th>
                );
              } else {
                return (
                  <th style={{ border: "2px double #0791a7", width: "15%" }} key={MomentDateString(day)}>
                    {moment(day).format("ddd DD/MM/YYYY")}
                  </th>
                );
              }
            })}
          </tr>
        </thead>
        <tbody>
          {this.state.users.map((user) => {
            //console.log("start looping on users : " + moment().format("HH:mm:ss"));
            return this.renderUserCell(user, delta);
          })}
        </tbody>
      </table>
    );
  }
  renderAddEventButton(user, day, cumulatedWorkTime) {
    return (
      <div className="text-center align-bottom px-5">
        <button
          className="btn btn-default btn-sm"
          style={{
            borderWidth: 0,
            backgroundColor: this.toggledColor
              ? day.day() === 0 || day.day() === 6
                ? "#E9E9E9"
                : "#FAFAFA"
              : day.day() === 0 || day.day() === 6
                ? "#D9D9D9"
                : "#F2F2F2",
          }}
          data-toggle="modal"
          data-target="#modal-default"
          onClick={(e) => {
            e.preventDefault();
            let startMom = new moment(day);
            startMom.hour(9);
            startMom.minute(0);
            let endMom = new moment(day);
            endMom.hour(17);
            endMom.minute(0);
            this.setState({ isFetching: true, modal: true });
            fetchCustomerConveyors(this.state.customersOptionTable[0].value).then((result) => {
              const customerSite = result.Sites.map((site) => {
                return {
                  value: site.id,
                  label: site.name,
                };
              });
              customerSite.push({ value: "all", label: Translator("all") });
              this.setState({
                ...this.state,
                siteOptionTable: customerSite,
                addEvent: { user: user, day: day },
                newEvent: {
                  customer: this.state.customersOptionTable[0],
                  users: { [user.id]: {} },
                  start: MomentDateTimeString(startMom),
                  end: MomentDateTimeString(endMom),
                  startHour: "9",
                  startMinute: "0",
                  endHour: "17",
                  endMinute: "0",
                  break: "00:30",
                  eventDescription: "",
                  eventTypes: JSON.parse(JSON.stringify(this.state.eventTypes)),
                  workTime: "none",
                  locked: false,
                  edit: true,
                  scope: 3,
                  fullDay: true,
                },
                isFetching: false,
              });
            });
          }}
        >
          <i className="fas fa-plus" style={{ color: "#DDDDDD" }}>
            { }
          </i>
        </button>&nbsp;<small style={{ color: cumulatedWorkTime > 456 ? cumulatedWorkTime > 912 ? "#FF5733" : "#66CC00" : "#DDDDDD" }}>{
          cumulatedWorkTime > 456 ?
            Math.floor((cumulatedWorkTime - 456) / 60).toFixed(0).padStart(2, '0') +
            ":" +
            Math.floor((cumulatedWorkTime - 456) % 60).toFixed(0).padStart(2, '0') :
            '-' + Math.ceil((cumulatedWorkTime - 456) / 60).toFixed(0).replace('-', '').padStart(2, '0') +
            ":" +
            Math.floor((cumulatedWorkTime - 456) % 60).toFixed(0).replace('-', '').padStart(2, '0')}</small>

      </div>
    );
  }
  renderUserEvents(user, day, event) {
    let valueToReturn = {};
    const handleEventType = (eventType, from) => {
      const time = new moment(from).hour(23).minute(59);
      const disabled = time.isBefore(moment().hour(0).minute(0)) ? " disabled " : " ";
      const visualAccessibility = store().getUser().shortName === "SVB" || store().getUser().shortName === "GV";
      const colorAccessibility = store().getUser().shortName === "SVB" || store().getUser().shortName === "BF";
      switch (eventType) {
        case `planning`:
          return `btn btn-block btn-outline-danger ${disabled}  ${visualAccessibility ? "" : "btn-sm"}`;
        case `private`:
          return `btn btn-block btn-outline-secondary  ${disabled}  ${visualAccessibility ? "" : "btn-sm"}`;
        case `info`:
          return `btn btn-block btn-outline-info  ${disabled}  ${visualAccessibility ? "" : "btn-sm"}`;
        case `customer`:
          return `btn btn-block btn-outline-${colorAccessibility ? "primary" : "success"} ${disabled}  ${visualAccessibility ? "" : "btn-sm"
            }`;
        case `holiday`:
          return `btn btn-block btn-warning ${disabled}   ${visualAccessibility ? "" : "btn-sm"}`;
        case `medical off`:
          return `btn btn-block btn-warning ${disabled}   ${visualAccessibility ? "" : "btn-sm"}`;
        case `technical off`:
          return `btn btn-block btn-warning ${disabled}   ${visualAccessibility ? "" : "btn-sm"}`;
        case `national holiday`:
          return `btn btn-block btn-warning ${disabled}  ${visualAccessibility ? "" : "btn-sm"}`;
        case `unpaid`:
          return `btn btn-block btn-warning ${disabled}   ${visualAccessibility ? "" : "btn-sm"}`;
        case `recup national holiday`:
          return `btn btn-block btn-warning ${disabled}   ${visualAccessibility ? "" : "btn-sm"}`;
        case `off`:
          return `btn btn-block btn-warning ${disabled}  ${visualAccessibility ? "" : "btn-sm"}`;
        case `small unemployement`:
          return `btn btn-block btn-warning ${disabled}  ${visualAccessibility ? "" : "btn-sm"}`;
        default:
          return `btn btn-block btn-outline-secondary ${disabled}   ${visualAccessibility ? "" : "btn-sm"}`;
      }
    };
    event.UserEvents.map((period) => {
      const buttonEventContent = (fullDay) => {
        if (fullDay) {
          return (
            <div className="container">
              <div className="row">
                <div className="col col-12 px-0">
                  <b>
                    {" "}
                    {period.locked ? (
                      <small>
                        {" "}
                        <i className="fas fa-unlock-alt"></i>{" "}
                      </small>
                    ) : null}
                    {this.returnEventDesc(event, period)}
                  </b>
                </div>
              </div>
              {this.returnEventSite(period) ? (
                <div className="row">
                  <div className="col col-12 px-0">{this.returnEventSite(period)}</div>
                </div>
              ) : null}
            </div>
          );
        } else {
          return (
            <div className="container">
              <div className="row">
                <div className="col col-12 px-0">
                  {period.locked ? (
                    <small>
                      {" "}
                      <i className="fas fa-unlock-alt"></i>{" "}
                    </small>
                  ) : null}
                  <small>{moment(period.from).format("HH:mm")}</small>
                  <small>{">"}</small>
                  <small>{moment(period.to).format("HH:mm")}</small>
                  {/*<small>...{period.workTime}...</small>*/}
                  {period.break !== "" && period.break !== "00:00" ? (
                    <small>
                      {" "}
                      <i className="fas fa-mug-hot"></i> {period.break}
                    </small>
                  ) : null}
                </div>
              </div>
              <div className="row">
                <div className="col col-12 px-0">{this.returnEventDesc(event, period)}</div>
              </div>
              {this.returnEventSite(period) ? (
                <div className="row">
                  <div className="col col-12 px-0">{this.returnEventSite(period)}</div>
                </div>
              ) : null}
            </div>
          );
        }
      };
      //console.log(event);
      if (period.UserId === user.id && day.isSame(period.from, "date")) {
        $(function () {
          $(".btn").tooltip({
            html: true,
            placement: "bottom",
          });
        });
        valueToReturn = {
          time: period.from,
          content: (
            <div className="row p-1" key={event.id}>
              <button
                className={handleEventType(event.type, period.from)}
                data-toggle="modal"
                data-target="#modal-default"
                onClick={(e) => {
                  e.preventDefault();
                  const userEventId = period.id;
                  let startMom = new moment(period.from);
                  let endMom = new moment(period.to);
                  const customer = this.state.customersOptionTable.find((el) => el.value === event.CustomerId);
                  let users = {};
                  let tmpEventTypes = JSON.parse(JSON.stringify(this.state.eventTypes));
                  event.UserEvents.map((ue) => (users[ue.UserId] = {}));
                  const eventType = tmpEventTypes.map((et) => {
                    if (et.value === event.type) {
                      et.active = true;
                    } else {
                      et.active = false;
                    }
                    if (event.type === "planning" && store().getUser().accreditation === 3) {
                      if (et.value === "customer") et.active = true;
                      if (et.value === "planning") et.active = false;
                    }
                    return et;
                  });
                  tmpEventTypes.forEach((et) => {
                    if (et.value === event.type) {
                      et.active = true;
                    } else {
                      et.active = false;
                    }
                    if (event.type === "planning" && store().getUser().accreditation === 3) {
                      if (et.value === "customer") et.active = true;
                      if (et.value === "planning") et.active = false;
                    }
                  });
                  let eventUsers = {};
                  let minMaxDate = {};
                  event.UserEvents.map((uevent) => {
                    eventUsers[uevent.UserId] = {};
                    if (!minMaxDate["min"]) minMaxDate["min"] = moment(uevent.from);
                    if (!minMaxDate["max"]) minMaxDate["max"] = moment(uevent.to);
                    if (moment(uevent.from).isBefore(minMaxDate["min"])) minMaxDate["min"] = moment(uevent.from);
                    if (moment(uevent.to).isAfter(minMaxDate["max"])) minMaxDate["max"] = moment(uevent.to);
                  });
                  let newState = {
                    ...this.state,
                    modal: true,
                    minMaxDate: minMaxDate,
                    addEvent: { user: user, day: day },
                    newEvent: {
                      userEventId: userEventId,
                      users: eventUsers,
                      start: MomentDateTimeString(startMom),
                      end: MomentDateTimeString(endMom),
                      startHour: startMom.hour(),
                      startMinute: startMom.minute(),
                      endHour: endMom.hour(),
                      endMinute: endMom.minute(),
                      break: period.break,
                      eventDescription: period.description,
                      eventTypes: JSON.parse(JSON.stringify(eventType)),
                      workTime: (period.workTime / 60).toFixed(0) + " et " + (period.workTime % 60),
                      locked: period.locked ? period.locked : false,
                      edit: false,
                      title: period.title,
                      fullDay: period.fullDay,
                      eventId: event.id,
                      periodUser: period.UserId,
                    },
                  };
                  if (customer) {
                    fetchCustomerConveyors(customer.value).then((result) => {
                      const customerSite = result.Sites.map((site) => {
                        return {
                          value: site.id,
                          label: site.name,
                        };
                      });
                      customerSite.push({
                        value: "all",
                        label: Translator("all"),
                      });
                      newState.newEvent.customer = customer;
                      newState.newEvent.site = customerSite.find((el) => el.value === period.SiteId);
                      newState.siteOptionTable = customerSite;
                      this.setState(newState);
                    });
                  } else {
                    this.setState(newState);
                  }
                }}
                rel="tooltip"
                data-html="true"
                data-placement="bottom"
                title={
                  period.fullDay
                    ? "<div>🕑 " + "07:36</div>" + period.description
                    : "<div>🕑 " +
                    Math.floor(period.workTime / 60).toFixed(0).padStart(2, '0') +
                    ":" +
                    Math.floor(period.workTime % 60).toFixed(0).padStart(2, '0') +
                    "</div>" +
                    period.description
                }
              >
                {buttonEventContent(period.fullDay)}
              </button>
              <hr></hr>
            </div>
          ),
        };
      }
    });
    return valueToReturn;
  }

  renderUserCell(user, delta) {
    let cumulatedWorkTime = 0;
    const userAvatar = () => {
      const avatarSize = user.shortName === store().getUser().shortName ? "4.5rem" : "3rem";
      const elevation =
        user.shortName === store().getUser().shortName ? "img-circle elevation-3" : "img-circle elevation-2";
      const imgStyle =
        user.shortName === store().getUser().shortName
          ? { width: avatarSize, border: "5px double #997777" }
          : { width: avatarSize };
      return (
        <div>
          <img src={"/assets/dist/img/prettyUnderline.png"} style={{ width: avatarSize }} />
          <div className="image" style={{ padding: "20px 0" }}>
            <img
              src={"/assets/dist/img/" + user.shortName + "160x160.jpg"}
              className={elevation}
              alt="User"
              style={imgStyle}
            />
          </div>
        </div>
      );
    };
    if (user.checked) {
      this.toggleColor();
      return (
        <tr key={user.id}>
          <td
            className="bg-light color-palette"
            style={{
              border: "2px double #cdd1d5",
              textAlign: "center",
            }}
          >
            {user.shortName}
            {moment().add("week", delta).startOf("week").isSame(moment(), "week") ? userAvatar() : null}
          </td>
          {this.getWeek(delta, user).map((day) => {
            cumulatedWorkTime = 0
            let uevents = [];
            this.state.fetchedEvents.map((event) => {
              if (!event.isRequest && event.users.includes(user.id)) {

                uevents.push(this.renderUserEvents(user, day, event));
              }
              const foundPeriod = event.UserEvents.find(ue => ue.UserId === user.id && day.isSame(ue.from, "date"))
              //if (!event.isRequest && event.users.include(user.id) && day.isSame(period.from, "date"))
              if (foundPeriod && event.type === "customer") {
                cumulatedWorkTime += foundPeriod.fullDay ? 456 : foundPeriod.workTime
                console.log(foundPeriod)
              }
            });
            const compare = (a, b) => {
              if (moment(a.time).isBefore(moment(b.time))) return -1;
              if (moment(a.time).isAfter(moment(b.time))) return 1;
              return 0;
            };
            uevents.sort(compare);
            return (
              <td
                style={{
                  borderRight: "2px solid #dee2e6",
                  height: "100px",
                  backgroundColor: moment().isSame(day, "days")
                    ? "#E9F3F5"
                    : this.toggledColor
                      ? day.day() === 0 || day.day() === 6
                        ? "#DDDDDD"
                        : "#FFFFFF"
                      : day.day() === 0 || day.day() === 6
                        ? "#CCCCCC"
                        : "#EEEEEE",
                }}
                key={MomentDateString(day)}
              >
                {uevents.map((event) => {
                  return event.content;
                })}
                {this.renderAddEventButton(user, day, cumulatedWorkTime)}
              </td>
            );
          })}
        </tr>
      );
    }
  }
  renderUserChecks() {
    let isAtLeastOneUnchecked = false;
    return (
      <div className="input-group mb-2 mr-md-2">
        {this.state.users.map((user) => {
          if (!user.checked) isAtLeastOneUnchecked = true;
          return (
            <div key={user.id} className="input-group-prepend"
              onClick={(e) => {
                console.log("setting is fetching to true")
                this.setState({ isFetching: true });
                let users = this.state.users.map((anUser) => {
                  if (anUser.id === user.id) {
                    store()
                      .getCookies()
                      .set(anUser.id + "checked", !anUser.checked + "", {
                        path: "/",
                        maxAge: 604800,
                        sameSite: "lax",
                      });
                    anUser.checked = !anUser.checked;
                  } else {
                    store()
                      .getCookies()
                      .set(anUser.id + "checked", anUser.checked + "", {
                        path: "/",
                        maxAge: 604800,
                        sameSite: "lax",
                      });
                  }
                  return anUser;
                });
                console.log("isFetching will be false");
                this.setState({ isFetching: false, users: users });
              }}>
              <div className="input-group-text">
                <input
                  type="checkbox"
                  value={user.id}
                  checked={user.checked}
                  onChange={(e) => {
                    //e.preventDefault();
                  }}

                ></input>
                <small>&nbsp;{user.shortName}</small>
              </div>
            </div>
          );
        })}
        <div key={"all"} className="input-group-prepend"
          onClick={(e) => {
            let users = this.state.users.map((anUser) => {
              anUser.checked = isAtLeastOneUnchecked;
              store()
                .getCookies()
                .set(anUser.id + "checked", isAtLeastOneUnchecked + "", {
                  path: "/",
                  maxAge: 604800,
                  sameSite: "lax",
                });
              return anUser;
            });

            this.setState({ isFetching: false, users: users });
          }}>
          <div className="input-group-text">
            <input
              type="checkbox"
              checked={!isAtLeastOneUnchecked}
              onChange={(e) => {

              }}
            ></input>
            <small>&nbsp;{Translator(!isAtLeastOneUnchecked ? "unselect all" : "select all")}</small>
          </div>
        </div>
        <div key={"me"} className="input-group-prepend"
          onClick={(e) => {
            this.setState({ isFetching: true });
            let users = this.state.users.map((anUser) => {
              if (anUser.id === store().getUser().userId) {
                store()
                  .getCookies()
                  .set(anUser.id + "checked", true + "", {
                    path: "/",
                    maxAge: 604800,
                    sameSite: "lax",
                  });
                anUser.checked = true;
              } else {
                store()
                  .getCookies()
                  .set(anUser.id + "checked", false + "", {
                    path: "/",
                    maxAge: 604800,
                    sameSite: "lax",
                  });
                anUser.checked = false;
              }

              return anUser;
            });
            this.setState({ isFetching: false, users: users });
          }}>
          <div className="input-group-text">
            <div className="image">
              <img
                src={"/assets/dist/img/" + store().getUser().shortName + "160x160.jpg"}
                className={"img-circle elevation-2"}
                alt="User"
                style={{ width: "1rem" }}
              />
              <small>&nbsp;{Translator("only me")}</small>
            </div>
          </div>
        </div>
      </div >
    );
  }
  renderAgendaButton() {
    return (
      <button
        className={`btn btn-default btn`}
        onClick={(e) => {
          e.preventDefault();
          this.componentDidMount.bind(this);
        }}
      >
        <i className="fas fa-sync-alt"></i>
      </button>
    );
  }

  renderAgendaPart() {
    let agenda = [];
    const start = this.props.numBefore;
    const end = this.props.numAfter;
    let i;
    for (i = start; i <= end; i++) {
      this.props.toggleColor(true);
      agenda.push(this.props.renderTable(i));
    }
    return agenda;
    this.setState({
      agenda: agenda
    })
  }
  renderSubmitDateRange() {
    const onClickCallBack = (e) => {
      {
        const savedUsers = this.state.users;
        store().getCookies().set("from", this.state.dateRange[0].startDate, { sameSite: "lax" });
        store().getCookies().set("to", this.state.dateRange[0].endDate, { sameSite: "lax" });
        this.setState({ isFetching: true, users: [], modal: false });
        fetchEvents(
          moment(this.state.dateRange[0].startDate).startOf("week").format("YYYY-MM-DD"),
          moment(this.state.dateRange[0].endDate).endOf("week").format("YYYY-MM-DD HH:mm"),
          savedUsers.map((user) => user.id)
        ).then((fetchedEvents) => {
          this.userifyEvents(fetchedEvents);

          this.setState({
            ...this.state,
            dateChanged: false,
            fetchedEvents: fetchedEvents,
            dateRangeValidated: this.state.dateRange,
            isFetching: false,
            users: savedUsers,
          });
        });
      }
    };
    const buttonEffect = this.state.dateChanged ? "btn-danger heartBeat animated" : "";
    return (
      <button
        className={`btn btn-block ${buttonEffect} btn-sm`}
        data-card-widget={"collapse"}
        onClick={onClickCallBack.bind(this)}
      >
        <i className="far fa-calendar-check"></i>
        &nbsp;{Translator("submit dates")}
      </button>
    );
  }
  renderResetDateRange() {
    const onClickCallBack = (e) => {
      {
        e.preventDefault();
        const savedUsers = this.state.users;
        let toDate = new Date();
        let fromDate = new Date();
        fromDate.setDate(fromDate.getDate() - 7);
        toDate.setDate(toDate.getDate() + 14);
        store().getCookies().remove("from");
        store().getCookies().remove("to");
        this.setState({ isFetching: true, users: [], modal: false });
        fetchEvents(
          moment(fromDate).startOf("week").format("YYYY-MM-DD"),
          moment(toDate).endOf("week").format("YYYY-MM-DD HH:mm"),
          savedUsers.map((user) => user.id)
        ).then((fetchedEvents) => {
          this.userifyEvents(fetchedEvents);

          this.setState({
            ...this.state,
            dateChanged: false,
            fetchedEvents: fetchedEvents,
            dateRangeValidated: [
              {
                startDate: fromDate,
                endDate: toDate,
                key: "selection",
              },
            ],
            isFetching: false,
            dateRange: [
              {
                startDate: fromDate,
                endDate: toDate,
                key: "selection",
              },
            ],
            users: savedUsers,
          });
        });
      }
    };
    return (
      <button className={`btn btn-block btn-sm`} onClick={onClickCallBack.bind(this)}>
        <i className="fas fa-backspace"></i>
        &nbsp;{Translator("reset dates")}
      </button>
    );
  }

  render() {
    moment.locale(store().getLanguage());
    /*const diffYear =
      moment(this.state.dateRangeValidated[0].endDate).year() -
      moment(this.state.dateRangeValidated[0].startDate).year();*/

    const minusWeeks = moment(this.state.dateRangeValidated[0].startDate).startOf("week").diff(moment(), "week");
    const plusWeeks = moment(this.state.dateRangeValidated[0].endDate).endOf("week").diff(moment(), "week");
    return (
      <div>
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0 text-dark">
                  <i className="nav-icon fa-solid fa-calendar-days"></i>&nbsp; {Translator("agenda")}
                </h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to={{ pathname: "../" }}>{Translator("Home")}</Link>
                  </li>
                  <li className="breadcrumb-item active">{Translator("Agenda")}</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="container-fluid">
            <Card
              title={(() => {
                return (
                  <span>
                    {Translator("Date selection")}&nbsp;{"->"}&nbsp;
                    {MomentDateString(moment(this.state.dateRange[0].startDate)) +
                      " - " +
                      MomentDateString(moment(this.state.dateRange[0].endDate))}
                  </span>
                );
              })()}
              tools={(() => {
                return (
                  <div>
                    {this.renderSubmitDateRange()}
                    {this.renderResetDateRange()}
                  </div>
                );
              })()}
              isFetching={this.state.isFetching}
              collapsible
              collapsed
            >
              <DateRange
                editableDateInputs={true}
                onChange={(item) => {
                  this.setState({
                    ...this.state,
                    dateChanged: true,
                    dateRange: [item.selection],
                  });
                }}
                moveRangeOnFirstSelection={false}
                ranges={this.state.dateRange}
                months={2}
                direction="horizontal"
                locale={locales["fr"]}
              />
            </Card>
            <Card title={this.renderUserChecks()} tools={this.renderAgendaButton()} isFetching={this.state.isFetching}>
              <div className="table-responsive">
                <Weeks
                  numBefore={minusWeeks}
                  numAfter={plusWeeks}
                  toggleColor={this.toggleColor.bind(this)}
                  renderTable={this.renderTable.bind(this)}
                  modal={this.state.modal}
                ></Weeks>
                {/*this.renderWeeks(minusWeeks, plusWeeks)*/}
              </div>
            </Card>
          </div>
          {this.renderModal()}
        </section >
      </div >
    );
  }
}

export default withRouter(Agenda);
