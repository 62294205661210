import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import Card from "../../components/Card";
import { Translator, store } from "../../tools/Tools";
import CustomerData from "./CustomerData";
import CustomerConveyor from "./CustomerConveyor";
import CustomerLogbook from "./CustomerLogbook";
import CustomerRemote from "./CustomerRemote";
import CustomerExtra from "./CustomerExtra";
import TodoList from "../Todolist/TodoList";
import Tickets from "../Tickets/Tickets";
import { fetchCustomerTodo } from "../../tools/Api_Customers";
import CustomerEvents from "./CustomerEvents";
import toastr from "toastr";
import CustomerChecklists from "./CustomerChecklists";

class Customer extends Component {
  constructor(props) {
    super(props);
    const logbook = props.history.location.state ? props.history.location.state.logbook : false;
    this.state = {
      tabs: [
        { title: Translator("datas"), active: !logbook },
        { title: Translator("conveyors"), active: false },
        { title: Translator("logbook"), active: logbook },
        { title: Translator("todo"), active: false },
        { title: Translator("remote maintenance"), active: false },
        { title: Translator("extra"), active: false },
        { title: Translator("events"), active: false },
        { title: Translator("checklists"), active: false },
        { title: Translator("tickets"), active: false },
      ],
      generalInformations: this.props.history.location.state ? this.props.history.location.state.genericInfos : null,
      user: store().getUser(),
      addingSite: false,
      addingLogbook: this.props.history.location.state ? this.props.history.location.state.addingLogbook : false,
      addingRemote: false,
      defaultLogbookContent: this.props.history.location.state
        ? this.props.history.location.state.logbookContent
        : null,
      defaultLogbookUsers: this.props.history.location.state ? this.props.history.location.state.logbookUsers : null,
      ticket: props.history.location.state
        ? props.history.location.state.ticketId
          ? props.history.location.state.ticketId
          : null
        : null,
      checklist: props.history.location.state
        ? props.history.location.state.checklistId
          ? props.history.location.state.checklistId
          : null
        : null,
    };
  }
  confirmButton(tab) {
    store().setConfirmation(false);
    this.handleClickTab(tab);
  }
  handleClickTab(tab) {
    this.setState((state) => {
      return {
        addingSite: false,
        addingLogbook: false,
        tabs: state.tabs.map((stateTab) => {
          return {
            title: stateTab.title,
            active: stateTab.title === tab.title,
          };
        }),
      };
    });
  }

  generateTabs() {
    return (
      <ul className="nav nav-tabs">
        {this.state.tabs.map((tab) => {
          return (
            <li
              key={tab.title}
              className={tab.active ? "nav-link active" : "nav-link"}
              onClick={this.handleClickTab.bind(this, tab)}
            >
              {tab.title}
            </li>
          );
        })}
      </ul>
    );
  }
  triggerAddingSite() {
    this.setState((state) => {
      return { addingSite: !state.addingSite };
    });
  }
  triggerAddingLogbook() {
    store().setConfirmation(false);
    this.setState((state) => {
      return { addingLogbook: !state.addingLogbook, defaultLogbookContent: null, defaultLogbookUsers: [] };
    });
  }
  triggerAddingRemote() {
    this.setState((state) => {
      return { addingRemote: !state.addingRemote };
    });
  }
  generateTabRelatedContent() {
    switch (this.state.tabs.find((tab) => tab.active).title) {
      case Translator("datas"):
        return <CustomerData className="fadeIn animated" generalInfos={this.state.generalInformations}></CustomerData>;
      case Translator("conveyors"):
        return (
          <CustomerConveyor
            className="fadeIn animated"
            generalInfos={this.state.generalInformations}
            creatingMode={this.state.addingSite}
            triggerCreatingMode={this.triggerAddingSite.bind(this)}
          ></CustomerConveyor>
        );
      case Translator("logbook"):
        return (
          <CustomerLogbook
            className="fadeIn animated"
            generalInfos={this.state.generalInformations}
            creatingMode={this.state.addingLogbook}
            defaultLogbookContent={this.state.defaultLogbookContent}
            defaultLogbookUsers={this.state.defaultLogbookUsers}
            logbookTicket={this.state.ticket}
            logbookChecklist={this.state.checklist}
            triggerCreatingMode={this.triggerAddingLogbook.bind(this)}
          ></CustomerLogbook>
        );
      case Translator("remote maintenance"):
        return (
          <CustomerRemote
            className="fadeIn animated"
            generalInfos={this.state.generalInformations}
            creatingMode={this.state.addingRemote}
            triggerCreatingMode={this.triggerAddingRemote.bind(this)}
          ></CustomerRemote>
        );
      case Translator("extra"):
        return (
          <CustomerExtra className="fadeIn animated" generalInfos={this.state.generalInformations}></CustomerExtra>
        );
      case Translator("events"):
        return (
          <CustomerEvents className="fadeIn animated" generalInfos={this.state.generalInformations}></CustomerEvents>
        );
      case Translator("checklists"):
        return (
          <CustomerChecklists className="fadeIn animated" generalInfos={this.state.generalInformations} fetchTodos={fetchCustomerTodo}
          ></CustomerChecklists>
        );
      case Translator("todo"):
        return <TodoList fetchTodos={fetchCustomerTodo} customer={this.state.generalInformations.id}></TodoList>;
      case Translator("tickets"): {
        return (
          <Tickets
            className="fadeIn animated"
            customer={{ value: this.state.generalInformations.id, label: this.state.generalInformations.name }}
          ></Tickets>
        );
      }
      default:
        return null;
    }
  }
  renderButtons() {
    if (this.state.tabs.find((tab) => tab.active).title === Translator("conveyors")) {
      return (
        <div>
          <button
            className="btn btn"
            onClick={(e) => {
              e.preventDefault();
              this.setState((state) => {
                return { addingSite: !state.addingSite };
              });
            }}
          >
            <span className="fa-stack" style={{ verticalAlign: "top" }}>
              <i className="fas fa-folder fa-stack-2x" style={{ color: "#6c757d" }}></i>
              <i className="fas fa-plus fa-stack-1x fa-inverse"></i>
            </span>
          </button>
        </div>
      );
    }
    if (this.state.tabs.find((tab) => tab.active).title === Translator("remote maintenance")) {
      return (
        <div>
          <button
            className="btn btn"
            onClick={(e) => {
              e.preventDefault();
              this.setState((state) => {
                return { addingRemote: !state.addingRemote };
              });
            }}
          >
            <span className="fa-stack" style={{ verticalAlign: "top" }}>
              <i className="fas fa-desktop fa-stack-2x fa-inverse" style={{ color: "#6c757d" }}></i>
              <i className="fas fa-plus fa-stack-1x" style={{ color: "#6c757d" }}></i>
            </span>
          </button>
        </div>
      );
    }
    if (this.state.tabs.find((tab) => tab.active).title === Translator("logbook")) {
      return (
        <div>
          <button
            className="btn btn"
            data-toggle="modal"
            data-target="#modal-redaction"
            data-keyboard="false"
            onClick={(e) => {
              e.preventDefault();
              store().setConfirmation(true);
              console.log(store().getConfirmation());
              this.setState((state) => {
                return { addingLogbook: !state.addingLogbook };
              });
            }}
          >
            {this.state.addingLogbook ? (
              <i className="fas fa-undo"></i>
            ) : (
              <span className="fa-stack" style={{ verticalAlign: "top" }}>
                <i className="fas fa-file fa-stack-2x" style={{ color: "#6c757d" }}></i>
                <i className="fas fa-plus fa-stack-1x fa-inverse"></i>
              </span>
            )}
          </button>
        </div>
      );
    }
  }
  render() {
    return this.props.history.location.state ? (
      <div>
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0 text-dark">{this.state.generalInformations.name}</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to={{ pathname: "../", state: this.props.location.state }}>{Translator("Home")}</Link>
                  </li>
                  <li className="breadcrumb-item">
                    {this.props.history.location.state.from ? (
                      <Link to={{ pathname: this.props.location.state.from, state: this.props.location.state }}>
                        {Translator(this.props.location.state.from.replaceAll("/", " "))}
                      </Link>
                    ) : (
                      <Link to={{ pathname: "/customers", state: this.props.location.state }}>
                        {Translator("customers")}
                      </Link>
                    )}
                  </li>
                  <li className="breadcrumb-item active">{this.state.generalInformations.name}</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="container-fluid">
            <Card title={this.generateTabs()} tabbedHeader tools={this.renderButtons()}>
              {this.generateTabRelatedContent()}
            </Card>
          </div>
        </section>
      </div>
    ) : null;
  }
}
export default withRouter(Customer);
