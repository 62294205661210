import React, { Component } from "react";
import { Translator } from "../../tools/Tools";
import Select from "react-select";
import RichText from "../../components/RichText";
import moment from "moment";
import { MomentDateString, MomentDateTimeString, store } from "../../tools/Tools";
import { fetchCustomers, fetchUsers, fetchCustomerConveyors } from "../../tools/Api_Customers";

class EventForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      minMaxDate: this.props.minMaxDate,
      date: props.date,
      users: props.users,
      user: props.user,
      newEvent: { ...props.newEvent, locked: false, fullDay: false },
      customersOptionTable: [],
      siteOptionTable: [],
      scope: props.newEvent.userEventId ? 1 : 3,
    };
    this.postEventCallback = props.postEventCallback;
    this.editUserEventCallback = props.editUserEventCallback;
    this.editEventCallback = props.editEventCallback;
    this.deleteUserEventCallback = props.deleteUserEventCallback;
    this.deleteEventCallback = props.deleteEventCallback;
  }

  componentDidMount() {
    fetchCustomers().then((fetchedCustomers) => {
      if (fetchedCustomers) {
        this.setState({
          customersOptionTable: fetchedCustomers.data.map((customer) => {
            return { value: customer.id, label: customer.name };
          }),
        });
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.newEvent !== this.props.newEvent) {
      this.setState({
        newEvent: this.props.newEvent,
        scope: this.props.newEvent.userEventId ? 1 : 3,
      });
    }
    if (prevProps.users !== this.props.users) {
      this.setState({ users: this.props.users });
    }
    if (prevProps.siteOptionTable !== this.props.siteOptionTable) {
      this.setState({ siteOptionTable: this.props.siteOptionTable });
    }
  }
  generateTabs(disabled) {
    return (
      <ul className="nav nav-pills">
        {this.state.newEvent.eventTypes.map((tab) => {
          if (store().getUser().accreditation <= tab.accreditation) {
            if (store().getUser().accreditation < 3 || !this.state.newEvent.userEventId || tab.active)
              return (
                <li
                  key={tab.value}
                  className={tab.active ? "nav-link active" : "nav-link"}
                  onClick={!disabled ? this.handleClickTab.bind(this, tab) : null}
                >
                  {tab.desc}
                </li>
              );
          }
          if (
            this.state.newEvent.eventTypes.find((et) => et.value === "planning" && et.active) &&
            store().getUser().accreditation < 4
          ) {
            if (tab.value === "planning") {
              return (
                <li
                  key={tab.value}
                  className={tab.active ? "nav-link active" : "nav-link"}
                  onClick={!disabled ? this.handleClickTab.bind(this, tab) : null}
                >
                  {Translator(tab.desc)}
                </li>
              );
            }
            if (tab.value === "customer") {
              return (
                <li
                  key={tab.value}
                  className={tab.active ? "nav-link active" : "nav-link"}
                  onClick={!disabled ? this.handleClickTab.bind(this, tab) : null}
                >
                  {Translator(tab.desc)}
                </li>
              );
            }
          }
        })}
      </ul>
    );
  }
  handleClickTab(tab) {
    if (this.state.newEvent.edit) {
      this.setState({
        newEvent: {
          ...this.state.newEvent,
          eventTypes: this.state.newEvent.eventTypes.map((stateTab) => {
            stateTab.active = stateTab.value === tab.value;
            return stateTab;
          }),
        },
      });
    }
  }

  renderFullDayCheckBox(eventType) {
    if (this.state.newEvent.edit) {
      if (eventType === "customer" || eventType === "off") {
        return (
          <div
            key="fullDay"
            className="input-group mb-2 mr-md-2"
            onChange={() => {
              this.setState({
                newEvent: {
                  ...this.state.newEvent,
                  fullDay: !this.state.newEvent.fullDay,
                },
              });
            }}
          >
            <div className="input-group-prepend">
              <div className="input-group-text">
                <input type="checkbox" checked={this.state.newEvent.fullDay} onChange={() => { }}></input>
              </div>
            </div>
            <label type="text" className="form-control text-truncate">
              {Translator("Full Day")}
            </label>
          </div>
        );
      }
    }
  }
  renderTitleInputText(eventType) {
    return (
      <div className="col-4 input-group my-3">
        <div className="input-group-prepend">
          <span className="input-group-text">{Translator("title")}</span>
        </div>
        <input
          type="text"
          className="form-control"
          defaultValue={this.state.newEvent.title}
          onChange={(e) => {
            this.setState({
              newEvent: { ...this.state.newEvent, title: e.target.value },
            });
          }}
        ></input>
      </div>
    );
  }
  renderUsersCheckBox(eventType) {
    return this.state.users.map((user) => {
      if (this.state.newEvent.edit) {
        return (
          <div
            key={user.id}
            className="input-group mb-2 mr-2"
            onClick={(e) => {
              const checked = this.state.newEvent.users[user.id] ? false : true;
              let result = this.state.newEvent.users;
              if (checked) {
                result = { ...result, [user.id]: {} };
              } else {
                delete result[user.id];
              }
              this.setState({
                newEvent: { ...this.state.newEvent, users: result },
              });
            }}
            style={{ width: "auto" }}
          >
            <div className="input-group-prepend">
              <div className="input-group-text">
                <input
                  type="checkbox"
                  checked={this.state.newEvent.users[user.id] ? true : false}
                  onChange={(e) => { }}
                ></input>
              </div>
            </div>
            <label
              type="text"
              className={
                this.state.newEvent.users[user.id]
                  ? "form-control text-truncate bg-danger"
                  : "form-control text-truncate"
              }
            >
              {user.shortName}
            </label>
          </div>
        );
      } else {
        return (
          <div key={user.id} className="input-group mb-2 mr-2"
            style={{ width: "auto" }}>
            <div className="input-group-prepend">
              <div className="input-group-text">
                <input
                  type="checkbox"
                  checked={this.state.newEvent.users[user.id] ? true : false}
                  onChange={(e) => { }}
                  disabled
                ></input>
              </div>
            </div>
            <span type="text" className="form-control text-truncate">
              {user.shortName}
            </span>
          </div>
        );
      }
    });
  }
  renderDatePicker(eventType) {
    return (
      <div>
        <form className="form-inline col-sm-12">
          <i className="far fa-calendar-alt fa-2x mx-2"></i>
          <div className="input-group mb-2 mr-sm-2">
            <div className="input-group-prepend">
              <div className="input-group-text">
                <span>{this.state.scope < 2 ? Translator("date") : Translator("from")}</span>
              </div>
            </div>
            {this.state.newEvent.edit ? (
              <input
                type="date"
                className="form-control"
                placeholder={Translator("start date")}
                value={moment(
                  this.state.newEvent.start
                    ? moment(this.state.newEvent.start, "DD/MM/YYYY HH:mm")
                    : moment(this.state.date, "DD/MM/YYYY HH:mm").format("DD/MM/YYYY"),
                  "DD/MM/YYYY HH:mm"
                ).format("YYYY-MM-DD")}
                onChange={(e) => {
                  let startMoment = moment(e.target.valueAsDate);
                  startMoment.hour(this.state.newEvent.startHour ? this.state.newEvent.startHour : 9);
                  startMoment.minute(this.state.newEvent.startMinute ? this.state.newEvent.startMinute : 0);
                  console.log(this.state.scope);
                  if (this.state.scope >= 2) {
                    if (startMoment.isAfter(moment(this.state.newEvent.end, "DD/MM/YYYY HH:mm"))) {
                      this.setState({
                        newEvent: {
                          ...this.state.newEvent,
                          start: MomentDateTimeString(startMoment),
                          end: MomentDateTimeString(startMoment),
                        },
                      });
                    } else {
                      this.setState({
                        newEvent: {
                          ...this.state.newEvent,
                          start: MomentDateTimeString(startMoment),
                        },
                      });
                    }
                  } else {
                    console.log(
                      "start : " +
                      MomentDateTimeString(startMoment) +
                      " end: " +
                      MomentDateTimeString(
                        startMoment.hour(this.state.newEvent.endHour).minute(this.state.newEvent.endMinute)
                      )
                    );
                    this.setState({
                      newEvent: {
                        ...this.state.newEvent,
                        start: MomentDateTimeString(startMoment),
                        end: MomentDateTimeString(
                          startMoment.hour(this.state.newEvent.endHour).minute(this.state.newEvent.endMinute)
                        ),
                      },
                    });
                  }
                }}
              ></input>
            ) : (
              <label className="form-control">
                {moment(this.state.newEvent.start, "DD/MM/YYYY HH:mm").format("YYYY-MM-DD")}
              </label>
            )}
          </div>
          {this.state.scope < 2 ? null : (
            <div className="input-group mb-2 mr-sm-2">
              <div className="input-group-prepend">
                <div className="input-group-text">
                  <span>{Translator("to")}</span>
                </div>
              </div>
              {this.state.newEvent.edit ? (
                <input
                  type="date"
                  className="form-control"
                  placeholder={Translator("end date")}
                  value={moment(
                    this.state.newEvent.end
                      ? moment(this.state.newEvent.end, "DD/MM/YYYY HH:mm")
                      : moment(this.state.date, "DD/MM/YYYY HH:mm").format("DD/MM/YYYY"),
                    "DD/MM/YYYY HH:mm"
                  ).format("YYYY-MM-DD")}
                  onChange={(e) => {
                    let endMoment = moment(e.target.valueAsDate);
                    endMoment.hour(this.state.newEvent.endHour ? this.state.newEvent.endHour : 9);
                    endMoment.minute(this.state.newEvent.endMinute ? this.state.newEvent.endMinute : 0);
                    if (endMoment.isBefore(moment(this.state.newEvent.start, "DD/MM/YYYY HH:mm"))) {
                      this.setState({
                        newEvent: {
                          ...this.state.newEvent,
                          start: MomentDateTimeString(endMoment),
                          end: MomentDateTimeString(endMoment),
                        },
                      });
                    } else {
                      this.setState({
                        newEvent: {
                          ...this.state.newEvent,
                          end: MomentDateTimeString(endMoment),
                        },
                      });
                    }
                  }}
                ></input>
              ) : (
                <label className="form-control">
                  {moment(this.state.newEvent.end, "DD/MM/YYYY HH:mm").format("YYYY-MM-DD")}
                </label>
              )}
            </div>
          )}
        </form>
        <hr></hr>
      </div>
    );
  }
  renderTimePicker(eventType) {
    if (eventType === "customer" || eventType === "off") {
      return (
        <div>
          <form className="form-inline col-sm-12">
            <i className="far fa-clock fa-2x mx-2"></i>
            <div className="input-group mb-2 mr-md-2">
              <div className="input-group-prepend">
                <div className="input-group-text">
                  <span>{Translator("from")}</span>
                </div>
              </div>
              {this.state.newEvent.edit ? (
                <input
                  type="time"
                  value={moment(this.state.newEvent.start, "DD/MM/YYYY HH:mm").format("HH:mm")}
                  step="00:05"
                  className="form-control"
                  disabled={this.state.newEvent.fullDay}
                  onChange={(e) => {
                    let startMom = new moment(this.state.newEvent.start, "DD/MM/YYYY HH:mm");
                    startMom.hour(moment(e.target.value, "HH:mm").hour());
                    startMom.minute(moment(e.target.value, "HH:mm").minute());
                    this.setState({
                      newEvent: {
                        ...this.state.newEvent,
                        start: MomentDateTimeString(startMom),
                        startHour: moment(e.target.value, "HH:mm").hour(),
                        startMinute: moment(e.target.value, "HH:mm").minute(),
                      },
                    });
                  }}
                ></input>
              ) : (
                <label className="form-control">
                  {moment(this.state.newEvent.start, "DD/MM/YYYY HH:mm").format("HH:mm")}
                </label>
              )}
            </div>
            <div className="input-group mb-2 mr-md-2 ">
              <div className="input-group-prepend">
                <div className="input-group-text">
                  <span>{Translator("to")}</span>
                </div>
              </div>
              {this.state.newEvent.edit ? (
                <input
                  type="time"
                  value={moment(this.state.newEvent.end, "DD/MM/YYYY HH:mm").format("HH:mm")}
                  step="00:05"
                  className="form-control"
                  disabled={this.state.newEvent.fullDay}
                  onChange={(e) => {
                    let endMom = new moment(this.state.newEvent.end, "DD/MM/YYYY HH:mm");
                    endMom.hour(moment(e.target.value, "HH:mm").hour());
                    endMom.minute(moment(e.target.value, "HH:mm").minute());
                    this.setState({
                      newEvent: {
                        ...this.state.newEvent,
                        end: MomentDateTimeString(endMom),
                        endHour: moment(e.target.value, "HH:mm").hour(),
                        endMinute: moment(e.target.value, "HH:mm").minute(),
                      },
                    });
                  }}
                ></input>
              ) : (
                <label className="form-control">
                  {moment(this.state.newEvent.end, "DD/MM/YYYY HH:mm").format("HH:mm")}
                </label>
              )}
            </div>
            <div className="input-group mb-2 mr-md-2">
              <div className="input-group-prepend">
                <div className="input-group-text">
                  <span>{Translator("Pause")}</span>
                </div>
              </div>
              {this.state.newEvent.edit ? (
                <input
                  type="time"
                  defaultValue={this.state.newEvent.break}
                  step="00:05"
                  className="form-control"
                  disabled={this.state.newEvent.fullDay}
                  onChange={(e) => {
                    const breakTime = e.target.value;
                    this.setState({
                      newEvent: {
                        ...this.state.newEvent,
                        break: breakTime,
                      },
                    });
                  }}
                ></input>
              ) : (
                <label className="form-control">{this.state.newEvent.break}</label>
              )}
            </div>
            {this.renderFullDayCheckBox(eventType)}
          </form>
          <hr></hr>
        </div>
      );
    }
    if (eventType === "planning" || eventType === "holiday" || eventType === "medical off" || eventType === "unpaid") {
    }
    if (eventType === "private" || eventType === "info") {
      return (
        <div>
          <form className="form-inline col-sm-12">
            <i className="far fa-clock fa-2x mx-2"></i>
            <div className="input-group mb-2 mr-md-2">
              <div className="input-group-prepend">
                <div className="input-group-text">
                  <span>{Translator("from")}</span>
                </div>
              </div>
              {this.state.newEvent.edit ? (
                <input
                  type="time"
                  value={moment(this.state.newEvent.start, "DD/MM/YYYY HH:mm").format("HH:mm")}
                  step="00:05"
                  className="form-control"
                  onChange={(e) => {
                    let startMom = new moment(this.state.newEvent.start, "DD/MM/YYYY HH:mm");
                    startMom.hour(moment(e.target.value, "HH:mm").hour());
                    startMom.minute(moment(e.target.value, "HH:mm").minute());
                    this.setState({
                      newEvent: {
                        ...this.state.newEvent,
                        start: MomentDateTimeString(startMom),
                        startHour: moment(e.target.value, "HH:mm").hour(),
                        startMinute: moment(e.target.value, "HH:mm").minute(),
                      },
                    });
                  }}
                ></input>
              ) : (
                <label className="form-control">
                  {moment(this.state.newEvent.start, "DD/MM/YYYY HH:mm").format("HH:mm")}
                </label>
              )}
            </div>
            <div className="input-group mb-2 mr-md-2 ">
              <div className="input-group-prepend">
                <div className="input-group-text">
                  <span>{Translator("to")}</span>
                </div>
              </div>
              {this.state.newEvent.edit ? (
                <input
                  type="time"
                  value={moment(this.state.newEvent.end, "DD/MM/YYYY HH:mm").format("HH:mm")}
                  step="00:05"
                  className="form-control"
                  onChange={(e) => {
                    let endMom = new moment(this.state.newEvent.end, "DD/MM/YYYY HH:mm");
                    endMom.hour(moment(e.target.value, "HH:mm").hour());
                    endMom.minute(moment(e.target.value, "HH:mm").minute());
                    this.setState({
                      newEvent: {
                        ...this.state.newEvent,
                        end: MomentDateTimeString(endMom),
                        endHour: moment(e.target.value, "HH:mm").hour(),
                        endMinute: moment(e.target.value, "HH:mm").minute(),
                      },
                    });
                  }}
                ></input>
              ) : (
                <label className="form-control">
                  {moment(this.state.newEvent.end, "DD/MM/YYYY HH:mm").format("HH:mm")}
                </label>
              )}
            </div>
          </form>
          <hr></hr>
        </div>
      );
    }
  }
  renderCustomerPicker(eventType) {
    if (eventType === "planning" || eventType === "customer") {
      return (
        <div>
          <div className="row">
            <div className="ml-3 my-2 col-lg-1">
              <label>{Translator("customer")}</label>
            </div>
            <div className="mx-3 mb-2 col-lg-9">
              {this.state.newEvent.edit && this.state.scope >= 2 ? (
                <Select
                  noOptionsMessage={() => {
                    return <label>{Translator("none")}</label>;
                  }}
                  value={
                    this.state.newEvent.customer
                      ? this.state.newEvent.customer
                      : this.state.customersOptionTable[0]
                        ? this.state.customersOptionTable[0]
                        : null
                  }
                  onChange={(selectedOption) => {
                    fetchCustomerConveyors(selectedOption.value).then((result) => {
                      const customerSite = result.Sites.map((site) => {
                        return {
                          value: site.id,
                          label: site.name,
                        };
                      });
                      customerSite.push({
                        value: "all",
                        label: Translator("all"),
                      });
                      this.setState({
                        newEvent: {
                          ...this.state.newEvent,
                          customer: selectedOption,
                        },
                        siteOptionTable: customerSite,
                      });
                    });
                  }}
                  options={this.state.customersOptionTable}
                />
              ) : (
                <label className="form-control">
                  {this.state.newEvent.customer
                    ? this.state.newEvent.customer.label
                    : this.state.customersOptionTable[0]
                      ? this.state.customersOptionTable[0].label
                      : null}
                </label>
              )}
            </div>
          </div>
          <div className="row">
            <div className="ml-3 my-2 col-lg-1">
              <label>{Translator("site")}</label>
            </div>
            <div className="mx-3 mb-2 col-lg-9">
              {this.state.newEvent.edit && this.state.scope >= 2 ? (
                <Select
                  filterOption={(toFilter, filter) => {
                    if (
                      toFilter.label
                        .toUpperCase()
                        .normalize("NFD")
                        .replace(/[\u0300-\u036f]/g, "")
                        .startsWith(
                          filter
                            .toUpperCase()
                            .normalize("NFD")
                            .replace(/[\u0300-\u036f]/g, "")
                        )
                    )
                      return toFilter;
                  }}
                  noOptionsMessage={() => {
                    return <label>{Translator("none")}</label>;
                  }}
                  value={
                    this.state.newEvent.site
                      ? this.state.newEvent.site
                      : this.state.siteOptionTable.find((el) => el.value === "all")
                  }
                  onChange={(selectedOption) => {
                    this.setState({
                      newEvent: {
                        ...this.state.newEvent,
                        site: selectedOption,
                      },
                    });
                  }}
                  options={this.state.siteOptionTable}
                />
              ) : (
                <label className="form-control">
                  {this.state.newEvent.site
                    ? this.state.newEvent.site.label
                    : this.state.siteOptionTable.find((el) => el.value === "all")
                      ? this.state.siteOptionTable.find((el) => el.value === "all").label
                      : null}
                </label>
              )}
            </div>
          </div>
          <hr></hr>
        </div>
      );
    }
  }
  renderLockedSwitch(eventType) {
    return store().getUser().accreditation < 2 ? (
      <div className="col sm-12">
        <div className="custom-control custom-switch">
          <input
            type="checkbox"
            disabled={!this.state.newEvent.edit}
            checked={this.state.newEvent.locked}
            className="custom-control-input"
            id="customSwitch1"
            onChange={(e) => {
              this.setState({
                newEvent: {
                  ...this.state.newEvent,
                  locked: !this.state.newEvent.locked,
                },
              });
            }}
          ></input>
          <label className="custom-control-label" htmlFor="customSwitch1">
            {Translator("locked")}
          </label>
        </div>
        <hr></hr>
      </div>
    ) : null;
  }

  renderEventText(eventType, disabled) {
    const renderHtml = (html) => {
      return { __html: html };
    };
    if (this.state.newEvent.edit) {
      if (eventType === "private" || eventType === "info") {
        return (
          <RichText
            title={Translator("description")}
            defaultValue={this.state.newEvent.eventDescription ? this.state.newEvent.eventDescription : ""}
            onChange={(content) => {
              this.setState({
                ...this.state,
                newEvent: { ...this.state.newEvent, eventDescription: content },
              });
            }}
            developped
          ></RichText>
        );
      }
      return (
        <form className="form col-sm-12">
          {disabled || (this.state.newEvent.userEventId && store().getUser().accreditation > 3) ? (
            <div dangerouslySetInnerHTML={renderHtml(this.state.newEvent.eventDescription)}></div>
          ) : (
            <RichText
              title={(() => {
                const length = this.state.descLength ? this.state.descLength : 0;
                const renderWarning = (length) => {
                  if (store().getUser().accreditation > 3) {
                    if (length > 50) {
                      return (
                        <span style={{ color: "Tomato" }}>
                          <i className="fas fa-exclamation-triangle"></i>
                          &nbsp;
                          {Translator("description exceeds 50 characters and is too long, it won't be saved.")}
                        </span>
                      );
                    } else {
                      return Translator("description");
                    }
                  } else {
                    return Translator("description");
                  }
                };
                return renderWarning(length);
              })()}
              defaultValue={this.state.newEvent.eventDescription ? this.state.newEvent.eventDescription : ""}
              onChange={(content) => {
                if (content.length < 50 || store().getUser().accreditation < 4) {
                  this.setState({
                    ...this.state,
                    descLength: content.length,
                    newEvent: {
                      ...this.state.newEvent,
                      eventDescription: content,
                    },
                  });
                } else {
                  this.setState({
                    descLength: content.length,
                  });
                }
              }}
              developped
            ></RichText>
          )}
        </form>
      );
    } else {
      return <div dangerouslySetInnerHTML={renderHtml(this.state.newEvent.eventDescription)}></div>;
    }
  }

  renderScopePicker(eventType, disabled) {
    if (store().getUser().accreditation < 3) {
      return (
        <div>
          <h4 className="mr-3">{this.state.scope < 2 ? Translator("modify for") : null}</h4>
          <form className="form-inline col-sm-12">
            {this.state.scope <= 2 ? (
              <span>
                <div className="form-group">
                  <div className="custom-control custom-radio">
                    <input
                      className="custom-control-input"
                      type="radio"
                      id="onlyMe"
                      name="scope"
                      checked={this.state.scope === 1}
                      onChange={(e) => {
                        this.setState({ scope: 1 });
                      }}
                    ></input>
                    <label htmlFor="onlyMe" className="custom-control-label">
                      {Translator("only me and this specific day")}
                    </label>
                  </div>
                </div>
                <div className="form-group">
                  <div className="custom-control custom-radio">
                    <input
                      className="custom-control-input"
                      type="radio"
                      id="wholeEvent"
                      name="scope"
                      checked={this.state.scope === 2}
                      onChange={(e) => {
                        if (this.props.minMaxDate.min) {
                          this.setState({
                            scope: 2,
                            newEvent: {
                              ...this.state.newEvent,
                              start: this.props.minMaxDate.min.format("DD/MM/YYYY HH:mm"),
                              end: this.props.minMaxDate.max.format("DD/MM/YYYY HH:mm"),
                            },
                          });
                        }
                        this.setState({ scope: 2 });
                      }}
                    ></input>
                    <label htmlFor="wholeEvent" className="custom-control-label">
                      {Translator("whole event")}
                    </label>
                  </div>
                </div>
              </span>
            ) : null}
            {this.state.scope >= 2 ? (
              <span className="input-group mb-2 mr-2">
                <i className="fas fa-users fa-2x mx-2"></i>
                {this.renderUsersCheckBox(eventType)}
              </span>
            ) : null}
          </form>
          <hr></hr>
        </div>
      );
    }
  }
  renderEventTitle(eventType, disabled) {
    if (this.state.newEvent.edit) {
      if (eventType === "private" || eventType === "info") {
        return this.renderTitleInputText();
      }
    } else {
      return <h2>{this.state.newEvent.title}</h2>;
    }
  }
  renderEditButton(eventType, isDisabled) {
    let disabled = isDisabled;
    if (
      store().getUser().userId !== this.props.newEvent.periodUser &&
      store().getUser().accreditation > 1 &&
      store().getUser().accreditation !== 3
    ) {
      disabled = true;
    }
    if (store().getUser().accreditation > this.state.newEvent.eventTypes.find((et) => et.active).accreditation) {
      disabled = true;
      if (
        this.state.newEvent.eventTypes.find((et) => et.active).value === "planning" &&
        store().getUser().accreditation === 3
      ) {
        disabled = false;
      }
    }
    return (
      <button
        className="btn btn-primary"
        disabled={disabled}
        onClick={(e) => {
          e.preventDefault();
          this.setState({ newEvent: { ...this.state.newEvent, edit: true } });
        }}
      >
        {Translator("edit")}
      </button>
    );
  }

  renderDeleteButton(eventType, isDisabled) {
    let disabled = isDisabled;
    if (this.props.user !== store().getUser().shortName && store().getUser().accreditation > 1) {
      disabled = true;
    }
    if (store().getUser().accreditation > this.state.newEvent.eventTypes.find((et) => et.active).accreditation) {
      disabled = true;
      if (
        this.state.newEvent.eventTypes.find((et) => et.active).value === "planning" &&
        store().getUser().accreditation === 3
      ) {
        disabled = false;
      }
    }

    return (
      <button
        type="button"
        className="btn btn-danger"
        data-dismiss="modal"
        disabled={disabled}
        onClick={(e) => {
          e.preventDefault();
          this.state.scope === 2
            ? this.deleteEventCallback(this.props.newEvent.eventId)
            : this.deleteUserEventCallback(this.props.newEvent.userEventId);
        }}
        rel="tooltip"
        data-placement="bottom"
        title={
          this.state.scope === 2
            ? "DELETE WHOLE EVENT " + this.props.newEvent.eventId
            : "DELETE THIS DAY" + this.props.newEvent.userEventId
        }
      >
        {Translator("delete")}
      </button>
    );
  }
  render() {
    const disabled = this.state.newEvent.locked && store().getUser().accreditation > 1;
    const eventType = this.state.newEvent.eventTypes.find((et) => et.active).value;
    return (
      <div className="modal fade" id="modal-default" data-backdrop="static" data-keyboard="false">
        <div className="modal-dialog modal-xl" role="document">
          {this.props.isFetching ? (
            <div className="modal-content" style={{ textAlign: "center" }}>
              <div className="modal-header">
                <h4 className="modal-title">{Translator("loading")}</h4>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <i className="fas fa-2x fa-sync-alt fa-spin"></i>
              </div>
            </div>
          ) : (
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">{this.generateTabs(disabled)}</h4>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <div className="modal-body">
                <div className={this.creatingMode ? "info-box slideOutUp animated" : "info-box slideInDown animated"}>
                  <span className={disabled ? "info-box-icon bg-secondary" : "info-box-icon bg-info"}>
                    <i className={disabled ? "fas fa-unlock-alt" : "fas fa-calendar-plus"}></i>
                  </span>
                  <div className="table-responsive">
                    <div className="info-box-content">
                      {this.renderLockedSwitch(eventType)}
                      {this.renderScopePicker(eventType, disabled)}
                      {this.renderDatePicker(eventType)}
                      {this.renderTimePicker(eventType)}
                      {this.renderCustomerPicker(eventType)}
                      {this.renderEventTitle(eventType, disabled)}
                      {this.renderEventText(eventType, disabled)}
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer justify-content-between">
                {this.renderDeleteButton(eventType, disabled)}
                {this.state.newEvent.edit ? (
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-dismiss="modal"
                    //data-toggle="tooltip"
                    disabled={disabled}
                    //title={JSON.stringify(this.state.newEvent)}
                    onClick={(e) => {
                      e.preventDefault();
                      let forceFullDay = false;
                      const eventType = this.state.newEvent.eventTypes.find((evtType) => evtType.active).value;
                      if (
                        eventType === "planning" ||
                        eventType === "holiday" ||
                        eventType === "medical off" ||
                        eventType === "unpaid" ||
                        eventType === "technical off" ||
                        eventType === "national holiday" ||
                        eventType === "recup national holiday"
                      ) {
                        forceFullDay = true;
                      }

                      const breakTime =
                        eventType === "private" || eventType === "info" || eventType === "OFF"
                          ? ""
                          : this.state.newEvent.break;

                      const payload = {
                        eventId: this.state.newEvent.eventId,
                        userEventId: this.state.scope <= 2 ? this.state.newEvent.userEventId : null,
                        start: this.state.newEvent.start,
                        end: this.state.newEvent.end,
                        customer:
                          eventType === "planning" || eventType === "customer"
                            ? this.state.newEvent.customer.value
                            : null,
                        site: this.state.newEvent.site ? this.state.newEvent.site.value : null,
                        title: this.state.newEvent.title ? this.state.newEvent.title : null,
                        users: Object.keys(this.state.newEvent.users),
                        break: breakTime,
                        eventDescription: this.state.newEvent.eventDescription,
                        eventType: eventType,
                        locked: this.state.newEvent.locked,
                        fullDay: forceFullDay
                          ? forceFullDay
                          : eventType === "private" || eventType === "info"
                            ? false
                            : this.state.newEvent.fullDay,
                      };
                      console.log(this.state.scope);
                      this.state.scope <= 2
                        ? this.state.scope <= 1
                          ? this.editUserEventCallback(payload)
                          : this.editEventCallback(payload)
                        : this.postEventCallback(payload);
                    }}
                  >
                    {Translator("save")}
                  </button>
                ) : (
                  this.renderEditButton(eventType, disabled)
                )}
                <button type="button" className="btn btn-default" data-dismiss="modal" data-placement="right">
                  {Translator("close")}
                </button>
              </div>
            </div>
          )}
          <div className="ribbon-wrapper ribbon-xl">
            <div
              className={
                this.state.scope < 2 ? (disabled ? "ribbon bg-secondary" : "ribbon bg-info") : "ribbon bg-success"
              }
            >
              {this.state.scope < 2
                ? disabled
                  ? Translator("locked event")
                  : Translator("edit event")
                : Translator("new event")}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EventForm;
