import React, { Component } from "react";
import LogbookEntry from "../../components/LogbookEntry";
import RichText from "../../components/RichText";
import { v4 as uuidv4 } from 'uuid';
import { MomentDateString, parseForTable, store } from "../../tools/Tools";
import { Translator } from "../../tools/Tools";
import moment from "moment";
import { postNewLogbook, fetchLogbooks, fetchUsers } from "../../tools/Api_Customers";
import { withRouter } from "react-router-dom";
import $ from "jquery";

class CustomerLogbook extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newEntry: {
        author: [],
        description: this.props.defaultLogbookContent ? this.props.defaultLogbookContent : null,
        ticket: this.props.logbookTicket ? this.props.logbookTicket : null,
        checklist: this.props.logbookChecklist ? this.props.logbookChecklist : null,

      },

      editedEntry: { author: [] },
      creatingMode: props.creatingMode,
      editingMode: {},
      data: [],
      users: { data: [] },
    };
  }

  componentDidMount() {
    this.reload();
  }

  reload() {
    this.setState({ isFetching: true });
    fetchLogbooks(this.props.generalInfos.id).then((logbooks) => {
      const filteredLogbooks = {
        data: logbooks.data.filter((log) => moment(log.date).isSame(moment(), "year")),
        columns: logbooks.columns,
      };
      fetchUsers().then((users) => {
        this.setState(() => {
          return {
            ...parseForTable(logbooks),
            isFetching: false,
            users: users,
            newEntry: {
              author: users.data.filter((user) => {
                return (
                  store().getUser().userId === user.id ||
                  (this.props.defaultLogbookUsers && this.props.defaultLogbookUsers.includes(user.id))
                );
              }),
              date: MomentDateString(new Date()),
              description: this.props.defaultLogbookContent ? this.props.defaultLogbookContent : null,
              ticket: this.props.logbookTicket ? this.props.logbookTicket : null,
              checklist: this.props.logbookChecklist ? this.props.logbookChecklist : null,
            },
          };
        });
      });
    });
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.isFetching) {
      this.setState({ isFetching: false });
    }
    if (prevProps.creatingMode !== this.props.creatingMode) {
      this.setState({ creatingMode: this.props.creatingMode });
    }
    if (this.state.creatingMode) {
      $("#modal-redaction").modal('show');
    }
  }

  postCallback(newLogbook) {
    this.setState({ isFetching: true });
    postNewLogbook(this.props.generalInfos.id, newLogbook).then(() => {
      this.reload();
    });
  }
  customCreateForm() {
    return (
      <div className={this.state.creatingMode ? "info-box slideInDown animated" : "info-box"}>
        <span className="info-box-icon bg-info">
          <span className="fa-stack">
            <i className="fas fa-file fa-stack-2x"></i>
            <i className="fas fa-plus fa-stack-1x fa-inverse" style={{ color: "#17a2b8" }}></i>
          </span>
        </span>
        <div className="table-responsive">
          <div className="info-box-content">
            <div className="row">
              <div className="form col-md-3">
                <div className="mb-2 mr-md-2">
                  <input
                    type="date"
                    className="form-control"
                    value={moment(this.state.newEntry.date, "DD/MM/YYYY").format("YYYY-MM-DD")}
                    onChange={(e) => {
                      this.setState({
                        newEntry: {
                          ...this.state.newEntry,
                          date: MomentDateString(e.target.valueAsDate),
                        },
                      });
                    }}
                  ></input>
                </div>
                <div className="mb-2 mr-md-2">
                  <select
                    className="custom-select"
                    defaultValue="telemaintenance"
                    onChange={(e) => {
                      this.setState({
                        newEntry: {
                          ...this.state.newEntry,
                          type: e.target.value,
                        },
                      });
                    }}
                  >
                    <option value="telemaintenance">{Translator("telemaintenance")}</option>
                    <option value="sur place">{Translator("sur place")}</option>
                    <option value="visite">{Translator("visite préventive")}</option>
                  </select>
                </div>

                <div className="mb-2 mr-md-2">
                  <h6>- {store().getUser().shortName}</h6>
                </div>
                {this.state.users.data.map((user) => {
                  if (user.id !== store().getUser().userId) {
                    return (
                      <div key={user.id} className="input-group mb-2 mr-2">
                        <div className="input-group-prepend">
                          <div className="input-group-text">
                            <input
                              defaultValue={user.id}
                              defaultChecked={this.state.newEntry.author.map((x) => x.id).includes(user.id)}
                              type="checkbox"
                              onChange={(e) => {
                                let authors = e.target.checked
                                  ? [...this.state.newEntry.author, user]
                                  : this.state.newEntry.author.filter((entry) => entry.id !== user.id);
                                this.setState((this.state.newEntry.author = authors));
                              }}
                            ></input>
                          </div>
                        </div>
                        <label type="text" className="form-control text-truncate">
                          {user.shortName}
                        </label>
                      </div>
                    );
                  }
                })}
              </div>
              <div className="col-md-9">
                <RichText
                  title={Translator("description")}
                  inModal
                  defaultValue={this.props.defaultLogbookContent ? this.props.defaultLogbookContent : null}
                  onChange={(content) => {
                    this.setState({
                      newEntry: {
                        ...this.state.newEntry,
                        description: content,
                      },
                    });
                  }}
                  developped
                ></RichText>
                <RichText
                  title={Translator("replaced parts")}
                  inModal
                  defaultValue={null}
                  onChange={(content) => {
                    this.setState({
                      newEntry: {
                        ...this.state.newEntry,
                        parts_replaced: content,
                      },
                    });
                  }}
                ></RichText>
                <RichText
                  title={Translator("parts to replace")}
                  inModal
                  defaultValue={null}
                  onChange={(content) => {
                    this.setState({
                      newEntry: {
                        ...this.state.newEntry,
                        parts_to_replace: content,
                      },
                    });
                  }}
                ></RichText>
              </div>
            </div>
          </div>
        </div>
        <div className="ribbon-wrapper ribbon-lg">
          <div className="ribbon bg-success">{Translator("new logbook")}</div>
        </div>
      </div>
    );
  }
  customEditForm(data) {
    return (
      <div className="info-box">
        <span className="info-box-icon bg-info">
          <i className="fas fa-edit"></i>
        </span>
        <div className="table-responsive">
          <div className="info-box-content">
            <div className="row">
              <div className="form col-md-3">
                <div className="mb-2 mr-md-2">
                  <input
                    type="date"
                    className="form-control"
                    value={moment(
                      this.state.editedEntry.date
                        ? moment(this.state.editedEntry.date)
                        : moment(data.date).format("DD/MM/YYYY"),
                      "DD/MM/YYYY"
                    ).format("YYYY-MM-DD")}
                    onChange={(e) => {
                      this.setState({
                        editedEntry: {
                          ...this.state.editedEntry,
                          date: e.target.valueAsDate,
                        },
                      });
                    }}
                  ></input>
                </div>
                <div className="mb-2 mr-md-2">
                  <select
                    className="custom-select"
                    defaultValue={data.type}
                    onChange={(e) => {
                      this.setState({
                        editedEntry: {
                          ...this.state.editedEntry,
                          type: e.target.value,
                        },
                      });
                    }}
                  >
                    <option value="telemaintenance">{Translator("telemaintenance")}</option>
                    <option value="sur place">{Translator("sur place")}</option>
                    <option value="visite">{Translator("visite préventive")}</option>
                  </select>
                </div>
                {this.state.users.data.map((user) => {
                  return (
                    <div key={user.id} className="input-group mb-2 mr-2">
                      <div className="input-group-prepend">
                        <div className="input-group-text">
                          <input
                            defaultChecked={data.author.map((author) => author.id).includes(user.id)}
                            defaultValue={user.id}
                            type="checkbox"
                            onChange={(e) => {
                              let authors = e.target.checked
                                ? [...this.state.editedEntry.author, user]
                                : this.state.editedEntry.author.filter((entry) => entry.id !== user.id);
                              this.setState((this.state.editedEntry.author = authors));
                            }}
                          ></input>
                        </div>
                      </div>
                      <label type="text" className="form-control text-truncate">
                        {user.shortName}
                      </label>
                    </div>
                  );
                })}
                <hr></hr>
                <form className="form-inline col-sm-12"></form>
              </div>
              <div className="col-md-9">
                <RichText
                  title={Translator("description")}
                  inModal
                  defaultValue={data.description}
                  onChange={(content) => {
                    this.setState({
                      editedEntry: {
                        ...this.state.editedEntry,
                        description: content,
                      },
                    });
                  }}
                  developped
                ></RichText>
                <RichText
                  title={Translator("replaced parts")}
                  inModal
                  defaultValue={data.parts_replaced}
                  onChange={(content) => {
                    this.setState({
                      editedEntry: {
                        ...this.state.editedEntry,
                        parts_replaced: content,
                      },
                    });
                  }}
                ></RichText>
                <RichText
                  title={Translator("parts to replace")}
                  //inModal to handle properly the insert file
                  inModal
                  defaultValue={data.parts_to_replace}
                  onChange={(content) => {
                    this.setState({
                      editedEntry: {
                        ...this.state.editedEntry,
                        parts_to_replace: content,
                      },
                    });
                  }}
                ></RichText>
              </div>
            </div>
          </div>
        </div>{" "}
        <div className="ribbon-wrapper ribbon-lg">
          <div className="ribbon bg-warning">{Translator("edit logbook")}</div>
        </div>
      </div>
    );
  }
  setEditModeCallBack(data, key) {
    if (!this.state.editingMode[key]) {
      this.setState({
        editingMode: { [key]: true },
        editedEntry: this.state.data.find((element) => element.id === key),
        editedData: data,
      });
    } else {
      this.setState({
        editingMode: { [key]: false },
        editedEntry: { author: [] },
      });
    }
  }
  renderComponentTable() {
    let sortedData = this.state.data;
    const compare = (a, b) => {
      if (moment(a.date).isBefore(moment(b.date))) return this.state.sort ? -1 : 1;
      if (moment(a.date).isAfter(moment(b.date))) return this.state.sort ? 1 : -1;
      if (moment(a.createdAt).isBefore(moment(b.createdAt))) return this.state.sort ? -1 : 1;
      if (moment(a.createdAt).isAfter(moment(b.createdAt))) return this.state.sort ? 1 : -1;
      return 0;
    };

    sortedData.sort(compare);

    return sortedData.map((data) => {
      return (
        <div key={"edit" + data.id}>
          <div className="info-box slideInLeft animated">
            <LogbookEntry
              key={data.id}
              id={data.id}
              date={data.date}
              author={data.author}
              type={data.type}
              description={data.description}
              replaced={data.parts_replaced}
              toReplace={data.parts_to_replace}
              editCallback={this.setEditModeCallBack.bind(this, data)}
            ></LogbookEntry>
          </div>
        </div>
      );
    });
  }
  saveLogbook() {
    const newEntry = this.state.newEntry;
    newEntry.id = uuidv4();
    newEntry.type = newEntry.type ? newEntry.type : "telemaintenance";
    newEntry.date = newEntry.date ? newEntry.date : MomentDateString(new Date());
    newEntry.description = newEntry.description
      ? newEntry.description !== "<br>"
        ? newEntry.description
        : null
      : null;
    newEntry.parts_replaced = newEntry.parts_replaced
      ? newEntry.parts_replaced !== "<br>"
        ? newEntry.parts_replaced
        : null
      : null;
    newEntry.parts_to_replace = newEntry.parts_to_replace
      ? newEntry.parts_to_replace !== "<br>"
        ? newEntry.parts_to_replace
        : null
      : null;
    console.log(newEntry);
    this.postCallback(newEntry);
    this.props.triggerCreatingMode();
    newEntry.date = newEntry.date ? moment(newEntry.date, "DD/MM/YYYY") : new Date();
    this.setState({
      //data: [...this.state.data, newEntry],
      creatingMode: false,
      newEntry: {
        author: this.state.users.data.filter((user) => {
          return store().getUser().userId === user.id;
        }),
        date: MomentDateString(new Date()),
      },
    });
  }
  editLogbook() {
    const editedEntry = this.state.editedEntry;
    editedEntry.id = this.state.editedData.id;
    editedEntry.type = editedEntry.type ? editedEntry.type : "telemaintenance";
    editedEntry.date = editedEntry.date ? MomentDateString(editedEntry.date) : MomentDateString(new Date());
    editedEntry.description = editedEntry.description
      ? editedEntry.description !== "<br>"
        ? editedEntry.description
        : null
      : this.state.editedData.description;
    editedEntry.parts_replaced = editedEntry.parts_replaced
      ? editedEntry.parts_replaced !== "<br>"
        ? editedEntry.parts_replaced
        : null
      : this.state.editedData.parts_replaced;
    editedEntry.parts_to_replace = editedEntry.parts_to_replace
      ? editedEntry.parts_to_replace !== "<br>"
        ? editedEntry.parts_to_replace
        : null
      : this.state.editedData.toReplace;
    editedEntry.author = editedEntry.author;
    const index = this.state.data
      .map((e) => {
        return e.id;
      })
      .indexOf(this.state.editedData.id);
    let newDatas = this.state.data;
    console.log(JSON.stringify(editedEntry));
    this.postCallback(editedEntry);
    editedEntry.date = editedEntry.date
      ? moment(editedEntry.date, "DD/MM/YYYY")
      : moment(this.state.editedData.date, "DD/MM/YYYY");
    newDatas[index] = JSON.parse(JSON.stringify(editedEntry));
    console.log(JSON.stringify(editedEntry));

    this.setState({
      editedEntry: { author: [] },
      //data: newDatas,
      editingMode: false,
      editedData: null,
    });
  }
  isSaveButtonDisabled() {
    return this.state.newEntry
      ? this.state.newEntry.description
        ? this.state.newEntry.description !== "<br>"
          ? false
          : true
        : true
      : true;
  }
  cancelEdit() {
    this.setState({ editingMode: { [this.state.editedData.id]: false }, editedData: null });
  }
  renderModalFooterButtons() {
    if (this.state.creatingMode) {
      return (
        <form className="form-inline col-sm-12">
          <button
            className="btn btn-info btn "
            data-dismiss="modal"
            onClick={(e) => {
              e.preventDefault();
              this.saveLogbook();
            }}
            disabled={this.isSaveButtonDisabled()}
          >
            {Translator("enregistrer")}
          </button>
        </form>
      );
    } else {
      return (
        <form className="form-inline col-sm-12">
          <div className="input-group mb-2 mr-sm-2">
            <button
              className="btn btn-info btn "
              data-dismiss="modal"
              onClick={(e) => {
                e.preventDefault();
                this.editLogbook();
              }}
            >
              {Translator("enregistrer")}
            </button>
          </div>
          <div className="input-group mb-2 mr-sm-2">
            <button
              className="btn btn-info btn "
              data-dismiss="modal"
              onClick={(e) => {
                e.preventDefault();
                this.cancelEdit();
              }}
            >
              {Translator("annuler")}
            </button>
          </div>
        </form>
      );
    }
  }
  render() {
    return (
      <div>
        <div
          className="modal fade"
          id="modal-redaction"
          data-backdrop="static"
          tabIndex="-1"
          role="dialog"
          data-keyboard="false"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="staticBackdropLabel">
                  {Translator(this.state.creatingMode ? "new logbook" : this.state.editedData ? "edit logbook" : "")}{" "}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={(e) => {
                    e.preventDefault();
                    if (this.state.creatingMode) {
                      this.props.triggerCreatingMode();
                      this.setState({
                        newEntry: {
                          author: this.state.users.data.filter((user) => {
                            return store().getUser().userId === user.id;
                          }),
                          date: MomentDateString(new Date()),
                          description: null,
                        },
                      });
                    }
                    if (this.state.editingMode && this.state.editingMode[this.state.editedEntry.id]) this.cancelEdit();
                  }}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                {this.state.creatingMode
                  ? this.customCreateForm()
                  : this.state.editedData
                    ? this.customEditForm(this.state.editedData)
                    : null}
              </div>

              <div className="modal-footer">{this.renderModalFooterButtons()}</div>
            </div>
          </div>
        </div>

        {this.renderComponentTable()}
      </div>
    );
  }
}

export default withRouter(CustomerLogbook);
