import React, { Component, cloneElement } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { Translator, store } from "../../tools/Tools";
import Card from "../../components/Card";
import { fetchPendingProjects } from "../../tools/Api_Customers";
import { v4 as uuidv4 } from 'uuid';
import ProjectTextOverview from "./ProjectTextOverview";
import ProjectWidget from "./ProjectWidget";
import ProjectDropSlot from "./ProjectDropSlot";

class ProjectsOverview extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.sliderTimeout = null;
    this.state = {
      truncate: true,
      currentOffset: 1,
      displayedOffset: 1,
      min: 1,
      max: 1,
      //projects: props.projects ? props.projects : [],
    };
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.min !== this.state.min ||
      prevState.max !== this.state.max ||
      prevState.currentOffset !== this.state.currentOffset
    ) {
      //console.log("will update again")
      let min = moment();
      let max = moment();
      this.props.projects.map((p) => {
        p.ProjectPeriods.map((pp) => {
          if (moment(pp.from).isBefore(min)) min = moment(pp.from);
          if (moment(pp.to).isAfter(max)) max = moment(pp.to);
        });
      });
      const offset = max.diff(min, "month");
      this.setState({
        min: 1,
        max: offset + 2,
        projOverview: this.renderProjectOverview()
      });
    }
    if (prevProps.projects !== this.props.projects || this.state.truncate != prevState.truncate) {
      this.setState({ projOverview: this.renderProjectOverview() })
    }
    if (this.state.prevProjOverview !== null) this.setState({ prevProjOverview: null, projOverview: this.state.prevProjOverview })

  }

  componentDidMount() {
    let min = moment();
    let max = moment();
    this.props.projects.map((p) => {
      p.ProjectPeriods.map((pp) => {
        if (moment(pp.from).isBefore(min)) min = moment(pp.from);
        if (moment(pp.to).isAfter(max)) max = moment(pp.to);
      });
    });
    const offset = max.diff(min, "month");
    this.setState({ min: 1, max: offset + 1, currentOffset: offset + 2, displayedOffset: offset + 2, projOverview: this.renderProjectOverview() });
  }
  translateProjectPeriod(projectPeriodId, offset) {
    this.props.translateProjectPeriod(projectPeriodId, offset);
  }

  refreshOverview() {
    this.setState({ prevProjOverview: this.state.projOverview, projOverview: null })
  }

  renderProjectOverview() {
    const daysLoop = (offset, project, weeknumHeader) => {
      let empty = true;
      let result = [];
      if (project) {
        for (let i = 1; i <= moment().add(offset, "month").daysInMonth(); i++) {
          let firstDayOfPeriod = false;
          const weekDay = moment().add(offset, "month").startOf("month").add(i, "days").isoWeekday();
          let cellContent = "";
          let cellContent2 = "";
          let color1 = "";
          let color2 = "";
          let cellBackground = "none";
          let icon = "";
          let bufferedPp = null;
          project.ProjectPeriods.map((pp) => {
            if (
              moment()
                .add(offset, "month")
                .startOf("month")
                .add(i - 1, "days")
                .isBetween(moment(pp.from), moment(pp.to), "days", [])
            ) {
              firstDayOfPeriod = moment(pp.from).isSame(moment()
                .add(offset, "month")
                .startOf("month")
                .add(i - 1, "days"), "days")

              if (firstDayOfPeriod) bufferedPp = pp
              empty = false;
              switch (pp.type) {
                case "preparation":
                  cellBackground = "primary";
                  if (!color1) color1 = "#007bff"
                  color2 = "#007bff"
                  icon = "fa-database";
                  break;
                case "delivery":
                  cellBackground = "navy";
                  if (!color1) color1 = "#001f3f"
                  color2 = "#001f3f"
                  icon = "fa-truck-moving";
                  break;
                case "assembly":
                  cellBackground = "warning";
                  if (!color1) color1 = "#ffc107"
                  color2 = "#ffc107"
                  icon = "fa-tools";
                  break;
                case "tests":
                  cellBackground = "fuchsia";
                  if (!color1) color1 = "#f012be"
                  color2 = "#f012be"
                  icon = "fa-power-off";
                  break;
                case "tests & startup":
                  cellBackground = "danger";
                  if (!color1) color1 = "#dc3545"
                  color2 = "#dc3545"
                  icon = "fa-power-off";
                  break;
                case "startup":
                  cellBackground = "danger";
                  if (!color1) color1 = "#dc3545"
                  color2 = "#dc3545"
                  icon = "fa-flag-checkered";
                  break;
                case "training":
                  cellBackground = "success";
                  if (!color1) color1 = "#28a745"
                  color2 = "#28a745"
                  icon = "fa-graduation-cap";
                  break;
                case "upgrade software":
                  cellBackground = "purple";
                  if (!color1) color1 = "#605ca8"
                  color2 = "#605ca8"
                  icon = "fa-upload";
                  break;
                case "upgrade hardware":
                  cellBackground = "orange";
                  if (!color1) color1 = "#ff851b"
                  color2 = "#ff851b"
                  icon = "fa-rocket";
                  break;
                case "maintenance":
                  cellBackground = "info";
                  if (!color1) color1 = "#17a2b8"
                  color2 = "#17a2b8"
                  icon = "fa-oil-can";
                  break;
                case "waiting":
                  cellBackground = "teal";
                  if (!color1) color1 = "#39cccc"
                  color2 = "#39cccc"
                  icon = "fa-question";

                  break;
              }
              if (!cellContent) {
                //color1 = cellBackground;
                cellContent2 = (
                  <span className={`fa-stack mx-n2`} rel="tooltip" data-html="true" data-placement="bottom" title={pp.note}
                    style={{
                      transform: "scale(0.7, 0.7) translate(-10px, -15px)",
                    }}>
                    <i className={`fas ${icon} fa-stack-2x`} style={{ color: "white" }}></i>
                    <span className=" fa-stack-2x" style={{ color: "black" }}>
                      <b>{pp.workersCount}</b>
                    </span>
                  </span>
                );
                cellContent = (
                  <span className={`fa-stack mx-n2`} rel="tooltip" data-html="true" data-placement="bottom" title={pp.note}>
                    <i className={`fas ${icon} fa-stack-2x`} style={{ color: "white" }}></i>
                    <span className=" fa-stack-2x" style={{ color: "black" }}>
                      <b>{pp.workersCount}</b>
                    </span>
                  </span>
                );
              } else {
                //color2 = cellBackground;
                if (cellContent2) {
                  cellContent = <span style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>{cellContent2}<span className={`fa-stack mx-n2`} rel="tooltip" data-html="true" data-placement="bottom"
                    title={pp.note} style={{
                      transform: "scale(0.7, 0.7) translate(10px, 15px)",
                    }}>
                    <i className={`fas ${icon} fa-stack-2x`} style={{ color: "white" }} ></i>
                    <span className=" fa-stack-2x" style={{ color: "black" }}>
                      <b>{pp.workersCount}</b>
                    </span>
                  </span></span>
                  cellContent2 = "";
                }
              }
            }
          });
          if (weekDay === 1 || weekDay === 7) {
            result.push(
              <td
                key={i}
                style={{
                  backgroundColor: "lightgray",
                }}
              ></td>
            );
          } else {
            let style = !cellContent2 && cellContent ? { border: firstDayOfPeriod ? "2px dotted black" : "0px", textAlign: "center", verticalAlign: "middle", backgroundImage: `linear-gradient(to bottom right, ${color1} 49%, black 50%, ${color2} 51%)` }
              : { border: firstDayOfPeriod ? "2px dotted black" : "0px", textAlign: "center", verticalAlign: "middle", backgroundColor: color1 };
            result.push(firstDayOfPeriod && store().getUser().accreditation < 2 ?
              <ProjectWidget key={i} style={style} content={cellContent}
                hasTwo={!cellContent2 && cellContent}
                refreshOverview={this.refreshOverview.bind(this)}
                translateProjectPeriod={this.translateProjectPeriod.bind(this)}
                project={project} projectPeriod={bufferedPp} date={moment().add(offset, "month").startOf("month").add(i - 1, "days")}></ProjectWidget> : <td key={i} style={style}>{cellContent}</td>
            );
          }
        }
      } else if (weeknumHeader) {
        let previousWeekNum = moment().add(offset, "month").startOf("month").startOf("week").isoWeek();
        let previousWeekCpt = 0;
        let sameWeek = true;
        result.push(
          <td key="weekNums" style={{ textAlign: "center", backgroundColor: "#CCCCCC" }}>
            {Translator("week number")}
          </td>
        );
        for (let i = 0; i <= moment().add(offset, "month").daysInMonth(); i++) {
          const weekNum = moment().add(offset, "month").startOf("month").add(i, "days").startOf("week").isoWeek();

          if (weekNum !== previousWeekNum) {
            result.push(
              <td
                key={uuidv4()}
                colSpan={previousWeekCpt}
                style={{ textAlign: "center", backgroundColor: "whitesmoke", border: "1px solid black" }}
              >
                {previousWeekNum}
              </td>
            );
            previousWeekNum = weekNum;
            previousWeekCpt = 1;
            sameWeek = false;
          } else {
            previousWeekCpt++;
            sameWeek = true;
          }
        }
        if (sameWeek) {
          const nextWeek = moment().isoWeek(previousWeekNum).isoWeek();
          result.push(
            <td
              key={uuidv4()}
              colSpan={previousWeekCpt}
              style={{ textAlign: "center", backgroundColor: "whitesmoke", border: "1px solid black" }}
            >
              {nextWeek}
            </td>
          );
        }
      } else {
        for (let i = 1; i <= moment().add(offset, "month").daysInMonth(); i++) {
          const weekDay = moment().add(offset, "month").startOf("month").add(i, "days").isoWeekday();
          const weekNum = moment()
            .add(offset, "month")
            .startOf("month")
            .add(i - 1, "days")
            .startOf("week")
            .isoWeek();
          if (weekDay === 1 || weekDay === 7) {
            result.push(
              <td key={i} style={{ backgroundColor: "#bdbdbd" }}>{i}</td>
            );
          } else {
            result.push(<ProjectDropSlot key={i} content={i} date={moment().add(offset, "month").startOf("month").add(i - 1, "days")}></ProjectDropSlot>);
          }
        }
      }

      return { result, empty };
    };
    const monthLoop = () => {
      let result = [];

      for (let i = 0; i < this.state.currentOffset; i++) {
        result.push(
          <div className="table-responsive" key={i}>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <td
                    colSpan={1 + moment().add(i, "month").daysInMonth()}
                    style={{ textAlign: "center", color: "white", backgroundColor: "gray" }}
                  >
                    <b>
                      {moment().add(i, "month").format("MMMM")} - {moment().add(i, "month").format("YYYY")}
                    </b>
                  </td>
                </tr>
                <tr>{daysLoop(i, false, true).result.map((td) => td)}</tr>
                <tr style={{ textAlign: "center", color: "black", backgroundColor: "whitesmoke" }}>
                  <td style={{ textAlign: "center", color: "black", backgroundColor: "whitesmoke" }}>
                    {Translator("project")}&nbsp;
                    <button
                      className="btn btn-default"
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({ truncate: !this.state.truncate });
                      }}
                    >
                      {this.state.truncate ? "<->" : "..."}
                    </button>
                  </td>
                  {daysLoop(i).result.map((td) => td)}
                </tr>
              </thead>
              <tbody>
                {(this.props.projects).sort((a, b) => {
                  if (a.Customer.name < b.Customer.name) return -1
                  if (a.Customer.name > b.Customer.name) { return 1 }
                  else { return 0 }

                }).map((project) => {
                  const projectLoopResult = daysLoop(i, project)
                  if (!projectLoopResult.empty) {
                    return (
                      <tr key={project.id}>
                        <td
                          className={this.state.truncate ? "text-truncate" : ""}
                          style={{ maxWidth: this.state.truncate ? "150px" : "none" }}
                        >
                          {project.Customer.name}
                          <br></br>
                          <small>{project.Site ? project.Site.name : null}</small>
                        </td>
                        {projectLoopResult.result.map((td) => td)}
                      </tr>
                    );
                  }
                })}
              </tbody>
            </table>
          </div>
        );
      }
      return result;
    };
    const toReturn = (<div className="content-fluid">
      <div>{monthLoop().map((table) => table)}</div>
    </div>)
    return toReturn
    /*return (
      <div className="content-fluid">
        <div>{monthLoop().map((table) => table)}</div>
      </div>
    );*/
  }
  renderLegend() {
    return (
      <div className="row text-truncate">
        <label className="mx-2 col">{Translator("Legend")} :</label>
        <div className="bg-primary col m-1" style={{ border: "1px", borderRadius: "0.5em" }}>
          {Translator("preparation")}
          <i className="fas fa-database mx-1"></i>
        </div>
        <div className="bg-navy col m-1" style={{ border: "1px", borderRadius: "0.5em" }}>
          {Translator("delivery")}
          <i className="fas fa-truck-moving mx-1"></i>
        </div>
        <div className="bg-warning col m-1" style={{ border: "1px", borderRadius: "0.5em" }}>
          {Translator("assembly")}
          <i className="fas fa-tools mx-1"></i>
        </div>

        <div className="bg-fuchsia col m-1" style={{ border: "1px", borderRadius: "0.5em" }}>
          {Translator("tests")}
          <i className="fas fa-power-off mx-1"></i>
        </div>
        <div className="bg-danger col m-1" style={{ border: "1px", borderRadius: "0.5em" }}>
          {Translator("startup")}
          <i className="fas fa-flag-checkered mx-1"></i>
        </div>
        <div className="bg-success col m-1" style={{ border: "1px", borderRadius: "0.5em" }}>
          {Translator("training")}
          <i className="fas fa-graduation-cap mx-1"></i>
        </div>

        <div className="bg-purple col m-1" style={{ border: "1px", borderRadius: "0.5em" }}>
          {Translator("upgrade software")}
          <i className="fas fa-upload mx-1"></i>
        </div>

        <div className="bg-orange col m-1" style={{ border: "1px", borderRadius: "0.5em" }}>
          {Translator("upgrade hardware")}
          <i className="fas fa-rocket mx-1"></i>
        </div>
        <div className="bg-info col m-1" style={{ border: "1px", borderRadius: "0.5em" }}>
          {Translator("maintenance")}
          <i className="fas fa-oil-can mx-1"></i>
        </div>
        <div className="bg-teal col m-1" style={{ border: "1px", borderRadius: "0.5em" }}>
          {Translator("waiting")}
          <i className="fas fa-question mx-1"></i>
        </div>
      </div>
    );
  }
  render() {
    moment.locale(store().getLanguage());
    const projOverview = this.state.projOverview
    return (
      <div>
        <ProjectTextOverview projects={this.props.projects}></ProjectTextOverview>
        {this.props.extendedOverview ? <Card title={Translator("project timeline")} tools={this.renderLegend()} isFetching={this.state.isFetching}>
          <div className="form-group">
            <label htmlFor="customRange1">
              {Translator("months displayed")} : {this.state.currentOffset}/{this.state.max}
            </label>
            <input
              type="range"
              className="custom-range"
              value={this.state.displayedOffset}
              min={this.state.min}
              max={this.state.max}
              id="customRange1"
              onChange={(e) => {
                const offSet = e.target.value;
                this.setState({ displayedOffset: offSet, isFetching: true })
                const callback = (offset) => {
                  this.setState({ currentOffset: offset, isFetching: false });
                }
                clearTimeout(this.sliderTimeout);
                this.sliderTimeout = setTimeout(callback, 200, offSet);
              }}
              step={1}
            ></input>
          </div>
          {projOverview}
        </Card> : null}
      </div>
    );
  }
}

export default withRouter(ProjectsOverview);
