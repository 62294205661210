import React, { Component } from "react";
import ReactSummernote from "react-summernote";
import "react-summernote/dist/react-summernote.css"; // import styles
import "react-summernote/lang/summernote-fr-FR"; // you can import any other locale
import { Translator } from "../tools/Tools";
import "bootstrap/js/dist/modal";
import "bootstrap/js/dist/dropdown";
import "bootstrap/js/dist/tooltip";
import $ from "jquery"; //import 'bootstrap/dist/css/bootstrap.css';
import toastr from "toastr";


class RichText extends Component {
  constructor(props) {
    super(props);
    this.state = {
      developped: this.props.developped ? true : false,
    };
    this.quillObj
  }
  toggleCollapse() {
    this.setState((state) => {
      return { developped: !state.developped };
    });
  }


  render() {

    const dialogsInBody = !this.props.inModal;
    const renderHtml = (html) => {
      return { __html: html };
    };
    return (
      <div className={!this.state.developped ? "card card collapsed-card p-0" : "card card p-0"}>
        <div className="card-header">
          <h3 className="card-title">{this.props.title}</h3>
          <div className="card-tools">
            <button
              type="button"
              className="btn btn-tool"
              data-card-widget="collapse"
              onClick={this.toggleCollapse.bind(this)}
            >
              <i className={!this.state.developped ? "fas fa-chevron-down" : "fas fa-chevron-up"}></i>
            </button>
          </div>
        </div>
        <div className="card-body m-n2">
          {this.props.readOnly ? (
            <span dangerouslySetInnerHTML={renderHtml(this.props.defaultValue)}></span>
          ) : (<div>
            <ReactSummernote
              value={this.props.defaultValue ? this.props.defaultValue : ""}
              /*onInit={({ summernote }) => {
                try {
                  return summernote("code", this.props.defaultValue ? this.props.defaultValue : null);
                } catch (e) {
                  console.log(e);
                }
              }}*/
              //children={this.props.defaultValue ? this.props.defaultValue : "other default"}
              options={{
                lang: "fr-FR",
                height: 220,
                dialogsInBody: dialogsInBody,
                toolbar: [
                  ["style", ["style"]],
                  ["font", ["bold", "underline", "italic", "clear"]],
                  ["fontname", ["fontname"]],
                  ["para", ["ul", "ol", "paragraph"]],
                  ["table", ["table"]],
                  ["insert", ["link", "picture", "video"]],
                ],
              }}
              onImageUpload={(files, insertImage) => {
                toastr.options = {
                  progressBar: true,
                  timeOut: "5000",
                };
                console.log(files, insertImage);
                try {
                  if (files[0].size > 1024000) {
                    console.log("throwing error");
                    throw "File is bigger than 1MB";
                  }
                  const formData = new FormData();
                  formData.append("file", files[0]);
                  const options = {
                    // Your POST endpoint
                    method: "POST",
                    body: formData, // This is your file object
                  };
                  fetch("/api/upload/", options)
                    .then((response) => {
                      return response.json();
                    })
                    .then(
                      //Because the file selection open a modal and we are already inside a modal, the bootstrap modal design does not support nested modals,
                      //we need to hide and show again the modal to get focus back after the file is selected
                      async (success) => {
                        const show = () => {
                          console.log("showing again");
                          setTimeout(() => {
                            $("#modal-redaction").modal("show");
                          }, 500);
                        };
                        $("#modal-redaction").modal("hide");
                        insertImage(
                          "/" + success.destination + success.filename,
                          "/" + success.destination + success.filename
                        );
                        let result = await show();
                      } // Handle the success response object
                    )
                    .catch(
                      (error) => console.log(error) // Handle the error response object
                    );
                } catch (error) {
                  console.log(error);
                  toastr.error(Translator(error));
                } // Handle the error response object)
              }}
              onChange={
                this.props.onChange
                  ? this.props.onChange
                  : (content) => {
                    console.log(content);
                  }
              }
            />
          </div>)}
        </div>
      </div>
    );
  }
}

export default RichText;
