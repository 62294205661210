import React, { Component } from "react";
import GeneralInfos from "../../components/GeneralInfos";
import BSTable from "../../components/BSTable";
import isLoading from "../../components/Loading";
import {
  fetchContacts,
  postNewContact,
  deleteContact,
  fetchCustomers,
  addRelatedCustomers,
  removeRelatedCustomers,
} from "../../tools/Api_Customers";
import { Translator, parseForTable, store } from "../../tools/Tools";
import { v4 as uuidv4 } from 'uuid';
import { withRouter } from "react-router-dom";
import Select from "react-select";

class CustomerData extends Component {
  constructor(props) {
    super(props);
    this.canUpdate = true;
    this.state = {
      customers: [],
      relatedTo: [],
      showLinkedContacts: false,
    };
  }
  componentWillUnmount() {
    this.canUpdate = false;
  }

  handleCreateButton(e) {
    e.preventDefault();
    this.setState((state) => {
      const oldTable = this.state.data;
      const newTable = [
        {
          id: uuidv4(),
          name: "Nouveau Contact",
          position: "",
          phone1: "",
          phone2: "",
          mail: "",
          note: "",
        },
      ];
      //Cheat : I should not mutate props...
      //this.props.data.push(newTable[0]);
      //
      const mergedTable = newTable.concat(oldTable);

      return {
        edit: true,
        data: mergedTable,
      };
    });
  }
  componentDidMount() {
    this.reload();
    //this.setState( parseForTable(fakeFetched))
  }
  reload() {
    this.setState({ isFetching: true });
    fetchContacts(this.props.generalInfos.id).then((contacts) => {
      fetchCustomers().then((customers) => {
        console.log(customers)
        const relatedTo = customers.data.find((customer) => customer.id === this.props.generalInfos.id).relatedTo;
        const relatedToIds = relatedTo.map((rel) => rel.id);
        let mergedContacts = JSON.parse(JSON.stringify(contacts)); -
          Promise.all(
            relatedTo.map((cust) => {
              return fetchContacts(cust.id).then((fetchedContacts) => fetchedContacts);
            })
          ).then((results) => {
            results.map((result) => {
              //console.log(result);
              result.data.map((aContact) => {
                if (
                  !mergedContacts.data.find((toCheck) => {
                    return (
                      toCheck.name === aContact.name &&
                      toCheck.position === aContact.position &&
                      toCheck.phone1 === aContact.phone1 &&
                      toCheck.phone2 === aContact.phone2 &&
                      toCheck.mail === aContact.mail
                    );
                  })
                ) {
                  mergedContacts.data.push(aContact);
                }
              });
            });
          });
        const currentCustomer = customers.data.find((x) => x.id === this.props.generalInfos.id);
        customers = customers.data
          .filter((cust) => !relatedToIds.includes(cust.id))
          .map((customer) => {
            return { value: customer.id, label: customer.name };
          })
          .filter((customer) => customer.value !== this.props.generalInfos.id);
        console.log("building general informations");
        console.log(currentCustomer)
        const generalInformations = {
          activeClient: currentCustomer.activeClient,
          address: currentCustomer.address,
          contract: currentCustomer.contract,
          country: currentCustomer.country,
          id: currentCustomer.id,
          name: currentCustomer.name,
          phone: currentCustomer.phone,
          postal: currentCustomer.postal,
          relatedTo: 0,
          type: currentCustomer.type,
        };
        console.log(generalInformations);
        if (this.canUpdate) {
          this.setState(() => {
            return {
              generalInfos: generalInformations,
              contacts: parseForTable(contacts),
              mergedContacts: parseForTable(mergedContacts),
              customers: customers,
              isFetching: false,
              relatedTo: relatedTo,
              selectedRelatedTo: null,
            };
          });
        }
      });
    });
  }

  addRelatedCustomer() {
    addRelatedCustomers(this.props.generalInfos.id, [this.state.selectedRelatedTo.value]).then(() => {
      this.reload();
    });
  }
  removeRelatedCustomer(mainCustomer, toRemoveCustomers) {
    removeRelatedCustomers(this.props.generalInfos.id, toRemoveCustomers).then(() => {
      this.reload();
    });
  }
  postCallback(newContacts) {
    this.setState({ isFetching: true });
    Promise.all(
      newContacts.map((newContact) => {
        //console.log(newContact);
        postNewContact(this.props.generalInfos.id, newContact);
      })
    ).then(() => this.reload());
  }
  deleteContact(ids) {
    this.setState({ isFetching: true });
    deleteContact(ids).then((result) => {
      this.reload();
    });
  }
  renderShowLinkedContactsButton() {
    return (
      <button
        className="btn btn-info btn-xs"
        type="button"
        onClick={() => {
          this.setState({ showLinkedContacts: !this.state.showLinkedContacts });
        }}
      >
        {this.state.showLinkedContacts ? <i className="fas fa-user"></i> : <i className="fas fa-users"></i>}
        &nbsp;
        {this.state.showLinkedContacts ? Translator("show only this customer's contacts") : Translator("show all")}
      </button>
    );
  }
  render() {
    const contactsToDisplay = this.state.showLinkedContacts
      ? this.state.mergedContacts
        ? this.state.mergedContacts
        : { columns: [], data: [] }
      : this.state.contacts
        ? this.state.contacts
        : { columns: [], data: [] };
    return (
      <div>
        <div className="row">
          <div className="col-md-3">
            <div className="row">
              <div className="col-12">
                <GeneralInfos
                  generalInfos={this.state.generalInfos ? this.state.generalInfos : this.props.generalInfos}
                  infoType="customer"
                />
              </div>
            </div>
            <div className="callout callout-info col-12">
              <h2>Related to</h2>
              <ul>
                {this.state.relatedTo.map((customer) => {
                  return (
                    <li key={customer.id} className="text-truncate">
                      <button
                        type="button"
                        className="btn"
                        onClick={(e) => {
                          e.preventDefault();
                          this.removeRelatedCustomer(this.props.generalInfos.id, [customer.id]);
                        }}
                      >
                        <span style={{ color: "Tomato" }}>
                          <i className="fas fa-times"></i>
                        </span>
                      </button>
                      {customer.name}
                    </li>
                  );
                })}
              </ul>
              <div className="row">
                <div className="col-7">
                  <Select
                    noOptionsMessage={() => {
                      return <label>{Translator("none")}</label>;
                    }}
                    value={this.state.selectedRelatedTo}
                    onChange={(selectedOption) => {
                      this.setState({ selectedRelatedTo: selectedOption });
                    }}
                    options={this.state.customers}
                  />
                </div>
                <div className="col-5">
                  <button
                    type="button"
                    className="btn btn-block btn-default"
                    onClick={(e) => {
                      e.preventDefault();
                      this.addRelatedCustomer();
                    }}
                  >
                    {Translator("add")}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-9">
            <BSTable
              icon="user-"
              columns={contactsToDisplay ? contactsToDisplay.columns : []}
              data={contactsToDisplay ? contactsToDisplay.data : []}
              title={Translator("contacts")}
              header={this.renderShowLinkedContactsButton()}
              cud={
                this.state.showLinkedContacts
                  ? { c: false, u: false, d: false }
                  : { c: this.handleCreateButton, u: true, d: true }
              }
              theme="info"
              postCallback={this.postCallback.bind(this)}
              deleteCallback={this.deleteContact.bind(this)}
              updateFromProps={this.showLinkedContacts}
            />
          </div>
        </div>
        {isLoading(this.state.isFetching)}
      </div>
    );
  }
}
export default withRouter(CustomerData);
