import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import Card from "../../components/Card";
import { Translator, store, MomentDateString, parseForTable, computeWorkTime } from "../../tools/Tools";
import BSTable from "../../components/BSTable";
import moment from "moment";
import { fetchUserEvents, fetchUsers, fetchParameters } from "../../tools/Api_Customers";
import HeuresCard from "./HeuresCard";

class Heures extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: moment(),
      monthsColumns: [],
      data: [],
      total: 0,
      tabs: [
        {
          value: "my hours",
          active: true,
          accreditation: 3,
          desc: Translator("my hours"),
        },
        {
          value: "colleagues hours",
          active: false,
          accreditation: 1,
          desc: Translator("colleagues hours"),
        },
      ],
      yearTabs: [],
      userTabs: [],
    };
    this.shaffled = false;
  }

  componentDidMount() {
    fetchUsers()
      .then((users) => {
        fetchParameters().then((params) => {
          const startDataParam = params.find((param) => param.type === "startDate");
          let userTabs = [];
          users.data.map((user) => {
            if (user.accreditation !== 99) {
              userTabs.push({
                value: user.id,
                active: false,
                accreditation: 2,
                desc: user.shortName,
              });
            }
          });
          if (userTabs.length > 0) userTabs[0].active = true;
          this.setState({
            userTabs: userTabs,
            users: users,
            startDate: moment(startDataParam.value, "DD/MM/YYYY"),
          });
          this.hoursComputeWorkTime(store().getUser().userId);
        });
      })
      .then(() => {});
  }
  hoursComputeWorkTime(userId) {
    this.setState({ isFetching: true });
    fetchUserEvents(userId).then((result) => {
      computeWorkTime(userId).then((resultWorkTime) => {
        let datas = [];
        let fullTotal = 0;
        let monthDays = {};
        Object.keys(resultWorkTime.workTimeDays).map((day) => {
          if (!monthDays[moment(day, "DD/MM/YYYY").format("MM/YYYY")]) {
            monthDays[moment(day, "DD/MM/YYYY").format("MM/YYYY")] = { [day]: resultWorkTime.workTimeDays[day] };
          } else {
            monthDays[moment(day, "DD/MM/YYYY").format("MM/YYYY")][day] = resultWorkTime.workTimeDays[day];
          }
        });
        Object.keys(resultWorkTime.workTimeMonths).map((month) => {
          datas.push({
            id: month,
            month: month,
            year: month.substr(3, 4),
            days: monthDays[month],
            hours:
              (resultWorkTime.workTimeMonths[month] < 0 ? "- " : "") +
              Math.floor(Math.abs(resultWorkTime.workTimeMonths[month] / 456)) +
              Translator(" jours et ") +
              Math.floor(Math.abs((resultWorkTime.workTimeMonths[month] % 456).toFixed(0) / 60)).toFixed(0) +
              Translator("h et ") +
              ((Math.abs(resultWorkTime.workTimeMonths[month]) % 456).toFixed(0) % 60).toFixed(0) +
              Translator(" minutes"),
            rawTime: resultWorkTime.workTimeMonths[month],
          });

          fullTotal += resultWorkTime.workTimeMonths[month];
        });
        const parsed = parseForTable({
          data: datas,
          columns: [
            {
              dataField: "month",
              text: Translator("month"),
            },
            {
              dataField: "hours",
              text: Translator("hours"),
            },
            {
              dataField: "id",
              text: Translator("id"),
              hidden: true,
            },
            {
              dataField: "year",
              text: Translator("year"),
              hidden: true,
            },
          ],
        });

        let years = [];
        let total = 0;
        parsed.data.map((a) => {
          if (!years.find((year) => year.value === a.year)) {
            years.push({ value: a.year, active: false + "" === a.year, desc: a.year, accreditation: 4 });
          }
        });
        years.push({ value: "total", active: true, desc: Translator("total"), accreditation: 4 });
        let selectedYearData = [];
        parsed.data.map((data) => {
          selectedYearData.push(data);
          total = total + data.rawTime;
        });
        this.setState({
          data: selectedYearData,
          fullData: parsed.data,
          monthsColumns: parsed.columns,
          isFetching: false,
          total: total,
          fullTotal: fullTotal,
          yearTabs: years,
        });
      });
    });
  }
  renderTable() {
    const defaultSorted = [
      {
        dataField: "month", // if dataField is not match to any column you defined, it will be ignored.
        order: "asc", // desc or asc
      },
    ];
    const expandRow = {
      renderer: (row) => {
        return (
          <div className="row">
            <table className="col">
              <tbody>
                {Object.keys(row.days).map((day) => {
                  const isNegative = row.days[day] < 0;
                  const resultHour =
                    (isNegative ? "- " : "") +
                    Math.floor(Math.abs(row.days[day].toFixed(0) / 60)).toFixed(0) +
                    Translator("h et ") +
                    (Math.abs(row.days[day]).toFixed(0) % 60).toFixed(0) +
                    Translator(" minutes");
                  return (
                    <tr key={day} className={isNegative ? "text-danger" : row.days[day] !== 0 ? "text-success" : ""}>
                      <td>{day}</td>
                      <td>{resultHour}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        );
      },
      showExpandColumn: true,
      onlyOneExpanding: true,
    };
    return (
      <BSTable
        data={this.state.data}
        columns={this.state.monthsColumns}
        hideSearch
        title={this.generateYearTabs()}
        updateFromProps
        defaultSorted={defaultSorted}
        expandRow={expandRow}
      ></BSTable>
    );
  }
  renderColleagueTable() {
    return <Card title={this.generateUserTabs()}>{this.renderTable()}</Card>;
  }

  generateTabRelatedContent() {
    switch (this.state.tabs.find((tab) => tab.active).value) {
      case "my hours":
        return <div className="col-sm-6">{this.renderTable()}</div>;
        break;
      case "colleagues hours":
        return <div className="col-sm-6">{this.renderColleagueTable()}</div>;
        break;
      default:
        return null;
    }
  }

  generateUserTabs() {
    return (
      <ul className="nav nav-tabs">
        {this.state.userTabs.map((tab) => {
          return (
            <li
              key={tab.value}
              className={tab.active ? "nav-link active" : "nav-link"}
              onClick={this.handleClickUserTab.bind(this, tab)}
            >
              {tab.desc}
            </li>
          );
        })}
      </ul>
    );
  }
  generateTabs() {
    return (
      <ul className="nav nav-tabs">
        {this.state.tabs.map((tab) => {
          if (store().getUser().accreditation <= tab.accreditation) {
            return (
              <li
                key={tab.value}
                className={tab.active ? "nav-link active" : "nav-link"}
                onClick={this.handleClickTab.bind(this, tab)}
              >
                {tab.desc}
              </li>
            );
          }
        })}
      </ul>
    );
  }
  generateYearTabs() {
    return (
      <ul className="nav nav-tabs">
        {this.state.yearTabs.map((tab) => {
          if (store().getUser().accreditation <= tab.accreditation) {
            return (
              <li
                key={tab.value}
                className={tab.active ? "nav-link active" : "nav-link"}
                onClick={this.handleClickYearTab.bind(this, tab)}
              >
                {tab.desc}
              </li>
            );
          }
        })}
      </ul>
    );
  }

  handleClickTab(tab) {
    const newTabs = this.state.tabs.map((stateTab) => {
      stateTab.active = stateTab.value === tab.value;
      return stateTab;
    });
    if (tab.value === "my hours") {
      this.hoursComputeWorkTime(store().getUser().userId);
    } else {
      const activeUser = this.state.userTabs.find((ut) => {
        return ut.active;
      });
      if (activeUser) {
        this.hoursComputeWorkTime(activeUser.value);
      }
    }
    this.setState({
      tabs: newTabs,
    });
  }
  handleClickYearTab(tab) {
    let selectedYearData = [];
    let total = 0;

    const newTabs = this.state.yearTabs.map((stateTab) => {
      stateTab.active = stateTab.value === tab.value;
      if (stateTab.value === tab.value) {
        if (tab.value !== "total") {
          this.state.fullData.map((data) => {
            if (stateTab.value + "" === data.year) {
              selectedYearData.push(data);
              total += data.rawTime;
            }
          });
        } else {
          this.state.fullData.map((data) => {
            selectedYearData.push(data);
            total += data.rawTime;
          });
        }
      }
      return stateTab;
    });
    this.setState({
      yearTabs: newTabs,
      data: selectedYearData,
      total: total,
    });
  }
  handleClickUserTab(tab) {
    const newTabs = this.state.userTabs.map((stateTab) => {
      stateTab.active = stateTab.value === tab.value;
      return stateTab;
    });
    this.hoursComputeWorkTime(tab.value);
    this.setState({
      userTabs: newTabs,
    });
  }
  getData = () => {
    return [
      ["Label", "Value"],
      ["Heures", this.state.total],
    ];
  };
  render() {
    const active = this.state.userTabs.find((ut) => ut.active);
    const isTotal =
      this.state.yearTabs.length > 0
        ? this.state.yearTabs.find((tab) => tab.value === "total").active
          ? true
          : false
        : false;
    const user =
      this.state.tabs.find((tab) => tab.active).value === "my hours"
        ? this.state.users
          ? this.state.users.data.find((us) => us.id === store().getUser().userId)
          : store().getUser()
        : active
        ? this.state.users.data.find((us) => us.id === active.value)
        : { firstName: "", lastName: "" };
    return (
      <div>
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0 text-dark">{Translator("Heures")}</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to={{ pathname: "../", state: this.props.history.state }}>{Translator("Home")}</Link>
                  </li>
                  <li className="breadcrumb-item active">{Translator("Heures")}</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <Card title={this.generateTabs()} isFetching={this.state.isFetching}>
              <div className="row">
                {this.generateTabRelatedContent()}
                <div className="col-sm-6">
                  <div className="col-12">
                    <HeuresCard user={user} total={this.state.total} withInitialCredit={isTotal}></HeuresCard>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </section>
      </div>
    );
  }
}

export default withRouter(Heures);
