import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { Translator } from "../../tools/Tools";
import TodoList from "./TodoList";
import { fetchTodos } from "../../tools/Api_Customers";

class UserTodoList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      todos: [],
    };
  }
  componentDidMount() {}
  render() {
    return (
      <div>
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0 text-dark">{Translator("Todolist")}</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to={{ pathname: "../", state: this.props.history.state }}>{Translator("Home")}</Link>
                  </li>
                  <li className="breadcrumb-item active">{Translator("Todolist")}</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="container-fluid">
            <TodoList fetchTodos={fetchTodos}></TodoList>
          </div>
        </section>
      </div>
    );
  }
}

export default withRouter(UserTodoList);
