import React, { Component } from "react";
import { v4 as uuidv4 } from 'uuid';
import moment from "moment";
import { withRouter } from "react-router-dom";

class Weeks extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.modal) {
      return false;
    } else {
      return true;
    }
  }

  render() {
    let agenda = [];
    const start = this.props.numBefore;
    const end = this.props.numAfter;
    let i;
    for (i = start; i <= end; i++) {
      this.props.toggleColor(true);
      agenda.push(this.props.renderTable(i));
    }
    return agenda.map((week) => {
      return <div key={uuidv4()}>{week}</div>;
    });
  }
}

export default withRouter(Weeks);
