import React, { useEffect, useState } from "react";
import { filterOptions, store, Translator } from "../../tools/Tools";
import Select from "react-select";
import Creatable from "react-select/creatable";
import RichText from "../../components/RichText";
import Card from "../../components/Card";
import BSTable from "../../components/BSTable";
import { set } from "date-fns";

const ModuleCreationForm = (props) => {
    const [selectedAvailableTasks, setSelectedAvailableTasks] = useState([])
    const [lastSelectedAvailableTask, setLastSelectedAvailableTask] = useState({})
    const [isTaskSelectionEnabled, setTaskSelectionEnabled] = useState(false)
    const [newModulePayload, setNewModulePayload] = useState({ ChecklistTasks: [], ChecklistTypes: [] })
    const [sortingOrder, setSortingOrder] = useState(10)
    const [selectedMachineType, setSelectedMachineType] = useState(null)


    const availableTasksRowEvent = {
        onClick: (e, row, rowIndex) => {
            console.log("clicked row")
            setLastSelectedAvailableTask(row)
        },
        onSelectAll: (e, rows, isSelect) => {
            if (isSelect) {
                setLastSelectedAvailableTask({})
                setSelectedAvailableTasks(rows)
            } else {
                setLastSelectedAvailableTask({})
                setSelectedAvailableTasks([])
            }
        },
    };
    const resetForm = () => {
        setNewModulePayload({ ChecklistTasks: [], ChecklistTypes: [] })
        setSelectedMachineType(null)
        setTaskSelectionEnabled(false)
        setSortingOrder(10)
        setLastSelectedAvailableTask({})
        setSelectedAvailableTasks([])
        props.cancelModuleCreation();
    }

    useEffect(() => {
        //Add last selected task to tasklist or remove it from list if already included
        if (Object.keys(lastSelectedAvailableTask).length > 0) {
            console.log("use effect fot tasks")
            const tmpTask = lastSelectedAvailableTask
            setLastSelectedAvailableTask({})
            selectedAvailableTasks.find(t => t.id === tmpTask.id) ?
                setSelectedAvailableTasks(selectedAvailableTasks.filter(t => t.id !== tmpTask.id)) :
                setSelectedAvailableTasks([...selectedAvailableTasks, tmpTask])
        }
        if (props.payload && JSON.stringify(props.payload) !== JSON.stringify(newModulePayload) && !$('#modal-module').hasClass('show')) {
            console.log("use effect fot props payload")
            setNewModulePayload(props.payload)
            setSelectedMachineType({ label: props.payload.MachineType.name, value: props.payload.MachineType.id })
        }
    })

    const handleSelectFromAvailableTasks = (e) => {
        e.preventDefault()
        setTaskSelectionEnabled(!isTaskSelectionEnabled)
    }

    const handleRemoveFromModuleTasks = (e) => {
        e.preventDefault();
    }

    const moveSelectedTasks = () => {
        let tmpNewModulePayload = newModulePayload
        tmpNewModulePayload.ChecklistTasks = [...tmpNewModulePayload.ChecklistTasks, ...selectedAvailableTasks]
        setNewModulePayload(tmpNewModulePayload)
        setSelectedAvailableTasks([])
        setTaskSelectionEnabled(false)
    }

    const deleteTaskCallback = (ids) => {
        const idArray = ids.split(',')
        let tmpNewModulePayload = newModulePayload
        tmpNewModulePayload.ChecklistTasks = [...tmpNewModulePayload.ChecklistTasks.filter(el => !idArray.includes(el.id))]

        setNewModulePayload(tmpNewModulePayload)
        setSelectedAvailableTasks([])

    }
    const renderModuleModalFooterButtons = () => {

        return (
            <form className="form-inline col-sm-12">
                <div className="input-group mb-2">
                    <button
                        className="btn btn-info btn mr-2 "
                        onClick={(e) => {
                            e.preventDefault();
                            let toSendPayload = newModulePayload
                            toSendPayload.MachineTypeId = newModulePayload.MachineType ? newModulePayload.MachineType.id : null
                            toSendPayload.ChecklistTypesIds = newModulePayload.ChecklistTypes.map(type => type.id)
                            toSendPayload.ChecklistTasksIds = newModulePayload.ChecklistTasks.map(task => task.id)
                            console.log(JSON.stringify(toSendPayload))
                            props.submitCallback(toSendPayload, props.editingMode).then(success => {
                                if (success) {
                                    resetForm();
                                }
                            });

                        }}
                    >
                        {props.creatingMode ? Translator("save") : Translator("update")}
                    </button>
                    <button
                        className="btn btn-info btn "
                        data-dismiss="modal"
                        onClick={(e) => {
                            e.preventDefault();
                            resetForm()
                        }}
                    >
                        {Translator("annuler")}
                    </button>
                </div>
            </form>
        );

    }


    return (
        <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title" id="staticBackdropLabel">
                    {Translator("new module ")}
                </h5>
                <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={(e) => {
                        e.preventDefault();
                        resetForm();
                    }}
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <div className={props.creatingMode ? "info-box slideInDown animated" : "info-box"}>
                    <span className="info-box-icon bg-info">
                        <span className="fa-stack">
                            <i className="fa-solid fa-list-ol fa-stack-2x"></i>
                        </span>
                    </span>
                    <div className="table-responsive">
                        <div className="info-box-content">
                            <div className="row my-1">
                                <div className="col-6">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={Translator("title")}
                                        onChange={(e) => {
                                            const tmpNewModulePayload = { ...newModulePayload, name: e.target.value.trim().length !== 0 ? e.target.value : null }
                                            setNewModulePayload(tmpNewModulePayload)
                                        }}
                                        value={newModulePayload.name || ""}
                                    ></input>
                                </div>
                            </div>
                            <hr></hr>
                            <div className="row my-1">
                                <Select
                                    className="col-6"
                                    isClearable
                                    noOptionsMessage={() => {
                                        return <label>{Translator("none")}</label>;
                                    }}
                                    placeholder={Translator("machine type")}
                                    onChange={(selectedOption) => {
                                        let tmpNewModulePayload = newModulePayload
                                        tmpNewModulePayload.MachineType = { id: selectedOption.value, label: selectedOption.label }
                                        setNewModulePayload(tmpNewModulePayload)
                                        setSelectedMachineType(selectedOption)
                                        //setTriggerRender(!triggerRender)
                                    }}
                                    options={props.machineTypes.map(type => { return { value: type.id, label: type.name } })}
                                    value={selectedMachineType}
                                    menuPortalTarget={document.body}
                                    isSearchable
                                    filterOption={filterOptions}
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                />
                            </div>
                            <hr></hr>
                            <div className="row">
                                <div className="col-6">
                                    <Card title={Translator("list types")}>
                                        {props.checklistTypes.map(type => {
                                            return (
                                                <div className="form-check" key={type.id} >
                                                    <input className="form-check-input" type="checkbox" value="" id={type.id} checked={newModulePayload.ChecklistTypes.find(el => el.id === type.id) || false} onChange={e => {
                                                        e.stopPropagation();
                                                        let newChecklistTypePayload = []
                                                        if (newModulePayload.ChecklistTypes.find(el => el.id === type.id)) {
                                                            newChecklistTypePayload = newModulePayload.ChecklistTypes.filter(el => el.id !== type.id)
                                                            console.log(newChecklistTypePayload)
                                                        } else {
                                                            newChecklistTypePayload = [...newModulePayload.ChecklistTypes, type]
                                                        }
                                                        const tmpNewModulePayload = { ...newModulePayload, ChecklistTypes: newChecklistTypePayload }
                                                        setNewModulePayload(tmpNewModulePayload)
                                                    }}>

                                                    </input>
                                                    <label className="form-check-label" htmlFor={type.id}>
                                                        {Translator(type.name)}
                                                    </label>
                                                </div>
                                            )
                                        })}
                                    </Card>
                                </div>
                            </div>
                            <hr></hr>
                            <div className="row">
                                <div className="col-2 pt-1 mr-n5">
                                    <label htmlFor="order">{Translator("sorting order")}</label>
                                </div>
                                <div className="col"></div>
                                <div className="col-4 col-lg-3 col-xl-2">
                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <button className="input-group-text" onClick={() => {
                                                let tmpNewModulePayload = newModulePayload
                                                tmpNewModulePayload.sortingOrder = sortingOrder - 10
                                                setSortingOrder(sortingOrder - 10)
                                                setNewModulePayload(tmpNewModulePayload)
                                            }}>-</button>
                                        </div>
                                        <input id="order" type="text" className="form-control" value={sortingOrder} onChange={(e) => {
                                            const parsed = parseInt(e.target.value)
                                            console.log(parsed)
                                            if (!isNaN(parsed)) {
                                                let tmpNewModulePayload = newModulePayload
                                                tmpNewModulePayload.sortingOrder = parsed
                                                setSortingOrder(parsed)
                                                setNewModulePayload(tmpNewModulePayload)

                                            }
                                        }}></input>
                                        <div className="input-group-append">
                                            <button className="input-group-text" onClick={() => {
                                                let tmpNewModulePayload = newModulePayload
                                                tmpNewModulePayload.sortingOrder = sortingOrder + 10
                                                setSortingOrder(sortingOrder + 10)
                                                setNewModulePayload(tmpNewModulePayload)


                                            }}>+</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr></hr>
                            <h5>{Translator("select tasks from available tasks list")}</h5>
                            <div className="row">
                                <div className="col-5">
                                    <BSTable
                                        title={Translator("available tasks")}
                                        theme={"orange"}
                                        columns={props.tasks.columns.filter(col => col.dataField !== "createdAt" && col.dataField !== "updatedAt")}
                                        data={props.tasks.data.filter(task => {
                                            return newModulePayload.ChecklistTypes.every(ct => {
                                                return task.ChecklistTypes.includes(ct.id) || task.ChecklistTypes.length === 0
                                            })
                                        }).filter(el => newModulePayload.ChecklistTasks.findIndex(subEl => subEl.id === el.id) < 0)}
                                        pagination
                                        updateFromProps
                                        clickToSelectMultiple={isTaskSelectionEnabled}
                                        rowEvents={isTaskSelectionEnabled ? availableTasksRowEvent : null}
                                        paginate
                                        defaultSorted={[{ dataField: "sortingOrder", order: "asc" }, { dataField: "taskText", order: "asc" }]}
                                    ></BSTable>
                                </div>
                                <div className="col-1 align-self-center">
                                    <button className="btn btn-default"
                                        onClick={moveSelectedTasks}>{'>>>'}</button>
                                </div>
                                <div className="col-5">
                                    <BSTable
                                        title={Translator("module tasks")}
                                        theme={"info"}
                                        columns={props.tasks.columns.filter(col => col.dataField !== "createdAt" && col.dataField !== "updatedAt")}
                                        data={newModulePayload.ChecklistTasks}
                                        updateFromProps
                                        hideSearch
                                        cud={{ c: handleSelectFromAvailableTasks, u: false, d: handleRemoveFromModuleTasks }}
                                        //clickToSelectMultiple
                                        rowEvents={{
                                            onClick: (e, row, rowIndex) => { },
                                        }}
                                        deleteCallback={deleteTaskCallback}
                                        paginate
                                        defaultSorted={[{ dataField: "sortingOrder", order: "asc" }, { dataField: "taskText", order: "asc" }]}
                                    ></BSTable>
                                    {/*"task selection enabled " + isTaskSelectionEnabled}
                                    <br></br>
                                    {"last selected " + JSON.stringify(lastSelectedAvailableTask)}
                                    <br></br>
                                    {"selectedTasks " + JSON.stringify(selectedAvailableTasks)*/}
                                    <br></br>
                                </div>
                            </div>
                            {/*<h5>preview debug</h5>
                            {"newPaylod " + JSON.stringify(newModulePayload)}*/}
                        </div>
                    </div >
                    <div className="ribbon-wrapper ribbon-xl">
                        <div className="ribbon bg-success">{Translator("new module")}</div>
                    </div>
                </div >
            </div>
            <div className="modal-footer">{renderModuleModalFooterButtons()}</div>
        </div>
    );
};

export default ModuleCreationForm;
