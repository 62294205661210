import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Translator, store, parseForTable } from "../../tools/Tools";
import {
  fetchCustomer,
  patchCustomerExtraInfos,
  fetchCustomerEvents,
  fetchUsers,
  fetchCustomerConveyors,
} from "../../tools/Api_Customers";
import RichText from "../../components/RichText";
import Card from "../../components/Card";
import moment from "moment";
import BSTable from "../../components/BSTable";

class CustomerEvents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.generalInfos.id,
      events: [],
      total: 0,
      selectedEvent: [],
      allDetails: false,
    };
  }

  componentDidMount() {
    this.fetchInformations();
  }

  fetchInformations() {
    fetchCustomerEvents(this.state.id).then((events) => {
      fetchUsers().then((users) => {
        fetchCustomerConveyors(this.state.id).then((result) => {
          let total = 0;
          events = events.map((event) => {
            event.totalTime = 0;
            if (event.UserEvents.length) {
              event.from = event.UserEvents[0].from;
              event.to = event.UserEvents[0].to;
            }

            if (!event.title) {
              event.title = this.props.generalInfos.name;
            }
            event.UserEvents.forEach((userEvent) => {
              event.totalTime += userEvent.workTime;
              if (moment(userEvent.from).isBefore(event.from)) {
                event.from = userEvent.from;
              }
              if (moment(userEvent.to).isAfter(event.to)) {
                event.to = userEvent.to;
              }
            });
            total += event.totalTime;
            event.totalTime =
              Math.floor(event.totalTime / 60) +
              " " +
              Translator("heures") +
              " " +
              (event.totalTime % 60) +
              " " +
              Translator("minutes") +
              " ";
            event.from = moment(event.from).format("DD/MM/YYYY HH:mm");
            event.to = moment(event.to).format("DD/MM/YYYY HH:mm");

            return event;
          });

          this.setState({ events: events, total: total, users: users.data, sites: result.Sites });
        });
      });
    });
  }

  render() {
    const filteredEvents = this.state.events.filter((event) => event.UserEvents.length > 0);
    const columns =
      this.state.events.length > 0
        ? [
            {
              dataField: "type",
              text: "type",
              headerStyle: {
                backgroundColor: "#CCCCDD",
              },
              footerStyle: {
                backgroundColor: "#888888",
              },
              footer: "",
            },
            {
              dataField: "title",
              text: "title",
              headerStyle: {
                backgroundColor: "#CCCCDD",
              },
              footerStyle: {
                backgroundColor: "#888888",
              },
              footer: "",
            },
            {
              dataField: "from",
              text: "from",
              headerStyle: {
                backgroundColor: "#CCCCDD",
              },
              footerStyle: {
                backgroundColor: "#888888",
              },
              footer: "",
            },
            {
              dataField: "to",
              text: "to",
              headerStyle: {
                backgroundColor: "#CCCCDD",
              },
              footerStyle: {
                backgroundColor: "#BBBBBB",
              },
              footer: "total",
            },
            {
              dataField: "totalTime",
              text: "totalTime",
              headerStyle: {
                backgroundColor: "#CCCCDD",
              },
              footerStyle: {
                backgroundColor: "#BBBBBB",
              },
              footer:
                Math.floor(this.state.total / 60) +
                " " +
                Translator("h") +
                ", " +
                Math.floor(this.state.total % 60) +
                " " +
                Translator("min"),
            },
          ]
        : [];
    const detailsColumns =
      this.state.selectedEvent.length > 0
        ? [
            {
              dataField: "user",
              text: "user",
              headerStyle: {
                backgroundColor: "#DDDDDD",
              },
            },
            {
              dataField: "site",
              text: "site",
              headerStyle: {
                backgroundColor: "#DDDDDD",
              },
            },
            {
              dataField: "day",
              text: "day",
              headerStyle: {
                backgroundColor: "#DDDDDD",
              },
            },
            {
              dataField: "from",
              text: "from",
              headerStyle: {
                backgroundColor: "#DDDDDD",
              },
            },
            {
              dataField: "to",
              text: "to",
              headerStyle: {
                backgroundColor: "#DDDDDD",
              },
            },
            {
              dataField: "description",
              text: "desc",
              headerStyle: {
                backgroundColor: "#DDDDDD",
              },
            },
            {
              dataField: "workTime",
              text: "workTime",
              headerStyle: {
                backgroundColor: "#DDDDDD",
              },
            },
          ]
        : [];

    const selectedEvent = this.state.selectedEvent.map((userEvent) => {
      let copyUserEvent = JSON.parse(JSON.stringify(userEvent));
      const siteTmp = this.state.sites.find((site) => site.id === userEvent.SiteId);
      const site = siteTmp ? siteTmp.name : Translator("all");
      const user = this.state.users.find((user) => user.id === userEvent.UserId).shortName;
      copyUserEvent.site = site;
      copyUserEvent.user = user;
      copyUserEvent.day = moment(userEvent.from).format("DD/MM/YYYY");
      copyUserEvent.from = moment(userEvent.from).format("HH:mm");
      copyUserEvent.to = moment(userEvent.to).format("HH:mm");
      copyUserEvent.description = userEvent.description;
      copyUserEvent.workTime =
        Math.floor(copyUserEvent.workTime / 60) +
        " " +
        Translator("h") +
        ", " +
        Math.floor(copyUserEvent.workTime % 60) +
        " " +
        Translator("min");

      return copyUserEvent;
    });

    const forTable = parseForTable({ data: filteredEvents, columns: columns });
    const forDetailTable = parseForTable({ data: selectedEvent, columns: detailsColumns });
    moment.locale(store().getLanguage());
    console.log(forTable);
    const renderHtml = (html) => {
      return { __html: html };
    };
    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        this.setState({ selectedEvent: row.UserEvents });
      },
    };
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-5">
            <BSTable
              title={Translator("global list")}
              theme={"primary"}
              data={forTable.data}
              columns={forTable.columns}
              rowEvents={rowEvents}
              updateFromProps
              defaultSorted={[{ dataField: "from", order: "asc" }]}
            ></BSTable>
          </div>
          <div className="col-7">
            <div style={{ top: "0", position: "-webkit-sticky", position: "sticky" }}>
              <BSTable
                title={(() => {
                  return (
                    <div className="row">
                      <span>{Translator("detailed list")}</span>
                      <button
                        className="btn btn-secondary btn-xs col mx-3"
                        onClick={(e) => {
                          e.preventDefault();
                          let result = [];
                          this.state.events.forEach((event) => {
                            result = result.concat(event.UserEvents);
                          });
                          this.setState({ selectedEvent: result });
                        }}
                      >
                        {Translator("Show all")}
                      </button>
                    </div>
                  );
                })()}
                theme={"secondary"}
                data={forDetailTable.data}
                columns={forDetailTable.columns}
                updateFromProps
                defaultSorted={[
                  { dataField: "day", order: "asc" },
                  { dataField: "from", order: "asc" },
                ]}
              ></BSTable>
            </div>
          </div>
        </div>
      </div>
    );

    /* return <RichText title={Translator("extra informations")} defaultValue={this.state.extraInfos}
         developped></RichText> */
  }
}

export default withRouter(CustomerEvents);
