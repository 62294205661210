import React, { Component, memo } from "react";
import { withRouter, Link } from "react-router-dom";
import Card from "../../components/Card";
import {
  store,
  Translator,
  MomentDateString,
  MomentDateTimeString,
  parseForTable,
  computeWorkTime,
  NotifyReloadTicket,
  ShouldTimedReloadTicket,
  ShouldReloadTicket,
  parseTime,
} from "../../tools/Tools";
import $ from "jquery";
import Select, { components } from "react-select";
import Creatable, { makeCreatableSelect } from "react-select/creatable";
import {
  fetchCustomers,
  fetchUsers,
  fetchCustomerConveyors,
  fetchContacts,
  postTicket,
  fetchOpenCustomerTickets,
  fetchClosedTickets,
  closeTicket,
  handleTicket,
  releaseTicket,
  changeTicketStatus,
  fetchParameters,
  linkTicketToInvoice,
  fetchCustomerUnpaidInvoices,
  fetchOldestTicketYear,
  fetchClosedInternalTickets,
  fetchOpenInternalTickets,
  fetchOldestInternalTicketYear,
  pauseTicket,
} from "../../tools/Api_Customers";
import TicketWidget from "./TicketWidget";
import moment from "moment";
import { TicketListFlow } from "./TicketListFlow";
import TicketCreationForm from "./TicketCreationForm";

class Tickets extends Component {
  constructor(props) {
    super(props);
    this.path = props.match.url;
    this.push = props.history.push;
    this.defaultNewTicketFormPayload = {
      code: "red",
      customer: {},
      site: null,
      contact: null,
      contract: "",
    };
    this.audio = new Audio("/assets/dist/sounds/new_ticket_voice.mp3");
    this.state = {
      firstLoad: true,
      play: false,
      filterText: "",
      yearFilter: { value: moment().year(), label: moment().year() },
      userFilter: {},
      customerFilter: {},
      invoiceFilter: false,
      logbookFilter: false,
      ticketPrices: {},
      creatingMode: false,
      tickets: [],
      collapsed: [],
      tabs: [
        {
          value: "customer",
          active: true,
          accreditation: 99,
          desc: Translator("customer"),
        },
        {
          value: "internal",
          active: false,
          accreditation: 99,
          desc: Translator("internal"),
        },
      ],
      subtabs: [
        {
          value: "open",
          active: true,
          accreditation: 99,
          desc: Translator("open"),
        },
        {
          value: "closed",
          active: false,
          accreditation: 99,
          desc: Translator("closed"),
        },
      ],
      pendingTicketTemporaryStatuses: [
        {
          value: "active",
          checked: true,
          desc: Translator("active"),
        },
        {
          value: "waitForMp",
          checked: true,
          desc: Translator("waiting for MP"),
        },
        {
          value: "waitForCustomer",
          checked: true,
          desc: Translator("waiting for Customer"),
        },
      ],
      ticketContracts: [
        {
          value: "omnium",
          checked: true,
          desc: Translator("omnium"),
        },
        {
          value: "basic1",
          checked: true,
          desc: Translator("basic1"),
        },
        {
          value: "basic2",
          checked: true,
          desc: Translator("basic2"),
        },
        {
          value: "warranty",
          checked: true,
          desc: Translator("warranty"),
        },
        {
          value: "conveyor",
          checked: true,
          desc: Translator("conveyor"),
        },
        {
          value: "none",
          checked: true,
          desc: Translator("none"),
        },
      ],
      newTicketFormPayload: this.defaultNewTicketFormPayload,
      customersOptionTable: [],
      userOptionsTable: [],
      siteOptionTable: [],
      contactsOptionTable: [],
      yearOptionTable: [{ value: moment().year(), label: moment().year() }],
    };
    this.shouldUpdate = true;
    //console.log(this.state.newTicketFormPayload);
  }
  componentDidMount() {
    this.reloadTickets();
    this.reload();
    if (this.props.customer) {
      this.fetchCustomerInformations(this.props.customer);
    }
    setInterval(() => {
      if (this.shouldUpdate) {
        if (ShouldTimedReloadTicket("ticket") || ShouldReloadTicket("ticket")) {
          //console.log("should reload");
          this.reloadTickets();
        }
      }
    }, 1000);
    this.audio.addEventListener("ended", () => this.setState({ play: false }));
  }
  componentWillUnmount() {
    this.shouldUpdate = false;
    this.audio.removeEventListener("ended", () => this.setState({ play: false }));
  }
  reloadTickets() {
    this.setState({ isFetching: true });
    const type = this.state.tabs.find((x) => x.active).value;
    const status = this.state.subtabs.find((x) => x.active).value;
    //console.log("reloading tickets type " + type);
    fetchParameters().then((params) => {
      //console.log(params);
      const hotlinePrice = params.filter((param) => param.type.startsWith("hotlinePrice"));
      fetchUsers().then((users) => {
        const userOptionsTable = users.data.map((user) => {
          return { value: user.id, label: user.shortName };
        });
        if (type === "customer") {
          //fetch customer tickets
          if (status === "open") {
            //console.log("open");
            fetchOpenCustomerTickets().then((result) => {
              let tickets = result.map((ticket) => {
                let running = false;
                let ticketWorkTime = 0;
                ticket.TicketPeriods.forEach((tp) => {
                  ticketWorkTime += tp.workTime;
                  if (!tp.endDateTime) {
                    running = true;
                    ticketWorkTime += moment.duration(moment().diff(tp.startDateTime)).asMinutes();
                  }
                });
                return {
                  id: ticket.id,
                  author: ticket.Author,
                  number: ticket.ticketNumber,
                  customer: ticket.Customer,
                  description: ticket.description,
                  site: ticket.Site ? ticket.Site.name : Translator("no specific site"),
                  assignee: ticket.Assignee ? ticket.Assignee.shortName : null,
                  open: true,
                  status: ticket.status,
                  type: "customer",
                  priority: ticket.priority,
                  contact: ticket.contact,
                  contract: ticket.contract,
                  creationDate: ticket.createdAt,
                  invoiced: ticket.invoiced,
                  closed: ticket.closed,
                  ticketPeriods: ticket.TicketPeriods,
                  workTime: ticketWorkTime,
                  running: running,
                };
              });
              //console.log(tickets);
              if (this.props.customer) {
                tickets = tickets.filter((x) => x.customer.id === this.props.customer.value);
              }
              if (this.state.tickets.length < tickets.length && !this.state.firstLoad) {
                console.log("new ticket");
                this.togglePlayNewTicketSound();
              }
              this.setState({
                firstLoad: false,
                tickets: tickets,
                isFetching: false,
                users: users.data,
                hotlinePrice: hotlinePrice,
                userOptionsTable: userOptionsTable,
              });
            });
          } else {
            //console.log("closed");
            fetchOldestTicketYear().then((resOldestYear) => {
              fetchClosedTickets(this.state.yearFilter.value).then((result) => {
                let oldestYear = resOldestYear.oldestYear;
                let tickets = result.map((ticket) => {
                  let running = false;
                  let ticketWorkTime = 0;
                  ticket.TicketPeriods.forEach((tp) => {
                    ticketWorkTime += tp.workTime;
                    if (!tp.endDateTime) {
                      running = true;
                      ticketWorkTime += moment.duration(moment().diff(tp.startDateTime)).asMinutes();
                    }
                  });
                  return {
                    id: ticket.id,
                    number: ticket.ticketNumber,
                    author: ticket.Author,
                    customer: ticket.Customer,
                    description: ticket.description,
                    site: ticket.Site ? ticket.Site.name : Translator("no specific site"),
                    assignee: ticket.Assignee ? ticket.Assignee.shortName : null,
                    open: true,
                    status: ticket.status,
                    priority: ticket.priority,
                    type: "customer",
                    contact: ticket.contact,
                    contract: ticket.contract,
                    creationDate: ticket.createdAt,
                    invoiced: ticket.invoiced,
                    closed: ticket.closed,
                    ticketPeriods: ticket.TicketPeriods,
                    workTime: ticketWorkTime,
                    logbookId: ticket.LogbookId,
                    running: running,
                  };
                });
                let yearOptionTable = [];
                for (let i = oldestYear; i <= moment().year(); i++) {
                  yearOptionTable.push({ value: i, label: i });
                }
                yearOptionTable.push({ value: "all", label: Translator("all") });
                if (this.props.customer) {
                  tickets = tickets.filter((x) => x.customer.id === this.props.customer.value);
                }
                this.setState({
                  firstLoad: false,
                  tickets: tickets,
                  isFetching: false,
                  users: users.data,
                  hotlinePrice: hotlinePrice,
                  yearOptionTable: yearOptionTable,
                  userOptionsTable: userOptionsTable,
                });
              });
            });
          }
        } else {
          //fetch internal tickets
          if (status === "open") {
            console.log("open");
            fetchOpenInternalTickets().then((result) => {
              let tickets = result.map((ticket) => {
                let running = false;
                let ticketWorkTime = 0;
                ticket.TicketPeriods.forEach((tp) => {
                  ticketWorkTime += tp.workTime;
                  if (!tp.endDateTime) {
                    running = true;
                    ticketWorkTime += moment.duration(moment().diff(tp.startDateTime)).asMinutes();
                  }
                });
                return {
                  id: ticket.id,
                  number: ticket.ticketNumber,
                  author: ticket.Author,
                  customer: ticket.Customer,
                  description: ticket.description,
                  site: ticket.Site ? ticket.Site.name : Translator("no specific site"),
                  assignee: ticket.Assignee ? ticket.Assignee.shortName : null,
                  open: true,
                  status: ticket.status,
                  priority: ticket.priority,
                  type: "internal",
                  contact: ticket.contact,
                  contract: ticket.contract,
                  creationDate: ticket.createdAt,
                  invoiced: ticket.invoiced,
                  closed: ticket.closed,
                  ticketPeriods: ticket.TicketPeriods,
                  workTime: ticketWorkTime,
                  running: running,
                };
              });
              if (this.props.customer) {
                tickets = tickets.filter((x) => x.customer.id === this.props.customer.value);
              }
              this.setState({
                firstLoad: false,
                tickets: tickets,
                isFetching: false,
                users: users.data,
                hotlinePrice: hotlinePrice,
                userOptionsTable: userOptionsTable,
              });
            });
          } else {
            console.log("closed");
            fetchOldestInternalTicketYear().then((resOldestYear) => {
              fetchClosedInternalTickets(this.state.yearFilter.value).then((result) => {
                let oldestYear = resOldestYear.oldestYear;
                let tickets = result.map((ticket) => {
                  let running = false;
                  let ticketWorkTime = 0;
                  ticket.TicketPeriods.forEach((tp) => {
                    ticketWorkTime += tp.workTime;
                    if (!tp.endDateTime) {
                      running = true;
                      ticketWorkTime += moment.duration(moment().diff(tp.startDateTime)).asMinutes();
                    }
                  });
                  return {
                    id: ticket.id,
                    number: ticket.ticketNumber,
                    author: ticket.Author,
                    customer: ticket.Customer,
                    description: ticket.description,
                    site: ticket.Site ? ticket.Site.name : Translator("no specific site"),
                    assignee: ticket.Assignee ? ticket.Assignee.shortName : null,
                    open: true,
                    status: ticket.status,
                    type: "internal",
                    priority: ticket.priority,
                    contact: ticket.contact,
                    contract: ticket.contract,
                    creationDate: ticket.createdAt,
                    invoiced: ticket.invoiced,
                    closed: ticket.closed,
                    ticketPeriods: ticket.TicketPeriods,
                    workTime: ticketWorkTime,
                    logbookId: ticket.LogbookId,
                    running: running,
                  };
                });
                let yearOptionTable = [];
                for (let i = oldestYear; i <= moment().year(); i++) {
                  yearOptionTable.push({ value: i, label: i });
                }
                yearOptionTable.push({ value: "all", label: Translator("all") });
                if (this.props.customer) {
                  tickets = tickets.filter((x) => x.customer.id === this.props.customer.value);
                }

                this.setState({
                  firstLoad: false,
                  tickets: tickets,
                  isFetching: false,
                  users: users.data,
                  hotlinePrice: hotlinePrice,
                  yearOptionTable: yearOptionTable,
                  userOptionsTable: userOptionsTable,
                });
              });
            });
          }
          this.setState({ isFetching: false });
        }
      });
    });
  }

  reload() {
    fetchCustomers().then((fetchedCustomers) => {
      if (fetchedCustomers) {
        this.setState({
          customers: fetchedCustomers.data,
          customersOptionTable: fetchedCustomers.data.map((customer) => {
            return { value: customer.id, label: customer.name };
          }),
        });
      }
    });
  }

  togglePlayNewTicketSound = () => {
    if (store().getUser().accreditation === 99) {
      this.setState({ play: !this.state.play }, () => {
        this.state.play ? this.audio.play() : this.audio.pause();
      });
    }
  };
  linkTicketToInvoice(ticket, invoice) {
    linkTicketToInvoice(ticket, invoice.value).then(() => {
      this.reloadTickets();
    });
  }
  handleTicket(ticketId, userId) {
    handleTicket(ticketId, userId).then(() => {
      NotifyReloadTicket("ticket");
      this.reloadTickets();
    });
  }
  pauseTicket(ticketId, userId) {
    pauseTicket(ticketId, userId).then(() => {
      NotifyReloadTicket("ticket");
      this.reloadTickets();
    });
  }
  closeTicket(ticket, userId) {
    closeTicket(ticket.id, userId).then(() => {
      NotifyReloadTicket("ticket");
      this.openDetails(ticket);
    });
  }
  releaseTicket(ticketId) {
    releaseTicket(ticketId).then(() => {
      NotifyReloadTicket("ticket");
      this.reloadTickets();
    });
  }
  submitTicket() {
    console.log(this.defaultNewTicketFormPayload);
    let ticketToPost = {};
    ticketToPost.description = this.state.newTicketFormPayload.description;
    ticketToPost.contact = this.state.newTicketFormPayload.contact.label;
    ticketToPost.contract = this.state.newTicketFormPayload.contract;
    ticketToPost.priority = this.state.newTicketFormPayload.code;
    ticketToPost.type = this.state.tabs.find((tab) => tab.active).value;
    ticketToPost.customer = this.state.newTicketFormPayload.customer.value;
    ticketToPost.user = store().getUser().userId;
    ticketToPost.site = this.state.newTicketFormPayload.site.value;
    console.log(ticketToPost);
    postTicket(ticketToPost).then(() => {
      this.setState({
        newTicketFormPayload: this.defaultNewTicketFormPayload,
        creatingMode: !this.state.creatingMode,
        highlightMandatoryFields: false,
      });
      NotifyReloadTicket("ticket");
      this.reloadTickets();
    });
  }

  openDetails(ticket) {
    const path = this.path;
    this.push({
      pathname: `${path}/${ticket.number}`,
    });
    //this.setState({ selectedTicket: ticket });
    console.log(ticket);
  }

  generateTabs() {
    return (
      <ul className="nav nav-pills">
        {this.state.tabs.map((tab) => {
          if (store().getUser().accreditation <= tab.accreditation) {
            return (
              <li
                key={tab.value}
                className={tab.active ? "nav-link active" : "nav-link"}
                onClick={this.handleClickTab.bind(this, tab)}
              >
                {tab.desc}
              </li>
            );
          }
        })}
      </ul>
    );
  }
  generateSubTabs() {
    return (
      <ul className="nav nav-tabs">
        {this.state.subtabs.map((tab) => {
          if (store().getUser().accreditation <= tab.accreditation) {
            return (
              <li className="nav-item" key={tab.value}>
                <div
                  className={tab.active ? "nav-link active" : "nav-link"}
                  onClick={this.handleClickSubTab.bind(this, tab)}
                >
                  {tab.desc}
                </div>
              </li>
            );
          }
        })}
      </ul>
    );
  }
  handleClickSubTab(tab) {
    const newTabs = this.state.subtabs.map((stateTab) => {
      stateTab.active = stateTab.value === tab.value;
      return stateTab;
    });
    this.reloadTickets();
    this.setState({
      subtabs: newTabs,
    });
  }
  handleClickTab(tab) {
    const newTabs = this.state.tabs.map((stateTab) => {
      stateTab.active = stateTab.value === tab.value;
      return stateTab;
    });

    //fetch tickets for selected tab then set the new tickets in the state
    this.reloadTickets();
    this.setState({
      tabs: newTabs,
      newTicketFormPayload: {
        ...this.state.newTicketFormPayload,
        code: newTabs.find((x) => x.active).value === "customer" ? "red" : "asap",
      },
    });
  }
  renderContractCheckBoxes() {
    return (
      <div>
        <span className="fa-stack" style={{ verticalAlign: "top" }}>
          <i className="fas fa-file-contract fa-stack-2x"></i>
          <i className="fas fa-wrench fa-stack-1x fa-inverse pr-1 pt-1"></i>
        </span>
        <div className="btn-group">
          <button
            className="btn btn-default"
            onClick={() => {
              let contracts = this.state.ticketContracts.map((aContract) => {
                store()
                  .getCookies()
                  .set(aContract.value + "checked", true + "", {
                    path: "/",
                    maxAge: 604800,
                    sameSite: "lax",
                  });
                aContract.checked = true;
                return aContract;
              });
              this.setState({ ticketContracts: contracts });
            }}
          >
            {Translator("all")}
          </button>
          <button
            className="btn btn-default"
            onClick={() => {
              let contracts = this.state.ticketContracts.map((aContract) => {
                if (aContract.value === "none") {
                  store()
                    .getCookies()
                    .set(aContract.value + "checked", true + "", {
                      path: "/",
                      maxAge: 604800,
                      sameSite: "lax",
                    });
                  aContract.checked = true;
                } else {
                  store()
                    .getCookies()
                    .set(aContract.value + "checked", false + "", {
                      path: "/",
                      maxAge: 604800,
                      sameSite: "lax",
                    });
                  aContract.checked = false;
                }
                return aContract;
              });
              this.setState({ ticketContracts: contracts });
            }}
          >
            {Translator("none")}
          </button>
          <div className="btn-group">
            <button className="btn btn-default dropdown-toggle" type="button" data-toggle="dropdown">
              {this.state.ticketContracts.filter((x) => x.checked).length > 1
                ? Translator("all selected")
                : Translator(this.state.ticketContracts.find((x) => x.checked).value)}
            </button>
            <div className="dropdown-menu">
              {this.state.ticketContracts.map((contract) => {
                return (
                  <a
                    className="dropdown-item"
                    key={contract.value}
                    onClick={() => {
                      let contracts = this.state.ticketContracts.map((aContract) => {
                        if (aContract.value === contract.value) {
                          store()
                            .getCookies()
                            .set(aContract.value + "checked", true + "", {
                              path: "/",
                              maxAge: 604800,
                              sameSite: "lax",
                            });
                          aContract.checked = true;
                        } else {
                          store()
                            .getCookies()
                            .set(aContract.value + "checked", false + "", {
                              path: "/",
                              maxAge: 604800,
                              sameSite: "lax",
                            });
                          aContract.checked = false;
                        }
                        return aContract;
                      });
                      this.setState({ ticketContracts: contracts });
                    }}
                  >
                    {contract.desc}
                  </a>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
  renderStatusesCheckBoxes() {
    return (
      <div className="input-group mb-2 mr-md-2">
        {this.state.pendingTicketTemporaryStatuses
          .filter((x) => x.value === "active" || !this.props.monitoringMode)
          .map((status) => {
            return (
              <div key={status.value} className="input-group-prepend">
                <div
                  className="form-check"
                  onClick={() => {
                    let statuses = this.state.pendingTicketTemporaryStatuses.map((aStatus) => {
                      if (aStatus.value === status.value) {
                        store()
                          .getCookies()
                          .set(aStatus.value + "checked", !aStatus.checked + "", {
                            path: "/",
                            maxAge: 604800,
                            sameSite: "lax",
                          });
                        aStatus.checked = !aStatus.checked;
                      }
                      return aStatus;
                    });
                    this.setState({ pendingTicketTemporaryStatuses: statuses });
                  }}
                >
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value={status.value}
                    checked={status.checked}
                    onChange={(e) => {}}
                  ></input>
                  <label className="form-check-label">&nbsp;{status.desc}&nbsp;</label>
                </div>
              </div>
            );
          })}
        {this.state.isFetching ? <i className="fas fa-2x fa-spinner fa-spin"></i> : null}
      </div>
    );
  }
  cancelCreate() {
    this.setState({
      creatingMode: false,
      newTicketFormPayload: this.defaultNewTicketFormPayload,
      highlightMandatoryFields: false,
    });
  }
  fetchCustomerInformations(selectedOption) {
    fetchCustomerConveyors(selectedOption.value).then((result) => {
      console.log(this.state.tickets.filter((x) => x.id === selectedOption.id));
      fetchContacts(selectedOption.value).then((contacts) => {
        const customerContacts = contacts.data.map((contact) => {
          return {
            value: contact.name + " - " + contact.phone1 + " - " + contact.phone2 + " - " + contact.mail,
            label: contact.name + " - " + contact.phone1 + " - " + contact.phone2 + " - " + contact.mail,
          };
        });
        const customerSite = result.Sites.map((site) => {
          return {
            value: site.id,
            label: site.name,
          };
        });
        customerSite.push({
          value: "all",
          label: Translator("all"),
        });
        this.setState({
          newTicketFormPayload: {
            ...this.state.newTicketFormPayload,
            customer: selectedOption,
            site: customerSite.find((el) => el.value === "all"),
            contact:
              this.state.tabs.find((x) => x.active).value === "customer"
                ? ""
                : this.state.newTicketFormPayload.contact
                ? this.state.newTicketFormPayload.contact
                : "",
            contract: this.state.customers.find((c) => c.id === selectedOption.value).contract,
          },
          sites: result.Sites,
          siteOptionTable: customerSite,
          contactsOptionTable: customerContacts,
        });
      });
    });
  }

  renderModalFooterButtons() {
    if (this.state.creatingMode) {
      return (
        <form className="form-inline col-sm-12">
          <div className="input-group mb-2">
            <button
              className="btn btn-info btn mr-2 "
              data-dismiss={this.state.newTicketFormPayload.customer.value === undefined ? undefined : "modal"}
              //disabled={this.state.newTicketFormPayload.customer.value === undefined}
              onClick={(e) => {
                e.preventDefault();
                if (this.state.newTicketFormPayload.customer.value === undefined) {
                  this.setState({ highlightMandatoryFields: true });
                } else {
                  this.submitTicket();
                }

                //this.saveLogbook();
              }}
              //disabled={this.isSaveButtonDisabled()}
            >
              {Translator("enregistrer")}
            </button>
            <button
              className="btn btn-info btn "
              data-dismiss="modal"
              onClick={(e) => {
                e.preventDefault();
                this.cancelCreate();
              }}
            >
              {Translator("annuler")}
            </button>
          </div>
        </form>
      );
    } else {
      return (
        <form className="form-inline col-sm-12">
          <div className="input-group mb-2 mr-2">
            <button
              className="btn btn-info btn mr-2"
              data-dismiss="modal"
              onClick={(e) => {
                e.preventDefault();
                //this.editLogbook();
              }}
            >
              {Translator("enregistrer")}
            </button>
          </div>
          <div className="input-group mb-2">
            <button
              className="btn btn-info btn mr-2"
              data-dismiss="modal"
              onClick={(e) => {
                e.preventDefault();
                this.toggleCreatingMode();
              }}
            >
              {Translator("annuler")}
            </button>
          </div>
        </form>
      );
    }
  }
  settingCurrentTicket(ticket, ticketCalculatedPrice) {
    ticket.price = ticketCalculatedPrice;
    //fetchCustomerUnpaidInvoices(ticket.Customer)
    console.log(ticket);
    this.setState({ currentTicket: ticket });
  }
  renderClosedTickets(invoiced, propfilterText) {
    const translatePriority = (priority) => {
      switch (priority) {
        case "yellow":
        case "low_priority":
          return "warning";
        case "priority":
        case "orange":
          return "orange";
        case "asap":
        case "red":
          return "danger";
      }
    };
    const filterText = propfilterText
      .toUpperCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
    return (
      <div className="col">
        {
          /*
           * Filter only checked contract, assignee, customer, year or text, then sort tickets by creation date
           */
          this.state.tickets
            .filter((ticket) => {
              return (
                this.state.ticketContracts
                  .filter((contract) => {
                    return contract.checked;
                  })
                  .find((ticketContract) => {
                    return ticketContract.value === ticket.contract;
                  }) !== undefined
              );
            })
            .filter((ticket) => {
              return (
                this.state.yearFilter.value === "all" ||
                moment(ticket.creationDate).year() === this.state.yearFilter.value
              );
            })
            .filter((ticket) => (this.state.userFilter.label ? ticket.assignee === this.state.userFilter.label : true))
            .filter((ticket) =>
              this.state.customerFilter.value ? ticket.customer.id === this.state.customerFilter.value : true
            )

            .filter((ticket) => {
              if (filterText) {
                if (
                  ticket.description
                    .toUpperCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .includes(filterText)
                ) {
                  return true;
                }
                if (
                  ticket.customer.name
                    .toUpperCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .includes(filterText)
                ) {
                  return true;
                }
                if (
                  ticket.assignee &&
                  ticket.assignee
                    .toUpperCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .includes(filterText)
                ) {
                  return true;
                }
                let res = false;
                var BreakException = {};
                try {
                  ticket.ticketPeriods.forEach((period) => {
                    if (
                      period.description &&
                      period.description
                        .toUpperCase()
                        .normalize("NFD")
                        .replace(/[\u0300-\u036f]/g, "")
                        .includes(filterText)
                    ) {
                      console.log("true in period desc");
                      res = true;
                    }
                    if (res) throw BreakException;
                  });
                } catch (e) {
                  if (e !== BreakException) throw e;
                }

                return res;
              } else {
                return true;
              }
            })
            .filter((ticket) => {
              return !ticket.invoiced || this.state.invoiceFilter;
            })
            .filter((ticket) => {
              return !ticket.logbookId || this.state.logbookFilter;
            })

            //.filter((ticket) => (ticket.invoiced != null) === invoiced)
            .sort((a, b) => {
              return moment(a.creationDate).isSameOrBefore(moment(b.creationDate)) ? 1 : -1;
            })
            .map((ticket) => {
              let ticketWorktime = 0;
              if (ticket.closed) {
                const totalWorkTime = ticket.ticketPeriods.forEach((tp) => {
                  ticketWorktime += tp.workTime;
                  //console.log(tp.endDateTime);
                  if (!tp.endDateTime) {
                    ticketWorktime += moment.duration(moment().diff(tp.startDateTime)).asMinutes();
                  }
                });

                return (
                  <Card
                    key={ticket.id}
                    title={
                      ticket.number + " | " + MomentDateTimeString(ticket.creationDate) + " | " + ticket.customer.name
                    }
                    tools={Translator("closed by") + " " + ticket.assignee}
                    type={translatePriority(ticket.priority)}
                    slim={true}
                  >
                    <div className="row py-1">
                      <div>{ticket.logbookId ? <i className="fas fa-book mt-2"></i> : null}</div>
                      <div className="col-1">
                        <button
                          className="btn btn-default my-n1"
                          onClick={(e) => {
                            e.preventDefault();
                            this.openDetails(ticket);
                          }}
                        >
                          {Translator("details")}
                        </button>
                      </div>
                      <div
                        className="col text-truncate mt-1"
                        dangerouslySetInnerHTML={this.renderHtml(ticket.description)}
                        rel="tooltip"
                        data-html="true"
                        data-placement="bottom"
                        title={ticket.description}
                        style={{ maxHeight: "1.5em" }}
                      ></div>
                      {ticket.invoiced ? (
                        <button className="btn btn-default">{Translator("invoiced")}</button>
                      ) : (
                        <div className="col-3 form-inline input-group input-group-sm  mt-1">
                          <div className="mr-1">{Translator("total") + " : " + parseTime(ticketWorktime)}</div>
                          <div className="input-group-prepend">
                            <span className="input-group-text">{Translator("price/H")}</span>
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            value={
                              this.state.ticketPrices[ticket.id] !== undefined
                                ? this.state.ticketPrices[ticket.id]
                                : this.state.hotlinePrice
                                ? this.state.hotlinePrice.find((x) =>
                                    x.type.toLowerCase().includes(ticket.contract.toLowerCase())
                                  )
                                  ? this.state.hotlinePrice.find((x) =>
                                      x.type.toLowerCase().includes(ticket.contract.toLowerCase())
                                    ).value
                                  : this.state.hotlinePrice.find((x) =>
                                      ticket.contract === "none"
                                        ? x.type === "hotlinePrice"
                                        : x.type === "hotlinePriceContract"
                                    ).value
                                : 80
                            }
                            onChange={(e) => {
                              let val = e.target.value;
                              if (val.trim() === "") val = 0;

                              this.setState({
                                ticketPrices: { ...this.state.ticketPrices, [ticket.id]: val },
                              });
                            }}
                          ></input>
                          <div className="input-group-append">
                            <button
                              type="button"
                              className="btn btn-info btn-flat"
                              rel="tooltip"
                              data-html="true"
                              data-placement="bottom"
                              title={Translator("Add to invoice")}
                              data-toggle="modal"
                              data-target="#modal-invoice"
                              onClick={() =>
                                this.settingCurrentTicket(
                                  ticket,
                                  (
                                    (ticketWorktime / 60) *
                                    (this.state.ticketPrices[ticket.id] ? this.state.ticketPrices[ticket.id] : 80)
                                  ).toFixed(1)
                                )
                              }
                            >
                              {(
                                (ticketWorktime / 60) *
                                (this.state.ticketPrices[ticket.id] ? this.state.ticketPrices[ticket.id] : 80)
                              ).toFixed(1)}
                              €
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </Card>
                );
              }
            })
        }
      </div>
    );
  }

  DateSort(toSort, direction) {
    const compare = (a, b) => {
      if (direction === "descending") {
        if (moment(a.startDateTime).isBefore(moment(b.startDateTime))) return this.state.dateSort ? -1 : 1;
        if (moment(a.startDateTime).isAfter(moment(b.startDateTime))) return this.state.dateSort ? 1 : -1;
        return 0;
      } else {
        if (moment(a.startDateTime).isBefore(moment(b.startDateTime))) return this.state.dateSort ? 1 : -1;
        if (moment(a.startDateTime).isAfter(moment(b.startDateTime))) return this.state.dateSort ? -1 : 1;
        return 0;
      }
    };

    let sorted = toSort;
    sorted.sort(compare);
    return sorted;
  }
  renderTickets(status, assigned, propfilterText, collapsed) {
    const filterText = propfilterText
      .toUpperCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
    return (
      <div className="row justify-content-md-center">
        {
          /*
           * Filter only current status  then sort tickets by priority => RED > ORANGE > YELLOW
           */
          this.state.tickets
            .filter((ticket) => ticket.status === status || ticket.status.includes(status))
            .filter((ticket) => {
              if (filterText) {
                if (
                  ticket.description
                    .toUpperCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .includes(filterText)
                ) {
                  return true;
                }
                if (
                  ticket.customer.name
                    .toUpperCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")

                    .includes(filterText)
                ) {
                  return true;
                }
                if (
                  ticket.assignee &&
                  ticket.assignee
                    .toUpperCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .includes(filterText)
                ) {
                  return true;
                }
                let res = false;
                var BreakException = {};
                try {
                  ticket.ticketPeriods.forEach((period) => {
                    if (
                      period.description &&
                      period.description
                        .toUpperCase()
                        .normalize("NFD")
                        .replace(/[\u0300-\u036f]/g, "")

                        .includes(filterText)
                    ) {
                      console.log("true in period desc");
                      res = true;
                    }
                    if (res) throw BreakException;
                  });
                } catch (e) {
                  if (e !== BreakException) throw e;
                }

                return res;
              } else {
                return true;
              }
            })
            .filter((ticket) => (ticket.assignee != null) === assigned)
            .sort((a, b) => {
              if (a.priority === b.priority) {
                //console.log(a.number + ":" + a.priority + " is same as " + b.number + ":" + b.priority);
                return 0;
              }
              if (a.priority === "red" && b.priority !== "red") {
                //console.log(a.number + ":" + a.priority + " is before " + b.number + ":" + b.priority);
                return -1;
              }
              /*if (a.priority === "orange" && b.priority !== "red") {
                console.log(a.number + ":" + a.priority + " is before " + b.number + ":" + b.priority);
                return -1;
              }*/
              if (a.priority === "yellow" && b.priority !== "yellow") {
                //console.log(a.number + ":" + a.priority + " is after " + b.number + ":" + b.priority);
                return 1;
              }
              return 1;
            })
            .map((ticket) => {
              /*
               * Select the bootstrap col size depending on the number of columns to display
               */
              const colSize = () => {
                //if (!assigned || this.props.monitoringMode) return 4;
                switch (this.state.pendingTicketTemporaryStatuses.filter((x) => x.checked).length) {
                  case 1:
                    return 4;
                  case 2:
                    return 9;
                  case 3:
                    return 12;
                }
              };
              if (!ticket.closed) {
                return (
                  <TicketWidget
                    key={ticket.id}
                    ticket={ticket}
                    colSize={colSize}
                    handleTicket={this.handleTicket.bind(this)}
                    openDetails={this.openDetails.bind(this)}
                    closeTicket={this.closeTicket.bind(this)}
                    changeStatus={this.changeTicketStatus.bind(this)}
                    pauseTicket={this.pauseTicket.bind(this)}
                    releaseTicket={this.releaseTicket.bind(this)}
                    collapsed={collapsed}
                  />
                );
              }
            })
        }
      </div>
    );
  }
  toggleCreatingMode() {
    this.setState({ creatingMode: !this.state.creatingMode });
  }

  changeTicketStatus(ticket, status) {
    if (ticket.status !== status) {
      changeTicketStatus(ticket.id, status).then(() => {
        NotifyReloadTicket("ticket");
        this.reloadTickets();
      });
    }
  }
  setSearchFilter = (e) => {
    this.setState({ filterText: e.target.value });
  };
  generateOpenTabContent() {
    const linePos = () => {
      switch (this.state.pendingTicketTemporaryStatuses.filter((x) => x.checked).length) {
        case 1:
          return "16%";
        case 2:
          return "25%";
        case 3:
          return "50%";
      }
    };
    return (
      <div className="container-fluid m-n2">
        <Card
          title={this.renderStatusesCheckBoxes()}
          //isFetching={this.state.isFetching}
          tools={<div className="row">{this.renderTextFilter()}</div>}
        >
          <div className="card card-primary">
            <div className="card-header">
              <h3 className="card-title">{Translator("not yet assigned")}</h3>
              <div className="card-tools">
                <button type="button" className="btn btn-tool" data-card-widget="collapse">
                  <i className="fas fa-minus"></i>
                </button>
              </div>
            </div>
            <div className="card-body">
              <div className="row">
                {[
                  {
                    value: "active",
                    checked: true,
                    desc: Translator("active"),
                  },
                  {
                    value: "wait",
                    checked: true,
                    desc: Translator("waiting"),
                  },
                ].map((status) => {
                  if (status.checked) {
                    return (
                      <div
                        key={status.value}
                        className="col"
                        //style={{ backgroundImage: "linear-gradient(to right, white, gray, white)" }}
                      >
                        <div
                          style={{
                            borderLeft: "6px dotted #DDDDDD",
                            height: "80%",
                            position: "absolute",
                            marginLeft: "-3px",
                            left: "50%",
                            marginTop: "55px",
                            top: "0",
                          }}
                        ></div>
                        <h2 className="flipInX animated" style={{ textAlign: "center" }}>
                          {status.desc}&nbsp;
                          <span
                            onClick={(e) => {
                              e.preventDefault();
                              if (this.state.collapsed[status.value] === true) {
                                this.setState({ collapsed: { ...this.state.collapsed, [status.value]: false } });
                              } else {
                                this.setState({ collapsed: { ...this.state.collapsed, [status.value]: true } });
                              }
                            }}
                          >
                            {this.state.collapsed[status.value] === true ? (
                              <i className="fas fa-compress-alt"></i>
                            ) : (
                              <i className="fas fa-expand-alt"></i>
                            )}
                          </span>
                        </h2>

                        {this.renderTickets(
                          status.value,
                          false,
                          this.state.filterText,
                          this.state.collapsed[status.value]
                        )}
                      </div>
                    );
                  }
                })}
              </div>
            </div>
          </div>

          <hr></hr>

          <div className="card card-secondary">
            <div className="card-header">
              <h3 className="card-title">{Translator("already assigned")}</h3>
              <div className="card-tools">
                <button type="button" className="btn btn-tool" data-card-widget="collapse">
                  <i className="fas fa-minus"></i>
                </button>
              </div>
            </div>
            <div className="card-body">
              <div className="row">
                {this.state.pendingTicketTemporaryStatuses.map((status) => {
                  if ((status.checked && !this.props.monitoringMode) || (status.value === "active" && status.checked)) {
                    return (
                      <TicketListFlow
                        key={status.value}
                        status={status}
                        renderTickets={this.renderTickets.bind(this)}
                        pendingTicketTemporaryStatuses={this.state.pendingTicketTemporaryStatuses}
                        filterText={this.state.filterText}
                      ></TicketListFlow>
                    );
                  }
                })}
              </div>
            </div>
          </div>
        </Card>
      </div>
    );
  }

  renderClosedTicketTitle() {
    return <div className="row">{this.renderContractCheckBoxes()}</div>;
  }
  generateClosedTabContent() {
    const linePos = () => {
      switch (this.state.pendingTicketTemporaryStatuses.filter((x) => x.checked).length) {
        case 1:
          return "16%";
        case 2:
          return "25%";
        case 3:
          return "50%";
      }
    };
    const DropdownIndicator = (props) => {
      return (
        components.DropdownIndicator && (
          <components.DropdownIndicator {...props}>
            <i className="fas fa-caret-down"></i>
          </components.DropdownIndicator>
        )
      );
    };
    return (
      <div className="container-fluid">
        <Card
          title={this.renderClosedTicketTitle()}
          tools={
            <div className="row">
              <div className="col mb-2 mt-1 mr-2" style={{ minWidth: "125px" }}>
                <Select
                  components={{ DropdownIndicator }}
                  options={this.state.userOptionsTable}
                  placeholder={this.state.userFilter.label}
                  value={this.state.userFilter}
                  isClearable
                  onChange={(selectedUser) => {
                    this.setState({ userFilter: selectedUser ? selectedUser : {} });
                    this.reloadTickets();
                  }}
                />
              </div>
              <div className="col mb-2 mt-1 mr-2" style={{ minWidth: "125px" }}>
                {" "}
                <Select
                  components={{ DropdownIndicator }}
                  options={this.state.yearOptionTable}
                  isClearable
                  placeholder={this.state.yearFilter.label}
                  value={this.state.yearFilter}
                  onChange={(selectedYear) => {
                    this.setState({
                      yearFilter: selectedYear ? selectedYear : { value: "all", label: Translator("all") },
                    });
                    this.reloadTickets();
                  }}
                />
              </div>
              {this.props.customer ? null : (
                <div className="col mb-2 mt-1 mr-2">
                  <Select
                    style={{ width: `${8 * this.state.customerFilter.length + 100}px` }}
                    isClearable
                    className="select-custom-class"
                    placeholder={Translator("customer")}
                    onChange={(selectedCustomer) => {
                      this.setState({ customerFilter: selectedCustomer ? selectedCustomer : {} });
                      this.reloadTickets();
                    }}
                    options={this.state.customersOptionTable}
                  ></Select>
                </div>
              )}
              {this.renderTextFilter()}
              {this.renderHideAlreadyInvoiced()}
              {this.renderHideLogbooked()}
            </div>
          }
        >
          {this.renderClosedTickets(false, this.state.filterText)}
        </Card>
      </div>
    );
  }
  handleKeyDown = (e) => {
    if (e.key === "Escape") {
      this.setState({ filterText: "" });
    }
  };
  renderTextFilter() {
    return (
      <div className="input-group col mb-2 mt-1 mx-1" style={{ minWidth: "200px" }}>
        <input
          className="form-control"
          placeholder={Translator("search")}
          type="text"
          value={this.state.filterText}
          onKeyDown={this.handleKeyDown}
          onChange={this.setSearchFilter.bind(this)}
        ></input>

        <div className="input-group-append">
          <button
            className="btn btn-default"
            onClick={(e) => {
              e.preventDefault();
              this.setState({ filterText: "" });
            }}
          >
            <span style={{ color: "Tomato" }}>
              <i className="fas fa-times"></i>
            </span>
          </button>
        </div>
      </div>
    );
  }
  renderHideAlreadyInvoiced() {
    return (
      <div
        className="input-group col mb-2 mt-1 mr-2"
        style={{ minWidth: "155px" }}
        onClick={() => {
          this.setState({ invoiceFilter: !this.state.invoiceFilter });
        }}
      >
        <div className="input-group-prepend">
          <div className="input-group-text">
            <input type="checkbox" checked={this.state.invoiceFilter} onChange={(e) => {}}></input>
          </div>
        </div>
        <label type="text" className="form-control text-truncate">
          {Translator("invoiced")}
        </label>
      </div>
    );
  }
  renderHideLogbooked() {
    return (
      <div
        className="input-group col mb-2 mt-1 mr-2"
        style={{ minWidth: "155px" }}
        onClick={() => {
          this.setState({ logbookFilter: !this.state.logbookFilter });
        }}
      >
        <div className="input-group-prepend">
          <div className="input-group-text">
            <input type="checkbox" checked={this.state.logbookFilter} onChange={(e) => {}}></input>
          </div>
        </div>
        <label type="text" className="form-control text-truncate">
          {Translator("logged")}
        </label>
      </div>
    );
  }
  generateTabRelatedContent() {
    {
      //test
      const currentTab = this.state.subtabs.find((x) => x.active);
      switch (currentTab.value) {
        case "open":
          return this.generateOpenTabContent();
        case "closed":
          return this.generateClosedTabContent();
          break;
        default:
          break;
      }
    }
  }
  renderHtml = (html) => {
    return { __html: html };
  };

  renderNewTicketModal() {
    return (
      <div
        className="modal fade"
        id="modal-redaction"
        data-backdrop="static"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                {this.state.creatingMode
                  ? Translator("new ticket ") +
                    (this.state.newTicketFormPayload.customer.label
                      ? " : " + this.state.newTicketFormPayload.customer.label
                      : "")
                  : this.state.editedData
                  ? "edit ticket"
                  : ""}{" "}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={(e) => {
                  e.preventDefault();
                  if (this.state.creatingMode) this.cancelCreate();
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {this.state.creatingMode ? (
                <TicketCreationForm
                  tickets={this.state.tickets}
                  creatingMode={this.state.creatingMode}
                  newTicketFormPayload={this.state.newTicketFormPayload}
                  customers={this.state.customers}
                  sites={this.state.sites}
                  userOptionsTable={this.state.userOptionsTable}
                  customersOptionTable={this.state.customersOptionTable}
                  siteOptionTable={this.state.siteOptionTable}
                  contactsOptionTable={this.state.contactsOptionTable}
                  customer={this.props.customer}
                  fetchCustomerInformations={this.fetchCustomerInformations.bind(this)}
                  openDetails={this.openDetails.bind(this)}
                  setState={this.setState.bind(this)}
                  ticketType={this.state.tabs.find((x) => x.active).value}
                  updateTicketDescription={((content) => {
                    this.setState({
                      newTicketFormPayload: { ...this.state.newTicketFormPayload, description: content },
                    });
                  }).bind(this)}
                  highlightMandatoryFields={this.state.highlightMandatoryFields}
                ></TicketCreationForm>
              ) : this.state.editedData ? (
                this.customEditForm(this.state.editedData)
              ) : null}
            </div>

            <div className="modal-footer">{this.renderModalFooterButtons()}</div>
          </div>
        </div>
      </div>
    );
  }
  renderInvoiceModal() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        borderBottom: "1px dotted pink",
        width: state.selectProps.width,
        color: state.isSelected ? "white" : "black",
      }),
      singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = "opacity 300ms";

        return { ...provided, opacity, transition };
      },
    };
    return (
      <div
        className="modal fade"
        id="modal-invoice"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-md" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                {Translator("Invoice")}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({ currentInvoice: null });
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body">
              <h5>{Translator("Invoice number")}</h5>
              <Creatable
                styles={customStyles}
                onChange={(selectedOrCreatedInvoice) => {
                  this.setState({ currentInvoice: selectedOrCreatedInvoice });
                }}
                placeholder={Translator("enter or select invoice number")}
                value={this.state.currentInvoice}
                options={this.state.invoicesOptionTable}
              ></Creatable>{" "}
            </div>

            <div className="modal-footer">
              <button
                className="btn btn-default"
                data-dismiss="modal"
                aria-label="submit"
                onClick={(e) => {
                  e.preventDefault();
                  this.linkTicketToInvoice(this.state.currentTicket, this.state.currentInvoice);
                  this.setState({ currentInvoice: null });

                  //submit invoice
                }}
              >
                {Translator("submit")}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
  render() {
    $(function () {
      $(".custom-radio").tooltip({
        html: true,
        placement: "right",
      });
    });
    $(function () {
      $(".btn").tooltip({
        html: true,
        placement: "bottom",
      });
    });
    $(function () {
      $(".col").tooltip({
        html: true,
        placement: "bottom",
      });
    });

    return (
      <div>
        <div className="container-fluid">
          {this.renderNewTicketModal()}
          {this.renderInvoiceModal()}
          <Card
            title={this.generateTabs()}
            tools={
              <button
                className="btn btn-block btn-default"
                data-toggle="modal"
                data-keyboard="false"
                data-target="#modal-redaction"
                onClick={(e) => {
                  e.preventDefault();
                  this.toggleCreatingMode();
                }}
              >
                {Translator("new ticket")}
              </button>
            }
          >
            <div className="m-n3">
              <Card title={this.generateSubTabs()} tabbedHeader>
                <div className="row">{this.generateTabRelatedContent()}</div>{" "}
              </Card>{" "}
            </div>
          </Card>
        </div>
      </div>
    );
  }
}
export default withRouter(Tickets);
