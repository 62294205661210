import React from "react";
import { store, Translator } from "../../tools/Tools";
import Select from "react-select";
import Creatable from "react-select/creatable";
import RichText from "../../components/RichText";

const TicketCreationForm = (props) => {
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px dotted pink",
      width: state.selectProps.width,
      color: state.isSelected ? "white" : "black",
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    },
  };

  const selectedCustomerTickets = props.tickets.filter(
    (t) => t.customer.id === props.newTicketFormPayload.customer.value && !t.closed
  );
  let contactTimeout = null;
  const renderCodeChoice = () => {
    let codeValues = [];
    switch (props.ticketType) {
      case "customer":
        codeValues = [
          {
            value: "red",
            color: "btn-danger",
            tooltip: Translator("Clean garments can't be delivered or soiled garments can't be collected"),
          },
          {
            value: "orange",
            color: "orange",
            tooltip: Translator(
              "One ore more delivery gate or dirty bin are out of orders, but there are still other that work"
            ),
          },
          {
            value: "yellow",
            color: "btn-warning",
            tooltip: Translator(
              "A question about statistics, or another problem that affect only a small group of users"
            ),
          },
        ];
        break;
      case "internal":
        codeValues = [
          {
            value: "asap",
            color: "btn-danger",
            tooltip: Translator("Needs to be done as soon as possible"),
          },
          {
            value: "priority",
            color: "orange",
            tooltip: Translator("To do when other urgent tasks are done"),
          },
          {
            value: "low_priority",
            color: "btn-warning",
            tooltip: Translator("To do when you have time to do it"),
          },
        ];
        break;
      default:
        break;
    }
    return (
      <div className="col-md-3">
        <div className="callout callout-danger pb-1 mr-2 row">
          <h4
            className="col-2"
            style={{
              textAlign: "center",
            }}
          >
            <div style={{ width: "1px", wordWrap: "break-word", whiteSpace: "pre-wrap" }}>{Translator("CODE")}</div>
          </h4>
          <div className="form-group col" id="radioGroup">
            {codeValues.map((code) => {
              return (
                <button
                  key={code.value}
                  className={
                    `custom-control custom-radio btn btn-block ` + (code.color !== "orange" ? code.color : null)
                  }
                  style={code.color === "orange" ? { backgroundColor: "#ff851b" } : null}
                  onClick={(e) => {
                    e.preventDefault();
                    props.setState({ newTicketFormPayload: { ...props.newTicketFormPayload, code: code.value } });
                  }}
                  rel="tooltip"
                  data-html="true"
                  data-placement="right"
                  title={code.tooltip}
                >
                  <input
                    className="radio custom-control-input"
                    type="radio"
                    id="customRadio1"
                    name="customRadio"
                    checked={props.newTicketFormPayload.code === code.value}
                    onChange={(e) => {
                      e.preventDefault();
                    }}
                  ></input>
                  <label htmlFor="customRadio1" className="custom-control-label">
                    {Translator(code.value)}
                  </label>
                </button>
              );
            })}
          </div>
        </div>
        <div
          className="mb-2 mr-2 row justify-content-between"
          style={{
            textAlign: "center",
          }}
        >
          <div className="col">{Translator("author")}:</div>
          <div className="col">{store().getUser().firstName}</div>
        </div>
      </div>
    );
  };
  const renderContactOrUserList = () => {
    switch (props.ticketType) {
      case "customer":
        return (
          <div className="row my-1 form-inline ">
            <div className="input-group-prepend">
              <div className="input-group-text">
                <label htmlFor="contactSelect">{Translator("contact")}</label>
              </div>
            </div>
            <div className="col">
              <Creatable
                styles={customStyles}
                getNewOptionData={(newOption) => {
                  clearTimeout(contactTimeout);
                  contactTimeout = setTimeout(() => {
                    console.log(newOption);
                    props.setState({
                      newTicketFormPayload: {
                        ...props.newTicketFormPayload,
                        contact: { value: newOption, label: newOption },
                      },
                    });
                    clearTimeout(contactTimeout);
                  }, 100);
                }}
                onChange={(selectedOrCreatedContact) => {
                  props.setState({
                    newTicketFormPayload: {
                      ...props.newTicketFormPayload,
                      contact: selectedOrCreatedContact,
                    },
                  });
                }}
                value={props.newTicketFormPayload.contact}
                options={props.contactsOptionTable}
              ></Creatable>
            </div>
          </div>
        );
      case "internal":
        return (
          <div className="row my-1 form-inline ">
            <div className="input-group-prepend">
              <div className="input-group-text">
                <label htmlFor="colleagueSelect">{Translator("colleague")}</label>
              </div>
            </div>
            <div className="col">
              <Creatable
                styles={customStyles}
                getNewOptionData={(newOption) => {
                  clearTimeout(contactTimeout);
                  contactTimeout = setTimeout(() => {
                    console.log(newOption);
                    props.setState({
                      newTicketFormPayload: {
                        ...props.newTicketFormPayload,
                        contact: { value: newOption, label: newOption },
                      },
                    });
                    clearTimeout(contactTimeout);
                  }, 100);
                }}
                onChange={(selectedOrCreatedColleague) => {
                  console.log(selectedOrCreatedColleague);
                  props.setState({
                    newTicketFormPayload: {
                      ...props.newTicketFormPayload,
                      contact: selectedOrCreatedColleague,
                    },
                  });
                }}
                defaultValue={props.newTicketFormPayload.contact}
                value={props.newTicketFormPayload.contact}
                options={props.userOptionsTable}
              ></Creatable>
            </div>
          </div>
        );
      default:
        return (
          <div className="row my-1 form-inline ">
            <div className="input-group-prepend">
              <div className="input-group-text">
                <label htmlFor="contactSelect">{Translator("contact")}</label>
              </div>
            </div>
            <div className="col">
              <Creatable
                styles={customStyles}
                onChange={(selectedOrCreatedContact) => {
                  props.setState({
                    newTicketFormPayload: {
                      ...props.newTicketFormPayload,
                      contact: selectedOrCreatedContact,
                    },
                  });
                }}
                value={props.newTicketFormPayload.contact}
                options={props.contactsOptionTable}
              ></Creatable>
            </div>
          </div>
        );
    }
  };

  const renderContractOrAuthor = () => {
    switch (props.ticketType) {
      case "customer":
        return (
          <div className="row justify-content-start mt-3">
            <div className="col-1 mr-n4">
              <i className="fas fa-file-contract"></i>
            </div>
            <div className="col-11">
              {" "}
              <h5>
                {Translator("Contract type : ")}
                {Translator(props.newTicketFormPayload.contract)}
              </h5>
            </div>
          </div>
        );
      case "internal":
        return (
          <div className="row justify-content-start mt-3">
            <div className="col-1 mr-n4">
              <i className="fas fa-user-tie"></i>{" "}
            </div>
            <div className="col-11">
              {" "}
              <h5>
                {Translator("Requester : ")}
                {Translator(store().getUser().firstName + " " + store().getUser().lastName)}
              </h5>
            </div>
          </div>
        );
    }
  };
  return (
    <div className={props.creatingMode ? "info-box slideInDown animated" : "info-box"}>
      <span className="info-box-icon bg-info">
        <span className="fa-stack">
          <i className="fas fa-file fa-stack-2x"></i>
          <i className="fas fa-plus fa-stack-1x fa-inverse" style={{ color: "#17a2b8" }}></i>
        </span>
      </span>
      <div className="table-responsive">
        <div className="info-box-content">
          <div className="row">
            <div className="col-sm-4">
              <div className="row my-1">
                <div className="form-inline col">
                  <div className="input-group-prepend">
                    <div
                      className="input-group-text"
                      style={{
                        border:
                          props.highlightMandatoryFields && props.newTicketFormPayload.customer.value === undefined
                            ? "solid 2px"
                            : "solid 1px",
                        borderColor:
                          props.highlightMandatoryFields && props.newTicketFormPayload.customer.value === undefined
                            ? "red"
                            : "lightgray",
                        borderRadius:
                          props.highlightMandatoryFields && props.newTicketFormPayload.customer.value === undefined
                            ? "5px"
                            : undefined,
                      }}
                    >
                      <label
                        htmlFor="customerSelect"
                        className={
                          props.highlightMandatoryFields && props.newTicketFormPayload.customer.value === undefined
                            ? "col shake animated"
                            : ""
                        }
                      >
                        {Translator("customer")}
                      </label>
                    </div>
                  </div>{" "}
                  {props.customer ? (
                    props.customer.label
                  ) : (
                    <div className="col">
                      <Select
                        styles={customStyles}
                        onChange={(selectedOption) => {
                          console.log(selectedOption);
                          console.log(props.tickets.filter((x) => x.customer.id === selectedOption.value));
                          props.fetchCustomerInformations(selectedOption);
                        }}
                        options={props.customersOptionTable}
                      ></Select>
                    </div>
                  )}
                </div>
              </div>
              <div className="row my-1">
                <div className="form-inline col">
                  <div className="input-group-prepend">
                    <div className="input-group-text">
                      <label htmlFor="customerSelect">{Translator("site")}</label>
                    </div>
                  </div>
                  <div className="col">
                    <Select
                      styles={customStyles}
                      filterOption={(toFilter, filter) => {
                        if (
                          toFilter.label
                            .toUpperCase()
                            .normalize("NFD")
                            .replace(/[\u0300-\u036f]/g, "")
                            .startsWith(
                              filter
                                .toUpperCase()
                                .normalize("NFD")
                                .replace(/[\u0300-\u036f]/g, "")
                            )
                        )
                          return toFilter;
                      }}
                      noOptionsMessage={() => {
                        return <label>{Translator("none")}</label>;
                      }}
                      value={
                        props.newTicketFormPayload.site
                          ? props.newTicketFormPayload.site
                          : props.siteOptionTable.find((el) => el.value === "all")
                      }
                      onChange={(selectedOption) => {
                        const selectedSite = props.sites.find((s) => s.id === selectedOption.value);
                        console.log("site=>" + selectedSite.contract);
                        console.log(
                          "customer=>" +
                            props.customers.find((c) => c.id === props.newTicketFormPayload.customer.value).contract
                        );
                        props.setState({
                          newTicketFormPayload: {
                            ...props.newTicketFormPayload,
                            site: selectedOption,
                            contract:
                              props.customers.find((c) => c.id === props.newTicketFormPayload.customer.value)
                                .contract === "conveyor"
                                ? selectedSite.contract
                                : props.customers.find((c) => c.id === props.newTicketFormPayload.customer.value)
                                    .contract,
                          },
                        });
                      }}
                      options={props.siteOptionTable}
                    ></Select>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-8">
              {renderContactOrUserList()}
              {renderContractOrAuthor()}
            </div>
          </div>
          <hr></hr>
          {selectedCustomerTickets.length > 0 ? (
            <div>
              <h5>
                <span style={{ fontSize: "2em", color: "orange" }}>
                  <i className="fas fa-exclamation-triangle danger"></i>
                </span>
                &nbsp;
                {Translator("existing open ticket for this customer")}
              </h5>
              {selectedCustomerTickets.map((x) => (
                <button
                  className="btn btn-default px-5 mx-1"
                  key={x.id}
                  rel="tooltip"
                  data-html="true"
                  data-placement="bottom"
                  title={x.description}
                  data-dismiss="modal"
                  onClick={(e) => {
                    e.preventDefault();
                    props.openDetails(x);
                  }}
                >
                  {x.number}
                </button>
              ))}
              <hr></hr>
            </div>
          ) : null}
          <div className="row">
            {renderCodeChoice()}
            <div className="col-md-9">
              <RichText
                title={Translator("description")}
                inModal
                defaultValue=" "
                onChange={props.updateTicketDescription}
                developped
              ></RichText>
            </div>
          </div>
        </div>
      </div>
      <div className="ribbon-wrapper ribbon-lg">
        <div className="ribbon bg-success">{Translator("new ticket")}</div>
      </div>
    </div>
  );
};

export default TicketCreationForm;
