import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import Card from "../../components/Card";
import { Translator, store } from "../../tools/Tools";
import { v4 as uuidv4 } from 'uuid';
import Select from "react-select";
import ProjectsOverview from "./ProjectsOverview";
import { fetchPendingProjects, fetchProject, deleteProject, patchProject } from "../../tools/Api_Customers";
import ProjectForm from "./ProjectForm";


class Projects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentOffset: 6,
      currentlySelectedProject: {},
      timeLineItems: [],
      projectsOptionTable: [],
      isFetching: true,
    };
  }
  componentDidMount() {
    this.reload();
  }
  reload(selected) {
    fetchPendingProjects().then(
      (fetchedProjects) => {
        const customersProjectList = fetchedProjects.map((project) => {
          return {
            value: project.id,
            label: project.Customer.name + (project.Site ? " - " + project.Site.name : ""),
          };
        });
        this.setState({ projects: fetchedProjects, projectsOptionTable: customersProjectList, isFetching: false });
        if (this.state.currentlySelectedProject.id) {
          this.fetchProject(this.state.currentlySelectedProject.id);
        }
        if (selected) {
          this.fetchProject(selected.id);
        }
      },
      (reject) => {
        console.log(reject);
      }
    );
  }

  deleteProject(projectId) {
    deleteProject(projectId).then((res) => {
      this.setState({ currentlySelectedProject: {} });
      this.reload();
    });
  }
  fetchProject(projectId) {
    fetchProject(projectId).then((project) => {
      let timeLineItems = [];
      project.ProjectPeriods.sort((a, b) => {
        if (moment(a.from).isBefore(moment(b.from), "day")) {
          return -1;
        } else if (moment(a.from).isSame(moment(b.from), "day")) {
          return 0;
        } else {
          return 1;
        }
      });
      project.ProjectPeriods.map((pp) => {
        const switchColor = (type) => {
          let color = "primary";
          switch (type) {
            case "preparation":
              break;
            case "delivery":
              color = "navy";
              break;
            case "assembly":
              color = "warning";
              break;
            case "tests":
              color = "fuchsia";
              break;
            case "tests & startup":
              color = "danger";
              break;
            case "startup":
              color = "danger";
              break;
            case "training":
              color = "success";
              break;
            case "upgrade software":
              color = "purple";
              break;
            case "upgrade hardware":
              color = "orange";
              break;
            case "maintenance":
              color = "info";
              break;
            case "waiting":
              color = "teal";
              break;
          }
          return color;
        };
        timeLineItems.push(
          <div key={uuidv4()} className="time-label">
            <span className={`bg-${switchColor(pp.type)}`}>{moment(pp.from).format("DD/MM/YYYY")}</span>
          </div>
        );
        const switchIcon = (type) => {
          let icon = "fas fa-database bg-primary";
          switch (type) {
            case "preparation":
              break;
            case "delivery":
              icon = "fas fa-truck-moving";
              break;
            case "assembly":
              icon = "fas fa-tools bg-warning";
              break;
            case "tests & startup":
              icon = "fas fa-power-off bg-danger";
              break;
            case "tests":
              icon = "fas fa-power-off bg-fuchsia";
              break;
            case "startup":
              icon = "fas fa-flag-checkered bg-danger";
              break;
            case "training":
              icon = "fas fa-graduation-cap bg-success";
              break;
            case "upgrade software":
              icon = "fas fa-upload bg-purple";
              break;
            case "upgrade hardware":
              icon = "fas fa-rocket bg-orange";
              break;
            case "maintenance":
              icon = "fas fa-oil-can bg-info";
              break;
            case "waiting":
              icon = "fas fa-question bg-teal";
              break;
          }
          return icon;
        };
        timeLineItems.push(
          <div key={pp.id}>
            <i className={switchIcon(pp.type)}></i>
            <div className="timeline-item">
              <Card
                title={Translator(pp.type)}
                type={"light"}
                tools={
                  <small>
                    <b>{pp.workersCount}</b>
                    <i className="fas fa-users ml-2"></i>
                  </small>
                }
              >
                <div className="row" style={{ textAlign: "center" }}>
                  <div className="col">{moment(pp.from).format("DD/MM/YYYY")}</div>
                  <div className="col">
                    <i className="fas fa-arrow-right"></i>
                  </div>
                  <div className="col">{moment(pp.to).format("DD/MM/YYYY")}</div>
                </div>
                <div className="row" style={{ textAlign: "center" }}>
                  <div className="col">{pp.note}</div>
                </div>
              </Card>
            </div>
          </div>
        );
        timeLineItems.push(
          <div key={uuidv4()} className="time-label">
            <span className={`bg-${switchColor(pp.type)}`}>{moment(pp.to).format("DD/MM/YYYY")}</span>
          </div>
        );
      });
      this.setState({ currentlySelectedProject: project, timeLineItems: timeLineItems });
    });
  }
  patchProject(project) {
    //console.log(project)
    patchProject(project).then((resp) => {
      //console.log(resp)
      this.reload(resp);
    });
  }
  translateProjectPeriod(projectPeriodId, offset) {
    let selectProject = null
    const copyProjects = JSON.parse(JSON.stringify(this.state.projects))
    let type = ""
    const editProjects = copyProjects.map(project => {
      return {
        ...project, ProjectPeriods: project.ProjectPeriods.map(pp => {
          if (pp.id === projectPeriodId) {
            selectProject = project.id
            const beforeAdd = moment(pp.from).format("DD/MM/YYYY")
            pp.from = moment(pp.from).add(offset, 'days')
            pp.to = moment(pp.to).add(offset, 'days')
            //console.log(beforeAdd + " +" + offset + " = " + moment(pp.from).format("DD/MM/YYYY"))
            type = pp.type
          }
          pp.from = moment(pp.from).format("DD/MM/YYYY")
          pp.to = moment(pp.to).format("DD/MM/YYYY")
          return pp
        })
      }
    })
    this.patchProject(editProjects.find(p => p.id === selectProject))
  }

  renderDeleteConfirmation() {
    return (
      <div
        className="modal fade"
        id="confirmModal"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{Translator("delete project")}</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p>{Translator("are you sure you want to delete this project ?")}</p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                data-dismiss="modal"
                onClick={(e) => {
                  e.preventDefault();
                  this.deleteProject(this.state.currentlySelectedProject.id);
                }}
              >
                {Translator("confirm deletion")}
              </button>
              <button type="button" className="btn btn-secondary" data-dismiss="modal">
                {Translator("cancel")}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
  render() {
    moment.locale(store().getLanguage());
    return (
      <div>
        <section className="content-header">
          <div className="container-fluid">
            <h1>{Translator("Projects")}</h1>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            {this.renderDeleteConfirmation()}
            <ProjectForm
              modal
              reload={this.reload.bind(this)}
              project={this.state.currentlySelectedProject}
              title={Translator("edit project")}
              tools={(() => (
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              ))()}
            ></ProjectForm>
            <div className="row">
              <div className="col-lg">
                <ProjectForm reload={this.reload.bind(this)}></ProjectForm>
              </div>
              <div className="col-lg">
                <Card title={Translator("project details")}>
                  <label htmlFor="projectSelection">{Translator("select a project")}</label>
                  <Select
                    id="projectSelection"
                    options={this.state.projectsOptionTable}
                    value={this.state.currentlySelectedProject.Customer ? {
                      label: this.state.currentlySelectedProject.Customer.name + " "
                        + (this.state.currentlySelectedProject.Site ? this.state.currentlySelectedProject.Site.name : ""), value: this.state.currentlySelectedProject.id
                    } : null}
                    onChange={(selectedOption) => {
                      this.fetchProject(selectedOption.value);
                    }}
                  ></Select>
                  {this.state.currentlySelectedProject.id ? (
                    <div>
                      <div className="row">
                        <h3 className="my-4 col">
                          {this.state.currentlySelectedProject.Customer.name}&nbsp;
                          {this.state.currentlySelectedProject.Site
                            ? this.state.currentlySelectedProject.Site.name
                            : null}
                        </h3>
                        <span className="col">
                          <div className="btn-group float-right m-3">
                            <button
                              className="btn btn-default "
                              data-toggle="modal"
                              data-target="#exampleModal"
                              onClick={(e) => {
                                e.preventDefault();
                              }}
                            >
                              {Translator("Edit")}
                            </button>
                            <button
                              className="btn btn-default "
                              data-toggle="modal"
                              data-target="#confirmModal"
                              onClick={(e) => {
                                e.preventDefault();
                              }}
                            >
                              {Translator("Delete")}
                            </button>
                          </div>
                        </span>
                      </div>
                      <div className="timeline">{this.state.timeLineItems.map((item) => item)}</div>
                    </div>
                  ) : null}
                </Card>
              </div>
            </div>
            <div className="row">
              <div className="col">
                {this.state.isFetching ? (
                  <Card isFetching></Card>
                ) : this.state.projects ? (
                  <ProjectsOverview
                    projects={this.state.projects}
                    selectedProject={this.state.currentlySelectedProject}
                    translateProjectPeriod={this.translateProjectPeriod.bind(this)}
                    extendedOverview
                  ></ProjectsOverview>
                ) : null}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
export default withRouter(Projects);
