import React, { useState } from "react";
import { useDrop } from "react-dnd";

export const TicketListFlow = (props) => {
  const [state, setstate] = useState({ collapsed: props.collapsed ? props.collapsed : false });

  const style = {
    height: "12rem",
    width: "12rem",
    marginRight: "1.5rem",
    marginBottom: "1.5rem",
    color: "white",
    padding: "1rem",
    textAlign: "center",
    fontSize: "1rem",
    lineHeight: "normal",
    float: "left",
  };
  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: "assignedTicketWidget",
    drop: () => ({ status: props.status.value }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));

  const isActive = canDrop && isOver;
  let dynamicStyle = { borderStyle: "solid", borderColor: "white", borderWidth: "3px", backgroundColor: "white" };
  if (isActive) {
    dynamicStyle.backgroundColor = "green";
  } else if (canDrop) {
    dynamicStyle.borderColor = "black";
  }
  return (
    <div
      key={props.status.value}
      ref={drop}
      className={
        props.pendingTicketTemporaryStatuses.filter((x) => x.checked).length == 2 ? "col card card-default mx-2" : "col"
      }
      style={dynamicStyle}
    >
      <div
        style={{
          borderLeft: "6px dotted #DDDDDD",
          height: "80%",
          position: "absolute",
          marginLeft: "-3px",
          left: "50%",
          marginTop: "55px",
          top: "0",
        }}
      ></div>
      <h2 className="flipInX animated" style={{ textAlign: "center" }}>
        {props.status.desc}&nbsp;
        <span
          onClick={(e) => {
            e.preventDefault();
            if (state.collapsed === true) {
              setstate({ collapsed: false });
            } else {
              setstate({ collapsed: true });
            }
          }}
        >
          {state.collapsed ? <i className="fas fa-compress-alt"></i> : <i className="fas fa-expand-alt"></i>}
        </span>
      </h2>
      {props.renderTickets(props.status.value, true, props.filterText, state.collapsed)}
    </div>
  );
};
