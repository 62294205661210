import React, { useEffect, useState } from "react";
import { filterOptions, Translator } from "../../tools/Tools";
import Select from "react-select";
import MultiCreateSelect from "../../components/MultiCreateSelect";
import Card from "../../components/Card";


const TaskCreationForm = (props) => {
    const [sortingOrder, setSortingOrder] = useState(10)
    const [newTaskPayload, setNewTaskPayload] = useState({ hasCustomAnswers: false, ChecklistTypes: [] })
    const [ChecklistTaskAnswers, setChecklistTaskAnswers] = useState([])
    const [selectedModule, setSelectedModule] = useState({})
    //const [loadedFromPayload, setLoadedFromPayload] = useState(false)

    const customStyles = {
        container: provided => ({
            ...provided, width: 150
        }),
        customStyles: {
            menuPortal: base => {
                const { zIndex, ...rest } = base;
                return { ...rest, zIndex: 9999 };
            },
        },
    };

    useEffect(() => {
        if (props.editingMode && props.selectedTask && JSON.stringify(props.selectedTask) !== JSON.stringify(newTaskPayload) && !$('#modal-task').hasClass('show')/* && !loadedFromPayload*/) {
            console.log("use effect for props payload")
            console.log(props.selectedTask)
            setNewTaskPayload({ ...props.selectedTask, ChecklistTypes: props.selectedTask.ChecklistTypes })
            if (props.selectedTask.hasCustomAnswers && !ChecklistTaskAnswers.length) setChecklistTaskAnswers(props.selectedTask.ChecklistTaskAnswers.map(answer => { return { value: answer.id, label: answer.name } }))
            if (props.selectedTask.sortingOrder) {
                console.log("setting sortingOrder " + parseInt(props.selectedTask.sortingOrder))
                setSortingOrder(parseInt(props.selectedTask.sortingOrder))
            }
            //setLoadedFromPayload(true)
        }
    })

    const exposeMultiSelectValues = (values) => {
        let tmpNewTaskPayload = newTaskPayload
        let cpt = 0;
        console.log(values)
        tmpNewTaskPayload.ChecklistTaskAnswers = values.map(value => { return { id: value.value, name: value.label, sortingOrder: cpt += 10 } })
        console.log(tmpNewTaskPayload.ChecklistTaskAnswers, ChecklistTaskAnswers)
        setChecklistTaskAnswers(values)

        setNewTaskPayload(tmpNewTaskPayload)
    }

    const resetForm = () => {
        console.log("RESETTING TASK FORM")
        setNewTaskPayload({ hasCustomAnswers: false, ChecklistTypes: [] })
        setSortingOrder(10)
        setChecklistTaskAnswers([])
        setSelectedModule({})
        //setLoadedFromPayload(false)
        props.cancelTaskCallback();
    }
    const renderTaskModalFooterButtons = () => {
        return (
            <form className="form-inline col-sm-12">
                <div className="input-group mb-2">
                    <button
                        className="btn btn-info btn mr-2 "
                        onClick={(e) => {
                            e.preventDefault();
                            let toSendPayload = {}
                            toSendPayload.id = newTaskPayload.id
                            toSendPayload.taskText = newTaskPayload.taskText
                            toSendPayload.taskHowTo = newTaskPayload.taskHowTo ? newTaskPayload.taskHowTo.replace(/\n\r?/g, '<br />') : ""
                            toSendPayload.sortingOrder = newTaskPayload.sortingOrder
                            toSendPayload.ChecklistTypes = newTaskPayload.ChecklistTypes
                            if (newTaskPayload.hasCustomAnswers) {
                                toSendPayload.hasCustomAnswers = ChecklistTaskAnswers.length > 0
                                toSendPayload.ChecklistTaskAnswers = newTaskPayload.ChecklistTaskAnswers
                            } else {
                                toSendPayload.hasCustomAnswers = false
                                toSendPayload.ChecklistTaskAnswers = []

                            }

                            if (newTaskPayload.module) toSendPayload.moduleId = newTaskPayload.module.value
                            props.submitCallback(toSendPayload, props.editingMode).then(success => {
                                if (success) {
                                    console.log("SUBMIT SUCCESS", success)
                                    resetForm();
                                }
                            });
                        }}
                        disabled={!newTaskPayload.taskText || (newTaskPayload.hasCustomAnswers && ChecklistTaskAnswers.length === 0)}
                    >
                        {Translator("enregistrer")}
                    </button>
                    <button
                        className="btn btn-info btn "
                        data-dismiss="modal"
                        onClick={(e) => {
                            e.preventDefault();
                            resetForm();
                        }}
                    >
                        {Translator("annuler")}
                    </button>
                </div>
            </form>
        );
    }


    return (
        <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title" id="staticBackdropLabel">
                    {Translator("new task ")}
                </h5>
                <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={(e) => {
                        e.preventDefault();
                        resetForm();
                    }}
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body" style={{ overflow: "visible" }}>
                <div className={props.creatingMode ? "info-box slideInDown animated" : "info-box"}>
                    <span className="info-box-icon bg-orange">
                        <span className="fa-stack">
                            <i className="fa-solid fa-square-check fa-stack-2x"></i>
                        </span>
                    </span>
                    <div className="table-responsive">
                        <div className="info-box-content">
                            <div className="row my-1">
                                <div className="col-6">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={Translator("task name")}
                                        onChange={(e) => {

                                            e.target.value.trim().length !== 0 ? (setNewTaskPayload({ ...newTaskPayload, taskText: e.target.value })) : (setNewTaskPayload({ ...newTaskPayload, taskText: null }));

                                        }}
                                        value={newTaskPayload.taskText || ""}
                                    ></input>
                                </div>
                            </div>
                            <hr></hr>
                            <div className="row my-1">
                                <div className="col">
                                    <Card title={Translator("list types")}>
                                        {props.checklistTypes.map(type => {
                                            return (
                                                <div className="form-check" key={type.id} onClick={e => {
                                                    e.stopPropagation();
                                                    let newChecklistTypePayload = []
                                                    if (newTaskPayload.ChecklistTypes.find(el => el === type.id)) {
                                                        newChecklistTypePayload = newTaskPayload.ChecklistTypes.filter(el => el !== type.id)
                                                        console.log(newChecklistTypePayload)
                                                    } else {
                                                        newChecklistTypePayload = [...newTaskPayload.ChecklistTypes, type.id]
                                                    }
                                                    const tmpnewTaskPayload = { ...newTaskPayload, ChecklistTypes: newChecklistTypePayload }
                                                    setNewTaskPayload(tmpnewTaskPayload)
                                                }}>
                                                    <input className="form-check-input" type="checkbox" value="" id={type.id} checked={newTaskPayload.ChecklistTypes.find(el => el === type.id) || false} onChange={e => { }}>

                                                    </input>
                                                    <label className="form-check-label" htmlFor={type.id}>
                                                        {Translator(type.name)}
                                                    </label>
                                                </div>
                                            )
                                        })}
                                    </Card>
                                </div>
                            </div>
                            <div className="row my-1">
                                <div className="col">
                                    <textarea
                                        type="text-area"
                                        className="form-control"
                                        placeholder={Translator("how to...")}
                                        onChange={(e) => {
                                            const tmpNewTaskPayload = { ...newTaskPayload, taskHowTo: e.target.value }
                                            setNewTaskPayload(tmpNewTaskPayload)
                                        }}
                                        value={(newTaskPayload.taskHowTo || "").replaceAll("<br />", "\n")}
                                    ></textarea>
                                </div>
                            </div>
                            <hr></hr>
                            <div className="row">
                                <div className="col-3 pt-1 mr-n5">
                                    <label htmlFor="order">{Translator("sorting order")}</label>
                                </div>
                                <div className="col"></div>
                                <div className="col-4 col-lg-3 col-xl-2">
                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <button className="input-group-text" onClick={() => {
                                                let tmpNewTaskPayload = newTaskPayload
                                                tmpNewTaskPayload.sortingOrder = sortingOrder - 10
                                                setSortingOrder(sortingOrder - 10)
                                                setNewTaskPayload(tmpNewTaskPayload)
                                            }}>-</button>
                                        </div>
                                        <input id="order" type="text" className="form-control" value={sortingOrder} onChange={(e) => {
                                            const parsed = parseInt(e.target.value)
                                            if (!isNaN(parsed)) {
                                                let tmpNewTaskPayload = newTaskPayload
                                                tmpNewTaskPayload.sortingOrder = parsed
                                                setSortingOrder(parsed)
                                                setNewTaskPayload(tmpNewTaskPayload)
                                            }
                                        }}></input>
                                        <div className="input-group-append">
                                            <button className="input-group-text" onClick={() => {
                                                let tmpNewTaskPayload = newTaskPayload
                                                tmpNewTaskPayload.sortingOrder = sortingOrder + 10
                                                setSortingOrder(sortingOrder + 10)
                                                setNewTaskPayload(tmpNewTaskPayload)
                                            }}>+</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr></hr>
                            <div className="row">
                                <div className="col-3 pt-1 mr-n5">
                                    <label htmlFor="module">{Translator("add to existing an module")}</label>
                                </div>
                                <div className="col"></div>
                                <div className="col">
                                    <Select
                                        id="module"
                                        isClearable
                                        noOptionsMessage={() => {
                                            return <label>{Translator("no modules found without this task")}</label>;
                                        }}
                                        placeholder={Translator("add to existing module")}
                                        onChange={(selectedOption) => {
                                            setSelectedModule(selectedOption)
                                            let tmpNewTaskPayload = newTaskPayload
                                            tmpNewTaskPayload.module = selectedOption
                                            //If a module is selected, checking the highest sortingOrder already present in it's task, and suggest +10 for this one
                                            const newSortingOrder = props.modules.find(mod => selectedOption.value === mod.id).ChecklistTasks.reduce((a, b) => {
                                                return Math.max(a, b.sortingOrder)
                                            }, 0) + 10
                                            tmpNewTaskPayload.sortingOrder = newSortingOrder
                                            setSortingOrder(newSortingOrder)
                                            setNewTaskPayload(tmpNewTaskPayload)
                                        }}
                                        options={props.modules.filter(module => !module.ChecklistTasks.find(t => t.id === newTaskPayload.id)).sort((a, b) => {
                                        if (a.sortingOrder > b.sortingOrder)
                                            return 1
                                        if (a.sortingOrder < b.sortingOrder)
                                            return -1
                                        return 0
                                    }).map(module => { return { value: module.id, label: module.name } })}
                                        value={selectedModule}
                                        menuPortalTarget={document.body}
                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                        isSearchable
                                        filterOption={filterOptions}
                                    />
                                </div>
                            </div>
                            <hr></hr>
                            <div className="row">
                                <div className="col">{Translator("answer type")}</div>
                                <div className="col"></div>
                                <div className="col-6">
                                    <div className="input-group">
                                        <div className="form-check  my-2" style={{ width: "100%" }}>
                                            <input className="form-check-input" type="radio" name="radio-answer" id="flexCheckDefault" checked={!newTaskPayload.hasCustomAnswers}
                                                onClick={e => {
                                                    setNewTaskPayload({ ...newTaskPayload, hasCustomAnswers: false })
                                                }
                                                }
                                                onChange={e => { }}>

                                            </input>
                                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                                {Translator("free text")}
                                            </label>
                                        </div>
                                        <div className="form-check" style={{ width: "100%" }} onClick={(e) => {
                                            setNewTaskPayload({ ...newTaskPayload, hasCustomAnswers: true })
                                            //document.getElementById("flexCheckChecked").checked = true;
                                        }} >
                                            <input className="form-check-input" type="radio" name="radio-answer" value="" id="flexCheckChecked" checked={newTaskPayload.hasCustomAnswers} onChange={e => { }}>
                                            </input>
                                            <label className="form-check-label mt-n1 mr-1" style={{ width: "100%" }}
                                                onChange={e => e.preventDefault()}>
                                                <MultiCreateSelect
                                                    //key={JSON.stringify(loadedFromPayload)}
                                                    exposeValues={exposeMultiSelectValues}
                                                    values={ChecklistTaskAnswers}
                                                ></MultiCreateSelect>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >
                    <div className="ribbon-wrapper ribbon-xl">
                        <div className="ribbon bg-success">{Translator("new task")}</div>
                    </div>
                </div >
            </div>
            <div className="modal-footer">{renderTaskModalFooterButtons()}</div>
        </div >
    );
};

export default TaskCreationForm;
