import React, { Component } from "react";
import TicketButton from "../../components/TicketButton";
import { fetchOpenTickets, pauseTicket, handleTicket } from "../../tools/Api_Customers";
import { Translator, store, NotifyReloadTicket, ShouldReloadTicket } from "../../tools/Tools";
import { Link } from "react-router-dom";
import moment from "moment";
import { hoursToMilliseconds } from "date-fns";

class TicketPillsList extends Component {
  //fetchTicketsToState();
  constructor(props) {
    super(props);
    this.state = { tickets: [], isDesktop: false, countdown: 20 };
    this.updatePredicate = this.updatePredicate.bind(this);
    this.logged = true;
    this.countdownInterval = setInterval(() => {
      if (this.logged) {
        this.setState({ countdown: this.state.countdown - 1 });
      }
    }, 1000);
  }

  componentDidMount = () => {
    this.updatePredicate();
    window.addEventListener("resize", this.updatePredicate);
    this.fetchTicketsToState();
    this.longInterval = setInterval(() => {
      if (this.logged) {
        this.fetchTicketsToState();
      }
    }, 20000);
    this.shortInteval = setInterval(() => {
      if (this.logged) {
        if (ShouldReloadTicket("ticketPillsList")) {
          this.fetchTicketsToState();
        }
      }
    }, 1000);
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.updatePredicate);
    clearInterval(this.shorInterval);
    clearInterval(this.longInterval);
    this.logged = false;
  }

  updatePredicate() {
    this.setState({ isDesktop: window.innerWidth > 1450 });
  }

  fetchTicketsToState() {
    fetchOpenTickets().then((result) => {
      if (result) {
        const tickets = result
          .map((ticket) => {
            let ticketWorkTime = 0;
            let running = false;
            //console.log("running ?" + ticket.TicketPeriods.length);
            ticket.TicketPeriods.forEach((tp) => {
              ticketWorkTime += tp.workTime;
              //console.log(tp.endDateTime);
              if (!tp.endDateTime) {
                running = true;
                ticketWorkTime += moment.duration(moment().diff(tp.startDateTime)).asMinutes();
              }
            });
            return {
              id: ticket.id,
              number: ticket.ticketNumber,
              customer: ticket.Customer.name,
              description: ticket.description,
              site: ticket.Site ? ticket.Site.name : Translator("no specific site"),
              assignee: ticket.Assignee ? ticket.Assignee.shortName : null,
              open: true,
              status: ticket.status,
              priority: ticket.priority,
              contact: ticket.contact,
              contract: ticket.contract,
              creationDate: ticket.createdAt,
              invoiced: ticket.invoiced,
              closed: ticket.closed,
              ticketPeriods: ticket.TicketPeriods,
              workTime: ticketWorkTime,
              running: running,
            };
          })
          .sort((a, b) => {
            if (a.running === b.running) return 0;
            if (a.running && !b.running) return -1;
            if (b.running && !a.running) return 1;
          });
        //console.log("setting state tickets");
        if (this.logged)
          this.setState({ tickets: tickets.filter((tick) => tick.status === "active" || tick.running), countdown: 20 });
      }
    });
  }
  toggleTicket(ticket) {
    if (ticket.running) {
      console.log("pause ticket");
      pauseTicket(ticket.id, store().getUser().userId).then(() => {
        console.log("will fetch ticket");
        NotifyReloadTicket("ticketPillsList");
        this.fetchTicketsToState();
      });
    } else {
      console.log("resume ticket");
      handleTicket(ticket.id, store().getUser().userId).then(() => {
        console.log("will fetch ticket");
        NotifyReloadTicket("ticketPillsList");
        this.fetchTicketsToState();
      });
    }
  }

  render() {
    let count = 0;
    //console.log(window.innerWidth);
    const runningTickets = this.state.tickets.filter((x) => x.running).length;
    return (
      <div className="align-items-center">
        <ul className="nav nav-pills my-n2 align-items-center">
          <span className="fa-stack">
            <i className="nav-icon fa-solid fa-ticket fa-stack-2x"></i>
            <span className="fa-layers-text fa-stack-1x fa-inverse">
              <small>
                {this.state.countdown > 1 ? (
                  this.state.countdown
                ) : (
                  <div className="spinner-grow fast spinner-grow-sm" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                )}
              </small>
            </span>
          </span>
          {this.state.tickets.map((ticket) => {
            if (this.state.displayAll || window.innerWidth / 100 > 12 + runningTickets * 0.05 + count) {
              if (window.innerWidth / 100 > 12 + runningTickets * 0.05 + count) count++;
              return (
                <li key={ticket.id}>
                  <TicketButton ticket={ticket} clickFunction={this.toggleTicket.bind(this)}></TicketButton>
                </li>
              );
            }
          })}
          <li>
            {this.state.tickets.length > count ? (
              this.state.displayAll ? (
                <Link
                  to=""
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({ displayAll: false });
                  }}
                >
                  {"..." + (this.state.tickets.length - count) + " less"}
                </Link>
              ) : (
                <Link
                  to=""
                  onClick={(e) => {
                    this.setState({ displayAll: true });
                    e.preventDefault();
                  }}
                >
                  {"..." + (this.state.tickets.length - count) + " more"}
                </Link>
              )
            ) : (
              ""
            )}
          </li>
        </ul>
      </div>
    );
  }
}

export default TicketPillsList;
