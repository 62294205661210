import toastr from "toastr";
import { store, Translator } from "./Tools";


//#region checklist basic elements
export const fetchMachineTypes = () => {
    return fetch("/api/machine/type/getAll").then((response) => {
        if (response.ok) {
            return response.json().then((result) => result);
        } else {
            toastr.options = {
                progressBar: true,
                timeOut: "5000",
            };
            console.log(response);
            response.json().then((body) => {
                console.log(body);
                if (body.errors) {
                    toastr.error(rawResponse.statusText);
                } else {
                    toastr.error(rawResponse.status + " " + rawResponse.statusText + (body.name ? ("\r\n " + body.name) : ("")));
                }
            });
        }
    });
};

export const postMachineType = (machineType) => {
    return fetch("/api/machine/type/new", {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(machineType),
    }).then((rawResponse) => {
        if (rawResponse.ok) {
            return rawResponse.json();
        } else {
            toastr.options = {
                progressBar: true,
                timeOut: "5000",
            };
            console.log(rawResponse);
            rawResponse.json().then((body) => {
                if (body.errors) {
                    toastr.error(rawResponse.statusText + " " + body.errors[0].type);
                } else {
                    toastr.error(rawResponse.status + " " + rawResponse.statusText + (body.name ? ("\r\n " + body.name) : ("")));
                }
            });
            return rawResponse.ok
        }
    });
};

export const patchMachineType = (machineType) => {
    return fetch("/api/machine/type/" + machineType.id + "/update", {
        method: "PATCH",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(machineType),
    }).then((rawResponse) => {
        if (rawResponse.ok) {
            rawResponse.json().then((body) => {
                toastr.success(body.name + " successfuly updated", "Success");
            })
            return rawResponse.ok;
        } else {
            toastr.options = {
                progressBar: true,
                timeOut: "5000",
            };
            console.log(rawResponse);
            rawResponse.json().then((body) => {
                console.log(body);
                if (body.errors) {
                    toastr.error(rawResponse.statusText + " " + body.errors[0].type);
                } else {
                    toastr.error(rawResponse.status + " " + rawResponse.statusText + (body.name ? ("\r\n " + body.name) : ("")));
                }
            });
            return rawResponse.ok
        }
    });
};

export const deleteMachineType = (machineType) => {
    return fetch("/api/machine/type/" + machineType + "/delete", {
        method: "DELETE",
        headers: {
            Accept: "application/json",
        },
    }).then((rawResponse) => {
        toastr.options = {
            progressBar: true,
            timeOut: "5000",
        };
        if (rawResponse.ok) {
            rawResponse.json().then((body) => {
                toastr.success(body.name + " successfuly deleted", "Success");
            })
            return rawResponse.ok;
        } else {
            rawResponse.json().then((body) => {
                if (body.errors[0].type === "Existing references") {
                    toastr.options.positionClass = "toast-top-full-width"
                }
                toastr.error(body.errors[0].type + "  " + body.errors[0].message, rawResponse.statusText);
            });
            return rawResponse.ok
        }
    });
};

export const fetchChecklistTypes = () => {
    return fetch("/api/checklist/type/getAll").then((response) => {
        if (response.ok) {
            return response.json().then((result) => result);
        } else {
            toastr.options = {
                progressBar: true,
                timeOut: "5000",
            };
            console.log(response);
            response.json().then((body) => {
                console.log(body);
                if (body.errors) {
                    toastr.error(rawResponse.statusText);
                } else {
                    toastr.error(rawResponse.status + " " + rawResponse.statusText + (body.name ? ("\r\n " + body.name) : ("")));
                }
            });
        }
    });
};

export const postChecklistType = (checklistType) => {
    return fetch("/api/checklist/type/new", {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(checklistType),
    }).then((rawResponse) => {
        if (rawResponse.ok) {
            return rawResponse.json();
        } else {
            toastr.options = {
                progressBar: true,
                timeOut: "5000",
            };
            console.log(rawResponse);
            rawResponse.json().then((body) => {
                console.log(body);
                if (body.errors) {
                    toastr.error(rawResponse.statusText + " " + body.errors[0].type);
                } else {
                    toastr.error(rawResponse.status + " " + rawResponse.statusText + (body.name ? ("\r\n " + body.name) : ("")));
                }
            });
            return rawResponse.ok
        }
    });
};

export const patchChecklistType = (checklistType) => {
    return fetch("/api/checklist/type/" + checklistType.id + "/update", {
        method: "PATCH",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(checklistType),
    }).then((rawResponse) => {
        if (rawResponse.ok) {
            rawResponse.json().then((body) => {
                toastr.success(body.name + " successfuly updated", "Success");
            })
            return rawResponse.ok;
        } else {
            toastr.options = {
                progressBar: true,
                timeOut: "5000",
            };
            console.log(rawResponse);
            rawResponse.json().then((body) => {
                console.log(body);
                if (body.errors) {
                    toastr.error(rawResponse.statusText + " " + body.errors[0].type);
                } else {
                    toastr.error(rawResponse.status + " " + rawResponse.statusText + (body.name ? ("\r\n " + body.name) : ("")));
                }
            });
            return rawResponse.ok
        }
    });
};


export const deleteChecklistType = (checklistType) => {
    return fetch("/api/checklist/type/" + checklistType + "/delete", {
        method: "DELETE",
        headers: {
            Accept: "application/json",
        },
    }).then((rawResponse) => {
        if (rawResponse.ok) {
            rawResponse.json().then((body) => {
                toastr.success(body.name + " successfuly deleted", "Success");
            })
            return rawResponse.ok;
        } else {
            toastr.options = {
                progressBar: true,
                timeOut: "5000",
            };
            console.log(rawResponse);
            rawResponse.json().then((body) => {
                console.log(body);
                if (body.errors[0].type === "Existing references") {
                    toastr.options.positionClass = "toast-top-full-width"
                }
                toastr.error(body.errors[0].type + "  " + body.errors[0].message, rawResponse.statusText);
            });
            return rawResponse.ok
        }
    });
};


export const fetchChecklistTasks = () => {
    return fetch("/api/checklist/task/getAll").then((response) => {
        if (response.ok) {
            return response.json().then((result) => result);
        } else {
            toastr.options = {
                progressBar: true,
                timeOut: "5000",
            };
            console.log(response);
            response.json().then((body) => {
                console.log(body);
                if (body.errors) {
                    toastr.error(rawResponse.statusText);
                } else {
                    toastr.error(rawResponse.status + " " + rawResponse.statusText + (body.name ? ("\r\n " + body.name) : ("")));
                }
            });
        }
    });
};

export const postChecklistTask = (checklistTask) => {
    return fetch("/api/checklist/task/new", {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(checklistTask),
    }).then((rawResponse) => {
        if (rawResponse.ok) {
            return rawResponse.json();
        } else {
            toastr.options = {
                progressBar: true,
                timeOut: "5000",
            };
            console.log(rawResponse);
            rawResponse.json().then((body) => {
                console.log(body);
                if (body.errors) {
                    toastr.error(rawResponse.statusText);
                } else {
                    toastr.error(rawResponse.status + " " + rawResponse.statusText + (body.name ? ("\r\n " + body.name) : ("")));
                }
            });
            return rawResponse.ok

        }
    });
};

export const patchChecklistTask = (checklistTask) => {
    return fetch("/api/checklist/task/" + checklistTask.id + "/update", {
        method: "PATCH",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(checklistTask),
    }).then((rawResponse) => {
        if (rawResponse.ok) {
            rawResponse.json().then((body) => {
                toastr.success(body.name + " successfuly updated", "Success");
            })
            return rawResponse.ok;
        } else {
            toastr.options = {
                progressBar: true,
                timeOut: "5000",
            };
            console.log(rawResponse);
            rawResponse.json().then((body) => {
                console.log(body);
                if (body.errors) {
                    toastr.error(rawResponse.statusText);
                } else {
                    toastr.error(rawResponse.status + " " + rawResponse.statusText + (body.name ? ("\r\n " + body.name) : ("")));
                }
            });
            return rawResponse.ok
        }
    });
};

export const deleteChecklistTask = (checklistTask) => {
    return fetch("/api/checklist/task/" + checklistTask + "/delete", {
        method: "DELETE",
        headers: {
            Accept: "application/json",
        },
    }).then((rawResponse) => {
        if (rawResponse.ok) {
            rawResponse.json().then((body) => {
                toastr.success(body.name + " successfuly deleted", "Success");
            })
            return rawResponse.ok;
        } else {
            toastr.options = {
                progressBar: true,
                timeOut: "5000",
            };
            console.log(rawResponse);
            rawResponse.json().then((body) => {
                console.log(body);
                if (body.errors[0].type === "Existing references") {
                    toastr.options.positionClass = "toast-top-full-width"
                }
                toastr.error(body.errors[0].type + "  " + body.errors[0].message, rawResponse.statusText);
            });
            return rawResponse.ok
        }
    });
};

export const fetchChecklistModules = () => {
    return fetch("/api/checklist/module/getAll").then((response) => {
        if (response.ok) {
            return response.json().then((result) => result);
        } else {
            toastr.options = {
                progressBar: true,
                timeOut: "5000",
            };
            console.log(response);
            response.json().then((body) => {
                console.log(body);
                if (body.errors) {
                    toastr.error(rawResponse.statusText);
                } else {
                    toastr.error(rawResponse.status + " " + rawResponse.statusText + (body.name ? ("\r\n " + body.name) : ("")));
                }
            });
        }
    });
};

export const postChecklistModule = (checklistModule) => {
    return fetch("/api/checklist/module/new", {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(checklistModule),
    }).then((rawResponse) => {
        if (rawResponse.ok) {
            return rawResponse.json();
        } else {
            toastr.options = {
                progressBar: true,
                timeOut: "5000",
            };
            console.log(rawResponse);
            rawResponse.json().then((body) => {
                console.log(body);
                if (body.errors) {
                    toastr.error(rawResponse.statusText);
                } else {
                    toastr.error(rawResponse.status + " " + rawResponse.statusText + (body.name ? ("\r\n " + body.name) : ("")));
                }
            });
            return rawResponse.ok

        }
    });
};

export const patchChecklistModule = (checklistModule) => {
    return fetch("/api/checklist/module/" + checklistModule.id + "/update", {
        method: "PATCH",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(checklistModule),
    }).then((rawResponse) => {
        if (rawResponse.ok) {
            rawResponse.json().then((body) => {
                toastr.success(body.name + " successfuly updated", "Success");
            })
            return rawResponse.ok;
        } else {
            toastr.options = {
                progressBar: true,
                timeOut: "5000",
            };
            console.log(rawResponse);
            rawResponse.json().then((body) => {
                console.log(body);
                if (body.errors) {
                    toastr.error(rawResponse.statusText);
                } else {
                    toastr.error(rawResponse.status + " " + rawResponse.statusText + (body.name ? ("\r\n " + body.name) : ("")));
                }
            });
            return rawResponse.ok

        }
    });
};


export const deleteChecklistModule = (checklistModule) => {
    return fetch("/api/checklist/module/" + checklistModule + "/delete", {
        method: "DELETE",
        headers: {
            Accept: "application/json",
        },
    }).then((rawResponse) => {
        toastr.options = {
            progressBar: true,
            timeOut: "5000",
        };
        if (rawResponse.ok) {
            rawResponse.json().then((body) => {
                toastr.success(body.name + " successfuly deleted", "Success");
            })
            return rawResponse.ok;
        } else {
            rawResponse.json().then((body) => {
                if (body.errors[0].type === "Existing references") {
                    toastr.options.positionClass = "toast-top-full-width"
                }
                toastr.error(body.errors[0].type + "  " + body.errors[0].message, rawResponse.statusText);
            });
            return rawResponse.ok
        }
    });
};
//#endregion

//#region checklist customer elements
export const fetchCustomerChecklistModels = (CustomerId) => {
    return fetch("/api/checklist/model/" + CustomerId + "/getAll").then((response) => {
        if (response.ok) {
            return response.json().then((result) => result);
        } else {
            toastr.options = {
                progressBar: true,
                timeOut: "5000",
            };
            console.log(response);
            response.json().then((body) => {
                console.log(body);
                if (body.errors) {
                    toastr.error(response.statusText);
                } else {
                    toastr.error(response.status + " " + response.statusText + (body.name ? ("\r\n " + body.name) : ("")));
                }
            });
        }
    });
};

export const postCustomerChecklistModel = (customerChecklistModel, CustomerId) => {
    return fetch("/api/checklist/model/" + CustomerId + "/new", {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(customerChecklistModel),
    }).then((rawResponse) => {
        if (rawResponse.ok) {
            return rawResponse.json();
        } else {
            toastr.options = {
                progressBar: true,
                timeOut: "5000",
            };
            console.log(rawResponse);

            rawResponse.json().then((body) => {
                console.log(body);
                if (body.errors) {
                    toastr.error(rawResponse.statusText);
                } else {
                    toastr.error(rawResponse.status + " " + rawResponse.statusText + (body.name ? ("\r\n " + body.name) : ("")));
                }
            });
            return rawResponse.ok

        }
    });
};

export const patchCustomerChecklistModel = (customerChecklistModel) => {
    return fetch("/api/checklist/model/" + customerChecklistModel.id + "/update", {
        method: "PATCH",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(customerChecklistModel),
    }).then((rawResponse) => {
        if (rawResponse.ok) {
            return rawResponse.json();
        } else {
            toastr.options = {
                progressBar: true,
                timeOut: "5000",
            };
            console.log(rawResponse);

            rawResponse.json().then((body) => {
                console.log(body);
                if (body.errors) {
                    toastr.error(rawResponse.statusText);
                } else {
                    toastr.error(rawResponse.status + " " + rawResponse.statusText + (body.name ? ("\r\n " + body.name) : ("")));
                }
            });
            return rawResponse.ok

        }
    });
};

export const deleteCustomerChecklistModel = (customerChecklistModel) => {
    return fetch("/api/checklist/model/" + customerChecklistModel + "/delete", {
        method: "DELETE",
        headers: {
            Accept: "application/json",
        },
    }).then((rawResponse) => {
        toastr.options = {
            progressBar: true,
            timeOut: "5000",
        };
        if (rawResponse.ok) {
            rawResponse.json().then((body) => {
                toastr.success(body.name + " successfuly deleted", "Success");
            })
            return rawResponse.ok;
        } else {
            rawResponse.json().then((body) => {
                if (body.errors[0].type === "Existing references") {
                    toastr.options.positionClass = "toast-top-full-width"
                }
                toastr.error(body.errors[0].type + "  " + body.errors[0].message, rawResponse.statusText);
            });
            return rawResponse.ok
        }
    });
};

export const fetchCustomerChecklistInstances = (CustomerId) => {
    return fetch("/api/checklist/instance/" + CustomerId + "/getAll").then((response) => {
        if (response.ok) {
            return response.json().then((result) => {
                return result
            });
        } else {
            toastr.options = {
                progressBar: true,
                timeOut: "5000",
            };
            response.json().then((body) => {
                console.log(body);
                if (body.errors) {
                    toastr.error(response.statusText);
                } else {
                    toastr.error(response.status + " " + response.statusText + (body.name ? ("\r\n " + body.name) : ("")));
                }
            });
        }
    });
};

export const fetchCustomerChecklistInstance = (checklistInstanceId) => {
    return fetch("/api/checklist/instance/" + checklistInstanceId + "/get").then((response) => {
        if (response.ok) {
            return response.json().then((result) => {
                return result
            });
        } else {
            toastr.options = {
                progressBar: true,
                timeOut: "5000",
            };
            response.json().then((body) => {
                console.log(body);
                if (body.errors) {
                    toastr.error(rawResponse.statusText);
                } else {
                    toastr.error(rawResponse.status + " " + rawResponse.statusText + (body.name ? ("\r\n " + body.name) : ("")));
                }
            });
        }
    });
};

export const postCustomerChecklistInstance = (body, modelId) => {
    return fetch("/api/checklist/instance/" + modelId + "/new", {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
    }).then((rawResponse) => {
        if (rawResponse.ok) {
            return rawResponse.json();
        } else {
            toastr.options = {
                progressBar: true,
                timeOut: "5000",
            };
            console.log(rawResponse);

            rawResponse.json().then((body) => {
                console.log(body);
                if (body.errors) {
                    toastr.error(rawResponse.statusText);
                } else {
                    toastr.error(rawResponse.status + " " + rawResponse.statusText + (body.name ? ("\r\n " + body.name) : ("")));
                }
            });
            return rawResponse.ok

        }
    });
};

export const editCustomerChecklistInstance = (updatorId, instanceToSubmit) => {
    instanceToSubmit.UpdatorId = updatorId

    return fetch("/api/checklist/instance/" + instanceToSubmit.id + "/update", {
        method: "PATCH",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(instanceToSubmit),
    }).then((rawResponse) => {
        if (rawResponse.ok) {
            rawResponse.json().then((body) => {
                toastr.success(body.name + " successfuly updated", "Success");
            })
            return rawResponse.ok;
        } else {
            toastr.options = {
                progressBar: true,
                timeOut: "5000",
            };
            console.log(rawResponse);

            rawResponse.json().then((body) => {
                console.log(body);
                if (body.errors) {
                    if (body.errors) {
                        toastr.error(rawResponse.statusText);
                    } else {
                        toastr.error(rawResponse.status + " " + rawResponse.statusText + (body.name ? ("\r\n " + body.name) : ("")));
                    }
                } else {
                    toastr.error(rawResponse.status + " " + rawResponse.statusText + (body.name ? ("\r\n " + body.name) : ("")));
                }
            });
            return rawResponse.ok

        }
    });
};

export const deleteChecklistInstance = (checklistInstance) => {
    return fetch("/api/checklist/instance/" + checklistInstance + "/delete", {
        method: "DELETE",
        headers: {
            Accept: "application/json",
        },
    }).then((rawResponse) => {
        toastr.options = {
            progressBar: true,
            timeOut: "5000",
        };
        if (rawResponse.ok) {
            rawResponse.json().then((body) => {
                toastr.success(body.name + " successfuly deleted", "Success");
            })
            return rawResponse.ok;
        } else {
            rawResponse.json().then((body) => {
                if (body.errors[0].type === "Existing references") {
                    toastr.options.positionClass = "toast-top-full-width"
                }
                toastr.error(body.errors[0].type + "  " + body.errors[0].message, rawResponse.statusText);
            });
            return rawResponse.ok
        }
    });
};
//#endregion