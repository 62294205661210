import React, { Component } from "react";
import Card from "./Card";
import { Translator, MomentDateString } from "../tools/Tools";
import { patchCustomer, patchSite, patchTodo, postCustomerTodo } from "../tools/Api_Customers";
import moment from "moment";
import Select from "react-select";
import { store } from "../tools/Tools";
import RichTextEditor from "./RichText";

class GeneralInfos extends Component {
  constructor(props) {
    super(props);
    this.infoType = props.infoType;
    const ededitedGeneralInformations = props.currentCustomer
      ? { ...props.generalInfos, CustomerId: props.currentCustomer }
      : props.generalInfos;
    this.state = {
      generalInfoEdit: props.infoEdit ? props.infoEdit : false,
      generalInformations: {
        ...props.generalInfos,
      },
      editedGeneralInformations: ededitedGeneralInformations,
    };

    this.parentMustReload = props.parentReload ? props.parentReload : () => {};
  }
  componentDidMount() {
    let dates = {};
    if (this.props.generalInfos.start) dates["start"] = MomentDateString(moment(this.props.generalInfos.start));
    if (this.props.generalInfos.warranty)
      dates["warranty"] = MomentDateString(moment(this.props.generalInfos.warranty));
    this.setState(dates);
  }
  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({
        generalInformations: this.props.generalInfos,
        generalInfoEdit: this.props.infoEdit ? this.props.infoEdit : false,
        editedGeneralInformations: this.props.generalInfos,
        warnTask: false,
        warnDeadline: false,
        warnCustomer: false,
        warnResponsible: false,
      });
    }
  }
  renderEditButton() {
    const renderSaveButton = () => {
      //console.log("rendering save button with ", this.state.generalInfoEdit);
      return this.state.generalInfoEdit ? (
        <button
          className="btn btn-secondary btn"
          onClick={(e) => {
            //console.log(e);
            e.preventDefault();
            //console.log("has preventDefault");
            switch (this.infoType) {
              case "customer":
                patchCustomer(this.state.editedGeneralInformations).then(() => {
                  this.setState({
                    generalInfoEdit: false,
                    generalInformations: this.state.editedGeneralInformations,
                  });
                  this.parentMustReload();
                });
                break;
              case "site":
                patchSite(this.state.editedGeneralInformations).then(() => {
                  this.setState({
                    generalInfoEdit: false,
                    generalInformations: this.state.editedGeneralInformations,
                  });
                  this.parentMustReload();
                });
                break;
              case "todo":
                if (this.props.generalInfos.id) {
                  let payload = this.state.editedGeneralInformations;
                  if (payload.status === "100" && this.state.generalInformations.status !== 100) {
                    payload.CloserId = store().getUser().userId;
                    payload.closingDate = moment();
                  }
                  if (payload.status < 100 && this.state.generalInformations.status === 100) {
                    console.log("unfinishing task");
                    payload.CloserId = null;
                    payload.closingDate = null;
                  }
                  patchTodo(payload).then((todo) => {
                    todo.json().then((result) => {
                      this.setState({
                        generalInfoEdit: false,
                        generalInformations: payload,
                      });
                      this.parentMustReload(result.id);
                    });
                  });
                } else {
                  let custOk = false;
                  let respOk = false;
                  //let deadOk = false;
                  let taskOk = false;
                  let payload = this.state.editedGeneralInformations;
                  if (payload.status === "100") {
                    payload.CloserId = store().getUser().userId;
                    payload.closingDate = moment();
                  }
                  if (payload.CustomerId || this.props.currentCustomer) {
                    custOk = true;
                  } else {
                    this.setState({ warnCustomer: true });
                  }
                  if (payload.ResponsibleId) {
                    respOk = true;
                  } else {
                    this.setState({ warnResponsible: true });
                  }
                  //if (this.state.editedGeneralInformations.deadline) { deadOk = true } else { this.setState({ warnDeadline: true }) }
                  if (payload.task) {
                    taskOk = true;
                  } else {
                    this.setState({ warnTask: true });
                  }
                  if (custOk && respOk && /* deadOk && */ taskOk) {
                    postCustomerTodo(payload).then((postedTodo) => {
                      this.setState({
                        generalInfoEdit: false,
                        generalInformations: payload,
                      });
                      postedTodo.json().then((todo) => {
                        if (todo.id) {
                          this.parentMustReload(todo.id);
                        }
                      });
                    });
                  }
                }
                break;
              default:
                break;
            }
          }}
        >
          <i className="far fa-save fa-pull-right"></i>
        </button>
      ) : (
        <button className="btn btn-secondary btn" disabled>
          <i className="far fa-save fa-pull-right"></i>
        </button>
      );
    };
    return (
      <div className="btn-group">
        <button
          disabled={!this.state.generalInformations.id && this.infoType === "todo"}
          className="btn btn-secondary btn"
          onClick={(e) => {
            e.preventDefault();
            this.setState((state) => {
              return {
                generalInfoEdit: !state.generalInfoEdit,
                editedGeneralInformations: state.generalInformations,
              };
            });
          }}
        >
          <i className={this.state.generalInfoEdit ? "fas fa-undo" : "fas fa-edit"}></i>
        </button>
        {renderSaveButton()}
        {this.props.infoType === "todo" &&
        this.state.editedGeneralInformations.status !== 100 &&
        this.props.generalInfos.id &&
        !this.state.generalInfoEdit ? (
          <button
            className="btn btn-secondary btn"
            onClick={(e) => {
              e.preventDefault();
              if (this.props.generalInfos.id) {
                let payload = this.state.editedGeneralInformations;
                payload.status = 100;
                payload.CloserId = store().getUser().userId;
                payload.closingDate = moment();
                patchTodo(payload).then((todo) => {
                  todo.json().then((result) => {
                    this.setState({
                      generalInfoEdit: false,
                      generalInformations: payload,
                    });
                    this.parentMustReload(result.id);
                  });
                });
              }
            }}
          >
            <i className="fas fa-check"></i>
          </button>
        ) : null}
      </div>
    );
  }

  selectProperInputType(key) {
    switch (key) {
      case "deadline": {
        const warn = this.state.warnDeadline ? "border border-danger" : "";
        console.log(this.state.editedGeneralInformations.deadline);
        return (
          <input
            type="date"
            className={`form-control ${warn}`}
            value={moment(
              this.state.editedGeneralInformations.deadline
                ? moment(this.state.editedGeneralInformations.deadline)
                : moment(this.state.generalInformations.deadline).format("DD/MM/YYYY"),
              "DD/MM/YYYY"
            ).format("YYYY-MM-DD")}
            onChange={(e) => {
              this.setState({
                editedGeneralInformations: {
                  ...this.state.editedGeneralInformations,
                  deadline: moment(e.target.valueAsDate),
                },
              });
            }}
          ></input>
        );
        break;
      }
      case "Customer": {
        const warn = this.state.warnCustomer ? "border border-danger" : "";
        if (!this.props.currentCustomer) {
          const customersOptionsTable = this.props.customers.map((customer) => {
            return { value: customer.id, label: customer.name };
          });
          return (
            <div className={`${warn}`}>
              <Select
                noOptionsMessage={() => {
                  return <label>{Translator("none")}</label>;
                }}
                defaultValue={customersOptionsTable.find(
                  (customer) => customer.value === this.props.generalInfos.Customer.id
                )}
                onChange={(selectedOption) => {
                  this.setState({
                    editedGeneralInformations: {
                      ...this.state.editedGeneralInformations,
                      CustomerId: selectedOption.value,
                    },
                  });
                }}
                options={customersOptionsTable}
              />
            </div>
          );
        } else {
          return (
            <span className="text-muted">
              {Translator(
                String(
                  JSON.stringify(
                    this.props.customers.find((customer) => customer.id === this.props.currentCustomer).name
                  )
                )
              )}
            </span>
          );
        }
      }
      case "description":
        return (
          <RichTextEditor
            title={Translator("description")}
            defaultValue={this.state.editedGeneralInformations.description}
            onChange={(content) => {
              this.setState({
                editedGeneralInformations: { ...this.state.editedGeneralInformations, description: content },
              });
            }}
            developped
          ></RichTextEditor>
        );

      case "author":
        return this.props.generalInfos.authorId;
      case "responsible": {
        const warn = this.state.warnResponsible ? "border border-danger" : "";
        const userOptionTable = this.props.users.map((user) => {
          return { value: user.id, label: user.shortName };
        });
        return (
          <div className={`${warn}`}>
            <Select
              defaultValue={userOptionTable.find((user) => user.value === this.props.generalInfos.responsibleId)}
              noOptionsMessage={() => {
                return <label>{Translator("none")}</label>;
              }}
              onChange={(selectedOption) => {
                this.setState({
                  editedGeneralInformations: {
                    ...this.state.editedGeneralInformations,
                    ResponsibleId: selectedOption.value,
                  },
                });
              }}
              options={userOptionTable}
            />
          </div>
        );
      }
      case "status":
        return (
          <div className="mx-3">
            <div className="row justify-content-md-center">
              <span className="badge">
                {this.state.editedGeneralInformations.status
                  ? this.state.editedGeneralInformations.status
                  : this.props.generalInfos.status}
              </span>
            </div>
            <div className="row  justify-content-md-center">
              <input
                type="range"
                defaultValue={this.state.generalInformations.status}
                className="custom-range"
                id="customRange1"
                onChange={(e) => {
                  this.setState({
                    editedGeneralInformations: { ...this.state.editedGeneralInformations, status: e.target.value },
                  });
                }}
              ></input>
            </div>
          </div>
        );
      case "start":
        console.log(this.state.editedGeneralInformations.start);
        return (
          <input
            type="date"
            className="form-control"
            value={moment(
              this.state.editedGeneralInformations.start
                ? moment(this.state.editedGeneralInformations.start)
                : moment(this.state.generalInformations.start, "DD/MM/YYYY").format("DD/MM/YYYY"),
              "DD/MM/YYYY"
            ).format("YYYY-MM-DD")}
            onChange={(e) => {
              this.setState({
                editedGeneralInformations: {
                  ...this.state.editedGeneralInformations,
                  start: moment(e.target.valueAsDate).toISOString(),
                },
              });
            }}
          ></input>
        );
        break;

      case "warranty":
        console.log(this.state.editedGeneralInformations.warranty);
        return (
          <input
            type="date"
            className="form-control"
            value={moment(
              this.state.editedGeneralInformations.warranty
                ? moment(this.state.editedGeneralInformations.warranty)
                : moment(this.state.generalInformations.warranty, "DD/MM/YYYY").format("DD/MM/YYYY"),
              "DD/MM/YYYY"
            ).format("YYYY-MM-DD")}
            onChange={(e) => {
              this.setState({
                editedGeneralInformations: {
                  ...this.state.editedGeneralInformations,
                  warranty: moment(e.target.valueAsDate).toISOString(),
                },
              });
            }}
          ></input>
        );
        break;
      case "country":
        return (
          <select
            className="custom-select"
            key={key}
            defaultValue={this.state.generalInformations[key]}
            onChange={(e) => {
              this.setState({
                editedGeneralInformations: { ...this.state.editedGeneralInformations, country: e.target.value },
              });
            }}
          >
            <option value="belgique">{Translator("belgique")}</option>
            <option value="france">{Translator("france")}</option>
            <option value="luxembourg">{Translator("luxembourg")}</option>
            <option value="suisse">{Translator("suisse")}</option>
            <option value="italie">{Translator("italie")}</option>
            <option value="allemagne">{Translator("allemagne")}</option>
            <option value="nederland">{Translator("nederland")}</option>
            <option value="grande-bretagne">{Translator("grande-bretagne")}</option>
            <option value="espagne">{Translator("espagne")}</option>
          </select>
        );
      case "phone":
        return (
          <input
            type="tel"
            className="form-control"
            key={key}
            defaultValue={this.state.generalInformations[key]}
            onChange={(e) => {
              const newValue = e.target.value.match(/[0-9+ ]/gi);
              e.target.value = newValue ? newValue.toString().replace(/,/gi, "") : "";
              this.setState({
                editedGeneralInformations: { ...this.state.editedGeneralInformations, phone: e.target.value },
              });
            }}
          ></input>
        );
      case "type":
        if (this.state.generalInformations.type !== "supplier") {
          return (
            <select
              className="custom-select"
              key={key}
              defaultValue={this.state.generalInformations[key]}
              onChange={(e) => {
                this.setState({
                  editedGeneralInformations: { ...this.state.editedGeneralInformations, type: e.target.value },
                });
              }}
            >
              <option value="hopital">{Translator("hopital")}</option>
              <option value="hotel">{Translator("hotel")}</option>
              <option value="blanchisserie">{Translator("blanchisserie")}</option>
              <option value="pressing">{Translator("pressing")}</option>
              <option value="industrie">{Translator("industrie")}</option>
            </select>
          );
        } else {
          return this.state.generalInformations.type;
        }
        break;
      case "contract":
        if (this.state.generalInformations.type !== "supplier") {
          return (
            <select
              className="custom-select"
              key={key}
              defaultValue={this.state.generalInformations[key]}
              onChange={(e) => {
                this.setState({
                  editedGeneralInformations: { ...this.state.editedGeneralInformations, contract: e.target.value },
                });
              }}
            >
              <option value="omnium">{Translator("omnium")}</option>
              <option value="basic1">{Translator("basic1")}</option>
              <option value="basic2">{Translator("basic2")}</option>
              <option value="warranty">{Translator("warranty")}</option>
              <option value="conveyor">{Translator("conveyor")}</option>
              <option value="none">{Translator("none")}</option>
            </select>
          );
        } else {
          return Translator("na");
        }
        break;
      case "activeClient":
        return (
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              defaultChecked={this.state.generalInformations[key]}
              key={key}
              onChange={(e) => {
                console.log(e.target.checked);
                this.setState({
                  editedGeneralInformations: {
                    ...this.state.editedGeneralInformations,
                    activeClient: e.target.checked,
                  },
                });
              }}
            ></input>
            <label className="form-check-label" htmlFor={[key]}>
              {Translator(key)}
            </label>
          </div>
        );
      default: {
        const warn = this.state.warnTask && key === "task" ? "border border-danger" : "";
        return (
          <input
            type="text"
            className={`form-control ${warn}`}
            key={key}
            defaultValue={this.state.generalInformations[key]}
            onChange={(e) => {
              this.setState({
                editedGeneralInformations: { ...this.state.editedGeneralInformations, [key]: e.target.value },
              });
            }}
          ></input>
        );
      }
    }
  }
  renderGeneralInformations() {
    const renderHtml = (html) => {
      return { __html: html };
    };
    if (this.state.generalInformations !== null) {
      return Object.keys(this.state.generalInformations).map((key) => {
        if (
          key !== "id" &&
          key !== "authorId" &&
          key !== "createdAt" &&
          key !== "closerId" &&
          key !== "responsibleId" &&
          key !== "CustomerId"
        ) {
          return (
            <span key={key}>
              {this.state.generalInformations[key] || this.state.generalInformations[key] === 0 ? (
                <dt>{Translator(key)}</dt>
              ) : this.state.generalInfoEdit && key !== "closingDate" && key !== "author" ? (
                <dt>{Translator(key)}</dt>
              ) : null}
              <dd>
                {this.state.generalInfoEdit &&
                key !== "closingDate" &&
                key !== "author" &&
                key !== "closer" &&
                key !== "updatedAt" ? (
                  this.selectProperInputType(key)
                ) : this.state.generalInformations[key] || this.state.generalInformations[key] === 0 ? (
                  key === "description" ? (
                    <span
                      className="text-muted"
                      dangerouslySetInnerHTML={renderHtml(this.state.generalInformations[key])}
                    ></span>
                  ) : (
                    <span className="text-muted">{Translator(this.state.generalInformations[key])}</span>
                  )
                ) : null}
              </dd>
            </span>
          );
        }
        return null;
      });
    }
  }
  render() {
    return (
      <div className="fadeIn animated">
        <Card type="secondary" title={Translator("general informations")} tools={this.renderEditButton()}>
          <dl>{this.renderGeneralInformations()}</dl>
        </Card>
      </div>
    );
  }
}

export default GeneralInfos;
