import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import {
    parseForTable,
    store,
    Translator
} from "../../tools/Tools";
import Card from "../../components/Card";
import BSTable from "../../components/BSTable";
import ModuleCreationForm from "./ModuleCreationForm";
import ChecklistTypeCreationForm from "./ChecklistTypeCreationForm";
import TaskCreationForm from "./TaskCreationForm";
import MachineTypeCreationForm from "./MachineTypeCreationForm";
import { fetchChecklistModules, fetchChecklistTasks, fetchChecklistTypes, fetchMachineTypes, postChecklistModule, postChecklistTask, postChecklistType, postMachineType, patchMachineType, patchChecklistTask, deleteChecklistTask, deleteMachineType, deleteChecklistType, patchChecklistType, patchChecklistModule } from "../../tools/Api_Checklists";
import { v4 as uuidv4 } from 'uuid';
import toastr from "toastr";



class Checklists extends Component {
    //#region lifecycle methods
    constructor(props) {
        super(props);
        this.state = {
            machineTypes: { columns: [], data: [] },
            checklistTypes: { columns: [], data: [] },
            checklistTasks: { columns: [], data: [] },
            filteredChecklistTasks: { columns: [], data: [] },
            usedTasks: [],
            checklistModules: { columns: [], data: [] },
            modulesTableKey: uuidv4(),
            machineTypesTableKey: uuidv4(),
            checklistTypesTableKey: uuidv4(),
            tasksTableKey: uuidv4(),
            showUsedTasks: false
        };
    }
    componentDidMount() {
        this.reload();
    }
    //#endregion

    //#region loading and parsing datas from API into component state
    reload() {
        console.log("Reloading")
        this.fetchMachineTypes();
        this.fetchChecklistTypes();
        this.fetchChecklistTasks();
        this.fetchChecklistModules();
    }

    fetchMachineTypes() {
        fetchMachineTypes().then(machineTypes => {
            const columns = []
            if (machineTypes !== undefined && machineTypes.length > 0) {
                for (let i = 0; i < 1; i++) {
                    Object.keys(machineTypes[0]).forEach(col => {
                        columns.push({ dataField: col })
                    })
                }
            }
            const parsed = parseForTable({ columns: columns, data: machineTypes })
            this.setState({ machineTypes: parsed })
        })
    }
    fetchChecklistTypes() {
        fetchChecklistTypes().then(checklistTypes => {
            const columns = []
            if (checklistTypes !== undefined && checklistTypes.length > 0) {
                for (let i = 0; i < 1; i++) {
                    Object.keys(checklistTypes[0]).forEach(col => {
                        columns.push({ dataField: col })
                    })
                }
            }
            const parsed = parseForTable({ columns: columns, data: checklistTypes })
            this.setState({ checklistTypes: parsed })
        })
    }

    fetchChecklistTasks() {
        fetchChecklistTasks().then(fetchedChecklistTasks => {
            const columns = []
            let checklistTasks = fetchedChecklistTasks.map(task => {
                task.ChecklistTypes = task.ChecklistTypes.map(type => type.id)
                return task
            })

            if (checklistTasks !== undefined && checklistTasks.length > 0) {
                for (let i = 0; i < 1; i++) {
                    Object.keys(checklistTasks[0]).forEach(col => {
                        columns.push({ dataField: col, hidden: (col === "ChecklistTaskAnswers" || col === "ChecklistTypes") })
                    })
                }
            }
            const parsed = parseForTable({ columns: columns, data: checklistTasks })

            this.setState({ filteredChecklistTasks: parsed, checklistTasks: parsed, tasksTableKey: uuidv4() })
        })
    }

    fetchChecklistModules() {
        fetchChecklistModules().then(checklistModules => {
            const columns = []
            let usedTasks = []
            const typesFormatter = (cell, row) => {
                let returnString = ""
                row.ChecklistTypes.forEach(type => {
                    if (!returnString) {
                        returnString = type.name
                    }
                    else {
                        returnString += ", " + type.name
                    }
                })
                return returnString
            }
            if (checklistModules !== undefined && checklistModules.length > 0) {
                for (let i = 0; i < 1; i++) {
                    Object.keys(checklistModules[0]).forEach(col => {
                        if (col === "MachineType") {
                            columns.push({ dataField: "MachineType.name", text: "machineType" })
                        } else if (col === "ChecklistTypes") {
                            columns.push({ dataField: "ChecklistTypes", formatter: typesFormatter })
                        } else {
                            columns.push({ dataField: col, hidden: col === "ChecklistTasks" || col === "MachineTypeId" })
                        }
                    })
                }
                for (let i = 0; i < checklistModules.length; i++) {
                    for (let j = 0; j < checklistModules[i].ChecklistTasks.length; j++) {
                        if (!usedTasks.includes(checklistModules[i].ChecklistTasks[j].id)) {
                            usedTasks.push(checklistModules[i].ChecklistTasks[j].id)
                        }
                    }
                }
                console.log(usedTasks)
            }
            const parsed = parseForTable({
                columns: columns, data: checklistModules
            })
            const filteredParsed = parseForTable({
                columns: this.state.checklistTasks.columns, data: this.state.checklistTasks.data.filter(task =>
                    this.state.showUsedTasks || !usedTasks.includes(task.id))
            })
            this.setState({ checklistModules: parsed, usedTasks: usedTasks, filteredChecklistTasks: filteredParsed })
        })
    }

    //#endregion

    //#region submit datas to API
    submitModule(moduleToSubmit, editingMode) {
        if (editingMode) {
            return patchChecklistModule(moduleToSubmit).then(result => {
                if (result) {
                    $('#modal-module').modal('hide')
                    this.reload();
                }
                return result;
            })
        } else {
            return postChecklistModule(moduleToSubmit).then(result => {
                if (result) {
                    $('#modal-module').modal('hide')
                    this.reload();
                }
                return result;
            })
        }
    }

    submitTask(taskToSubmit, editingMode) {
        if (editingMode) {
            return patchChecklistTask(taskToSubmit).then(result => {
                if (result) {
                    this.cancelTaskCreation()
                    $('#modal-task').modal('hide')
                    this.reload();
                }
                return result;
            })
        } else {
            return postChecklistTask(taskToSubmit).then(result => {
                if (result) {
                    this.cancelTaskCreation()
                    $('#modal-task').modal('hide')
                    this.reload();
                }
                return result;
            })
        }
    }

    submitMachineType(machineTypeToSubmit, editingMode) {
        if (editingMode) {
            return patchMachineType(machineTypeToSubmit).then(result => {
                if (result) {
                    this.cancelMachineTypeCreation();
                    $('#modal-machine-type').modal('hide')
                    this.reload();
                }

                return result;
            })
        } else {
            return postMachineType(machineTypeToSubmit).then(result => {
                if (result) {
                    this.cancelMachineTypeCreation();
                    $('#modal-machine-type').modal('hide')
                    this.reload();
                }

                return result;
            })
        }
    }

    submitChecklistType(checklistTypeToSubmit, editingMode) {
        if (editingMode) {
            return patchChecklistType(checklistTypeToSubmit).then(result => {
                if (result) {
                    this.cancelChecklistTypeCreation();
                    $('#modal-checklist-type').modal('hide')
                    this.reload();
                }
                return result;
            })
        } else {
            return postChecklistType(checklistTypeToSubmit).then(result => {
                if (result) {
                    $('#modal-checklist-type').modal('hide')
                    this.reload();
                }
                return result;
            })
        }
    }
    //#endregion

    //#region for api delete methods
    handleDeleteChecklistTask(id) {
        deleteChecklistTask(id).then((result) => {
            this.reload();
        })
    }

    handleDeleteMachineType(id) {
        deleteMachineType(id).then((result) => {
            this.reload();
        })
    }

    handleDeleteChecklistType(id) {
        deleteChecklistType(id).then((result) => {
            this.reload();
        })
    }
    //#endregion

    //#region handlers for BSTables buttons

    /*
    * Handler methods for Modules table
    */

    handleCreateModuleButton(e) {
        e.preventDefault();
        console.log("creating module")
        $('#modal-module').modal('show');
        this.setState({ moduleCreatingMode: true, moduleEditingMode: false, moduleSelected: null, modulesTableKey: uuidv4() })
    }

    deleteModuleCallback() {

    }

    handleEditModuleButton(e) {
        e.preventDefault();
        console.log("editing module")
        $('#modal-module').modal('show');
        this.setState({ moduleCreatingMode: false, moduleEditingMode: true })
    }

    moduleRowEvents = {
        onClick: (e, row, rowIndex) => {
            console.log("click event")
            e.preventDefault();
            if (this.state.moduleSelected !== row) {
                this.setState({ moduleSelected: row })
            } else {
                this.setState({ moduleSelected: null })
            }
        },
        onDoubleClick: (e, row, rowIndex) => {
            console.log("dbclick event")
            if (this.state.moduleSelected === row) {
                $('#modal-module').modal('show');
                this.setState({ moduleCreatingMode: false, moduleEditingMode: true })
                console.log("doubleclick " + this.state.moduleSelected)
            } else {
                this.setState({ moduleSelected: row })
                $('#modal-module').modal('show');
                this.setState({ moduleCreatingMode: false, moduleEditingMode: true })
            }
        }
    };

    /*
    * Handler methods for Machines Types table
    */

    handleCreateMachineTypeButton(e) {
        e.preventDefault();
        this.setState({ machineTypeCreatingMode: true, machineTypeEditingMode: false, machineTypeSelected: null, machineTypesTableKey: uuidv4() })
        $('#modal-machine-type').modal('show');
    }
    deleteMachineTypeCallback() {
        //REMOVE FROM DB YET TO IMPLEMENT
        this.handleDeleteMachineType(this.state.machineTypeSelected.id)
        //Visually remove selected machine type from table
        const newMachineTypeList = {
            data: this.state.machineTypes.data.filter(task => task.id !== this.state.machineTypeSelected.id), columns: this.state.machineTypes.columns
        }
        this.setState({ machineTypes: newMachineTypeList })
    }

    handleEditMachineTypeButton() {
        console.log(this.state.machineTypeSelected)
        if (this.state.machineTypeSelected) {
            $('#modal-machine-type').modal('show');
            this.setState({ machineTypeCreatingMode: false, machineTypeEditingMode: true })
        }
    }
    machineTypeRowEvents = {
        onClick: (e, row, rowIndex) => {
            console.log(row)
            if (this.state.machineTypeSelected === row) {
                this.setState({ machineTypeSelected: null })
            } else {
                this.setState({ machineTypeSelected: row })
            }
        },
    };
    /*
    * Handler methods for Checklist Types table
    */

    handleCreateChecklistTypeButton(e) {
        e.preventDefault();
        $('#modal-checklist-type').modal('show');
        this.setState({ checklistTypeCreatingMode: true, checklistTypeEditingMode: false, selectedChecklistType: null, checklistTypesTableKey: uuidv4() })
    }

    deleteChecklistTypeCallback() {
        //REMOVE FROM DB YET TO IMPLEMENT
        this.handleDeleteChecklistType(this.state.checklistTypeSelected.id)
        //Visually remove selected checklist type from table
        const newChecklistTypeList = {
            data: this.state.checklistTypes.data.filter(task => task.id !== this.state.checklistTypeSelected.id), columns: this.state.checklistTypes.columns
        }
        this.setState({ checklistTypes: newChecklistTypeList })
    }

    handleEditChecklistTypeButton() {
        console.log(this.state.checklistTypeSelected)
        if (this.state.checklistTypeSelected) {
            $('#modal-checklist-type').modal('show');
            this.setState({ checklistTypeCreatingMode: false, checklistTypeEditingMode: true })
        }
    }

    checklistTypeRowEvents = {
        onClick: (e, row, rowIndex) => {
            if (this.state.checklistTypeSelected === row) {
                this.setState({
                    checklistTypeSelected: null, filteredChecklistTasks: {
                        columns: this.state.checklistTasks.columns, data: this.state.checklistTasks.data.filter(task => {
                            return this.state.showUsedTasks || !this.state.usedTasks.includes(task.id)

                        })
                    }
                })
            } else {
                this.setState({
                    checklistTypeSelected: row, filteredChecklistTasks: {
                        columns: this.state.checklistTasks.columns, data: this.state.checklistTasks.data.filter(task => {
                            return (
                                (task.ChecklistTypes.includes(row.id) || task.ChecklistTypes.length === 0) && (this.state.showUsedTasks || !this.state.usedTasks.includes(task.id)))
                        }
                        )
                    }, taskSelected: null, tasksTableKey: uuidv4()
                })
            }
        },
    };

    /*
    * Handler methods for Tasks table
    */

    handleCreateTaskButton(e) {
        e.preventDefault();
        $('#modal-task').modal('show');
        this.setState({ taskCreatingMode: true, taskEditingMode: false, taskSelected: null, tasksTableKey: uuidv4() })
    }
    deleteTaskCallback() {
        this.handleDeleteChecklistTask(this.state.taskSelected.id)
        //Visually remove selected task from table
        const newTaskList = {
            data: this.state.checklistTasks.data.filter(task => task.id !== this.state.taskSelected.id), columns: this.state.checklistTasks.columns
        }
        this.setState({ checklistTasks: newTaskList })
    }

    handleEditTaskButton() {
        if (this.state.taskSelected) {
            $('#modal-task').modal('show');
            this.setState({ taskCreatingMode: false, taskEditingMode: true })
        }
    }
    taskRowEvents = {
        onClick: (e, row, rowIndex) => {
            if (this.state.taskSelected === row) {
                this.setState({ taskSelected: null })
            } else {
                this.setState({ taskSelected: row })
            }
        },
        onDoubleClick: (e, row, rowIndex) => {
            if (this.state.taskSelected === row) {
                $('#modal-task').modal('show');
                this.setState({ taskCreatingMode: false, taskEditingMode: true })
                console.log("doubleclick " + this.state.taskSelected)
            } else {
                this.setState({ taskSelected: row })
                $('#modal-task').modal('show');
                this.setState({ taskCreatingMode: false, taskEditingMode: true })
            }
        }
    };

    //#endregion

    //#region modal rendering methods

    renderNewModuleModal() {
        return (
            <div
                className="modal fade"
                id="modal-module"
                data-backdrop="static"
                tabIndex="-1"
                role="dialog"
                data-keyboard="false"
                aria-labelledby="staticBackdropLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-xl" role="document">
                    <ModuleCreationForm
                        tasks={this.state.checklistTasks}
                        machineTypes={this.state.machineTypes.data}
                        checklistTypes={this.state.checklistTypes.data}
                        payload={this.state.moduleEditingMode ? this.state.moduleSelected : null}
                        creatingMode={this.state.moduleCreatingMode}
                        editingMode={this.state.moduleEditingMode}
                        cancelModuleCreation={this.cancelModuleCreation.bind(this)}
                        submitCallback={this.submitModule.bind(this)}
                    ></ModuleCreationForm>
                </div>
            </div>
        );
    }
    renderNewMachineTypeModal() {
        return (
            <div
                className="modal fade"
                id="modal-machine-type"
                data-backdrop="static"
                tabIndex="-1"
                data-keyboard="false"
                role="dialog"
                aria-labelledby="staticBackdropLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-xl" role="document">
                    <MachineTypeCreationForm
                        selectedMachineType={this.state.machineTypeSelected}
                        cancelMachineTypeCallback={this.cancelMachineTypeCreation.bind(this)}
                        submitCallback={this.submitMachineType.bind(this)}
                        editingMode={this.state.machineTypeEditingMode}
                    >
                    </MachineTypeCreationForm>
                </div>
            </div>
        );
    }
    renderNewChecklistTypeModal() {
        return (
            <div
                className="modal fade"
                id="modal-checklist-type"
                data-backdrop="static"
                tabIndex="-1"
                data-keyboard="false"
                role="dialog"
                aria-labelledby="staticBackdropLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-xl" role="document">
                    <ChecklistTypeCreationForm
                        selectedChecklistType={this.state.checklistTypeSelected}
                        cancelChecklistTypeCallback={this.cancelChecklistTypeCreation.bind(this)}
                        submitCallback={this.submitChecklistType.bind(this)}
                        editingMode={this.state.checklistTypeEditingMode}
                    >
                    </ChecklistTypeCreationForm>
                </div>
            </div>
        );
    }
    renderNewTaskModal() {
        return (
            <div
                className="modal fade"
                id="modal-task"
                data-backdrop="static"
                tabIndex="-1"
                data-keyboard="false"
                role="dialog"
                aria-labelledby="staticBackdropLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-xxl" role="document"
                >
                    <TaskCreationForm
                        selectedTask={this.state.taskSelected}
                        checklistTypes={this.state.checklistTypes.data}
                        cancelTaskCallback={this.cancelTaskCreation.bind(this)}
                        modules={this.state.checklistModules.data}
                        submitCallback={this.submitTask.bind(this)}
                        editingMode={this.state.taskEditingMode}
                    ></TaskCreationForm>
                </div>
            </div>
        );
    }

    //#endregion

    //#region cancel creating modes callbacks


    cancelModuleCreation() {
        this.setState({ moduleCreatingMode: false, moduleEditingMode: false, moduleSelected: null, modulesTableKey: uuidv4() })
    }
    cancelChecklistTypeCreation() {
        this.setState({ checklistTypeCreatingMode: false, checklistTypeEditingMode: false, checklistTypeSelected: null, checklistTypesTableKey: uuidv4() })
    }
    cancelTaskCreation() {
        this.setState({ taskCreatingMode: false, taskEditingMode: false, taskSelected: null, tasksTableKey: uuidv4() })
    }
    cancelMachineTypeCreation() {
        this.setState({ machineTypeCreatingMode: false, machineTypeEditingMode: false, machineTypeSelected: null, machineTypesTableKey: uuidv4() })
    }

    //#endregion


    renderSelectedModule() {
        //<div style={{ top: "4em", position: "sticky" }}>
        return <div style={{ top: "4em", position: "sticky" }}>
            <Card title={Translator("selected module")}>
                {this.state.moduleSelected ? <div>
                    <ul>
                        <li>
                            <h4>{this.state.moduleSelected.name}</h4>
                        </li>
                        <hr></hr>
                        <li>
                            <h5>{Translator("for machine type :")}<br></br></h5>
                            <ul><li>{this.state.moduleSelected.MachineType.name}</li></ul>
                        </li>
                        <li>
                            <h5>{Translator("sorting order :")}<br></br></h5>
                            <ul><li>{this.state.moduleSelected.sortingOrder}</li></ul>
                        </li>
                        <hr></hr>
                        <li>
                            <h5>{Translator("applicable checklist types :")}</h5>
                            <ul>
                                {this.state.moduleSelected.ChecklistTypes.length > 0 ?
                                    this.state.moduleSelected.ChecklistTypes.map(type => {
                                        return (
                                            <li key={type.id}>
                                                {type.name}
                                            </li>
                                        );
                                    }) :
                                    <li>
                                        {Translator("none (that's not normal !)")}
                                    </li>}
                            </ul>
                        </li>
                        <hr></hr>
                        <li>
                            <h5>{Translator("tasks in this module :")}</h5>
                            <ul>
                                {this.state.moduleSelected && this.state.moduleSelected.ChecklistTasks.length > 0 ?
                                    this.state.moduleSelected.ChecklistTasks.sort((a, b) => {
                                        if (a.sortingOrder > b.sortingOrder)
                                            return 1
                                        if (a.sortingOrder < b.sortingOrder)
                                            return -1
                                        return 0
                                    })
                                        .map(task => {
                                            return (
                                                <li key={task.ChecklistModulesTasks.ChecklistTaskId}>
                                                    {Translator(task.taskText)}
                                                </li>
                                            );
                                        }) :
                                    <li>
                                        {Translator("none (that's not normal !)")}
                                    </li>}
                            </ul>
                        </li>
                    </ul>
                </div> : null}
            </Card>
        </div>
    }

    //#endregion

    render() {
        moment.locale(store().getLanguage());
        return (
            <div>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">
                                    <i className="fa-solid fa-clipboard-list"></i>&nbsp;
                                    {Translator("Checklists management")}
                                </h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <Link to={{ pathname: "../", state: this.props.history.state }}>{Translator("Home")}</Link>
                                    </li>
                                    <li className="breadcrumb-item active">{Translator("Checklists management")}</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid">
                        {this.renderNewModuleModal()}
                        {this.renderNewChecklistTypeModal()}
                        {this.renderNewMachineTypeModal()}
                        {this.renderNewTaskModal()}
                        <div className="row">
                            <div className="col" key="machineType">
                                <BSTable
                                    key={this.state.machineTypesTableKey}
                                    title={Translator("machine type")}
                                    theme={"warning"}
                                    columns={this.state.machineTypes.columns}
                                    data={this.state.machineTypes.data}
                                    rowEvents={this.machineTypeRowEvents}
                                    creatingMode={this.state.machineTypeCreatingMode}
                                    cud={{ c: this.handleCreateMachineTypeButton.bind(this), u: this.state.machineTypeSelected ? true : false, d: true }}
                                    isFetching={this.state.isFetching}
                                    deleteCallback={this.deleteMachineTypeCallback.bind(this)}
                                    handleEdit={this.handleEditMachineTypeButton.bind(this)} //should be a function to edit
                                    selected={[]}
                                    clickToSelect
                                    updateFromProps
                                    paginate />
                            </div>
                            <div className="col-6" key="checklistType">
                                <BSTable
                                    key={this.state.checklistTypesTableKey}
                                    title={Translator("checklist types")}
                                    theme={"olive"}
                                    columns={this.state.checklistTypes.columns}
                                    data={this.state.checklistTypes.data}
                                    rowEvents={this.checklistTypeRowEvents}
                                    creatingMode={this.state.checklistTypeCreatingMode}
                                    cud={{ c: this.handleCreateChecklistTypeButton.bind(this), u: this.state.checklistTypeSelected ? true : false, d: true }}
                                    isFetching={this.state.isFetching}
                                    deleteCallback={this.deleteChecklistTypeCallback.bind(this)}
                                    handleEdit={this.handleEditChecklistTypeButton.bind(this)} //should be a function to edit
                                    clickToSelect
                                    updateFromProps
                                    paginate />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-7" key="modules">
                                <BSTable
                                    theme={"info"}
                                    key={this.state.modulesTableKey}
                                    columns={this.state.checklistModules.columns}
                                    data={this.state.checklistModules.data}
                                    rowEvents={this.moduleRowEvents}
                                    title={Translator("existing modules")}
                                    creatingMode={this.state.moduleCreatingMode}
                                    cud={{ c: this.handleCreateModuleButton.bind(this), u: this.state.moduleSelected ? true : false, d: true }}
                                    isFetching={this.state.isFetching}
                                    deleteCallback={this.deleteModuleCallback.bind(this)}
                                    handleEdit={this.handleEditModuleButton.bind(this)} //should be a function to edit
                                    clickToSelect
                                    updateFromProps
                                    paginate
                                    defaultSorted={[{ dataField: "sortingOrder", order: "asc" }, { dataField: "name", order: "asc" }]} />
                            </div>
                            <div className="col-5">
                                {this.renderSelectedModule()}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12" key="tasks">
                                <BSTable
                                    key={this.state.tasksTableKey}
                                    theme={"orange"}
                                    columns={this.state.checklistTasks.columns}
                                    data={this.state.filteredChecklistTasks.data}
                                    rowEvents={this.taskRowEvents}
                                    title={(() => {
                                        return (
                                            <div className="row">
                                                <div className="col">{Translator("tasks")}</div>
                                                <div className="col">
                                                    < input className="form-check-input" type="checkbox" id="unusedTaskCheckbox" checked={this.state.showUsedTasks} onChange={e => {
                                                        const showUsedTasks = e.target.checked
                                                        this.setState({
                                                            showUsedTasks: !this.state.showUsedTasks,
                                                            filteredChecklistTasks: {
                                                                columns: this.state.checklistTasks.columns, data: this.state.checklistTasks.data.filter(task => {
                                                                    return (this.state.checklistTypeSelected ? task.ChecklistTypes.includes(this.state.checklistTypeSelected.id) : true || task.ChecklistTypes.length === 0) && (showUsedTasks || !this.state.usedTasks.includes(task.id))
                                                                }
                                                                )
                                                            }, taskSelected: null, tasksTableKey: uuidv4()
                                                        })
                                                        //this.reload();
                                                    }}>
                                                    </input>
                                                    <small>
                                                        <label className="form-check-label" htmlFor="unusedTaskCheckbox">
                                                            {Translator("show used tasks")}
                                                        </label>
                                                    </small>
                                                </div>
                                            </div>
                                        )
                                    })()}
                                    creatingMode={this.state.taskCreatingMode}
                                    cud={{ c: this.handleCreateTaskButton.bind(this), u: this.state.taskSelected ? true : false, d: true }}
                                    isFetching={this.state.isFetching}
                                    deleteCallback={this.deleteTaskCallback.bind(this)}
                                    handleEdit={this.handleEditTaskButton.bind(this)} //should be a function to edit
                                    clickToSelect
                                    updateFromProps
                                    paginate
                                    defaultSorted={[{ dataField: "sortingOrder", order: "asc" }, { dataField: "taskText", order: "asc" }]} />
                            </div>
                            {/*<div className={"col-" + (this.state.taskSelected ? 5 : 0)}>
                                {this.renderSelectedTask()}
                            </div>*/}
                        </div>
                    </div>
                </section>
            </div>
        );
    }


}
export default withRouter(Checklists);
