import React from "react";
import { withRouter, useHistory } from "react-router-dom";
import { store, Translator } from "./tools/Tools";
import TicketPillsList from "./pages/Tickets/TicketPillsList";

const Header = (props) => {
  const history = useHistory();

  return (
    <nav
      className="main-header navbar navbar-expand navbar-white navbar-light"
      style={{ top: "0", position: "-webkit-sticky", position: "sticky" }}
    >
      <ul className="navbar-nav">
        <li className="nav-item">
          <span className="nav-link" data-widget="pushmenu">
            <i className="fas fa-bars"></i>
          </span>
        </li>
      </ul>
      <ul className="nav nav-pills">
        <li
          key="fr"
          className={store().getLanguage() === "fr" ? "nav-link active" : "nav-link"}
          onClick={(e) => {
            e.preventDefault();
            store().setLanguage("fr");
            console.log("fr");
            history.push(history.location);
          }}
        >
          fr
        </li>
        <li
          key="nl"
          className={store().getLanguage() === "nl" ? "nav-link active" : "nav-link"}
          onClick={(e) => {
            e.preventDefault();
            store().setLanguage("nl");
            console.log("nl");
            history.push(history.location);
          }}
        >
          nl
        </li>
      </ul>
      {store().getLogged() ? <TicketPillsList></TicketPillsList> : null}

      <ul className="navbar-nav ml-auto">
        <li>
          {store().getLogged() ? (
            <div>
              <button className="btn btn-secondary btn " onClick={props.logFunction}>
                <i className="fas fa-sign-out-alt"></i>{Translator("logout")}
              </button>
            </div>
          ) : (
            <i className="fas fa-journal-whills"> EB - Agenda </i>
          )}
        </li>

      </ul>
    </nav >
  );
};

export default withRouter(Header);
