import React, { useEffect, useRef } from "react";
import { useDrag } from "react-dnd";


const ProjectWidget = (props) => {
    const [{ isDragging }, drag] = useDrag(() => {
        return {
            item: { id: props.projectPeriod.id },
            type: "assignedTicketWidget",
            end: (item, monitor) => {
                const dropResult = monitor.getDropResult();
                if (item && dropResult) {
                    props.translateProjectPeriod(props.projectPeriod.id, dropResult.date.diff(props.date, 'days'));
                }
            },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
                handlerId: monitor.getHandlerId(),
            }),
        };
    });
    const prevProps = usePrevious(props);

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
            //Bug if this widget previously contained more than 1 projectperiod, it does not handle subsequent drag&drop
            //call a custom method from the parent to force unmounting and remounting
            if (prevProps && (prevProps.hasTwo && !props.hasTwo)) props.refreshOverview();//window.location.reload(false)

        });
        return ref.current;
    }
    return <td ref={drag} style={props.style}>{props.content}</td>;
}


export default ProjectWidget;