import React from 'react';
import { useDrop } from "react-dnd";

const ProjectDropSlot = (props) => {
    const [{ canDrop, isOver }, drop] = useDrop(() => ({
        accept: "assignedTicketWidget",
        drop: (item, monitor) => {
            return ({ date: props.date })
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    }));

    const isActive = canDrop && isOver;
    const dynamicStyle = isActive ? { ...props.style, backgroundColor: "#22DD22" } : {
        ...props.style
    }
    return <td ref={drop} style={dynamicStyle}>{props.content}</td>;
}

export default ProjectDropSlot;