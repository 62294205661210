// globals.js
import React from "react";
import ReactDOM from "react-dom";
import jquery from "jquery";

window.React = React;
window.ReactDOM = ReactDOM;
window.jQuery = jquery;
window.jquery = jquery;
window.$ = jquery;
